import {FunctionComponent} from 'react';

import {Box, Typography} from '@mui/material';

import {Event} from './Event';
import type {Props} from './definitions';
import useStyle from './styles';

const EventLocation: FunctionComponent<Props> = ({events}) => {
  const {eventLocationContainerBox, eventLocation, eventLocationEventsBox} =
    useStyle();

  if (events.length > 0) {
    return (
      <Box sx={eventLocationContainerBox}>
        <Typography variant={'titleAktive2'} sx={eventLocation}>
          {events[0].location.name}
        </Typography>

        <Box sx={eventLocationEventsBox}>
          {events.map(event => (
            <Event key={event.id} event={event} />
          ))}
        </Box>
      </Box>
    );
  }

  return null;
};

export default EventLocation;
