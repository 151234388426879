import {useEffect} from 'react';
import {useMatch} from 'react-router-dom';

import {AnonymousRoutes, AuthRoutes} from '@/navigation/routes';

export const useOnPageFocused = (
  route: AuthRoutes | AnonymousRoutes,
  fn: () => void,
) => {
  const isCurrent = useMatch(route);

  useEffect(() => {
    if (isCurrent) {
      fn();
    }
  }, [fn, isCurrent]);
};
