import {useMemo} from 'react';

import {SxProps} from '@mui/material';

export const useStyle = () => {
  const container: SxProps = useMemo(
    (): SxProps => ({
      marginBottom: '50px',
    }),
    [],
  );

  const content: SxProps = useMemo(
    (): SxProps => ({
      minHeight: '85vh',
    }),
    [],
  );

  return {
    container,
    content,
  };
};
