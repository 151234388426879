import {useMemo} from 'react';

import {SxProps} from '@mui/material';

import {theme} from '@/themes/default';

import {IPropsStyle} from './type';

export const useStyle = (): IPropsStyle => {
  const modalContainerBox: SxProps = useMemo(
    (): SxProps => ({
      width: 'calc(100vw - 100px)', // 110 = 20*2 padding + 30*2 boxshadow
      [theme.breakpoints.up('md')]: {
        width: '450px',
      },
    }),
    [],
  );

  const titleBox: SxProps = useMemo(
    (): SxProps => ({
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '24px',
      [theme.breakpoints.up('md')]: {
        marginBottom: '40px',
      },
    }),
    [],
  );

  const title: SxProps = useMemo(
    (): SxProps => ({
      marginBottom: '16px',
    }),
    [],
  );

  const inputPhoneNumber: SxProps = useMemo(
    (): SxProps => ({
      marginBottom: '24px',
    }),
    [],
  );
  const formControl: SxProps = useMemo(
    (): SxProps => ({
      marginBottom: '24px',
      marginRight: 0,
      a: {
        textDecoration: 'underline',
      },
      [theme.breakpoints.up('md')]: {
        marginBottom: '48px',
      },
    }),
    [],
  );

  const formControlContainer: SxProps = useMemo(
    (): SxProps => ({
      width: '100%',
    }),
    [],
  );

  return {
    modalContainerBox,
    titleBox,
    title,
    inputPhoneNumber,
    formControl,
    formControlContainer,
  };
};
