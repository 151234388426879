import React, {
  ChangeEvent,
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {useLocation, useParams} from 'react-router-dom';

import {Box, TextField, Typography} from '@mui/material';
import {useTranslations} from '@vidiemme/react-i18n';

import {Button} from '@/atoms/Button';
import {useToast} from '@/hooks/toast';
import {useNavigation} from '@/navigation/useNavigation';
import {usePutSlot} from '@/store/slots';
import {Modal} from '@/templates/Modal';

import {useStyle} from './style';

const EventSlotEdit: FunctionComponent = () => {
  const {showSuccessToast, showErrorToast} = useToast(); // Get functions for displaying success and error toasts

  const {
    disableSlotBoxContainer,
    disableSlotTextBox,
    disableSlotTitle,
    disableSlotButtonsBox,
    disableSlotButton,
  } = useStyle(); // Get style-related functions

  const {t} = useTranslations(); // Get the translation function
  const {goToPreviousPage, goToEventCalendar} = useNavigation(); // Get the function for navigating back
  const {id: eventId, slotId} = useParams();
  const {
    state: {isEnabled},
  } = useLocation();

  const [slotSize, setSlotSize] = useState<number>(0);

  const cancelButtonHandler = goToPreviousPage;

  const successButtonHandler = useCallback(() => {
    if (eventId) {
      goToEventCalendar(parseInt(eventId));
    }
  }, [eventId, goToEventCalendar]);

  const {
    loading: loadingPutSlot,
    error: errorPutSlot,
    response: responsePutSlot,
    run: runPutSlot,
  } = usePutSlot(parseInt(slotId!), {slotLimit: slotSize || 0});

  const disableButtonHandler = useCallback(() => {
    if (runPutSlot) {
      return runPutSlot();
    }
    return null;
  }, [runPutSlot]);

  const setSlotLimitHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSlotSize(parseInt(event.target.value));
    },
    [],
  );

  const disabled = loadingPutSlot || (!isEnabled && slotSize === 0);

  const action = isEnabled ? 'DISABLE' : 'ENABLE';

  useEffect(() => {
    if (errorPutSlot) {
      showErrorToast({message: t('EVENT_SLOT_EDIT.RESPONSE_ERROR')});
    }

    if (responsePutSlot) {
      showSuccessToast({
        message: t('EVENT_SLOT_EDIT.RESPONSE_SUCCESS'),
      });
      successButtonHandler();
    }
  }, [
    cancelButtonHandler,
    errorPutSlot,
    goToPreviousPage,
    responsePutSlot,
    showErrorToast,
    showSuccessToast,
    successButtonHandler,
    t,
  ]);

  return (
    <Modal>
      <Box sx={disableSlotBoxContainer}>
        <Box>
          <Typography variant={'titleAktive1'} sx={disableSlotTitle}>
            {t(`EVENT_SLOT_EDIT.${action}.MODAL_TITLE`)}
          </Typography>
        </Box>
        <Box sx={disableSlotTextBox}>
          <Typography variant={'bodyRegular'}>
            {t(`EVENT_SLOT_EDIT.${action}.MODAL_TEXT`)}
          </Typography>
        </Box>
        {!isEnabled && (
          <TextField
            onChange={setSlotLimitHandler}
            id="slot_limit"
            label={t(`EVENT_SLOT_EDIT.${action}.SLOT_SIZE`)}
            type="number"
            value={slotSize ? slotSize.toString() : '0'}
          />
        )}
        <Box sx={disableSlotButtonsBox}>
          <Button
            variant={'secondaryBig'}
            color={'default'}
            sx={disableSlotButton}
            onClick={cancelButtonHandler}>
            {t('EVENT_SLOT_EDIT.BUTTON_CANCEL')}
          </Button>
          <Button
            variant={'primaryBig'}
            color={'default'}
            sx={disableSlotButton}
            onClick={disableButtonHandler}
            disabled={disabled}
            loading={loadingPutSlot}>
            {t(`EVENT_SLOT_EDIT.${action}.BUTTON_CONFIRM`)}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default EventSlotEdit;
