import {useCallback, useEffect, useRef} from 'react';

import {FormControl, TextField} from '@mui/material';
import {useTranslations} from '@vidiemme/react-i18n';

import {useStyle} from '@/pages/Users/style';

const TableFilter = ({onChange}: {onChange: (value: string) => void}) => {
  const {t} = useTranslations();
  const {formControlSearchBox} = useStyle();

  const debounceTimer = useRef<NodeJS.Timeout | undefined>();

  const handleChange = useCallback(
    ({target: {value}}: {target: {value: string}}) => {
      if (debounceTimer.current) {
        clearTimeout(debounceTimer.current);
        debounceTimer.current = undefined;
      }
      debounceTimer.current = setTimeout(() => {
        onChange(value);
      }, 500);
    },
    [onChange],
  );

  useEffect(() => {
    return () => {
      if (debounceTimer.current) {
        clearTimeout(debounceTimer.current);
      }
    };
  }, []);

  return (
    <FormControl sx={formControlSearchBox}>
      <TextField
        onChange={handleChange}
        id="standard-search"
        label={t('USER_MANAGEMENT.TABLE_FILTER_LABEL')}
        type="search"
      />
    </FormControl>
  );
};
export default TableFilter;
