import {useMemo} from 'react';

import {SxProps} from '@mui/material';

import {IPropsStyle} from '@/organisms/NavigationBar/type';
import {desktopPadding, mobilePadding, theme} from '@/themes/default';

export const useStyle = (): IPropsStyle => {
  const navbarItem: SxProps = useMemo(
    (): SxProps => ({
      textTransform: 'uppercase',
      width: '100%',
    }),
    [],
  );

  const navbarMenuLogoBox: SxProps = useMemo(
    (): SxProps => ({
      width: '50%',

      [theme.breakpoints.up('xl')]: {
        width: '100%',
      },
    }),
    [],
  );

  const navbarContainer = useMemo(
    () => ({
      backgroundColor: theme.palette.white,
      width: '100%',
      height: '56px',
      borderBottom: '1px solid #E0E0E0',
      boxShadow: 'none',
      justifyContent: 'space-between',
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      padding: `0 ${mobilePadding}`,
      position: 'sticky',
      zIndex: 2,
      [theme.breakpoints.up('lg')]: {
        padding: `0 ${desktopPadding}`,
        height: '89px',
      },
    }),
    [],
  );

  const navbarMenuItemsBox: SxProps = useMemo(
    (): SxProps => ({
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      width: '100%',
      marginRight: '-64px',
      li: {
        ':hover': {
          backgroundColor: 'transparent',
        },
        a: {
          ':hover': {
            backgroundColor: 'white',
            borderBottom: '1px solid black',
          },
          '&.active': {
            borderBottom: '1px solid black',
          },
        },
      },
    }),
    [],
  );

  const navbarLogoutBox: SxProps = useMemo(
    (): SxProps => ({
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      '.logout-box__surname': {
        display: 'none',

        [theme.breakpoints.up('xl')]: {
          display: 'inline',
        },
      },

      li: {
        paddingRight: '0',
        width: '100%',
        justifyContent: 'flex-end',
        ':hover': {
          backgroundColor: 'transparent',
          cursor: 'auto',
        },
      },
    }),
    [],
  );

  const drawerHeader: SxProps = useMemo(
    (): SxProps => ({
      width: '100%',
      height: '56px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingX: mobilePadding,
    }),
    [],
  );

  const drawerBody: SxProps = useMemo(
    (): SxProps => ({
      backgroundColor: theme.palette.gray1,
      height: '100%',
      paddingTop: '32px',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    }),
    [],
  );

  const drawerMenuItemsBox: SxProps = useMemo(
    (): SxProps => ({
      li: {
        paddingY: '0',
        paddingX: mobilePadding,
        a: {
          paddingY: '20px',
          width: '100%',
        },
      },
    }),
    [],
  );

  const drawerLogout: SxProps = useMemo(
    (): SxProps => ({
      backgroundColor: theme.palette.gray1,
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: '32px',
      li: {
        justifyContent: 'center',
        width: '100%',
        paddingX: mobilePadding,
      },
    }),
    [],
  );
  const drawerCTA: SxProps = useMemo(
    (): SxProps => ({
      width: '40px',
      height: '40px',
      minWidth: '40px',
      paddingRight: 0,
    }),
    [],
  );

  return {
    navbarItem,
    navbarContainer,
    navbarMenuItemsBox,
    navbarLogoutBox,
    navbarMenuLogoBox,
    drawerHeader,
    drawerBody,
    drawerMenuItemsBox,
    drawerLogout,
    drawerCTA,
  };
};
