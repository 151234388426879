import {useCallback, useMemo, useState} from 'react';

import {useTranslations} from '@vidiemme/react-i18n';

import useDate from '@/hooks/date';
import useEvent from '@/hooks/event';
import {LoadingMessage} from '@/organisms/LoadingMessage';
import {ResponseErrorMessage} from '@/organisms/ResponseErrorMessage';
import {SelectComponent} from '@/organisms/SelectComponent';
import {IProps} from '@/pages/BookingEdit/interfaces';
import {Presence} from '@/store/presence';
import {useGetSlots} from '@/store/slots/hooks';

const CorporateSelect = ({appointment, setSlotIdHandler}: IProps) => {
  const {t} = useTranslations();
  const {EventPresence} = useEvent();
  const {getAvailableSlots, sortAvailableSlots, getAvailableDateSlots} =
    useDate();

  // Define a query for available slots
  const slotsQuery = useMemo(
    () => ({
      event_day_id: appointment.eventId,
      available: true,
    }),
    [appointment.eventId],
  );

  // Fetch available slots using the query
  const {loading, error, response} = useGetSlots(slotsQuery);

  const [selectedSlotDate, setSelectedSlotDate] = useState(
    appointment.slot?.eventDay.id,
  );

  const [selectedSlotTime, setSelectedSlotTime] = useState(
    appointment.slot?.id,
  );

  const dateSlotChangeHandler = useCallback((el: number | undefined) => {
    setSelectedSlotTime(undefined);
    setSelectedSlotDate(el);
  }, []);

  const timeSlotChangeHandler = useCallback(
    (el: number | undefined) => {
      setSelectedSlotTime(el);
      setSlotIdHandler(el);
    },
    [setSlotIdHandler],
  );

  const [selectPresenceType, setSelectPresenceType] = useState<Presence>(
    EventPresence[appointment.presenceType] as Presence,
  );

  // Handle changes to the selected presence type
  const handleSelectedPresenceType = useCallback(
    (selectPresence: Presence): void => {
      setSelectPresenceType(selectPresence);
    },
    [],
  );

  // Construct the body of the component based on available slots
  const body = useMemo(() => {
    if (appointment.slot && selectedSlotDate) {
      if (response) {
        let slotsAvailable = [...response];

        /* Add the booking slot if there are no available slots or
         if the current slot is full (so the user can book the same slot) */
        if (
          slotsAvailable.findIndex(slot => slot.id === appointment.slot!.id) < 0
        ) {
          slotsAvailable = slotsAvailable.concat([{...appointment.slot}]);
          sortAvailableSlots(slotsAvailable); // sorting slots by date(startTime)
        }
        const availableDateSlotsOptions = getAvailableDateSlots(
          slotsAvailable,
          appointment.slot.eventDay.event.location.timezone,
        );

        const availableTimeSlots = getAvailableSlots(
          slotsAvailable,
          appointment.slot.eventDay.event.location.timezone,
        );

        const availableTimeSlotsOptions = availableTimeSlots[selectedSlotDate]
          ? availableTimeSlots[selectedSlotDate]
          : [];

        return (
          <>
            {/* Select the appointment date */}
            <SelectComponent
              label={t('INVITATIONS.APPOINTMENT_DATE')}
              menuItems={availableDateSlotsOptions}
              startValue={selectedSlotDate}
              handleFunction={dateSlotChangeHandler}
            />
            {/* Select the appointment time */}
            <SelectComponent
              label={t('INVITATIONS.APPOINTMENT_TIME')}
              menuItems={availableTimeSlotsOptions}
              startValue={selectedSlotTime || ''}
              handleFunction={timeSlotChangeHandler}
            />
            {/* Select the appointment presence type */}
            {/*<SelectComponent
              label={t('INVITATIONS.APPOINTMENT_PRESENCE_TYPE')}
              menuItems={Object.values(EventPresence)}
              startValue={selectPresenceType}
              // @ts-ignore
              handleFunction={handleSelectedPresenceType}
            />*/}
          </>
        );
      }
    }
    return null;
  }, [
    appointment.slot,
    dateSlotChangeHandler,
    getAvailableDateSlots,
    getAvailableSlots,
    response,
    selectedSlotDate,
    selectedSlotTime,
    sortAvailableSlots,
    t,
    timeSlotChangeHandler,
  ]);

  // Handle loading and error cases
  if (loading) {
    return <LoadingMessage />;
  }

  if (error) {
    return <ResponseErrorMessage />;
  }

  // Render the component body
  return <>{body}</>;
};

export default CorporateSelect;
