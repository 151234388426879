import {Link} from 'react-router-dom';

import {Breadcrumbs as MUIBreadcrumbs, Stack, Typography} from '@mui/material';

import {useStyle} from './style';
import {IProps} from './type';

const Breadcrumbs = ({breadcrumbs}: IProps) => {
  const {currentBreadcrumb: currentBreadcrumbStyle, separator: separatorStyle} =
    useStyle();

  return (
    <Stack direction="row" alignItems="center">
      <MUIBreadcrumbs separator={'|'} sx={separatorStyle}>
        {breadcrumbs.slice(0, -1).map(({label, uri}) => {
          return (
            <Link key={label} to={uri}>
              <Typography variant={'bodyRegularXSmall'}>{label}</Typography>
            </Link>
          );
        })}
        {breadcrumbs.slice(-1).map(({label}) => {
          return (
            <Typography
              key={label}
              variant={'bodyBoldXSmall'}
              sx={currentBreadcrumbStyle}>
              {label}
            </Typography>
          );
        })}
      </MUIBreadcrumbs>
    </Stack>
  );
};

export default Breadcrumbs;
