// Import the translation hook
import {useCallback, useMemo} from 'react';

import {useTranslations} from '@vidiemme/react-i18n';

import {AuthRoutes} from '@/navigation/routes';
import {useOnPageFocused} from '@/navigation/useOnPageFocused';
// Import the useMemo hook from React
import {LoadingMessage} from '@/organisms/LoadingMessage';
import {MessagePage} from '@/organisms/MessagePage';
import {ResponseErrorMessage} from '@/organisms/ResponseErrorMessage';
import {useGetUserBookings} from '@/store/user';
import {Base} from '@/templates/Base';

import {AppointmentsLocations} from './AppointmentsLocation';

const Bookings = () => {
  const {t} = useTranslations(); // Get the translation function from the hook

  const {error, response, run} = useGetUserBookings({onlyFuture: true}); // Use the custom hook to fetch booking data

  const onFocus = useCallback(() => {
    if (run) {
      run();
    }
  }, [run]);

  useOnPageFocused(AuthRoutes.BOOKINGS, onFocus);

  // Define the 'body' variable that will conditionally render content
  const body: JSX.Element | JSX.Element[] = useMemo(() => {
    if (error) {
      return <ResponseErrorMessage showToast />; // Render an error message
    }

    if (response) {
      // If it's not loading and there was no error, response should be an object
      const locationKeys: string[] = Object.keys(response); // Extract location keys

      if (locationKeys.length === 0) {
        return (
          <MessagePage
            message={t('APPOINTMENTS.NO_MESSAGE')}
            title={t('APPOINTMENTS.NO_TITLE')}
          />
        ); // Render a message if there are no locations
      }

      // Map through location keys and render AppointmentsLocations component for each
      return locationKeys.map((locationKey: string) => (
        <AppointmentsLocations
          key={locationKey}
          singleLocation={response[locationKey]}
        />
      ));
    }

    return <LoadingMessage />; // Render a loading message
  }, [error, response, t]);

  // Return the Base component with a localized title and the 'body' content
  return (
    <Base title={t('APPOINTMENTS.TITLE')} disableGutters={true}>
      {body}
    </Base>
  );
};

export default Bookings;
