import {useMemo} from 'react';

import {Box, Typography} from '@mui/material';

import {Breadcrumbs} from '@/atoms/Breadcrumbs';
import {useIsTablet} from '@/hooks/viewport';
import {useStyle} from '@/molecules/PageTitle/style';
import {IProps} from '@/molecules/PageTitle/type';

const PageTitle = ({breadCrumbs, preTitle, title, buttons}: IProps) => {
  const {pageTitleBoxContainer, titlesBox, pretitleBox, titleBox, buttonsBox} =
    useStyle();

  const isTablet = useIsTablet();

  const buttonsNode = useMemo(() => {
    if (buttons) {
      return <Box sx={buttonsBox}>{buttons}</Box>;
    }
  }, [buttons, buttonsBox]);

  return (
    <Box sx={pageTitleBoxContainer}>
      {breadCrumbs && <Breadcrumbs breadcrumbs={breadCrumbs} />}
      {(title || preTitle || buttons) && (
        <Box sx={titlesBox}>
          {!isTablet && buttonsNode}
          {preTitle && (
            <Box sx={pretitleBox}>
              <Typography variant={'titleAktive3'}>{preTitle}</Typography>
            </Box>
          )}
          <Box sx={titleBox}>
            {title && (
              <Typography variant={'titleVersace1'}>{title}</Typography>
            )}
            {isTablet && buttonsNode}
          </Box>
        </Box>
      )}
    </Box>
  );
};
export default PageTitle;
