import React, {FunctionComponent, useEffect, useState} from 'react';
import {BrowserRouter as Router, Outlet, Route, Routes} from 'react-router-dom';

import {useUserContext} from '@/contexts/user';
import {InvitationsFromEmailRedirect} from '@/navigation/InvitationsFromEmailRedirect';
import Authenticated from '@/navigation/middleware/Authenticated';
import Unauthenticated from '@/navigation/middleware/Unauthenticated';
import AtLeastAdmin from '@/navigation/middleware/roles/AtLeastAdmin';
import AtLeastEmployee from '@/navigation/middleware/roles/AtLeastEmployee';
import AtLeastGuest from '@/navigation/middleware/roles/AtLeastGuest';
import AtLeastOperator from '@/navigation/middleware/roles/AtLeastOperator';
import {AnonymousRoutes, AuthRoutes} from '@/navigation/routes';
import {PageNotFound} from '@/organisms/PageNotFound';
import BookingDelete from '@/pages/BookingDelete/BookingDelete';
import {BookingEdit} from '@/pages/BookingEdit';
import {Bookings} from '@/pages/Bookings';
import {Event} from '@/pages/Event';
import {EventBookingsDownload} from '@/pages/EventBookingsDownload';
import {EventCreate} from '@/pages/EventCreate';
import {EventDelete} from '@/pages/EventDelete';
import {EventEdit} from '@/pages/EventEdit';
import {EventSlotBehalfOfBooking} from '@/pages/EventSlotBehalfOfBooking';
import {EventSlotBookedPeople} from '@/pages/EventSlotBookedPeople';
import {EventSlotBookingDelete} from '@/pages/EventSlotBookingDelete';
import {EventSlotDelete} from '@/pages/EventSlotDelete';
import {EventSlotDetail} from '@/pages/EventSlotDetail';
import {EventSlotDoorman} from '@/pages/EventSlotDoorman';
import {EventSlotEdit} from '@/pages/EventSlotEdit';
import {EventTypeToCreate} from '@/pages/EventTypeToCreate';
import {Events} from '@/pages/Events';
import {Invitation} from '@/pages/Invitation';
import {InvitationGuest} from '@/pages/InvitationGuest';
import {InvitationSlotBooking} from '@/pages/InvitationSlotBooking';
import {InvitationSlotDetail} from '@/pages/InvitationSlotDetail';
import {Invitations} from '@/pages/Invitations';
import {RegisterInvitation} from '@/pages/Invitations/RegisterInvitation';
import {LoginEmployee} from '@/pages/LoginEmployee';
import {LoginExternal} from '@/pages/LoginExternal';
import {PrivacyPolicy} from '@/pages/PrivacyPolicy';
import {Users} from '@/pages/Users';

export const Navigation: FunctionComponent = () => {
  const {initialize} = useUserContext();

  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        await initialize();
      } catch (e) {
        // Something went wrong at the startup.
        // Just show the login page.
      } finally {
        setIsInitialized(true);
      }
    })();
  }, [initialize]);

  if (isInitialized) {
    return (
      <Router>
        <Routes>
          {/* Define routes for unauthenticated or authenticated users */}
          <Route
            path={AnonymousRoutes.PRIVACY_POLICY}
            element={<PrivacyPolicy />}
          />

          {/* Define routes for unauthenticated (guest) users */}
          <Route element={<Unauthenticated />}>
            <Route
              path={AnonymousRoutes.LOGIN_EMPLOYEE}
              element={<LoginEmployee />}
            />
            <Route
              path={AnonymousRoutes.LOGIN_EXTERNAL}
              element={<LoginExternal />}
            />
          </Route>

          {/* Define routes for authenticated users */}
          <Route element={<Authenticated />}>
            {/* User management */}
            <Route element={<AtLeastAdmin />}>
              <Route path={AuthRoutes.USERS} element={<Users />} />
            </Route>

            {/* Event management */}
            <Route element={<AtLeastOperator />}>
              <Route
                path={AuthRoutes.EVENTS}
                element={
                  <>
                    <Events />
                    <Outlet />
                  </>
                }>
                <Route element={<AtLeastAdmin />}>
                  <Route
                    path={AuthRoutes.EVENT_TYPE_TO_CREATE}
                    element={<EventTypeToCreate />}
                  />
                  <Route
                    path={AuthRoutes.EVENT_CREATE}
                    element={<EventCreate />}
                  />
                  <Route path={AuthRoutes.EVENT} element={<EventEdit />} />
                  <Route
                    path={AuthRoutes.EVENT_DELETE}
                    element={<EventDelete />}
                  />
                </Route>
              </Route>
              <Route
                path={AuthRoutes.EVENT_CALENDAR}
                element={
                  <>
                    <Event />
                    <Outlet />
                  </>
                }>
                <Route element={<AtLeastAdmin />}>
                  <Route
                    path={AuthRoutes.EVENT_SLOT_DELETE}
                    element={<EventSlotDelete />}
                  />
                  <Route
                    path={AuthRoutes.EVENT_SLOT_EDIT}
                    element={<EventSlotEdit />}
                  />
                </Route>
                <Route
                  path={AuthRoutes.EVENT_BOOKINGS_DOWNLOAD}
                  element={<EventBookingsDownload />}
                />
                <Route
                  path={AuthRoutes.EVENT_BEHALF_OF_BOOKING}
                  element={<EventSlotBehalfOfBooking />}
                />
                <Route
                  path={AuthRoutes.EVENT_DOORMAN}
                  element={<EventSlotDoorman />}
                />
                <Route
                  path={AuthRoutes.EVENT_INVITATIONS}
                  element={<EventSlotBookedPeople />}
                />
                <Route
                  path={AuthRoutes.EVENT_SLOT_BOOKING_DELETE}
                  element={<EventSlotBookingDelete />}
                />
                <Route
                  path={AuthRoutes.EVENT_SLOT_DETAILS}
                  element={<EventSlotDetail />}
                />
              </Route>
            </Route>

            {/* Invitations */}
            <Route element={<AtLeastGuest />}>
              <Route
                path={AuthRoutes.INVITATION_REGISTER}
                element={<RegisterInvitation />}
              />
              <Route
                path={AuthRoutes.INVITATION_FROM_EMAIL}
                element={<InvitationsFromEmailRedirect />}
              />
              <Route
                path={AuthRoutes.INVITATION}
                element={
                  <>
                    <Invitation />
                    <Outlet />
                  </>
                }>
                <Route
                  path={AuthRoutes.INVITATION_SLOT_DIALOG}
                  element={<EventSlotDetail />}
                />
                <Route
                  path={AuthRoutes.INVITATION_BEHALF_OF_BOOKING}
                  element={<EventSlotBehalfOfBooking />}
                />
                <Route
                  path={AuthRoutes.INVITATION_SLOT_BOOKING}
                  element={<InvitationSlotBooking />}
                />
                <Route element={<AtLeastEmployee />}>
                  <Route
                    path={AuthRoutes.INVITATION_GUEST}
                    element={<InvitationGuest />}
                  />
                </Route>
                <Route
                  path={AuthRoutes.INVITATION_SLOT_DETAILS}
                  element={
                    <>
                      <InvitationSlotDetail />
                      <Outlet />
                    </>
                  }
                />
                <Route
                  path={AuthRoutes.INVITATION_SLOT_DETAILS_EDIT}
                  element={<BookingEdit />}
                />
              </Route>
              <Route path={AuthRoutes.INVITATIONS} element={<Invitations />} />

              {/* Bookings */}
              <Route
                path={AuthRoutes.BOOKINGS}
                element={
                  <>
                    <Bookings />
                    <Outlet />
                  </>
                }>
                <Route
                  path={AuthRoutes.BOOKING_DELETE}
                  element={<BookingDelete />}
                />
                <Route
                  path={AuthRoutes.BOOKING_EDIT}
                  element={<BookingEdit />}
                />
              </Route>
            </Route>
          </Route>

          {/* Handle all not matching routes */}
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Router>
    );
  }

  return <></>;
};
