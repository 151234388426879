import React, {useState, useCallback, useEffect} from 'react';

import {Box} from '@mui/material';
import {useTranslations} from '@vidiemme/react-i18n';
import moment from 'moment-timezone';

import {Button} from '@/atoms/Button';
import useDate from '@/hooks/date';
import {SlotSizeManagement} from '@/molecules/SlotSizeManagement';
import {SlotSize} from '@/molecules/SlotSizeManagement/type';

import {useStyle} from './style';
import {SlotSizeEventProps} from './type';

const EventSlotSize = ({
  slotsLimit,
  changeInfoHandler,
  isLoading,
  eventStartDate,
  eventEndDate,
  timezone = 'UTC',
}: SlotSizeEventProps) => {
  const {t} = useTranslations();
  const {boxContainer: boxContainerStyle} = useStyle();
  const {usingTimezone} = useDate();

  const [slotList, setSlotList] = useState(() => {
    if (slotsLimit) {
      return slotsLimit.map(slot => ({
        ...slot,
        start_date: slot.start_date
          ? usingTimezone(slot.start_date, timezone)
          : null,
        end_date: slot.end_date ? usingTimezone(slot.end_date, timezone) : null,
      }));
    }
    return [
      {
        start_date: null,
        end_date: null,
        limit: 0,
      },
    ];
  });

  const addSlotHandler = useCallback(() => {
    setSlotList([
      ...slotList,
      {
        start_date: null,
        end_date: null,
        limit: 0,
      },
    ]);
  }, [slotList]);

  const deleteSlotHandler = useCallback(
    (index: number) => {
      const slotsUpdated = slotList.filter((_, i) => {
        return i !== index;
      });
      setSlotList(slotsUpdated);
    },
    [slotList],
  );

  const fieldChangeHandler = useCallback(
    (field: keyof SlotSize, newValue: moment.Moment | any, index: number) => {
      setSlotList(
        slotList.map((slot, i) => {
          if (i === index) {
            let value = newValue;
            if (field === 'limit') {
              value = isNaN(newValue.target.valueAsNumber)
                ? '0'
                : newValue.target.valueAsNumber;
            }
            return {
              ...slot,
              [field]: value,
            };
          }
          return {...slot};
        }),
      );
    },
    [slotList],
  );

  useEffect(() => {
    const obj = {
      slotLimits: slotList.map(slot => ({
        ...slot,
        start_date: slot.start_date?.format() ?? '',
        end_date: slot.end_date?.format() ?? '',
      })),
    };
    changeInfoHandler(obj);
  }, [slotList]); // DO NOT update dependency with changeInfoHandler method

  return (
    <Box sx={boxContainerStyle}>
      {slotList.map((slot, index) => {
        return (
          <SlotSizeManagement
            eventStartDate={eventStartDate}
            eventEndDate={eventEndDate}
            isLoading={isLoading}
            slot={slot}
            previuosSlot={
              index > 0 && slotList[index - 1] ? slotList[index - 1] : undefined
            }
            key={`slot-size-${index}`}
            hideDelete={slotList.length === 1}
            deleteSlotHandler={() => deleteSlotHandler(index)}
            fieldChangeHandler={(field, val) =>
              fieldChangeHandler(field, val, index)
            }
            timezone={timezone}
          />
        );
      })}
      <Button
        disabled={isLoading}
        variant={'primary'}
        color={'default'}
        onClick={addSlotHandler}>
        {t('EVENT_CREATE_EDIT.SLOT_SIZE.BUTTON_ADD')}
      </Button>
    </Box>
  );
};
export default EventSlotSize;
