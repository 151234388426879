import {useMemo} from 'react';

import {SxProps} from '@mui/material';

import {IUseStyle} from './type';

export const useStyle = (): IUseStyle => {
  const boxContainer: SxProps = useMemo(
    (): SxProps => ({
      display: 'flex',
      flexDirection: 'column',
      rowGap: '24px',
    }),
    [],
  );

  const addDateButton: SxProps = useMemo(
    (): SxProps => ({
      width: 'auto',
      alignSelf: 'center',
      marginTop: '16px',
    }),
    [],
  );

  return {
    boxContainer,
    addDateButton,
  };
};
