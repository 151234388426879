import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useLocation} from 'react-router-dom';

import {Typography} from '@mui/material';
import {useTranslations} from '@vidiemme/react-i18n';
import moment from 'moment-timezone';

import useDate from '@/hooks/date';
import {useToast} from '@/hooks/toast';
import {AccordionComponent} from '@/molecules/AccordionComponent';
import {useNavigation} from '@/navigation/useNavigation';
import {EventExtraordinarySchedule} from '@/organisms/EventExtraordinarySchedule';
import {EventGeneralInfo} from '@/organisms/EventGeneralInfo';
import {ReservationOpenings} from '@/organisms/EventReservationOpenings';
import {EventSlotSize} from '@/organisms/EventSlotSize';
import {EventWeeklySchedule} from '@/organisms/EventWeeklySchedule';
import {EventWeekDaySchedule} from '@/organisms/EventWeeklySchedule/type';
import {Category} from '@/store/categories';
import {useCreateEvent} from '@/store/events/hooks';
import {SideModal} from '@/templates/SideModal';

import {EventTemplateProps} from './type';

const EventCreate = () => {
  const {goToEvents} = useNavigation();
  const {t} = useTranslations();
  const {showErrorToast, showSuccessToast} = useToast();
  const {dateToDateFormat, dateToHoursAndFormat} = useDate();

  const location = useLocation(); // Get the current URL location

  const locationSelected = location.state?.location;
  const categorySelected = location.state?.category;

  const [eventTemplate, setEventTemplate] = useState<EventTemplateProps>({});

  /* Get date closures list from extraordinary schedule with empty intervals */
  const getDatesClosures = useMemo(() => {
    if (eventTemplate.extraordinarySchedules) {
      return eventTemplate.extraordinarySchedules
        .filter(
          extraordinarySchedule =>
            extraordinarySchedule.date &&
            extraordinarySchedule.intervals.length === 0,
        )
        .map(item => dateToDateFormat(item.date!));
    }
    return [];
  }, [dateToDateFormat, eventTemplate.extraordinarySchedules]);

  /* Get weekly closures list from weekly schedule with empty intervals */
  const getWeeklyClosures = useMemo(() => {
    if (eventTemplate.weeklySchedules) {
      return eventTemplate.weeklySchedules
        .filter(weeklySchedule => weeklySchedule.intervals.length === 0)
        .map(item => item.week_day_number);
    }
    return [];
  }, [eventTemplate.weeklySchedules]);

  /* Get opening hours type ordinary with start/end time intervals */
  const getOrdinaryOpeningHours = useMemo(() => {
    if (eventTemplate.weeklySchedules) {
      return eventTemplate.weeklySchedules?.reduce(
        (accumulator: any, weeklySchedule: EventWeekDaySchedule) => {
          let weeklyScheduledFiltered = {
            ...weeklySchedule,
            intervals: weeklySchedule.intervals.filter(
              itemIntervals =>
                itemIntervals.start_time && itemIntervals.end_time,
            ),
          };

          if (weeklyScheduledFiltered.intervals.length > 0) {
            weeklyScheduledFiltered.intervals =
              weeklyScheduledFiltered.intervals.map(item => ({
                // Send the time in the event timezone, the server will handle the UTC conversion based on the specific slot date
                start_time: item.start_time,
                end_time: item.end_time,
              }));

            return [...accumulator, weeklyScheduledFiltered];
          }
          return accumulator;
        },
        [],
      );
    }
    return [];
  }, [dateToHoursAndFormat, eventTemplate.weeklySchedules]);

  /* Get opening hours type extraordinary with start/end time intervals */
  const getExtraordinaryOpeningHours = useMemo(() => {
    if (eventTemplate.extraordinarySchedules) {
      return eventTemplate.extraordinarySchedules.reduce(
        (accumulator: any, dateSchedule) => {
          let dateScheduledFiltered = {
            ...dateSchedule,
            date: dateToDateFormat(dateSchedule.date!),
            intervals: dateSchedule.intervals.filter(
              itemIntervals =>
                itemIntervals.start_time && itemIntervals.end_time,
            ),
          };

          if (dateScheduledFiltered.intervals.length > 0) {
            dateScheduledFiltered.intervals =
              dateScheduledFiltered.intervals.map(item => ({
                // Send the time in the event timezone, the server will handle the UTC conversion based on the specific slot date
                start_time: moment
                  .parseZone(item.start_time)
                  .format('HH:mm:ss'),
                end_time: moment.parseZone(item.end_time).format('HH:mm:ss'),
              }));
            return [...accumulator, dateScheduledFiltered];
          }
          return accumulator;
        },
        [],
      );
    }
    return [];
  }, [
    dateToDateFormat,
    dateToHoursAndFormat,
    eventTemplate.extraordinarySchedules,
  ]);

  /* Get slot limits for each start/end date occurences */
  const getSlotLimits = useMemo(() => {
    if (eventTemplate.slotLimits) {
      return eventTemplate.slotLimits
        .filter(slot => slot.start_date && slot.end_date && slot.limit > 0)
        .map(slotFiltered => ({
          ...slotFiltered,
          start_date: dateToDateFormat(slotFiltered.start_date!),
          end_date: dateToDateFormat(slotFiltered.end_date!),
        }));
    }
    return [];
  }, [dateToDateFormat, eventTemplate.slotLimits]);

  /* Get reservation opening dates for each brand type */
  const getReservationOpenings = useMemo(() => {
    if (eventTemplate.reservationOpenings) {
      const {versace, capri, guests} = eventTemplate.reservationOpenings;
      return {
        capri: {
          start_date: capri.start_date
            ? dateToDateFormat(capri.start_date)
            : '',
          end_date: capri.end_date ? dateToDateFormat(capri.end_date) : '',
        },
        versace: {
          start_date: versace.start_date
            ? dateToDateFormat(versace.start_date)
            : '',
          end_date: versace.end_date ? dateToDateFormat(versace.end_date) : '',
        },
        guests: {
          start_date: guests.start_date
            ? dateToDateFormat(guests.start_date)
            : '',
          end_date: guests.end_date ? dateToDateFormat(guests.end_date) : '',
        },
      };
    }
    return undefined;
  }, [dateToDateFormat, eventTemplate.reservationOpenings]);

  /** Join all fields of event template for each accordion section
   * ('general-info'|'weekly-schedule'|'slot-size'|'reservation-openings'|'extraordinary-schedule') */
  const changeInfoHandler = useCallback(
    (fields: any) => {
      setEventTemplate({
        ...eventTemplate,
        ...fields,
      });
    },
    [eventTemplate],
  );

  /** Build event object template for server API - all hours is in UTC, all dates has in format YYYY-MM-DD,
   *  these dates are not converted to utc as agreed with the BE */
  const eventRequest: any = useMemo(() => {
    return {
      location_id: locationSelected?.id,
      category_id: categorySelected?.id,
      name: eventTemplate.generalInfo?.eventName || '',
      slot_duration: eventTemplate.generalInfo?.slotDuration,
      allow_multiple_bookings: eventTemplate.generalInfo?.allowMultipleBookings,
      guest_per_person: eventTemplate.generalInfo?.guestPerPerson,
      min_booking_time: eventTemplate.generalInfo?.minBookingTime,
      calendar: {
        start_date: eventTemplate.generalInfo?.startDate
          ? dateToDateFormat(eventTemplate.generalInfo.startDate)
          : '',
        end_date: eventTemplate.generalInfo?.endDate
          ? dateToDateFormat(eventTemplate.generalInfo.endDate)
          : '',
        closures: {
          dates: getDatesClosures,
          week_day_numbers: getWeeklyClosures,
        },
      },
      opening_hours: {
        ordinary: getOrdinaryOpeningHours,
        extraordinary: getExtraordinaryOpeningHours,
      },
      slot_limits: getSlotLimits,
      reservation_openings: getReservationOpenings,
    };
  }, [
    categorySelected?.id,
    dateToDateFormat,
    eventTemplate.generalInfo?.allowMultipleBookings,
    eventTemplate.generalInfo?.endDate,
    eventTemplate.generalInfo?.eventName,
    eventTemplate.generalInfo?.guestPerPerson,
    eventTemplate.generalInfo?.minBookingTime,
    eventTemplate.generalInfo?.slotDuration,
    eventTemplate.generalInfo?.startDate,
    getDatesClosures,
    getExtraordinaryOpeningHours,
    getOrdinaryOpeningHours,
    getReservationOpenings,
    getSlotLimits,
    getWeeklyClosures,
    locationSelected?.id,
  ]);

  /* Call POST create event api */
  const {
    loading,
    error,
    response,
    run: runCreateEventRequest,
  } = useCreateEvent(eventRequest);

  /* Show toast message if error occured, if event created go back to events list */
  useEffect(() => {
    if (error) {
      showErrorToast({
        message:
          error.userMessage ?? t('EVENT_CREATE_EDIT.CREATE_RESPONSE_ERROR'),
      });
    } else if (response) {
      showSuccessToast({
        message: t('EVENT_CREATE_EDIT.CREATE_RESPONSE_SUCCESS'),
      });
      goToEvents();
    }
  }, [error, goToEvents, response, showErrorToast, showSuccessToast, t]);

  /* Enable submit button only if all fields required is filled */
  const isEnableSubmit: boolean = useMemo(() => {
    /* Required fields for each category events */
    if (
      !(
        eventRequest &&
        eventRequest.name.length > 0 &&
        eventRequest.calendar.start_date &&
        eventRequest.calendar.end_date &&
        eventRequest.slot_duration &&
        eventRequest.opening_hours.ordinary.length > 0
      )
    ) {
      return false;
    }

    /* If category is not clothing slot limits are required */
    if (
      categorySelected?.name !== Category.CLOTHING &&
      eventRequest.slot_limits.length === 0
    ) {
      return false;
    }

    /* If category is special sale reservation opernings dates are required */
    if (categorySelected?.name === Category.SPECIAL_SALE) {
      if (
        !(
          eventRequest.reservation_openings?.versace.start_date &&
          eventRequest.reservation_openings?.versace.end_date &&
          /**
           * TODO: if capri brand will add into this platform, restore this piece of code
           * eventRequest.reservation_openings?.capri.start_date &&
           * eventRequest.reservation_openings?.capri.end_date &&
           * */
          eventRequest.reservation_openings?.guests.start_date &&
          eventRequest.reservation_openings?.guests.end_date
        )
      ) {
        return false;
      }
    }
    return true;
  }, [categorySelected?.name, eventRequest]);

  /* Create accordions section content ('general-info'|'weekly-schedule'|'slot-size'|'reservation-openings'|'extraordinary-schedule') */
  const accordionListItems = useMemo(
    () => [
      {
        id: 'general-info',
        title: t('EVENT_CREATE_EDIT.GENERAL_INFO.TITLE'),
        content: (
          <EventGeneralInfo
            isLoading={loading}
            eventCategory={categorySelected?.name}
            changeInfoHandler={changeInfoHandler}
            timezone={locationSelected?.timezone}
          />
        ),
      },
      {
        id: 'weekly-schedule',
        title: t('EVENT_CREATE_EDIT.WEEKLY_SCHEDULE.TITLE'),
        content: (
          <EventWeeklySchedule
            isLoading={loading}
            changeInfoHandler={changeInfoHandler}
            timezone={locationSelected?.timezone}
          />
        ),
      },
      {
        id: 'slot-size',
        title: t('EVENT_CREATE_EDIT.SLOT_SIZE.TITLE'),
        subtitle: t('EVENT_CREATE_EDIT.SLOT_SIZE.SUB_TITLE'),
        hidden: categorySelected?.name === Category.CLOTHING,
        content: (
          <EventSlotSize
            isLoading={loading}
            changeInfoHandler={changeInfoHandler}
            eventStartDate={eventTemplate.generalInfo?.startDate}
            eventEndDate={eventTemplate.generalInfo?.endDate}
            timezone={locationSelected?.timezone}
          />
        ),
      },
      {
        id: 'reservation-openings',
        title: t('EVENT_CREATE_EDIT.RESERVATION_OPENINGS.TITLE'),
        hidden: categorySelected?.name !== Category.SPECIAL_SALE,
        content: (
          <ReservationOpenings
            isLoading={loading}
            changeInfoHandler={changeInfoHandler}
            eventStartDate={eventTemplate.generalInfo?.startDate}
            eventEndDate={eventTemplate.generalInfo?.endDate}
            timezone={locationSelected?.timezone}
          />
        ),
      },
      {
        id: 'extraordinary-schedule',
        title: t('EVENT_CREATE_EDIT.EXTRAORDINARY_SCHEDULE.TITLE'),
        content: (
          <EventExtraordinarySchedule
            isLoading={loading}
            changeInfoHandler={changeInfoHandler}
            eventStartDate={eventTemplate.generalInfo?.startDate}
            eventEndDate={eventTemplate.generalInfo?.endDate}
            timezone={locationSelected?.timezone}
          />
        ),
      },
    ],
    [
      categorySelected?.name,
      changeInfoHandler,
      eventTemplate.generalInfo?.endDate,
      eventTemplate.generalInfo?.startDate,
      loading,
      locationSelected?.timezone,
      t,
    ],
  );

  if (!locationSelected || !categorySelected) {
    showErrorToast({message: t('RESPONSE_ERROR_MESSAGE.MESSAGE')});
    return <></>;
  }

  return (
    <SideModal
      title={t('EVENT_CREATE_EDIT.CREATE_TITLE', {
        location: locationSelected?.name,
        category: categorySelected?.name,
      })}
      subTitle={
        <>
          {t('EVENT_CREATE_EDIT.CREATE_SUB_TITLE')}
          &nbsp;&nbsp;|&nbsp;&nbsp;
          <Typography variant={'bodyBold'}>
            {t('EVENT_CREATE_EDIT.CREATE_SUB_TITLE_REQUIRED_FIELDS')}
          </Typography>
        </>
      }
      closeHandler={goToEvents}
      textButtonOne={t('EVENT_CREATE_EDIT.TEXT_BUTTON_ONE')}
      textButtonTwo={t('EVENT_CREATE_EDIT.CREATE_TEXT_BUTTON_TWO')}
      CTAButtonOne={goToEvents}
      CTAButtonTwo={runCreateEventRequest}
      buttonOneDisabled={false}
      buttonTwoDisabled={!isEnableSubmit}
      buttonTwoLoading={loading}>
      <AccordionComponent
        expandedDefaultItem={'general-info'}
        items={accordionListItems}
      />
    </SideModal>
  );
};
export default EventCreate;
