import {useMemo} from 'react';

import {SxProps} from '@mui/material';

import {theme} from '@/themes/default';

import {IUseStyle} from './type';

export const useStyle = (): IUseStyle => {
  const deleteSlotBoxContainer: SxProps = useMemo(
    (): SxProps => ({
      display: 'flex',
      flexDirection: 'column',
      width: 'calc(100vw - 100px)', // 110 = 20*2 padding + 30*2 boxshadow
      gap: '16px',
      [theme.breakpoints.up('md')]: {
        width: '450px',
      },
    }),
    [],
  );

  const deleteSlotTextBox: SxProps = useMemo(
    (): SxProps => ({
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      marginTop: '16px',
    }),
    [],
  );

  const deleteSlotTitle: SxProps = useMemo(
    (): SxProps => ({marginBottom: '16px'}),
    [],
  );

  const deleteSlotButtonsBox: SxProps = useMemo(
    (): SxProps => ({
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      rowGap: '10px',
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        rowGap: '0px',
      },
    }),
    [],
  );

  const deleteSlotButton: SxProps = useMemo(
    (): SxProps => ({
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '50%',
      },
    }),
    [],
  );

  return {
    deleteSlotBoxContainer,
    deleteSlotTextBox,
    deleteSlotTitle,
    deleteSlotButtonsBox,
    deleteSlotButton,
  };
};
