import {useMemo} from 'react';

import {SxProps} from '@mui/material';

import {IUseStyle} from '@/pages/Bookings/AppointmentsLocation/definitions';
import {desktopPadding, mobilePadding, theme} from '@/themes/default';

export const useStyle = (): IUseStyle => {
  const appointmentLocationContainerBox: SxProps = useMemo(
    (): SxProps => ({
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '72px',
    }),
    [],
  );

  const appointmentLocation: SxProps = useMemo(
    (): SxProps => ({
      marginBottom: '32px',
      paddingX: mobilePadding,
      [theme.breakpoints.up('md')]: {
        paddingX: desktopPadding,
      },
    }),
    [],
  );

  const appointmentLocationEventsBox: SxProps = useMemo(
    (): SxProps => ({
      display: 'flex',
      gap: '20px',
      paddingX: mobilePadding,
      [theme.breakpoints.down('md')]: {
        overflowX: 'auto',
      },
      [theme.breakpoints.up('md')]: {
        gap: '24px',
        paddingX: desktopPadding,
        flexWrap: 'wrap',
      },
    }),
    [],
  );

  return {
    appointmentLocationContainerBox,
    appointmentLocation,
    appointmentLocationEventsBox,
  };
};
