import {FunctionComponent} from 'react';

import {Box, Typography} from '@mui/material';

import Invitation from '@/pages/Invitations/InvitationsLocation/Invitation/Invitation';
import {IProps} from '@/pages/Invitations/InvitationsLocation/definitions';
import {useStyle} from '@/pages/Invitations/InvitationsLocation/style';
import {Invitation as IInvitation} from '@/store/invitations';

const InvitationsLocation: FunctionComponent<IProps> = ({
  invitationsSingleLocation,
}: IProps) => {
  const {
    invitationsLocationContainerBox,
    invitationsLocation,
    invitationsLocationEventsBox,
  } = useStyle();

  return (
    <Box sx={invitationsLocationContainerBox}>
      <Typography variant={'titleAktive2'} sx={invitationsLocation}>
        {invitationsSingleLocation[0].location.name}
      </Typography>

      <Box sx={invitationsLocationEventsBox}>
        {invitationsSingleLocation.map((invitation: IInvitation) => (
          <Invitation
            key={`${invitation.location.id}-${invitation.eventId}`}
            invitation={invitation}
          />
        ))}
      </Box>
    </Box>
  );
};

export default InvitationsLocation;
