import React, {useCallback, useEffect, useMemo} from 'react';

import {Box} from '@mui/material';
import {useTranslations} from '@vidiemme/react-i18n';

import {Button} from '@/atoms/Button';
import {IconSVG} from '@/atoms/IconSVG';
import {useUserContext} from '@/contexts/user';
import {useQueryString} from '@/hooks/queryString';
import {useToast} from '@/hooks/toast';
import {useStyle} from '@/organisms/LoginSSO/style';
import {IProps} from '@/organisms/LoginSSO/type';
import {useKeycloak} from '@/store/keycloak';

const LoginSSO = ({brand}: IProps) => {
  const {t} = useTranslations();
  const {showErrorToast} = useToast();
  const {realm, logo} = brand;

  const {
    loginSSOContainer,
    loginSSOLogoBox,
    loginSSOButton,
    loginSSOButtonBox,
  } = useStyle();

  const {loginSSO} = useUserContext();
  const {
    getSSOUrl,
    storeKeycloakRealmForLogin,
    retrieveKeycloakRealmForLogin,
    eraseKeycloakRealmForLogin,
  } = useKeycloak();

  const qsCode = useQueryString('code');
  const qsEventID = useQueryString('event_id');

  const doLoginSSO = useCallback(async () => {
    try {
      if (qsEventID) {
        await loginSSO(realm, qsCode, parseInt(qsEventID));
      } else {
        await loginSSO(realm, qsCode);
      }
      await eraseKeycloakRealmForLogin(); // remove realm from local storage as it is no longer needed
    } catch (e) {
      showErrorToast({
        message: t('LOGIN_EMPLOYEE.ERROR'),
      });
    }
  }, [
    loginSSO,
    realm,
    qsCode,
    qsEventID,
    showErrorToast,
    t,
    eraseKeycloakRealmForLogin,
  ]);

  useEffect(() => {
    const getRealmAndDoLogin = async () => {
      // this prevents each SSOLogin component from invoking doLoginSSO if any code is provided
      const selectedRealm = await retrieveKeycloakRealmForLogin();
      if (qsCode && selectedRealm === realm) {
        void doLoginSSO();
      }
    };
    getRealmAndDoLogin();
  }, []); // DO NOT update dependencies

  const ssoURL = useMemo(() => {
    if (qsEventID) {
      return getSSOUrl(realm, parseInt(qsEventID));
    }

    return getSSOUrl(realm);
  }, [getSSOUrl, realm, qsEventID]);

  const loginSSOButtonHandler = useCallback(() => {
    storeKeycloakRealmForLogin(realm); // realm needs to be temporarily stored in order to select the right one during SSO login
    window.location.href = ssoURL;
  }, [ssoURL, realm, storeKeycloakRealmForLogin]);

  return (
    <Box sx={loginSSOContainer}>
      <Box sx={loginSSOLogoBox}>
        <IconSVG icon={logo} size={1000} />
      </Box>
      <Box sx={loginSSOButtonBox}>
        <Button
          variant={'primary'}
          color={'default'}
          sx={loginSSOButton}
          onClick={loginSSOButtonHandler}>
          {t(`LOGIN_EMPLOYEE.BUTTON_TEXT`)}
        </Button>
        {/* TODO: waiting for know how to implement it
        <Link to={AnonymousRoutes.LOGIN_EMPLOYEE}>
          <Typography variant={'bodyRegularLink'}>
            {t(`LOGIN_EMPLOYEE.CONTACT`)}
          </Typography>
        </Link>*/}
      </Box>
    </Box>
  );
};

export default LoginSSO;
