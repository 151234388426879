import {REALMS} from '@/store/keycloak';

import {UseGetBrands} from './type';

export const useGetBrands: UseGetBrands = () => {
  return {
    loading: false,
    error: null,
    response: [
      {
        id: 1,
        realm: REALMS.VERSACE,
        logo: 'logo-versace',
      },
      /* To enable MK and Jimmy Choo SSO, uncomment this
      {
        id: 2,
        realm: REALMS.JIMMY_CHOO,
        logo: 'logo-jimmy-choo',
      },
      {
        id: 3,
        realm: REALMS.MICHAEL_KORS,
        logo: 'logo-michael-kors',
      },
      */
    ],
  };
};
