import {useMemo} from 'react';

import {SxProps} from '@mui/material';

import {UseStyle} from '@/pages/InvitationGuest/InvitedGuestComponent/type';
import {theme} from '@/themes/default';

export const useStyle = (): UseStyle => {
  const inviteGuestComponentContentBox: SxProps = useMemo(
    (): SxProps => ({
      display: 'flex',
      width: '100%',
      marginBottom: '16px',
      flexDirection: 'column',
      rowGap: '10px',
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        columnGap: '5%',
        rowGap: '0',
      },
    }),
    [],
  );

  const inviteGuestComponentEmail: SxProps = useMemo(
    (): SxProps => ({
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20%',
      },
    }),
    [],
  );

  return {
    inviteGuestComponentContentBox,
    inviteGuestComponentEmail,
  };
};
