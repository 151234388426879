import {useCallback} from 'react';
import {useLocation} from 'react-router-dom';

import {useTranslations} from '@vidiemme/react-i18n';

import useDate from '@/hooks/date';
import {CanNotEditProps, IUseEvents} from '@/hooks/event/interfaces';
import {AuthRoutes} from '@/navigation/routes';
import {Presence} from '@/store/presence';

const useEvent = (): IUseEvents => {
  const location = useLocation();
  const {t} = useTranslations();

  const {isPast} = useDate();

  const canNotEdit: CanNotEditProps = useCallback(
    ({
      eventStartDate,
      slotLimit,
      bookedSpots,
      isBookFound = false,
      timezone,
      minBookingTime,
    }) => {
      /* if is in events admin section I can view all slots(past or full slot limit)  */
      const isEventManagementSection =
        location.pathname.indexOf(AuthRoutes.EVENTS) > -1;
      if (isEventManagementSection) {
        return false;
      }
      const isOld = isPast(eventStartDate, timezone, minBookingTime);
      /* if I have booked a slot, I can edit my booked slot */
      if (isBookFound && !isOld) {
        return false;
      }
      return isOld || bookedSpots >= slotLimit;
    },
    [isPast, location.pathname],
  );

  const EventPresence = {
    in_person: t('EVENT.IN_PRESENCE') as Presence.PERSON,
    remote: t('EVENT.REMOTE') as Presence.REMOTE,
  };

  return {
    canNotEdit,
    EventPresence,
  };
};

export default useEvent;
