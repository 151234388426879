import {useCallback, useEffect, useState} from 'react';

import {FormControl, InputLabel, MenuItem, Select} from '@mui/material';
import {useTranslations} from '@vidiemme/react-i18n';

import {useStyle} from '@/pages/Users/style';
import {useGetBrands} from '@/store/brand';
import {REALMS} from '@/store/keycloak';

const BrandFilter = ({onChange}: {onChange: (realm: REALMS) => void}) => {
  const {t} = useTranslations();
  const {response: brands} = useGetBrands();

  const [searchBrand, setSearchBrand] = useState<REALMS>(REALMS.VERSACE);
  const {formControlSearchBox} = useStyle();

  const brandFilterHandler = useCallback(
    (event: any) => setSearchBrand(event.target.value),
    [],
  );

  useEffect(() => {
    if (brands !== null) {
      setSearchBrand(brands[0]?.realm ?? '');
    }
  }, [brands]);

  useEffect(() => {
    onChange(searchBrand);
  }, [onChange, searchBrand]);

  return (
    <FormControl sx={formControlSearchBox}>
      <InputLabel id="brandFilterSelectLabel">
        {t('USER_MANAGEMENT.BRAND_TITLE')}
      </InputLabel>
      <Select
        labelId="brandFilterSelectLabel"
        id="brandFilterSelect"
        value={searchBrand}
        label={t('USER_MANAGEMENT.BRAND_TITLE')}
        onChange={brandFilterHandler}>
        {brands?.map(brand => (
          <MenuItem key={brand.id} value={brand.realm}>
            {t(`BRANDS.${brand.realm}`)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
export default BrandFilter;
