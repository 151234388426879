import React, {useCallback, useEffect, useState} from 'react';

import {Box} from '@mui/material';
import moment from 'moment-timezone';

import useDate from '@/hooks/date';
import {WeeklySchedule} from '@/molecules/WeeklySchedule';
import {WeekDay} from '@/molecules/WeeklySchedule/type';

import {useStyle} from './style';
import {EventWeeklyScheduleProps} from './type';

const EventWeeklySchedule = ({
  days,
  changeInfoHandler,
  isLoading,
  disabled,
  timezone = 'UTC',
  applyTimezone = true,
}: EventWeeklyScheduleProps) => {
  const {boxContent: boxContentStyle} = useStyle();
  const {usingTimezone, formatTime} = useDate();

  const [weekItems, setWeekItems] = useState(() => {
    if (days?.length) {
      /* Convert all string dates with moment type */
      return days.map(day => ({
        week_day_number: day.week_day_number,
        intervals: day.intervals.map(dayInterval => {
          if (applyTimezone) {
            console.log('applying timezone');
            return {
              start_time: dayInterval.start_time
                ? usingTimezone(dayInterval.start_time, timezone)
                : null,
              end_time: dayInterval.end_time
                ? usingTimezone(dayInterval.end_time, timezone)
                : null,
            };
          } else {
            return {
              start_time: dayInterval.start_time
                ? usingTimezone(dayInterval.start_time)
                : null,
              end_time: dayInterval.end_time
                ? usingTimezone(dayInterval.end_time)
                : null,
            };
          }
        }),
      }));
    }
    /* Set default week day list with empty intervals */
    return Array.from({length: 7}, (_, i) => ({
      week_day_number: i + 1,
      intervals: [{start_time: null, end_time: null}],
    }));
  });

  /*
   * Update week schedule list state with day schedule changes (update intervals of the child component)
   */
  const handlerCallback = useCallback(
    ({week_day_number, intervals}: WeekDay) => {
      setWeekItems(
        weekItems.map(weekItem => {
          if (weekItem.week_day_number === week_day_number) {
            return {
              week_day_number: week_day_number,
              intervals: [...intervals],
            };
          }
          return weekItem;
        }),
      );
    },
    [weekItems],
  );

  useEffect(() => {
    const handleWeekItemsChange = () => {
      const formattedWeekItems = weekItems.map(weekDay => ({
        ...weekDay,
        intervals: weekDay.intervals.map(weekDayInterval => {
          return {
            start_time: weekDayInterval.start_time
              ? moment.parseZone(weekDayInterval.start_time).format('HH:mm:ss')
              : null,
            end_time: weekDayInterval.end_time
              ? formatTime(weekDayInterval.end_time.toString())
              : null,
          };
        }),
      }));

      changeInfoHandler({weeklySchedules: formattedWeekItems});
    };

    handleWeekItemsChange();
  }, [weekItems]);

  return (
    <Box sx={boxContentStyle}>
      {weekItems.map(day => (
        <WeeklySchedule
          isLoading={isLoading}
          disabled={disabled}
          day={day}
          key={day.week_day_number}
          handleChange={handlerCallback}
          timezone={timezone}
        />
      ))}
    </Box>
  );
};
export default EventWeeklySchedule;
