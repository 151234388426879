import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useParams} from 'react-router-dom';

import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography,
} from '@mui/material';
import {useTranslations} from '@vidiemme/react-i18n';

import {Button} from '@/atoms/Button';
import {useToast} from '@/hooks/toast';
import {AuthRoutes} from '@/navigation/routes';
import {useNavigation} from '@/navigation/useNavigation';
import {useOnPageFocused} from '@/navigation/useOnPageFocused';
import {LoadingMessage} from '@/organisms/LoadingMessage';
import {
  Booking,
  Bookings,
  useDownloadBookedPeople,
  useGetBookings,
  usePutBooking,
} from '@/store/bookings';
import {SideModal} from '@/templates/SideModal';

import {useStyle} from './style';

// Main component
const EventSlotDoorman = () => {
  // Hooks for translations, navigation, date formatting and fetching slot data
  const {t} = useTranslations();
  const {showErrorToast} = useToast();
  const {goToPreviousPage} = useNavigation();

  const firstRender = useRef(true);
  // Get slotId from URL parameters
  const {slotId} = useParams();

  // Body for usePutBooking api call
  const [bookingToUpdate, setBookingToUpdate] = useState<
    Partial<Booking> | undefined
  >();

  // Bookings lists
  const [bookingList, setBookingList] = useState<Bookings>([]);

  // Text input value for search field
  const [searchValue, setSearchValue] = useState<string>('');

  // Presence filter checkbox value
  const [presenceFilter, setPresenceFilter] = useState<boolean>(false);

  // page number for pagination
  const [page, setPage] = useState<number>(1);

  // items per page for pagination
  const [perPage, setPerPage] = useState<number>(10);

  // Styles for the component
  const {
    modalContainerBox,
    modalFilterBox,
    modalChecklistBox,
    searchFilterBox,
    filterButton,
    loadMoreButtonBox,
  } = useStyle();

  // Fetch booking data hook
  const {
    loading: loadingGetBookings,
    error: errorGetBookings,
    run: runGetBookings,
    response: responseGetBookings,
    meta: pagination,
  } = useGetBookings(
    {
      slotId: slotId ? parseInt(slotId) : -1,
      search: searchValue,
      isPresent: presenceFilter,
      page,
      perPage,
    },
    false,
  );

  // Use loadingGetBookings value to disable interaction when loading the data
  const disabled = loadingGetBookings;

  const callGetBookings = useCallback(() => {
    if (runGetBookings) {
      runGetBookings();
    }
  }, [runGetBookings]);

  useOnPageFocused(AuthRoutes.EVENT_DOORMAN, callGetBookings);

  // Update presence for a single booking
  const {
    loading: _loadingPutBooking,
    error: errorPutBooking,
    run: runPutBooking,
    response: responsePutBooking,
  } = usePutBooking(bookingToUpdate?.id || -1, {
    is_present: bookingToUpdate?.isPresent,
  });

  const callPutBookingApi = useCallback(() => {
    if (runPutBooking) {
      return runPutBooking();
    }
    return null;
  }, [runPutBooking]);

  // Download .xlsx file with all booked people
  const {
    loading: downloadBookedPeopleLoading,
    error: downloadBookedPeopleError,
    run: downloadBookedPeopleRun,
  } = useDownloadBookedPeople({slotId: slotId ? parseInt(slotId) : undefined});

  const handleDownloadBookedPeople = useCallback(() => {
    if (downloadBookedPeopleRun) {
      downloadBookedPeopleRun();
    }
  }, [downloadBookedPeopleRun]);

  // Checklist item change handler
  const checkboxHandler = useCallback(
    (bookingId: number) => {
      const selectedBooking = bookingList?.find(
        booking => booking.id === bookingId,
      )!;
      setBookingToUpdate({
        id: bookingId,
        isPresent: !selectedBooking.isPresent,
      });
      setPerPage(bookingList.length > 10 ? bookingList.length : 10);
      callPutBookingApi();
    },
    [bookingList, callPutBookingApi],
  );

  // Presence filter change handler
  const presenceFilterHandler = useCallback(() => {
    setPresenceFilter(!presenceFilter);
  }, [presenceFilter]);

  // Search text input handler
  const searchTextInputHandler = useCallback((event: any) => {
    const input = event.target.value;
    setSearchValue(input);
  }, []);

  const applyFiltersHandler = useCallback(() => {
    if (runGetBookings) {
      setPage(1);
      runGetBookings();
    }
  }, [runGetBookings]);

  const handleLoadMore = useCallback(() => {
    if (pagination && runGetBookings) {
      setPage(pagination.current_page + 1);
      runGetBookings();
    }
  }, [runGetBookings, pagination]);

  useEffect(() => {
    if (responsePutBooking && runGetBookings) {
      setPage(1);
      runGetBookings();
    }
  }, [responsePutBooking, runGetBookings]);

  useEffect(() => {
    if (pagination) {
      firstRender.current = false;
      const newBookings = responseGetBookings || [];
      setBookingList(
        page === 1
          ? newBookings
          : prevBookings => [...prevBookings, ...newBookings],
      );
    }
  }, [pagination]); // DON'T insert responseGetBookings and page as dependency

  // Show loading message while fetching data
  if (loadingGetBookings && firstRender.current) {
    return <LoadingMessage />;
  }

  // Show error message if there is an error while fetching data
  if (errorGetBookings || errorPutBooking || downloadBookedPeopleError) {
    showErrorToast({message: t('RESPONSE_ERROR_MESSAGE.MESSAGE')});
    return <></>;
  }

  // Render the component
  return (
    <SideModal
      title={t('EVENT_DOORMAN.TITLE')}
      subTitle={t('EVENT_DOORMAN.SUB_TITLE')}
      textButtonOne={t('EVENT_DOORMAN.CLOSE')}
      CTAButtonOne={goToPreviousPage}
      textButtonTwo={t('EVENT_DOORMAN.DOWNLOAD_CHECKLIST')}
      CTAButtonTwo={handleDownloadBookedPeople}
      buttonTwoDisabled={downloadBookedPeopleLoading || disabled}
      closeHandler={goToPreviousPage}>
      <Box sx={modalContainerBox}>
        <Box sx={modalFilterBox}>
          <Box sx={searchFilterBox}>
            <TextField
              onChange={searchTextInputHandler}
              id="search"
              label={t('EVENT_DOORMAN.SEARCH')}
              type="text"
              disabled={disabled}
              value={searchValue}
              sx={{width: '100%'}}
            />
            <Button
              variant={'primary'}
              color={'default'}
              onClick={applyFiltersHandler}
              disabled={disabled}
              sx={filterButton}>
              <SearchIcon />
            </Button>
          </Box>
          <FormControlLabel
            control={<Checkbox />}
            label={t('EVENT_DOORMAN.SHOW_ONLY_PRESENT')}
            onChange={presenceFilterHandler}
            checked={presenceFilter}
            disabled={disabled}
          />
        </Box>
        <Box sx={modalChecklistBox}>
          {bookingList && bookingList.length > 0 ? (
            <FormGroup>
              {bookingList?.map(({id, user, isPresent}) => (
                <FormControlLabel
                  control={<Checkbox />}
                  label={user?.name}
                  onChange={() => checkboxHandler(id)}
                  checked={isPresent}
                  disabled={disabled}
                  key={id}
                />
              ))}
            </FormGroup>
          ) : (
            <Typography>{t('EVENT_DOORMAN.NOT_FOUND')}</Typography>
          )}
          {pagination && pagination.current_page < pagination.last_page && (
            <Box sx={loadMoreButtonBox}>
              <Button
                variant={'secondary'}
                color={'default'}
                onClick={handleLoadMore}>
                {t('EVENT_DOORMAN.LOAD_MORE')}
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </SideModal>
  );
};

// Export the component
export default EventSlotDoorman;
