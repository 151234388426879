import React, {useCallback, useState} from 'react';

import {
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
} from '@mui/material';

import {IconSVG} from '@/atoms/IconSVG';

import {useStyle} from './style';
import {AccordionComponentProps} from './type';

const AccordionComponent = ({
  items,
  expandedDefaultItem,
}: AccordionComponentProps) => {
  const {detailContent: detailContentStyle} = useStyle();
  const [expanded, setExpanded] = useState<string | false>(expandedDefaultItem);
  const handleAccordionChange = useCallback(
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    },
    [],
  );

  return (
    <>
      {items
        .filter(item => !item.hidden)
        .map(({id, title, subtitle, content}) => {
          return (
            <Accordion
              key={`accordion-${id}`}
              expanded={expanded === id}
              onChange={handleAccordionChange(id)}>
              <AccordionSummary
                expandIcon={
                  <IconSVG icon={expanded === id ? 'minus' : 'add'} size={24} />
                }
                aria-controls={`${id}-content`}
                id={`${id}-header`}>
                <Typography variant={'titleAktive3'}>{title}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={detailContentStyle}>
                  {subtitle && (
                    <Typography variant={'bodyRegularSmall'}>
                      {subtitle}
                    </Typography>
                  )}
                  {content}
                </Box>
              </AccordionDetails>
            </Accordion>
          );
        })}
    </>
  );
};
export default AccordionComponent;
