import React from 'react';

import {Button} from '@/atoms/Button';
import {IconSVG} from '@/atoms/IconSVG';

import {useStyle} from '../style';
import {AddButtonProps} from '../type';

const AddButton = ({handleClick, disabled = false}: AddButtonProps) => {
  const {addSlot: addSlotStyle} = useStyle();

  return (
    <Button sx={addSlotStyle} onClick={handleClick} disabled={disabled}>
      <IconSVG icon="add" size={20} />
    </Button>
  );
};
export default AddButton;
