import {
  ServerUser,
  ServerUsersPage,
  User,
  UsersPage,
} from '@/store/userManagement/definitions';

export const normalizeUser = ({
  first_name: firstName,
  last_name: lastName,
  email,
  role_name: roleName,
}: ServerUser): User => ({
  contactName: `${firstName} ${lastName}`,
  email,
  role: roleName,
});

export const normalizeUsers = (
  serverUsers: ServerUsersPage | null,
): UsersPage | null => {
  if (serverUsers === null) {
    return null;
  }

  return {
    totalCount: serverUsers.total_count,
    nextPage: serverUsers.next_page,
    content: serverUsers.content.map(normalizeUser),
  };
};
