import {
  Invitations,
  InvitationsServerResponse,
} from '@/store/invitations/definitions';

export const normalizeInvitations = (
  invitations: InvitationsServerResponse | null,
): Invitations | null => {
  if (invitations === null) {
    return null;
  }

  return Object.keys(invitations).reduce((acc, locationName) => {
    const InvitationsByLocation = invitations[locationName];

    return {
      ...acc,
      [locationName]: InvitationsByLocation.map(
        ({
          id: eventId,
          name,
          location,
          calendar: {start_date: startDate, end_date: endDate},
          category,
        }) => ({
          eventId,
          name,
          location,
          startDate,
          endDate,
          category,
        }),
      ),
    };
  }, {});
};
