import {useMemo} from 'react';

import {useApi} from '@/store/api';

import type {
  UseGetEvents,
  UseGetEvent,
  UseCreateEvent,
  ServerEvent,
  UseUpdateEvent,
  UseDeleteEvent,
  UseToggleVisibility,
} from './definitions';

export const useGetEvents: UseGetEvents = (autoRun = true) => {
  const options = useMemo(
    () => ({
      autoRun,
    }),
    [autoRun],
  );
  return useApi('/api/v1/events', options);
};

export const useGetEvent: UseGetEvent = (eventId, autoRun = true) => {
  const options = useMemo(
    () => ({
      autoRun,
    }),
    [autoRun],
  );

  return useApi(`/api/v1/events/${eventId}`, options);
};

export const useCreateEvent: UseCreateEvent = event => {
  const options = useMemo(
    () => ({
      autoRun: false,
      req: {
        method: 'POST',
      },
      body: event,
    }),
    [event],
  );

  const {loading, error, response, run} = useApi<ServerEvent>(
    '/api/v1/events',
    options,
  );

  return {
    loading,
    error,
    response,
    run,
  };
};

export const useUpdateEvent: UseUpdateEvent = event => {
  const options = useMemo(
    () => ({
      autoRun: false,
      req: {
        method: 'PUT',
      },
      body: event,
    }),
    [event],
  );

  return useApi(`/api/v1/events/${event.id}`, options);
};

export const useToggleVisibility: UseToggleVisibility = eventId => {
  const options = useMemo(
    () => ({
      autoRun: false,
      req: {
        method: 'PUT',
      },
    }),
    [],
  );

  return useApi(`/api/v1/events/${eventId}/visibility`, options);
};

export const useDeleteEvent: UseDeleteEvent = (id: number) => {
  const {loading, error, response, run} = useApi<any>(`/api/v1/events/${id}`, {
    autoRun: false,
    req: {
      method: 'DELETE',
    },
  });

  return {loading, error, response, run};
};
