import {HookResponse} from '@/store/api';

export enum Category {
  ORDINARY_OPENING = 'Ordinary Opening',
  SPECIAL_SALE = 'Special Sale',
  CORPORATE = 'Corporate Event',
  CLOTHING = 'Clothing Allowance',
}

export type ServerCategory = {
  id: number;
  name:
    | Category.CORPORATE
    | Category.CLOTHING
    | Category.SPECIAL_SALE
    | Category.ORDINARY_OPENING;
  slot_duration_hours: number;
};

export type Categories = ServerCategory[];

export type CategoriesResponse = HookResponse<Categories | null>;

export type UseGetCategories = (
  locationId: number,
  autoRun?: boolean,
) => CategoriesResponse;
