import {useEffect} from 'react';

import {Box, Typography} from '@mui/material';
import {useTranslations} from '@vidiemme/react-i18n';

import {useToast} from '@/hooks/toast';
import {useStyle} from '@/organisms/ResponseErrorMessage/styles';

import {Props} from './definitions';

const ResponseErrorMessage = ({
  hideMessage = false,
  minimalMessage = false,
  showToast = false,
}: Props) => {
  const {container} = useStyle();

  const {t} = useTranslations();

  const {showErrorToast} = useToast(); // Get functions for displaying success and error toast

  useEffect(() => {
    if (showToast) {
      showErrorToast({
        message: `${t('RESPONSE_ERROR_MESSAGE.TITLE')}: ${t(
          'RESPONSE_ERROR_MESSAGE.MESSAGE',
        )}`,
      });
    }
  }, [showErrorToast, showToast, t]);

  return (
    <>
      {!hideMessage && (
        <Box sx={container}>
          {!minimalMessage && (
            <Typography variant={'bodyBold'}>
              {t('RESPONSE_ERROR_MESSAGE.TITLE')}
            </Typography>
          )}
          <Typography variant={'bodyBold'}>
            {t('RESPONSE_ERROR_MESSAGE.MESSAGE')}
          </Typography>
        </Box>
      )}
    </>
  );
};
export default ResponseErrorMessage;
