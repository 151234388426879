import {useMemo} from 'react';

import {SxProps} from '@mui/material';

import {theme} from '@/themes/default';

import {IUseStyle} from './type';

export const useStyle = (): IUseStyle => {
  const boxWrapperSlotTimes: SxProps = useMemo(
    (): SxProps => ({
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      rowGap: '10px',
      [theme.breakpoints.up('md')]: {
        rowGap: '0',
        columnGap: '10px',
        flexDirection: 'row',
      },
    }),
    [],
  );

  const wrapperButtons: SxProps = useMemo(
    (): SxProps => ({
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      columnGap: '10px',
      [theme.breakpoints.up('md')]: {
        width: '33.3333333333%',
        justifyContent: 'flex-end',
      },
    }),
    [],
  );

  const deleteSlot: SxProps = useMemo(
    (): SxProps => ({
      padding: 0,
      width: 'calc(100% - 44px)',
    }),
    [],
  );

  const deleteSlotFullsize: SxProps = useMemo(
    (): SxProps => ({
      padding: 0,
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: 'calc(100% - 44px)',
      },
    }),
    [],
  );

  return {
    boxWrapperSlotTimes,
    wrapperButtons,
    deleteSlot,
    deleteSlotFullsize,
  };
};
