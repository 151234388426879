import React, {useEffect} from 'react';

import {Container, Box} from '@mui/material';

import {PageTitle} from '@/molecules/PageTitle';
import {Footer} from '@/organisms/Footer';
import {NavigationBar} from '@/organisms/NavigationBar';

import {IProps} from './interfaces';
import {useStyle} from './style';

const Base = ({
  hideNavbar,
  hideFooter,
  title,
  preTitle,
  breadCrumbs,
  buttons,
  disableGutters,
  children,
}: IProps): JSX.Element => {
  const {container: containerStyle, content: contentStyle} = useStyle();

  /* For initial render, scroll to top each page */
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {!hideNavbar && <NavigationBar />}
      <Box sx={contentStyle}>
        <PageTitle
          title={title}
          preTitle={preTitle}
          breadCrumbs={breadCrumbs}
          buttons={buttons}
        />
        <Container
          maxWidth={false}
          disableGutters={disableGutters}
          sx={containerStyle}>
          {children}
        </Container>
      </Box>
      {!hideFooter && <Footer />}
    </>
  );
};

export default React.memo(Base);
