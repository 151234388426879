import {FunctionComponent, useCallback} from 'react';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, {SelectChangeEvent} from '@mui/material/Select';

import {IProps, menuItem} from '@/organisms/SelectComponent/interfaces';
import {useStyle} from '@/organisms/SelectComponent/styles';

const SelectComponent: FunctionComponent<IProps> = ({
  label,
  menuItems,
  isClothing = false,
  isDisabled = false,
  startValue,
  handleFunction,
}: IProps) => {
  const {formControlContainer} = useStyle({isClothing: isClothing});

  // To avoid MUI alert for missing value in select options
  const value = !menuItems.find(item => item.value === startValue)?.disabled
    ? startValue
    : '';

  const selectChangeHandler = useCallback(
    (event: SelectChangeEvent<any>) => {
      if (handleFunction) {
        handleFunction(event.target.value);
      }
    },
    [handleFunction],
  );

  return (
    <FormControl sx={formControlContainer}>
      <InputLabel id={label}>{label}</InputLabel>
      <Select
        labelId={label}
        id={`${label}-select`}
        value={value}
        label={label}
        disabled={isDisabled}
        onChange={selectChangeHandler}>
        {menuItems.map((element: menuItem) => {
          return (
            <MenuItem
              key={element.value}
              value={element.value}
              disabled={element.disabled}>
              {element.label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
export default SelectComponent;
