import {useApi} from '@/store/api';
import {ServerLocations, UseGetLocations} from '@/store/locations/definitions';

export const useGetLocations: UseGetLocations = () => {
  const {loading, error, response} =
    useApi<ServerLocations>('/api/v1/locations');

  return {
    loading,
    error,
    response,
  };
};
