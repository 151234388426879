import {useCallback, useMemo, useState} from 'react';
import {NavLink, useLocation} from 'react-router-dom';

import {AppBar, Box, MenuItem, Typography, Drawer} from '@mui/material';
import {useTranslations} from '@vidiemme/react-i18n';

import {Button} from '@/atoms/Button';
import {IconSVG} from '@/atoms/IconSVG';
import {useUserContext} from '@/contexts/user';
import {useToast} from '@/hooks/toast';
import {useIsDesktop} from '@/hooks/viewport';
import {AnonymousRoutes, AuthRoutes} from '@/navigation/routes';
import {useStyle} from '@/organisms/NavigationBar/style';
import {useUser} from '@/store/user';
import {theme} from '@/themes/default';

const NavigationBar = () => {
  const {
    navbarItem,
    navbarMenuLogoBox,
    navbarContainer,
    navbarMenuItemsBox,
    navbarLogoutBox,
    drawerHeader,
    drawerBody,
    drawerMenuItemsBox,
    drawerLogout,
    drawerCTA,
  } = useStyle();

  const {isAuthenticated, userInfo} = useUserContext();

  const location = useLocation();

  const {t} = useTranslations();

  const {showErrorToast} = useToast();

  const isDesktop = useIsDesktop();

  const {logout} = useUserContext();

  const {navigationItems} = useUser();

  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleLogoutButton = useCallback(async () => {
    try {
      await logout();
    } catch (e) {
      showErrorToast({
        message: t('RESPONSE_ERROR_MESSAGE.MESSAGE'),
      });
    }
  }, [logout, showErrorToast, t]);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }
      setDrawerOpen(open);
    };

  const getURI = useCallback((item: string) => {
    const found =
      Object.values(AuthRoutes)[Object.keys(AuthRoutes).indexOf(item)];

    if (found) {
      return found;
    }

    return Object.values(AnonymousRoutes)[
      Object.keys(AnonymousRoutes).indexOf(item)
    ];
  }, []);

  const links = useMemo(() => {
    return navigationItems(userInfo?.role).map(item => {
      return (
        <MenuItem key={item}>
          <NavLink
            className={
              location.pathname === `/${item.toLowerCase()}` ? 'active' : ''
            }
            to={getURI(item)}>
            <Typography variant={'bodyBold'} sx={navbarItem}>
              {t('NAVBAR.item', {context: item})}
            </Typography>
          </NavLink>
        </MenuItem>
      );
    });
  }, [
    getURI,
    location.pathname,
    navbarItem,
    navigationItems,
    t,
    userInfo?.role,
  ]);

  const userFullName = useMemo(() => {
    if (userInfo?.name) {
      // Use the first name a firstName is like "Giulio Mario Francesco"
      const [firstName] = userInfo.firstName.split(' ');

      return (
        <>
          <>{firstName}</>
          <span className="logout-box__surname">{` ${userInfo.lastName}`}</span>
        </>
      );
    }

    return <></>;
  }, [userInfo?.firstName, userInfo?.lastName, userInfo?.name]);

  const logoutContent = useMemo(
    () => (
      <>
        <MenuItem>
          {isAuthenticated && (
            <Typography color={'black'}>{userFullName}</Typography>
          )}
        </MenuItem>
        {isAuthenticated && (
          <MenuItem>
            <Button
              variant={'primary'}
              color={'default'}
              onClick={handleLogoutButton}
              fullWidth={!isDesktop}>
              {t('NAVBAR.item_LOGOUT')}
            </Button>
          </MenuItem>
        )}
      </>
    ),
    [handleLogoutButton, isAuthenticated, isDesktop, t, userFullName],
  );

  /* Desktop navbar */
  if (isDesktop) {
    return (
      <AppBar sx={navbarContainer}>
        <Box sx={navbarMenuLogoBox}>
          <NavLink to={AuthRoutes.BOOKINGS}>
            <IconSVG icon={'logo-versace'} size={125} />
          </NavLink>
        </Box>
        <Box sx={navbarMenuItemsBox}>{links}</Box>
        <Box sx={navbarLogoutBox}>{logoutContent}</Box>
      </AppBar>
    );
  }

  /* Mobile navbar with drawer */
  return (
    <AppBar sx={navbarContainer}>
      <Box sx={navbarMenuLogoBox}>
        <NavLink to={AuthRoutes.BOOKINGS}>
          <IconSVG icon={'logo-versace'} size={99} />
        </NavLink>
      </Box>
      <Button onClick={toggleDrawer(true)} sx={drawerCTA}>
        <IconSVG icon="burger-menu" size={24} color={theme.palette.black} />
      </Button>
      <Drawer
        PaperProps={{
          sx: {width: '100%', boxShadow: 'none'},
        }}
        anchor={'right'}
        open={drawerOpen}>
        <Box sx={drawerHeader}>
          <NavLink to={AuthRoutes.BOOKINGS}>
            <IconSVG icon={'logo-versace'} size={99} />
          </NavLink>
          <Button onClick={toggleDrawer(false)} sx={drawerCTA}>
            <IconSVG icon="cross" size={24} color={theme.palette.black} />
          </Button>
        </Box>
        <Box sx={drawerBody}>
          <Box sx={drawerMenuItemsBox}>{links}</Box>
          <Box sx={drawerLogout}>{logoutContent}</Box>
        </Box>
      </Drawer>
    </AppBar>
  );
};

export default NavigationBar;
