import React from 'react';

import {Box, Typography} from '@mui/material';
import {useTranslations} from '@vidiemme/react-i18n';

import {AddButton} from '@/molecules/WeeklySchedule/AddButton';
import {EmptyOpeningHoursProps} from '@/molecules/WeeklySchedule/EmptyOpeningHours/type';
import {useStyle} from '@/molecules/WeeklySchedule/style';

const EmptyOpeningHours = ({
  disabled = false,
  isLoading,
  incrementSlotChangeHandler,
}: EmptyOpeningHoursProps) => {
  const {t} = useTranslations();
  const {
    boxWrapperSlotClosed: boxWrapperSlotClosedStyle,
    slotClosed: slotClosedStyle,
  } = useStyle();
  return (
    <Box sx={boxWrapperSlotClosedStyle}>
      <Typography variant="bodyRegular" sx={slotClosedStyle}>
        {t('EVENT_CREATE_EDIT.WEEKLY_SCHEDULE.CLOSED')}
      </Typography>
      <Box>
        <AddButton
          disabled={isLoading || disabled}
          handleClick={incrementSlotChangeHandler}
        />
      </Box>
    </Box>
  );
};
export default EmptyOpeningHours;
