import React from 'react';
import {Navigate, Outlet} from 'react-router-dom';

import {useUserContext} from '@/contexts/user';
import {AuthRoutes} from '@/navigation/routes';
import {ROLES} from '@/store/roles';

const AtLeastAdmin = () => {
  const {role} = useUserContext();

  if (role === ROLES.ADMIN) {
    return <Outlet />;
  }
  return <Navigate to={AuthRoutes.BOOKINGS} replace />;
};

export default AtLeastAdmin;
