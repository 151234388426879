import {ChangeEvent, useCallback, useEffect, useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';

import {Box, TextField, Typography} from '@mui/material';
import {useTranslations} from '@vidiemme/react-i18n';
import validate from 'validate.js';

import {useStyle} from '@/pages/InvitationGuest/InviteGuestInput/style';
import {IProps} from '@/pages/InvitationGuest/InviteGuestInput/type';
import {InvitedGuest} from '@/pages/InvitationGuest/type';

const InviteGuestInput = ({
  guest,
  changeGuestInfoHandler,
  index,
  displayIndex,
  setErrorHandler,
}: IProps) => {
  // Styles
  const {
    inviteGuestInputContentBox,
    inviteGuestInputBox,
    inviteGuestInputInputBox,
    inviteGuestTitle,
  } = useStyle();

  // Translation
  const {t} = useTranslations();

  // State for Guest Name
  const [guestName, setGuestName] = useState(
    guest.first_name !== '' ? guest.first_name : '',
  );

  // State for Guest Last Name
  const [guestLastName, setGuestLastName] = useState(
    guest.last_name !== '' ? guest.last_name : '',
  );

  // State for Guest Email
  const [guestEmail, setGuestEmail] = useState(
    guest.email !== '' ? guest.email : '',
  );

  // Guest Name Number
  const guestNameNumber = useMemo(
    () => `${t('INVITE_GUEST.GUEST')} ${displayIndex}`,
    [displayIndex, t],
  );

  // Event ID from URL Params
  const {id: event_id} = useParams();

  // Current Guest Object
  const currentGuest: InvitedGuest = useMemo(
    () => ({
      first_name: guestName,
      last_name: guestLastName,
      email: guestEmail,
      event_id: Number(event_id),
    }),
    [event_id, guestEmail, guestLastName, guestName],
  );

  // Validate the guest data and set errors
  useEffect(() => {
    setErrorHandler(
      validate(
        {
          name: currentGuest.first_name,
          lastName: currentGuest.last_name,
          email: currentGuest.email,
        },
        {
          name: {
            length: {minimum: 2, message: ''},
          },
          lastName: {
            length: {minimum: 2, message: ''},
          },
          email: {
            email: true,
          },
        },
      ),
    );
  }, [
    currentGuest.email,
    currentGuest.first_name,
    currentGuest.last_name,
    setErrorHandler,
  ]);

  // Callback to change guest information
  const changeGuestInfoHandlerCallback = useCallback(() => {
    return changeGuestInfoHandler(currentGuest, index);
  }, [changeGuestInfoHandler, currentGuest, index]);

  // Handler for changing guest name
  const guestNameChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setGuestName(event.target.value);
    },
    [],
  );

  // Handler for changing guest last name
  const guestLastNameChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setGuestLastName(event.target.value);
    },
    [],
  );

  // Handler for changing guest email
  const guestEmailChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setGuestEmail(event.target.value);
    },
    [],
  );

  return (
    <Box sx={inviteGuestInputContentBox}>
      <Typography sx={inviteGuestTitle} variant={'titleVersace2'}>
        {guestNameNumber}
      </Typography>

      <Box sx={inviteGuestInputBox}>
        {/* Input field for guest first name */}
        <TextField
          onChange={guestNameChangeHandler}
          onBlur={changeGuestInfoHandlerCallback}
          id="standard-search"
          label={`${guestNameNumber} - ${t('INVITE_GUEST.FIRST_NAME')}*`}
          type="text"
          sx={inviteGuestInputInputBox}
          value={guestName}
        />

        {/* Input field for guest last name */}
        <TextField
          onChange={guestLastNameChangeHandler}
          onBlur={changeGuestInfoHandlerCallback}
          id="standard-search"
          label={`${guestNameNumber} - ${t('INVITE_GUEST.LAST_NAME')}*`}
          type="text"
          sx={inviteGuestInputInputBox}
          value={guestLastName}
        />

        {/* Input field for guest email */}
        <TextField
          onChange={guestEmailChangeHandler}
          onBlur={changeGuestInfoHandlerCallback}
          id="standard-search"
          label={`${guestNameNumber} - ${t('INVITE_GUEST.EMAIL_ADDRESS')}*`}
          type="text"
          sx={inviteGuestInputInputBox}
          value={guestEmail}
        />
      </Box>
    </Box>
  );
};

export default InviteGuestInput;
