import {useMemo} from 'react';

import {SxProps} from '@mui/material';

import {IPropsStyle} from '@/pages/Users/type';
import {theme} from '@/themes/default';

export const useStyle = (): IPropsStyle => {
  const searchContainerBox: SxProps = useMemo(
    (): SxProps => ({
      margin: '2% 0',
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',

      [theme.breakpoints.up('md')]: {
        width: '50%',
      },
    }),
    [],
  );

  const formControlSearchBox: SxProps = useMemo(
    (): SxProps => ({
      width: '45%',
    }),
    [],
  );

  const tableContainer: SxProps = useMemo(
    (): SxProps => ({
      width: '100%',
      overflowX: 'auto',
      borderLeft: `1px solid ${theme.palette.gray2}`,
      borderRight: `1px solid ${theme.palette.gray2}`,
    }),
    [],
  );

  /*
    The design suggests using a table with a fixed height.
    Currently, this behavior is implemented using the following style.
    However, the achieved result may not be considered very user-friendly.

    Nonetheless, let's keep this style here for potential future use.
  */
  const fixedHeightTable: SxProps = useMemo(
    (): SxProps => ({
      minWidth: '600px',

      tbody: {
        display: 'block',
        maxHeight: '580px',
        overflowY: 'scroll',

        tr: {
          height: '80px',

          td: {
            whiteSpace: 'normal',
            wordBreak: 'break-all',
          },
        },
      },

      // https://stackoverflow.com/a/23989771
      'thead, tbody tr, tfoot': {
        display: 'table',
        width: '100%',
        // scrollbar is average 1em/16px width, remove it from thead width
        // width: 'calc( 100% - 1em )',
        tableLayout: 'fixed',
      },

      thead: {
        width: '100%',

        tr: {
          backgroundColor: theme.palette.black,
          height: '64px',

          th: {
            color: theme.palette.white,
            fontWeight: 700,
          },
        },
      },

      tfoot: {
        width: '100%',

        tr: {
          backgroundColor: theme.palette.gray1,
          height: '64px',
        },
      },
    }),
    [],
  );

  const table: SxProps = useMemo(
    (): SxProps => ({
      minWidth: '600px',

      tbody: {
        tr: {
          height: '80px',

          td: {
            whiteSpace: 'normal',
            wordBreak: 'break-all',
          },
        },
      },

      thead: {
        tr: {
          backgroundColor: theme.palette.black,
          height: '64px',

          th: {
            color: theme.palette.white,
            fontWeight: 700,
          },
        },
      },

      tfoot: {
        tr: {
          backgroundColor: theme.palette.gray1,
          height: '64px',
        },
      },
    }),
    [],
  );

  return {
    searchContainerBox,
    formControlSearchBox,
    tableContainer,
    fixedHeightTable,
    table,
  };
};
