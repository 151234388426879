import {useCallback, useEffect, useMemo, useState} from 'react';

import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  TextField,
  Typography,
} from '@mui/material';
import {useTranslations} from '@vidiemme/react-i18n';

import {Button} from '@/atoms/Button';
import {useUserContext} from '@/contexts/user';
import {useToast} from '@/hooks/toast';
import {AnonymousRoutes} from '@/navigation/routes';
import {REALMS} from '@/store/keycloak';
import {ROLES} from '@/store/roles';
import {usePutUser} from '@/store/user';
import {Modal} from '@/templates/Modal';

import {useStyle} from './style';

const ExternalUserPhoneNumber = () => {
  const {
    modalContainerBox,
    titleBox,
    title,
    inputPhoneNumber,
    formControl,
    formControlContainer,
  } = useStyle();

  const {t} = useTranslations();

  const {showErrorToast} = useToast();

  const [phoneNumber, setPhoneNumber] = useState<string>('');

  const [controlLabel, setControlLabel] = useState<boolean>(false);

  const [disableButton, setDisableButton] = useState(true);

  const [invalidPhoneNumber, setInvalidPhoneNumber] = useState<boolean>(true);

  const {userInfo, setUserInfoHook} = useUserContext();

  const {loading, error, response, run} = usePutUser({
    realm: userInfo?.realm as REALMS,
    email: userInfo?.email,
    phone_number: phoneNumber,
  });

  const phoneNumberValidationHandler = useCallback(() => {
    const regex = new RegExp(/^(?:\+?\d{2}\s?)?(\d{3})\s?(\d{6,})$/);
    setInvalidPhoneNumber(!regex.test(phoneNumber));
  }, [phoneNumber]);

  const phoneNumberHandler = useCallback(
    (event: any) => {
      setPhoneNumber(event.target.value);
      phoneNumberValidationHandler();
    },
    [phoneNumberValidationHandler],
  );

  useEffect(() => {
    setDisableButton(invalidPhoneNumber || !controlLabel);
  }, [controlLabel, invalidPhoneNumber]);

  const controlLabelHandler = useCallback((event: any) => {
    setControlLabel(event.target.checked);
  }, []);

  const submitHandler = useCallback(() => {
    if (run) {
      run();
    }
  }, [run]);

  useEffect(() => {
    if (response) {
      setUserInfoHook(response);
    }
  }, [response, setUserInfoHook]);

  useEffect(() => {
    if (error) {
      showErrorToast({
        message: t('EXTERNAL_USER_PHONE_NUMBER_MODAL.RESPONSE_ERROR_MESSAGE'),
      });
    }
  }, [error, showErrorToast, t]);

  const controlCheckBox = useMemo(
    () => <Checkbox checked={controlLabel} onChange={controlLabelHandler} />,
    [controlLabel, controlLabelHandler],
  );

  const labelCheckBox = useMemo(
    () => (
      <Typography variant="bodyRegular">
        {t('EXTERNAL_USER_PHONE_NUMBER_MODAL.VALIDATION_TEXT_FIRST_PIECE')}
        {/* We cannot use Link by react-router-dom because ExternalUserPhoneNumber is outside the Navigation component */}
        <a href={AnonymousRoutes.PRIVACY_POLICY}>
          <Typography variant="bodyRegular">
            {t('EXTERNAL_USER_PHONE_NUMBER_MODAL.VALIDATION_TEXT_SECOND_PIECE')}
          </Typography>
        </a>
      </Typography>
    ),
    [t],
  );

  if (userInfo && userInfo.role === ROLES.GUEST && !userInfo.phone_number) {
    return (
      <Modal>
        <Box sx={modalContainerBox}>
          <Box sx={titleBox}>
            <Typography variant="titleAktive1" sx={title}>
              {t('EXTERNAL_USER_PHONE_NUMBER_MODAL.TITLE')}
            </Typography>
            <Typography variant="bodyRegular">
              {t('EXTERNAL_USER_PHONE_NUMBER_MODAL.TEXT')}
            </Typography>
          </Box>
          <FormControl sx={formControlContainer}>
            <TextField
              error={invalidPhoneNumber}
              id="phoneNumber"
              label={t('EXTERNAL_USER_PHONE_NUMBER_MODAL.PHONE_NUMBER')}
              variant="outlined"
              placeholder={t('EXTERNAL_USER_PHONE_NUMBER_MODAL.PHONE_NUMBER')}
              onChange={phoneNumberHandler}
              onBlur={phoneNumberValidationHandler}
              value={phoneNumber}
              type="tel"
              sx={inputPhoneNumber}
            />
            <FormControlLabel
              sx={formControl}
              required
              control={controlCheckBox}
              label={labelCheckBox}
            />
            <Button
              variant={'primaryBig'}
              color="default"
              onClick={submitHandler}
              disabled={disableButton}
              loading={loading}
              sx={inputPhoneNumber}>
              {t('EXTERNAL_USER_PHONE_NUMBER_MODAL.SEND_NUMBER')}
            </Button>
          </FormControl>
        </Box>
      </Modal>
    );
  }

  return <></>;
};

export default ExternalUserPhoneNumber;
