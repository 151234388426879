import React, {useCallback, useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';

import {
  Box,
  FormControlLabel,
  FormGroup,
  Switch,
  Typography,
} from '@mui/material';
import {useTranslations} from '@vidiemme/react-i18n';
import moment from 'moment-timezone';

import {Button} from '@/atoms/Button';
import {useUserContext} from '@/contexts/user';
import {useCalendar} from '@/hooks/calendar';
import useDate from '@/hooks/date';
import {AuthRoutes} from '@/navigation/routes';
import {useNavigation} from '@/navigation/useNavigation';
import {useOnPageFocused} from '@/navigation/useOnPageFocused';
import {Calendar} from '@/organisms/Calendar';
import {LoadingMessage} from '@/organisms/LoadingMessage';
import {MessagePage} from '@/organisms/MessagePage';
import {ResponseErrorMessage} from '@/organisms/ResponseErrorMessage';
import {Booking} from '@/store/bookings/definitions';
import {Category} from '@/store/categories';
import {useGetEvent} from '@/store/events';
import {REALMS} from '@/store/keycloak';
import {useGetSlots} from '@/store/slots/hooks';
import {useGetUserBookings} from '@/store/user';
import {Base} from '@/templates/Base';

import {useStyle} from './style';

const Invitation = () => {
  const {t} = useTranslations();
  const {switchBox, minBookingTimeInfoBox} = useStyle();

  const {
    goToInviteGuest,
    goToInvitationSlotDetail,
    goToEventSlotDetail,
    goToInvitationSlotBooking,
  } = useNavigation();

  const {userInfo: {realm} = {}} = useUserContext();

  const {id} = useParams();
  const {getNow, isPast, isSameTimezone, usingTimezone} = useDate();

  const eventId = parseInt(id!);

  const [showTimeLocation, setShowTimeLocation] = useState(true);

  const {
    loading: loadingEvent,
    error: errorEvent,
    response: responseEvent,
    run: runGetEvent,
  } = useGetEvent(eventId, false);

  const getTimezone = useCallback(() => {
    if (responseEvent) {
      return showTimeLocation
        ? responseEvent.location.timezone
        : moment.tz.guess();
    }
    return '';
  }, [responseEvent, showTimeLocation]);

  const slotsQuery = useMemo(
    () => ({
      eventId,
    }),
    [eventId],
  );

  const {
    loading: loadingSlots,
    error: errorSlots,
    response: responseSlots,
    run: runGetSlots,
  } = useGetSlots(slotsQuery, false);

  const {
    loading: loadingGetUserBookings,
    error: errorGetUserBookings,
    response: responseGetUserBookings,
    run: runGetUserBookings,
  } = useGetUserBookings({});

  const onFocus = useCallback(() => {
    runGetEvent!();
    runGetSlots!();
    runGetUserBookings!();
  }, [runGetEvent, runGetSlots, runGetUserBookings]);

  useOnPageFocused(AuthRoutes.INVITATION, onFocus);

  const bookedSlotIds = useMemo(() => {
    if (responseGetUserBookings) {
      for (const locationName in responseGetUserBookings) {
        const bookings = responseGetUserBookings[locationName];

        const foundBookings = bookings
          .filter(booking => booking.eventId === eventId)
          .map(booking => booking.slotId);

        if (foundBookings.length > 0) {
          return foundBookings;
        }
      }
    }
    return [];
  }, [eventId, responseGetUserBookings]);

  const {convertSlotsToFullCalendarEvents} = useCalendar();

  const isClosed = useMemo(() => {
    if (responseEvent) {
      return isPast(responseEvent.calendar.slots.last, getTimezone());
    }

    return false;
  }, [getTimezone, isPast, responseEvent]);

  const inviteGuestsButtonHandler = useCallback(() => {
    goToInviteGuest(eventId);
  }, [eventId, goToInviteGuest]);

  const goToInvitationSlotHandler = useCallback(
    (slotId: number) => {
      if (
        responseGetUserBookings &&
        responseEvent &&
        Object.keys(responseGetUserBookings).length !== 0
      ) {
        const bookingFound = responseGetUserBookings[
          responseEvent.location.name
        ]?.find((booking: Booking): boolean => booking.slotId === slotId);

        if (bookingFound) {
          goToInvitationSlotDetail(eventId, slotId, bookingFound.id);
          return;
        }
      }

      // In the ordinary opening events users can book on behalf of
      responseEvent?.category.name === Category.ORDINARY_OPENING
        ? goToEventSlotDetail(eventId, slotId, true)
        : goToInvitationSlotBooking(eventId, slotId);
    },
    [
      eventId,
      goToEventSlotDetail,
      goToInvitationSlotBooking,
      goToInvitationSlotDetail,
      responseEvent,
      responseGetUserBookings,
    ],
  );

  const switchTimezoneHandler = useCallback(() => {
    setShowTimeLocation(prevShowTimeLocation => !prevShowTimeLocation);
  }, []);

  const buttons = useMemo(() => {
    if (isClosed) {
      return <></>;
    }

    if (realm === REALMS.GUESTS) {
      return <></>;
    }

    if (responseEvent?.category.name === Category.SPECIAL_SALE) {
      return (
        <Button
          variant={'primaryBig'}
          color="default"
          onClick={inviteGuestsButtonHandler}>
          {t('EVENT_LIST.BUTTON_TEXT')}
        </Button>
      );
    }
    return <></>;
  }, [
    inviteGuestsButtonHandler,
    isClosed,
    realm,
    responseEvent?.category.name,
    t,
  ]);

  const body = useMemo(() => {
    if (errorEvent || errorSlots || errorGetUserBookings) {
      return <ResponseErrorMessage showToast />; // Display error message
    }

    if (isClosed) {
      return (
        <MessagePage
          title={t('EVENT_ENDED.TITLE')}
          message={t('EVENT_ENDED.MESSAGE')}
        />
      );
    }

    if (
      responseSlots &&
      responseGetUserBookings &&
      responseEvent &&
      !loadingSlots
    ) {
      /* Get only user bookings for this event */
      const userBookings = responseGetUserBookings[
        responseEvent.location.name
      ]?.filter((booking: Booking): boolean => booking.eventId === eventId);

      /* Remove past bookings */
      const userFutureBookings = userBookings
        ? userBookings.reduce((accumulator: any, booking: Booking) => {
            if (isPast(booking.slot!.startTime, getTimezone())) {
              return accumulator;
            }
            return [...accumulator, booking];
          }, [])
        : [];

      const events = convertSlotsToFullCalendarEvents({
        slots: responseSlots,
        bookings: userBookings,
        timezone: getTimezone(),
        minBookingTime: responseEvent.min_booking_time,
      });

      /** Get initial calendar date, the default is first event slot(if is not past),
       * but if you have a valid booking set it or set the current date */
      const startDateFirstSlot = responseSlots[0]?.startTime;
      let initialDate = usingTimezone(
        startDateFirstSlot,
        getTimezone(),
      ).format();
      if (userFutureBookings.length > 0) {
        initialDate = usingTimezone(
          userFutureBookings[0].slot!.startTime,
          getTimezone(),
        ).format();
      } else if (isPast(startDateFirstSlot, getTimezone())) {
        initialDate = getNow(getTimezone());
      }

      return (
        <FormGroup>
          {!!responseEvent.min_booking_time && (
            <Box sx={minBookingTimeInfoBox}>
              <Typography variant={'bodyRegular'}>
                {t('INVITATIONS.MIN_BOOKING_ALERT', {
                  hours: responseEvent.min_booking_time,
                })}
              </Typography>
            </Box>
          )}
          <Box sx={switchBox}>
            {!isSameTimezone(responseEvent.location.timezone) && (
              <FormControlLabel
                control={
                  <Switch
                    checked={showTimeLocation}
                    onChange={switchTimezoneHandler}
                  />
                }
                label={t('TIMEZONE.SWITCH_TIME_LOCATION', {
                  location: responseEvent.location.city,
                })}
              />
            )}
          </Box>

          <Calendar
            events={events}
            fullCalendarProps={{initialDate}}
            itemClickHandler={goToInvitationSlotHandler}
            bookedSlotIds={bookedSlotIds}
            allowMultipleBookings={responseEvent.allow_multiple_bookings}
            timezone={getTimezone()}
          />
        </FormGroup>
      );
    }

    if (loadingSlots || loadingGetUserBookings || loadingEvent) {
      return <LoadingMessage />; // Display loading message
    }

    return null;
  }, [
    errorEvent,
    errorSlots,
    errorGetUserBookings,
    isClosed,
    responseSlots,
    responseGetUserBookings,
    responseEvent,
    loadingSlots,
    loadingGetUserBookings,
    loadingEvent,
    t,
    convertSlotsToFullCalendarEvents,
    getTimezone,
    usingTimezone,
    isPast,
    minBookingTimeInfoBox,
    switchBox,
    isSameTimezone,
    showTimeLocation,
    switchTimezoneHandler,
    goToInvitationSlotHandler,
    bookedSlotIds,
    eventId,
    getNow,
  ]);

  const {name: eventName = '', location: {name: locationName = ''} = {}} =
    responseEvent || {};

  return (
    <Base
      preTitle={locationName}
      breadCrumbs={[
        {label: t('NAVBAR.item_INVITATIONS'), uri: AuthRoutes.INVITATIONS},
        {
          label: eventName && `${locationName} - ${eventName}`,
          uri: '',
        },
      ]}
      title={eventName}
      buttons={buttons}>
      {body}
    </Base>
  );
};

export default Invitation;
