import {useMemo} from 'react';

import {SxProps} from '@mui/material';

import {IPropsStyle} from '@/pages/LoginExternal/LoginExternalForm/type';

export const useStyle = (): IPropsStyle => {
  const formContainer: SxProps = useMemo(
    (): SxProps => ({
      padding: '0px !important',
      width: '100%',
    }),
    [],
  );

  const externalFormInputEmail: SxProps = useMemo(
    (): SxProps => ({
      width: '100%',
      marginBottom: '18px',
    }),
    [],
  );

  const externalFormInputPassword: SxProps = useMemo(
    (): SxProps => ({
      width: '100%',
      marginBottom: '62px',
    }),
    [],
  );

  const externalFormButton: SxProps = useMemo(
    (): SxProps => ({
      width: '100%',
    }),
    [],
  );

  const loginErrorMessage: SxProps = useMemo(
    (): SxProps => ({
      span: {
        color: '#BF2D2D',
        margin: '-50px 0 64px 0',
        height: '100%',
        display: 'flex',
        position: 'absolute',
      },
    }),
    [],
  );

  return {
    externalFormInputEmail,
    externalFormInputPassword,
    externalFormButton,
    loginErrorMessage,
    formContainer,
  };
};
