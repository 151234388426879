import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';

import {Box, Typography} from '@mui/material';
import {DatePicker} from '@mui/x-date-pickers';
import {useTranslations} from '@vidiemme/react-i18n';

import useDate from '@/hooks/date';
import {useToast} from '@/hooks/toast';
import {useNavigation} from '@/navigation/useNavigation';
import {dateProp} from '@/organisms/EventGeneralInfo/type';
import {useDownloadBookedPeople} from '@/store/bookings';
import {SideModal} from '@/templates/SideModal';

import {useStyle} from './style';

const EventBookingsDownload = () => {
  const {bookingModalContainerBox, dateFieldStyle} = useStyle();

  const {goToPreviousPage} = useNavigation();
  const {t} = useTranslations();
  const {showErrorToast} = useToast();
  const {dateToDateFormat} = useDate();

  const {id: eventId} = useParams();
  const timezone = 'UTC';

  const [startDate, setStartDate] = useState<dateProp>(null);
  const [endDate, setEndDate] = useState<dateProp>(null);

  const startDateChangeHandler = setStartDate;

  const endDateChangeHandler = setEndDate;

  const closeModalHandler = useCallback(() => {
    goToPreviousPage();
  }, [goToPreviousPage]);

  const bookingsRequest = useMemo(
    () => ({
      eventId: parseInt(eventId!),
      startDate: startDate ? dateToDateFormat(startDate.format()) : '',
      endDate: endDate ? dateToDateFormat(endDate.format()) : '',
    }),
    [dateToDateFormat, eventId, endDate, startDate],
  );

  const {
    loading: downloadBookedPeopleLoading,
    error: downloadBookedPeopleError,
    run: downloadBookedPeopleRun,
  } = useDownloadBookedPeople(bookingsRequest);

  // Show error toast if download booked people fails
  useEffect(() => {
    if (downloadBookedPeopleError) {
      showErrorToast({message: t('RESPONSE_ERROR_MESSAGE.MESSAGE')});
    }
  }, [downloadBookedPeopleError, showErrorToast, t]);

  return (
    <SideModal
      title={t('EVENT_BOOKINGS_DOWNLOAD.TITLE')}
      subTitle={t('EVENT_BOOKINGS_DOWNLOAD.SUB_TITLE')}
      closeHandler={closeModalHandler}
      textButtonOne={t('EVENT_BOOKINGS_DOWNLOAD.TEXT_BUTTON_ONE')}
      textButtonTwo={t('EVENT_BOOKINGS_DOWNLOAD.TEXT_BUTTON_TWO')}
      CTAButtonOne={closeModalHandler}
      CTAButtonTwo={downloadBookedPeopleRun!}
      buttonOneDisabled={false}
      buttonTwoDisabled={
        downloadBookedPeopleLoading || (!startDate && !endDate)
      }
      buttonTwoLoading={downloadBookedPeopleLoading}>
      <Box sx={bookingModalContainerBox}>
        <Typography variant={'titleAktive3'}>
          {t('EVENT_BOOKINGS_DOWNLOAD.ADDITIONAL_TEXT')}
        </Typography>
        <DatePicker
          label={t('EVENT_BOOKINGS_DOWNLOAD.START_DATE')}
          onChange={startDateChangeHandler}
          value={startDate ?? null}
          timezone={timezone}
          maxDate={endDate ?? undefined}
          disabled={downloadBookedPeopleLoading}
          sx={dateFieldStyle}
        />
        <DatePicker
          label={t('EVENT_BOOKINGS_DOWNLOAD.END_DATE')}
          onChange={endDateChangeHandler}
          value={endDate ?? null}
          minDate={startDate ?? undefined}
          timezone={timezone}
          disabled={downloadBookedPeopleLoading}
          sx={dateFieldStyle}
        />
      </Box>
    </SideModal>
  );
};
export default EventBookingsDownload;
