import {CSSProperties, useMemo} from 'react';

import {theme} from '@/themes/default';

export const useStyle = () => {
  const h2 = useMemo(
    () => ({
      marginTop: '16px',
    }),
    [],
  );

  const h3 = useMemo(
    () => ({
      marginTop: '12px',
    }),
    [],
  );

  const p = useMemo(
    () => ({
      marginBottom: '8px',
      a: {
        color: theme.palette.common.black,
      },
    }),
    [],
  );

  const table: CSSProperties = useMemo(
    () => ({
      marginTop: '20px',
      marginBottom: '20px',
      display: 'block',
      overflowX: 'auto',
      whiteSpace: 'nowrap',
    }),
    [],
  );

  const tableCell: CSSProperties = useMemo(
    () => ({
      paddingLeft: '15px',
      fontWeight: 400,
    }),
    [],
  );

  const dateUpdated = useMemo(
    () => ({
      marginTop: '80px',
      marginBottom: '50px',
    }),
    [],
  );

  const divider = useMemo(
    () => ({
      marginBottom: '50px',
    }),
    [],
  );

  return {h2, h3, p, table, tableCell, dateUpdated, divider};
};
