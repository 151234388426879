import {useCallback, useEffect, useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';

import {Box} from '@mui/material';
import {useTranslations} from '@vidiemme/react-i18n';

import {Button} from '@/atoms/Button';
import {useToast} from '@/hooks/toast';
import {useNavigation} from '@/navigation/useNavigation';
import {InviteGuestInput} from '@/pages/InvitationGuest/InviteGuestInput';
import {InvitedGuestComponent} from '@/pages/InvitationGuest/InvitedGuestComponent';
import {useStyle} from '@/pages/InvitationGuest/style';
import {InvitedGuest, InvitedGuests} from '@/pages/InvitationGuest/type';
import {useGetEvent} from '@/store/events';
import {useGetUsersMeGuest, usePutUserInvite} from '@/store/user';
import {SideModal} from '@/templates/SideModal';

const InvitationGuest = () => {
  // Styles
  const {inviteGuestAddInputBox, inviteGuestContentBox} = useStyle();

  // Toast Messages
  const {showSuccessToast, showErrorToast} = useToast();

  // Translation
  const {t} = useTranslations();

  // Navigation
  const {goToPreviousPage} = useNavigation();

  // Event ID from URL Params
  const {id: event_id} = useParams();

  // Validation Error State
  const [validateError, setValidateError] = useState(false);

  const [showGuestsLimit, setShowGuestsLimit] = useState(true);

  const [activeInviteButton, setActiveInviteButton] = useState(true);

  // Initial Guest State
  const initialGuest: InvitedGuest = useMemo(() => {
    return {
      first_name: '',
      last_name: '',
      email: '',
      event_id: 0,
    };
  }, []);

  // Invited Guests State
  const [invitedGuests, setInvitedGuests] = useState<InvitedGuests>([]);

  // Fetch User Me Guest
  const {error: getUserMeGuestError, response: getUserMeGuestResponse} =
    useGetUsersMeGuest(Number(event_id));

  const {response: responseGetEvent} = useGetEvent(Number(event_id));

  const guestsLimit: number = useMemo(
    () => (responseGetEvent ? responseGetEvent.guests_limit : 0),
    [responseGetEvent],
  );

  // Send User Invite
  const {
    loading: putUserInviteLoading,
    error: putUserInviteError,
    response: putUserInviteResponse,
    run,
  } = usePutUserInvite(invitedGuests);

  // Add a New Guest
  const addNewGuestHandler = useCallback(() => {
    setInvitedGuests((prevState: InvitedGuests) => [
      ...prevState,
      {...initialGuest, event_id: Number(event_id)},
    ]);
  }, [event_id, initialGuest]);

  // Change Guest Information
  const changeGuestInfoHandler = useCallback(
    (guest: InvitedGuest, index: number) => {
      const tempInvitedGuests = [...invitedGuests];
      tempInvitedGuests[index] = guest;
      setInvitedGuests(tempInvitedGuests);
    },
    [invitedGuests],
  );

  // Handle Invite Button Click
  const buttonInviteGuestHandler = useCallback(() => {
    if (run) {
      run();
    }
  }, [run]);

  useEffect(() => {
    if (putUserInviteError) {
      showErrorToast({message: t('INVITE_GUEST.RESPONSE_ERROR')});
    } else if (putUserInviteResponse) {
      showSuccessToast({message: t('INVITE_GUEST.RESPONSE_SUCCESS')});
      goToPreviousPage();
    }
  }, [
    goToPreviousPage,
    putUserInviteError,
    putUserInviteResponse,
    showErrorToast,
    showSuccessToast,
    t,
  ]);

  // Set Validation Error State
  const setErrorHandler = setValidateError;

  useEffect(() => {
    if (putUserInviteError || getUserMeGuestError) {
      showErrorToast({message: t('RESPONSE_ERROR_MESSAGE.MESSAGE')});
    }
  }, [getUserMeGuestError, putUserInviteError, showErrorToast, t]);

  useEffect(() => {
    if (responseGetEvent && getUserMeGuestResponse) {
      setShowGuestsLimit(
        invitedGuests.length + getUserMeGuestResponse.data.length <
          responseGetEvent.guests_limit,
      );
      if (invitedGuests[0]?.first_name.length !== 0) {
        setActiveInviteButton(
          validateError || putUserInviteLoading || invitedGuests.length === 0,
        );
      }
    }
  }, [
    getUserMeGuestResponse,
    invitedGuests,
    invitedGuests.length,
    putUserInviteLoading,
    responseGetEvent,
    showGuestsLimit,
    validateError,
  ]);

  const body = useMemo(() => {
    if (getUserMeGuestResponse && responseGetEvent) {
      return (
        <SideModal
          title={t('INVITE_GUEST.TITLE')}
          subTitle={t('INVITE_GUEST.SUB_TITLE', {guests: guestsLimit})}
          closeHandler={goToPreviousPage}
          textButtonOne={t('INVITE_GUEST.TEXT_BUTTON_ONE')}
          textButtonTwo={t('INVITE_GUEST.TEXT_BUTTON_TWO')}
          CTAButtonOne={goToPreviousPage}
          CTAButtonTwo={buttonInviteGuestHandler}
          buttonOneDisabled={false}
          buttonTwoDisabled={
            putUserInviteLoading || Boolean(activeInviteButton)
          }
          buttonTwoLoading={putUserInviteLoading}>
          <Box sx={inviteGuestContentBox}>
            <Box sx={inviteGuestAddInputBox}>
              {/* Display Invited Guests */}
              {getUserMeGuestResponse?.data.map(
                (guest: InvitedGuest, index: number) => (
                  <InvitedGuestComponent
                    key={`${guest.email}-${index}`}
                    guest={guest}
                    index={index}
                  />
                ),
              )}

              {/* Display InviteGuestInput Components for New Guests */}
              {invitedGuests.map((guest: InvitedGuest, index: number) => (
                <InviteGuestInput
                  key={`${guest.email}-${index}`}
                  guest={guest}
                  changeGuestInfoHandler={newGuest =>
                    changeGuestInfoHandler(newGuest, index)
                  }
                  index={index}
                  setErrorHandler={setErrorHandler}
                  displayIndex={Number(
                    index + getUserMeGuestResponse?.data.length + 1,
                  )}
                />
              ))}

              {/* Add New Guest Button */}
              <Button
                variant="primarySmall"
                color="default"
                disabled={validateError || !showGuestsLimit}
                onClick={addNewGuestHandler}>
                +
              </Button>
            </Box>
          </Box>
        </SideModal>
      );
    }

    return null;
  }, [
    activeInviteButton,
    addNewGuestHandler,
    buttonInviteGuestHandler,
    changeGuestInfoHandler,
    getUserMeGuestResponse,
    goToPreviousPage,
    guestsLimit,
    inviteGuestAddInputBox,
    inviteGuestContentBox,
    invitedGuests,
    putUserInviteLoading,
    responseGetEvent,
    setErrorHandler,
    showGuestsLimit,
    t,
    validateError,
  ]);

  return body;
};

export default InvitationGuest;
