import {useMemo} from 'react';

import {useApi} from '@/store/api';
import {
  PutSlotOptions,
  SlotServerResponse,
  SlotsServerResponse,
  UseDeleteSlot,
  UseGetSlot,
  UseGetSlots,
  UsePutSlot,
} from '@/store/slots/definitions';

import {normalizeSlot, normalizeSlots} from './normalizers';

///slots
export const useGetSlots: UseGetSlots = (
  {available, eventDayId, eventId, fromDate, toDate} = {},
  autoRun = true,
) => {
  const options = useMemo(
    () => ({
      autoRun,
      query: {
        available: available,
        event_day_id: eventDayId,
        event_id: eventId,
        from_date: fromDate,
        to_date: toDate,
      },
    }),
    [autoRun, available, eventDayId, eventId, fromDate, toDate],
  );

  const {
    loading,
    error,
    response: serverResponse,
    run,
  } = useApi<SlotsServerResponse>('/api/v1/slots', options);

  return {
    loading,
    error,
    response: normalizeSlots(serverResponse),
    run,
  };
};

export const useGetSlot: UseGetSlot = (id: number) => {
  const {
    loading,
    error,
    response: serverResponse,
  } = useApi<SlotServerResponse>(`/api/v1/slots/${id}`);

  return {
    loading,
    error,
    response: normalizeSlot(serverResponse),
  };
};

export const usePutSlot: UsePutSlot = (
  id: number,
  {slotLimit}: PutSlotOptions,
) => {
  const options = useMemo(
    () => ({
      autoRun: false,
      req: {
        method: 'PUT',
      },
      body: {
        slot_limit: slotLimit,
      },
    }),
    [slotLimit],
  );

  const {
    loading,
    error,
    response: serverResponse,
    run,
  } = useApi<SlotServerResponse>(`/api/v1/slots/${id}`, options);

  return {
    loading,
    error,
    response: normalizeSlot(serverResponse),
    run,
  };
};

export const useDeleteSlot: UseDeleteSlot = (id: number) => {
  const options = useMemo(
    () => ({
      autoRun: false,
      req: {
        method: 'DELETE',
      },
    }),
    [],
  );

  const {loading, error, response, run} = useApi<any>(
    `/api/v1/slots/${id}`,
    options,
  );

  return {
    loading,
    error,
    response,
    run,
  };
};
