import {useMemo} from 'react';

import {SxProps} from '@mui/material';

import {UseStyle} from '@/pages/InvitationGuest/InviteGuestInput/type';
import {theme} from '@/themes/default';

export const useStyle = (): UseStyle => {
  const inviteGuestInputBox: SxProps = useMemo(
    (): SxProps => ({
      display: 'flex',
      marginBottom: '40px',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      alignContent: 'space-between',
      flexDirection: 'column',
      rowGap: '10px',
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        rowGap: '0',
      },
    }),
    [],
  );

  const inviteGuestInputInputBox: SxProps = useMemo(
    (): SxProps => ({
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '30%',
      },
    }),
    [],
  );

  const inviteGuestTitle: SxProps = useMemo(
    (): SxProps => ({
      display: 'flex',
      marginBottom: '16px',
    }),
    [],
  );

  const inviteGuestInputContentBox: SxProps = useMemo(
    (): SxProps => ({
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
    }),
    [],
  );

  return {
    inviteGuestInputBox,
    inviteGuestInputInputBox,
    inviteGuestTitle,
    inviteGuestInputContentBox,
  };
};
