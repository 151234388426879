import {useMemo} from 'react';

import {SxProps} from '@mui/material';

import {theme} from '@/themes/default';

import {IPropsStyle} from './type';

export const useStyle = (): IPropsStyle => {
  const modalContainerBox: SxProps = useMemo(
    (): SxProps => ({
      width: '100%',
      height: 'auto',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      [theme.breakpoints.up('md')]: {
        width: '450px',
      },
    }),
    [],
  );

  const modalEventBox: SxProps = useMemo(
    (): SxProps => ({
      display: 'flex',
      flexDirection: 'column',
    }),
    [],
  );

  const modalGuestBox: SxProps = useMemo(
    () => ({
      marginBottom: '24px',
      display: 'flex',
      justifyContent: 'space-between',
    }),
    [],
  );

  const modalGuestInfoBox: SxProps = useMemo(
    () => ({
      flexWrap: 'wrap',
      flexDirection: 'row',
      columnGap: '8px',
      rowGap: '4px',
    }),
    [],
  );

  const modalNoteBox: SxProps = useMemo(
    (): SxProps => ({
      display: 'flex',
      flexDirection: 'column',
      marginTop: '32px',
      marginBottom: '24px',
    }),
    [],
  );

  const modalNoteTitleBox: SxProps = useMemo(
    (): SxProps => ({
      display: 'flex',
      alignItems: 'center',
      svg: {marginRight: '8px'},
    }),
    [],
  );

  const deleteButton: SxProps = useMemo(
    (): SxProps => ({
      width: '34px',
      padding: '0',
      marginLeft: '16px',
      '&:hover': {
        svg: {
          fill: theme.palette.common.white,
        },
      },
    }),
    [],
  );

  return {
    modalContainerBox,
    modalEventBox,
    modalNoteBox,
    modalGuestBox,
    modalGuestInfoBox,
    modalNoteTitleBox,
    deleteButton,
  };
};
