import type {Bookings} from '@/store/bookings';
import {normalizeBooking} from '@/store/bookings/normalizers';
import {
  ServerSlot,
  Slot,
  Slots,
  SlotsServerResponse,
} from '@/store/slots/definitions';

export const normalizeSlot = (slot: ServerSlot | null): Slot | null => {
  if (slot === null) {
    return null;
  }

  const {
    id,
    start_time: startTime,
    end_time: endTime,
    slot_limit: slotLimit,
    booked_spots: bookedSpots,
    bookings: serverBookings,
    ...restSlot
  } = slot;

  const bookings = serverBookings
    ? (serverBookings.map(normalizeBooking) as Bookings)
    : undefined;

  return {
    ...restSlot,
    id,
    startTime,
    endTime,
    slotLimit,
    bookedSpots,
    bookings,
  };
};

export const normalizeSlots = (
  slots: SlotsServerResponse | null,
): Slots | null => {
  if (slots === null) {
    return null;
  }

  return slots.map(slot => {
    return normalizeSlot(slot) as Slot;
  });
};
