import {HookResponse} from '@/store/api';

export enum ROLES {
  ADMIN = 'admin',
  OPERATOR = 'operator',
  EMPLOYEE = 'employee',
  GUEST = 'external_user',
  UNAUTHENTICATED = 'UNAUTHENTICATED',
}

export interface ServerRole {
  id: number;
  name: ROLES;
  description: string;
}

export type ServerRoles = ServerRole[];

export type CompleteRoleData = ServerRole;
export type CompleteRolesData = CompleteRoleData[];

export type RolesResponse = HookResponse<CompleteRolesData | null>;

export type UseGetRoles = () => RolesResponse;
