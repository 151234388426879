import {useMemo} from 'react';

import {SxProps} from '@mui/material';

import {
  IUseStyle,
  IUseStyleProps,
} from '@/organisms/SelectComponent/interfaces';
import {theme} from '@/themes/default';

export const useStyle = ({isClothing}: IUseStyleProps): IUseStyle => {
  const formControlContainer: SxProps = useMemo(
    (): SxProps => ({
      //height: '40px',
      width: '100%',
      marginBottom: '20px',

      '& label.Mui-focused': {
        color: theme.palette.gray3,
      },
      '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
          borderColor: theme.palette.gray3,
        },
      },
      [theme.breakpoints.down('md')]: {
        paddingBottom: '15px',
      },
      [theme.breakpoints.up('md')]: {
        minWidth: '286px',
        width: isClothing
          ? 'calc((100% / 3) - 15px)'
          : 'calc((100% / 2) - 15px)',
      },
      [theme.breakpoints.up('lg')]: {
        minWidth: 'auto',
      },
    }),
    [isClothing],
  );

  return {
    formControlContainer,
  };
};
