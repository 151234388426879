import React, {useCallback, useMemo} from 'react';

import {Box, Typography} from '@mui/material';
import {DatePicker, TimePicker} from '@mui/x-date-pickers';
import {useTranslations} from '@vidiemme/react-i18n';

import {Button} from '@/atoms/Button';
import useDate from '@/hooks/date';

import {AddButton} from './AddButton';
import {useStyle} from './style';
import {ExtraordinaryScheduleProps, Interval} from './type';

const ExtraordinarySchedule = ({
  day,
  handleChange,
  timezone,
  isLoading,
  eventStartDate,
  eventEndDate,
}: ExtraordinaryScheduleProps) => {
  const {
    boxContainer: boxContainerStyle,
    boxContent: boxContentStyle,
    boxWrapperSlotClosed: boxWrapperSlotClosedStyle,
    slotClosed: slotClosedStyle,
    boxWrapperSlotTimes: boxWrapperSlotTimesStyle,
    day: dayStyle,
    wrapperButtons: wrapperButtonsStyle,
    timePicker: timePickerStyle,
    deleteSlot: deleteSlotStyle,
    deleteSlotFullsize: deleteSlotFullsizeStyle,
  } = useStyle();
  const {usingTimezone} = useDate();
  const {t} = useTranslations();

  const incrementSlotChangeHandler = useCallback(() => {
    handleChange({
      ...day,
      intervals: [
        ...day.intervals,
        {
          start_time: null,
          end_time: null,
        },
      ],
    });
  }, [day, handleChange]);

  const decrementSlotChangeHandler = useCallback(
    (index: number) => {
      handleChange({
        ...day,
        intervals: day.intervals.filter((_, i) => {
          return i !== index;
        }),
      });
    },
    [day, handleChange],
  );

  const timeChangeHandler = useCallback(
    (value: any, index: number, timeKey: 'end_time' | 'start_time') => {
      handleChange({
        date: day.date,
        intervals: day.intervals.map((slotInterval, i) => {
          if (i === index) {
            return {
              ...slotInterval,
              [timeKey]: value,
            };
          }
          return {...slotInterval};
        }),
      });
    },
    [day.date, day.intervals, handleChange],
  );

  const dateChangeHandler = useCallback(
    (value: moment.Moment | null) => {
      handleChange({
        ...day,
        date: value,
      });
    },
    [day, handleChange],
  );

  const content = useMemo(() => {
    if (day.intervals.length === 0) {
      return (
        <Box sx={boxWrapperSlotClosedStyle}>
          <Typography variant="bodyRegular" sx={slotClosedStyle}>
            {t('EVENT_CREATE_EDIT.EXTRAORDINARY_SCHEDULE.CLOSED')}
          </Typography>
          <Box>
            <AddButton
              disabled={isLoading}
              handleClick={incrementSlotChangeHandler}
            />
          </Box>
        </Box>
      );
    }

    return day.intervals.map((slotInterval: Interval, index: number) => {
      console.log(slotInterval.start_time);
      return (
        <Box key={index} sx={boxWrapperSlotTimesStyle}>
          <TimePicker
            disabled={isLoading}
            label={t('EVENT_CREATE_EDIT.EXTRAORDINARY_SCHEDULE.START_TIME')}
            minutesStep={30}
            minTime={index > 0 ? day.intervals[index - 1].end_time : undefined}
            skipDisabled={true}
            onChange={newValue =>
              timeChangeHandler(newValue, index, 'start_time')
            }
            value={slotInterval.start_time ?? null}
            timezone={'UTC'} // we don't want to convert the time, the one sent from the backend is in the timezone of the event
            sx={timePickerStyle}
            ampm={false}
          />
          <TimePicker
            disabled={isLoading}
            label={t('EVENT_CREATE_EDIT.EXTRAORDINARY_SCHEDULE.END_TIME')}
            minutesStep={30}
            //minTime={slotInterval.start_time ?? undefined} // I don't know how to fix this, sorry :(
            skipDisabled={true}
            onChange={newValue =>
              timeChangeHandler(newValue, index, 'end_time')
            }
            value={slotInterval.end_time ?? null}
            timezone={'UTC'} // we don't want to convert the time, the one sent from the backend is in the timezone of the event
            sx={timePickerStyle}
            ampm={false}
          />
          <Box sx={wrapperButtonsStyle}>
            {index === day.intervals.length - 1 && (
              <AddButton
                disabled={isLoading}
                handleClick={incrementSlotChangeHandler}
              />
            )}
            {/* DELETE BUTTON */}
            <Button
              disabled={isLoading}
              sx={
                index === day.intervals.length - 1
                  ? deleteSlotStyle
                  : deleteSlotFullsizeStyle
              }
              variant={'secondary'}
              color={'default'}
              onClick={() => decrementSlotChangeHandler(index)}>
              {t('EVENT_CREATE_EDIT.EXTRAORDINARY_SCHEDULE.DELETE_SLOT')}
            </Button>
          </Box>
        </Box>
      );
    });
  }, [
    day.intervals,
    boxWrapperSlotClosedStyle,
    slotClosedStyle,
    t,
    incrementSlotChangeHandler,
    boxWrapperSlotTimesStyle,
    isLoading,
    timezone,
    timePickerStyle,
    wrapperButtonsStyle,
    deleteSlotStyle,
    deleteSlotFullsizeStyle,
    timeChangeHandler,
    decrementSlotChangeHandler,
  ]);

  return (
    <Box sx={boxContainerStyle}>
      <DatePicker
        disabled={isLoading}
        sx={dayStyle}
        timezone={timezone}
        value={day.date}
        onChange={dateChangeHandler}
        minDate={
          eventStartDate ? usingTimezone(eventStartDate, timezone) : undefined
        }
        maxDate={
          eventEndDate ? usingTimezone(eventEndDate, timezone) : undefined
        }
      />
      <Box sx={boxContentStyle}>{content}</Box>
    </Box>
  );
};
export default ExtraordinarySchedule;
