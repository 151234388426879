import React from 'react';

import IcomoonReact from 'icomoon-react';

import {IProps} from './interfaces';
import iconSet from './selection.json';

const IconSVG = ({className, icon, size, color}: IProps): JSX.Element => (
  <IcomoonReact
    className={className}
    iconSet={iconSet}
    color={color}
    size={size}
    icon={icon}
  />
);

export default React.memo(IconSVG);
