import type {ReactNode} from 'react';
import {createContext, useCallback, useContext, useMemo, useState} from 'react';

import {jwtDecode} from 'jwt-decode';

import {REALMS} from '@/store/keycloak';
import {ROLES} from '@/store/roles';
import {
  AccessToken,
  LoginFn,
  LoginSSOFn,
  LogoutFn,
  User,
  useUser,
} from '@/store/user';

interface IUserContext {
  lang: string | undefined;
  handleChangeLang: () => void;
  isAuthenticated: boolean;
  token: AccessToken;
  role: ROLES;
  initialize: () => Promise<void>;
  login: LoginFn;
  loginSSO: LoginSSOFn;
  logout: LogoutFn;
  userInfo: User | undefined;
  setUserInfoHook: (userInfo: Partial<User>) => void;
}

export const defaultValue = (): IUserContext => ({
  lang: undefined,
  handleChangeLang: () => {},
  isAuthenticated: false,
  token: null,
  role: ROLES.UNAUTHENTICATED,
  initialize: async () => {
    console.log('UserProvider not initialized');
  },
  login: async (_realm, _email, _password) => {
    console.log('UserProvider login - not initialized');
    return Promise.resolve('');
  },
  loginSSO: async (_realm, _code) => {
    console.log('UserProvider loginSSO - not initialized');
    return Promise.resolve('');
  },
  logout: async () => {
    console.log('UserProvider logout - not initialized');
  },
  setUserInfoHook: async () => {
    console.log('UserProvider setUserInfoHook - not initialized');
  },
  userInfo: {
    user_id: 0,
    firstName: '',
    lastName: '',
    name: '',
    realm: REALMS.GUESTS,
    role: ROLES.UNAUTHENTICATED,
    permission: '',
    phone_number: '',
    email: '',
  },
});

export const UserContext = createContext<IUserContext>(defaultValue());

export const useUserContext = () => useContext(UserContext);

export const UserProvider = ({children}: {children: ReactNode}) => {
  const {retrieveToken, login, loginSSO, logout, getUserInfo} = useUser();

  const [token, setToken] = useState<IUserContext['token']>(null);
  const [role, setRole] = useState<IUserContext['role']>(ROLES.UNAUTHENTICATED);
  const [userInfo, setUserInfo] = useState<any>();

  const [lang, setLang] = useState<string>();

  const handleChangeLang = useCallback(() => {
    /* Language default is english, set italian only if browser language is "it-IT" */
    if (navigator.language === 'it-IT') {
      setLang('it');
    } else {
      setLang('en');
    }
  }, []);

  const handleLogout: LogoutFn = useCallback(async () => {
    let realm = userInfo?.realm;
    /* If realm is undefined, get it from jwt token */
    if (!realm) {
      const token = await retrieveToken();
      if (token) {
        const decoded = jwtDecode(token);
        realm = decoded.iss!.split('/realms/')[1];
      } else {
        return Promise.reject();
      }
    }
    await logout(realm);
    setToken(null);
    setUserInfo(undefined);
    return Promise.resolve();
  }, [logout, retrieveToken, userInfo?.realm]);

  const handleGetUserInfo = useCallback(
    async (token: string) => {
      const userInfo = await getUserInfo(token);

      setToken(token);
      setUserInfo(userInfo);
      setRole(userInfo.role);
    },
    [getUserInfo],
  );

  const initialize = useCallback(async () => {
    handleChangeLang();
    const token = await retrieveToken();

    if (token) {
      try {
        if (!userInfo) {
          await handleGetUserInfo(token);
        }
        return Promise.resolve();
      } catch (e) {
        console.error('initialize - error', e);
        void handleLogout();
        throw e;
      }
    }
  }, [
    handleChangeLang,
    handleGetUserInfo,
    handleLogout,
    retrieveToken,
    userInfo,
  ]);

  const callHandleGetUserInfo = useCallback(
    async (token: AccessToken) => {
      if (token) {
        try {
          await handleGetUserInfo(token);
          return userInfo;
        } catch (e) {
          console.error('callHandleGetUserInfo - error', e);
          void handleLogout();
          throw e;
        }
      }
      return null;
    },
    [handleGetUserInfo, handleLogout, userInfo],
  );

  const handleLogin = useCallback(
    async (realm: REALMS, email: string, password: string) => {
      const token = await login(realm, email, password);
      await callHandleGetUserInfo(token);
      return token;
    },
    [callHandleGetUserInfo, login],
  );

  const handleSSOLogin = useCallback(
    async (realm: REALMS, code: string, eventId?: number) => {
      const token = await loginSSO(realm, code, eventId);
      await callHandleGetUserInfo(token);
      return token;
    },
    [callHandleGetUserInfo, loginSSO],
  );

  const setUserInfoHook = useCallback((userInfo: Partial<User>) => {
    setUserInfo((prevState: User) => ({
      ...prevState,
      ...userInfo,
    }));
  }, []);

  const value = useMemo(
    () => ({
      lang,
      isAuthenticated: Boolean(token),
      token,
      role,
      userInfo,
      initialize,
      login: handleLogin,
      loginSSO: handleSSOLogin,
      logout: handleLogout,
      setUserInfoHook,
      handleChangeLang,
    }),
    [
      lang,
      token,
      role,
      userInfo,
      initialize,
      handleLogin,
      handleSSOLogin,
      handleLogout,
      setUserInfoHook,
      handleChangeLang,
    ],
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
