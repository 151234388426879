import {useMemo} from 'react';

import {SxProps} from '@mui/material';

import {IPropsStyle, IUseStyle} from '@/templates/Modal/type';
import {theme} from '@/themes/default';

export const useStyle = ({backdropVisible}: IUseStyle): IPropsStyle => {
  const modalBoxContainer: SxProps = useMemo(
    (): SxProps => ({
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      zIndex: '3',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      boxShadow: '0px 2px 30px 0px rgba(18, 18, 18, 0.30)',
    }),
    [],
  );

  const modalBox: SxProps = useMemo(
    (): SxProps => ({
      minHeight: 'auto',
      backgroundColor: theme.palette.white,
      paddingX: '20px',
      paddingY: '24px',
      [theme.breakpoints.up('md')]: {
        padding: '40px',
      },
    }),
    [],
  );

  const backdropStyle: SxProps = useMemo(
    (): SxProps => ({
      position: 'fixed',
      zIndex: 2,
      backgroundColor: backdropVisible
        ? 'rgba(18, 18, 18, 0.15)'
        : 'transparent',
      height: '100%',
      width: '100%',
      top: 0,
      left: 0,
    }),
    [backdropVisible],
  );

  return {
    modalBoxContainer,
    modalBox,
    backdropStyle,
  };
};
