import {useTranslations} from '@vidiemme/react-i18n';

import {MessagePage} from '@/organisms/MessagePage';

const LoadingMessage = () => {
  const {t} = useTranslations();
  return (
    <MessagePage
      message={t('LOADING_MESSAGE.MESSAGE')}
      title={t('LOADING_MESSAGE.TITLE')}
    />
  );
};

export default LoadingMessage;
