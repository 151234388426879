import {useCallback, useEffect, useMemo, useState} from 'react';

import {useTranslations} from '@vidiemme/react-i18n';

import useDate from '@/hooks/date';
import {LoadingMessage} from '@/organisms/LoadingMessage';
import {ResponseErrorMessage} from '@/organisms/ResponseErrorMessage';
import {SelectComponent} from '@/organisms/SelectComponent';
import {useGetSlots} from '@/store/slots/hooks';

import {IProps} from './interfaces';

const OrdinarySelect = ({
  appointment,
  setSlotIdHandler,
  disableButtonHandler,
  setGuests,
  setSlotLimit,
  setBookedSpots,
  setInitialGuestsCount,
}: IProps) => {
  const {t} = useTranslations();

  const {getAvailableSlots, sortAvailableSlots, getAvailableDateSlots} =
    useDate();

  // Define a query for available slots
  const slotsQuery = useMemo(
    () => ({
      eventId: appointment.eventId,
      available: true,
    }),
    [appointment.eventId],
  );
  // Fetch available slots using the query
  const {loading, error, response} = useGetSlots(slotsQuery);

  const [selectedSlotDate, setSelectedSlotDate] = useState(
    appointment.slot?.eventDay.id,
  );

  const [selectedSlotTime, setSelectedSlotTime] = useState(
    appointment.slot?.id,
  );

  const dateSlotChangeHandler = useCallback((el: number | undefined) => {
    setSelectedSlotTime(undefined);
    setSelectedSlotDate(el);
  }, []);

  const timeSlotChangeHandler = useCallback(
    (el: number) => {
      setSelectedSlotTime(el);
      setSlotIdHandler(el);
    },
    [setSlotIdHandler],
  );

  // If the user select a different slot time, all info about guests and booked slots are set with values from that specific slot
  useEffect(() => {
    if (response && selectedSlotTime) {
      const slot = response.find(slot => slot.id === selectedSlotTime);
      if (slot) {
        setBookedSpots(slot?.bookedSpots || 0);
        setSlotLimit(slot?.slotLimit || 0);
        const existingUserBooking = slot.bookings!.find(
          booking => booking.userId === appointment.userId,
        );
        setGuests(existingUserBooking?.guests || []);
        let guestCount = existingUserBooking?.guests?.length || 0;
        if (appointment.slotId !== selectedSlotTime) {
          guestCount -= 1;
        }
        setInitialGuestsCount(guestCount);
      }
    }
  }, [
    response,
    selectedSlotTime,
    appointment.userId,
    appointment.slotId,
    setBookedSpots,
    setGuests,
    setInitialGuestsCount,
    setSlotLimit,
  ]);

  // Disable submit button if there are empty fields, or during loading time
  useEffect(() => {
    return disableButtonHandler(
      loading || !selectedSlotTime || !selectedSlotDate,
    );
  }, [loading, selectedSlotDate, selectedSlotTime, disableButtonHandler]);

  // Construct the body of the component based on available slots
  const body = useMemo(() => {
    if (appointment.slot && selectedSlotDate) {
      // Handle loading and error cases
      if (loading) {
        return <LoadingMessage />;
      }

      if (error) {
        return <ResponseErrorMessage minimalMessage showToast />;
      }

      if (response) {
        let slotsAvailable = [...response];

        /* Add the booking slot if there are no available slots or
         if the current slot is full (so the user can book the same slot) */
        if (
          slotsAvailable.findIndex(slot => slot.id === appointment.slot!.id) < 0
        ) {
          slotsAvailable = slotsAvailable.concat([{...appointment.slot}]);
          sortAvailableSlots(slotsAvailable); // sorting slots by date(startTime)
        }
        const availableDateSlotsOptions = getAvailableDateSlots(
          slotsAvailable,
          appointment.slot.eventDay.event.location.timezone,
        );

        const availableTimeSlots = getAvailableSlots(
          slotsAvailable,
          appointment.slot.eventDay.event.location.timezone,
        );

        const availableTimeSlotsOptions = availableTimeSlots[selectedSlotDate]
          ? availableTimeSlots[selectedSlotDate]
          : [];

        return (
          <>
            {/* Select the appointment date */}
            <SelectComponent
              label={t('INVITATIONS.APPOINTMENT_DATE')}
              menuItems={availableDateSlotsOptions}
              startValue={selectedSlotDate}
              handleFunction={dateSlotChangeHandler}
            />
            {/* Select the appointment time */}
            <SelectComponent
              label={t('INVITATIONS.APPOINTMENT_TIME')}
              menuItems={availableTimeSlotsOptions}
              startValue={selectedSlotTime || ''}
              handleFunction={timeSlotChangeHandler}
            />
          </>
        );
      }
    }

    return null;
  }, [
    appointment.slot,
    dateSlotChangeHandler,
    error,
    getAvailableDateSlots,
    getAvailableSlots,
    loading,
    response,
    selectedSlotDate,
    selectedSlotTime,
    sortAvailableSlots,
    t,
    timeSlotChangeHandler,
  ]);

  // Render the component body
  return <>{body}</>;
};

export default OrdinarySelect;
