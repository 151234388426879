import {useMemo, useRef} from 'react';

import {useApi} from '@/store/api';
import {User} from '@/store/user';
import {
  Accessor,
  ServerUsersPage,
  UseGetUsers,
  UseUpdateUserRole,
} from '@/store/userManagement/definitions';
import {normalizeUsers} from '@/store/userManagement/normalize';

export const useGetTableColumns = (): {
  loading: boolean;
  response: Accessor[];
} => {
  return {
    loading: false,
    response: ['CONTACTNAME', 'EMAIL', 'ROLE'],
  };
};

export const useGetUsers: UseGetUsers = (
  realm,
  pageNumber,
  fullText,
  perPage,
) => {
  // A list of "nextPageUrl"
  // At the end of any request, we update this list with the next_page field
  const pagesURL = useRef<Array<string>>([]);

  // When retrieving page 0, the nextPageURL is at position [-1], which is undefined.
  // Upon fetching page 0, we insert the next_page into the array at position 0.
  // To retrieve page 1, we use the nextPageURL at position [0], which is the last next_page received.
  // After fetching page 1, we add the next_page to the array at position 1.
  // For page 2, we require the nextPageURL at position [1], which is now the last next_page received.
  // Upon fetching page 2, we insert the next_page into the array at position 2.

  // Now, if we want to go back:
  // To retrieve page 1, we use the nextPageURL at position [0], received with the first page.
  // After fetching page 1, we update the next_page in the array at position 1.
  // To go back further to page 0, we use the nextPageURL at position [-1].
  // Since the nextPageURL is undefined, we receive the first page.
  // After fetching page 0, we update the next_page in the array at position 0.
  const nextPageURL = pagesURL.current[pageNumber - 1];

  const options = useMemo(() => {
    return {
      autoRun: false,
      query: {
        realm,
        page: nextPageURL,
        full_text: fullText.length === 0 ? undefined : fullText,
        per_page: perPage,
      },
    };
  }, [realm, nextPageURL, fullText, perPage]);

  const {
    loading,
    error,
    response: serverResponse,
    run,
  } = useApi<ServerUsersPage>(`/api/v1/users`, options);

  if (serverResponse !== null) {
    pagesURL.current.splice(
      pageNumber - 1,
      pageNumber,
      serverResponse.next_page,
    );
  }

  return {
    loading,
    error,
    response: normalizeUsers(serverResponse),
    run,
  };
};

export const useUpdateUserRole: UseUpdateUserRole = (realm, email, roleId) => {
  const options: any = useMemo(() => {
    return {
      autoRun: false,
      req: {
        method: 'PUT',
      },
      body: {
        realm,
        role_id: roleId,
        email: email,
      },
    };
  }, [email, realm, roleId]);

  const {loading, error, response, run} = useApi(
    `/api/v1/users/update`,
    options,
  );

  return {loading, error, response: response as Partial<User>, run};
};
