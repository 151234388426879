import {SxProps} from '@mui/material';

export interface IPropsStyle {
  boxContainerPrivacy: SxProps;
  closePrivacyPolicy: SxProps;
  boxContainerBanner: SxProps;
  buttonsBanner: SxProps;
  buttonBanner: SxProps;
  backdrop: SxProps;
}

export enum PolicyAccepted {
  NAME = 'eplanning-tool-policy',
}
