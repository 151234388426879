import {useMemo} from 'react';

import {SxProps} from '@mui/material';

import {IUseStyle} from '@/pages/EventTypeToCreate/type';
import {theme} from '@/themes/default';

export const useStyle = (): IUseStyle => {
  const modalContainerBox: SxProps = useMemo(
    (): SxProps => ({
      display: 'flex',
      flexDirection: 'column',
      width: 'calc(100vw - 100px)', // 110 = 20*2 padding + 30*2 boxshadow
      [theme.breakpoints.up('md')]: {
        width: '680px',
      },
    }),
    [],
  );

  const title: SxProps = useMemo(
    (): SxProps => ({
      marginBottom: '16px',
    }),
    [],
  );

  const text: SxProps = useMemo(
    (): SxProps => ({
      marginBottom: '24px',
      [theme.breakpoints.up('md')]: {
        marginBottom: '40px',
      },
    }),
    [],
  );

  const location: SxProps = useMemo(
    (): SxProps => ({
      marginBottom: '10px',
    }),
    [],
  );

  const category: SxProps = useMemo(
    (): SxProps => ({
      marginBottom: '24px',
      [theme.breakpoints.up('md')]: {
        marginBottom: '132px',
      },
    }),
    [],
  );

  const buttonsBox: SxProps = useMemo(
    (): SxProps => ({
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      rowGap: '10px',
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        rowGap: '0px',
      },
    }),
    [],
  );

  const modalButton: SxProps = useMemo(
    (): SxProps => ({
      width: '100%',
      height: '54px',
      [theme.breakpoints.up('md')]: {
        width: '50%',
      },
    }),
    [],
  );

  return {
    modalContainerBox,
    title,
    text,
    location,
    category,
    buttonsBox,
    modalButton,
  };
};
