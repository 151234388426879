import {useMediaQuery} from '@mui/material';

import {theme} from '@/themes/default';

/* Checks if the current device screen is a tablet by using the useMediaQuery hook
 * with a breakpoint condition for medium-sized screens (md) */
export const useIsTablet = (): boolean => {
  return useMediaQuery(theme.breakpoints.up('md'));
};

/* Checks if the current device screen is a desktop by using the useMediaQuery hook
 * with a breakpoint condition for large-sized screens (lg) */
export const useIsDesktop = (): boolean => {
  return useMediaQuery(theme.breakpoints.up('lg'));
};
