import {useMemo} from 'react';

import {SxProps} from '@mui/material';

import {IPropsStyle} from '@/pages/LoginEmployee/type';
import {theme} from '@/themes/default';

export const useStyle = (): IPropsStyle => {
  const loginEmployeeContainer: SxProps = useMemo(
    (): SxProps => ({
      width: '100%',
      overflowY: 'scroll',
      overflowX: 'hidden',
    }),
    [],
  );

  const loginEmployeeMainBox: SxProps = useMemo(
    (): SxProps => ({
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100vh',
      [theme.breakpoints.up('md')]: {
        justifyContent: 'center',
      },
    }),
    [],
  );

  const loginEmployeeTitleBox: SxProps = useMemo(
    (): SxProps => ({
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: '32px',
      marginBottom: '64px',
      svg: {
        height: '22px',
        marginBottom: '8px',
      },
      [theme.breakpoints.up('md')]: {
        marginBottom: '140px',
      },
    }),
    [],
  );

  const loginEmployeeTitleTypography: SxProps = useMemo(
    (): SxProps => ({
      marginBottom: '20px',
    }),
    [],
  );

  const loginEmployeeSSOBox: SxProps = useMemo(
    (): SxProps => ({
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: '28px',
      'div:last-child': {
        borderBottom: 0,
      },
      [theme.breakpoints.up('md')]: {
        width: 'calc(100%/3);',
        margin: '0 auto 96px',
        'div:nth-of-type(2)': {
          borderLeft: '1px solid #757575',
        },
      },
    }),
    [],
  );

  const privacyBox: SxProps = useMemo(
    (): SxProps => ({
      marginTop: '16px',
      marginBottom: '32px',
      display: 'flex',
      justifyContent: 'center',
      a: {
        span: {
          marginX: '12px',
        },
      },
    }),
    [],
  );

  const guestLoginButton = useMemo(
    (): SxProps => ({
      width: '100%',
      maxWidth: '210px',
      height: '54px',
      marginBottom: '16px',
      [theme.breakpoints.up('md')]: {
        marginBottom: '40px',
      },
    }),
    [],
  );

  return {
    loginEmployeeMainBox,
    loginEmployeeTitleBox,
    loginEmployeeSSOBox,
    loginEmployeeContainer,
    loginEmployeeTitleTypography,
    privacyBox,
    guestLoginButton,
  };
};
