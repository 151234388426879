import type {ServerUser, User} from './definitions';

export const normalizerUser = (serverUser: ServerUser): User => {
  const {
    first_name: firstName,
    last_name: lastName,
    realm_name: realmName,
    role_name: roleName,
    permission_names: permissionNames,
    phone_number,
    email,
    id,
  } = serverUser;

  return {
    firstName,
    lastName,
    name: `${firstName} ${lastName}`,
    realm: realmName,
    role: roleName,
    user_id: id,
    permission: permissionNames,
    email,
    phone_number,
  };
};
