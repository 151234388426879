import {useCallback, useEffect} from 'react';

import DownloadIcon from '@mui/icons-material/Download';
import {Box, Typography} from '@mui/material';
import {useTranslations} from '@vidiemme/react-i18n';

import {Button} from '@/atoms/Button';
import useDate from '@/hooks/date';
import {useToast} from '@/hooks/toast';
import {IProps} from '@/organisms/Calendar/DayHeaderContent/type';
import {useStyle} from '@/organisms/Calendar/style';
import {useDownloadBookedPeople} from '@/store/bookings';

const DayHeaderContent = ({date, hasDownloadButton, eventDayId}: IProps) => {
  const {
    headerWrapperContentBox,
    headerContentBox,
    headerContentBoxIsToday,
    headerCellDayString,
    headerCellDayNumber,
    buttonDownload,
    downloadIcon,
  } = useStyle();
  const {t} = useTranslations();
  const {showErrorToast} = useToast();
  const {dateToDayName, dateToDayNumber} = useDate();

  const dayString = dateToDayName(date.date);
  const dayNumber = dateToDayNumber(date.date);

  const {
    loading: downloadBookedPeopleLoading,
    error: downloadBookedPeopleError,
    run: downloadBookedPeopleRun,
  } = useDownloadBookedPeople({eventDayId});

  const handleDownloadBookedPeople = useCallback(() => {
    if (downloadBookedPeopleRun) {
      downloadBookedPeopleRun();
    }
  }, [downloadBookedPeopleRun]);

  useEffect(() => {
    if (downloadBookedPeopleError) {
      showErrorToast({message: t('RESPONSE_ERROR_MESSAGE.MESSAGE')});
    }
  }, [downloadBookedPeopleError, showErrorToast, t]);

  return (
    <Box sx={headerWrapperContentBox}>
      <Box sx={headerContentBox}>
        <Typography variant="bodyRegularSmall" sx={headerCellDayString}>
          {dayString}
        </Typography>
        <Typography
          variant="titleVersace2"
          sx={date.isToday ? headerContentBoxIsToday : headerCellDayNumber}>
          {dayNumber}
        </Typography>
      </Box>
      {hasDownloadButton && (
        <Button
          sx={buttonDownload}
          onClick={handleDownloadBookedPeople}
          disabled={downloadBookedPeopleLoading}
          loading={downloadBookedPeopleLoading}>
          <DownloadIcon sx={downloadIcon} />
        </Button>
      )}
    </Box>
  );
};
export default DayHeaderContent;
