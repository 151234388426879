import {useMemo} from 'react';

import {SxProps} from '@mui/material';

import {desktopPadding, mobilePadding, theme} from '@/themes/default';

import {IPropsStyle} from './type';

export const useStyle = (): IPropsStyle => {
  const container: SxProps = useMemo(
    (): SxProps => ({
      marginTop: '150px',
      paddingY: '20px',
      paddingX: mobilePadding,
      backgroundColor: theme.palette.common.black,
      textAlign: 'end',
      [theme.breakpoints.up('lg')]: {
        paddingX: desktopPadding,
      },
    }),
    [],
  );

  const linkItem: SxProps = useMemo(
    (): SxProps => ({
      color: theme.palette.common.white,
      textDecoration: 'none',
      ':hover': {
        textDecoration: 'underline',
      },
    }),
    [],
  );

  return {
    container,
    linkItem,
  };
};
