import 'moment/locale/en-gb';
import 'moment/locale/it';
import moment from 'moment/moment';

import {useUserContext} from '@/contexts/user';

const DateMoment = () => {
  const {lang} = useUserContext();
  moment.locale(lang);
  return null;
};
export default DateMoment;
