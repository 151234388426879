import AktivGroteskBold from './assets/fonts/AktivGrotesk_Bd.ttf';
import AktivGroteskBoldItalic from './assets/fonts/AktivGrotesk_BdIt.ttf';
import AktivGroteskItalic from './assets/fonts/AktivGrotesk_It.ttf';
import AktivGroteskLight from './assets/fonts/AktivGrotesk_Lt.ttf';
import AktivGroteskLightItalic from './assets/fonts/AktivGrotesk_LtIt.ttf';
import AktivGroteskRegular from './assets/fonts/AktivGrotesk_Rg.ttf';
import VersaceRegular from './assets/fonts/Versace-Regular.ttf';

export const FontFace = `
  @font-face {
    font-family: Versace;
    font-style: normal;
    font-weight: 400;
    src: local('VersaceRegular'), url(${VersaceRegular}) format('truetype');
  }
  @font-face {
    font-family: AktivGrotesk;
    font-style: normal;
    font-weight: 400;
    src: local('AktivGroteskRegular'), url(${AktivGroteskRegular}) format('truetype');
  }
   @font-face {
    font-family: AktivGrotesk;
    font-style: italic;
    font-weight: 400;
    src: local('AktivGroteskItalic'), url(${AktivGroteskItalic}) format('truetype');
  }
  @font-face {
    font-family: AktivGrotesk;
    font-style: normal;
    font-weight: 300;
    src: local('AktivGroteskLight'), url(${AktivGroteskLight}) format('truetype');
  }
  @font-face {
    font-family: AktivGrotesk;
    font-style: normal;
    font-weight: 700;
    src: local('AktivGroteskBold'), url(${AktivGroteskBold}) format('truetype');
  }
  @font-face {
    font-family: AktivGrotesk;
    font-style: italic;
    font-weight: 300;
    src: local('AktivGroteskLightItalic'), url(${AktivGroteskLightItalic}) format('truetype');
  }
   @font-face {
    font-family: AktivGrotesk;
    font-style: italic;
    font-weight: 700;
    src: local('AktivGroteskBoldItalic'), url(${AktivGroteskBoldItalic}) format('truetype');
  }

  `;
