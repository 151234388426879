import {useCallback, useMemo} from 'react';

import {AnonymousRoutes} from '@/navigation/routes';

import {REALMS} from './definitions';

export const useKeycloak = () => {
  const getRedirectURL = useCallback((eventId?: number) => {
    const uri = `${process.env.REACT_APP_KEYCLOACK_AUTH_REDIRECT_URI!}${
      AnonymousRoutes.LOGIN_EMPLOYEE
    }`;

    if (eventId) {
      return `${uri}?event_id=${eventId}`;
    }

    return uri;
  }, []);

  const getSSOUrl = useCallback(
    (realm: REALMS, eventId?: number) => {
      const redirectURI = getRedirectURL(eventId);

      return (process.env.REACT_APP_KEYCLOACK_AUTH_BASE_URL as string)
        .replace(/:realm/g, realm)
        .replace(/:redirect_uri/g, encodeURIComponent(redirectURI));
    },
    [getRedirectURL],
  );

  const getTokenURL = useCallback(
    (realm: string) =>
      (process.env.REACT_APP_KEYCLOACK_BASE_URL as string).replace(
        /:realm/g,
        realm,
      ),
    [],
  );

  const getLogoutURL = useCallback(
    (realm: string) => {
      const redirectURI = getRedirectURL();
      return (process.env.REACT_APP_KEYCLOACK_LOGOUT_URL as string)
        .replace(/:realm/g, realm)
        .replace(/:redirect_uri/g, encodeURIComponent(redirectURI));
    },
    [getRedirectURL],
  );

  const keycloakGuestSettings = useMemo(
    () => ({
      client_id: `${REALMS.GUESTS}-sso`,
      grant_type: 'password',
      URL_REALM: REALMS.GUESTS,
      URL_TOKEN: getTokenURL(REALMS.GUESTS),
      URL_LOGOUT: getLogoutURL(REALMS.GUESTS),
    }),
    [getTokenURL, getLogoutURL],
  );

  const keycloakVersaceSettings = useMemo(
    () => ({
      client_id: `${REALMS.VERSACE}-sso`,
      grant_type: 'authorization_code',
      URL_REALM: REALMS.VERSACE,
      URL_TOKEN: getTokenURL(REALMS.VERSACE),
      URL_LOGOUT: getLogoutURL(REALMS.VERSACE),
    }),
    [getTokenURL, getLogoutURL],
  );

  const keycloakMichaelKorsSettings = useMemo(
    () => ({
      client_id: `${REALMS.MICHAEL_KORS}-sso`,
      grant_type: 'authorization_code',
      URL_REALM: REALMS.MICHAEL_KORS,
      URL_TOKEN: getTokenURL(REALMS.MICHAEL_KORS),
      URL_LOGOUT: getLogoutURL(REALMS.MICHAEL_KORS),
    }),
    [getTokenURL, getLogoutURL],
  );

  const keycloakJimmyChooSettings = useMemo(
    () => ({
      client_id: `${REALMS.JIMMY_CHOO}-sso`,
      grant_type: 'authorization_code',
      URL_REALM: REALMS.JIMMY_CHOO,
      URL_TOKEN: getTokenURL(REALMS.JIMMY_CHOO),
      URL_LOGOUT: getLogoutURL(REALMS.JIMMY_CHOO),
    }),
    [getTokenURL, getLogoutURL],
  );

  const getKeycloakSettings = useCallback(
    (realm: REALMS) => {
      switch (realm) {
        case REALMS.VERSACE:
          return keycloakVersaceSettings;
        case REALMS.MICHAEL_KORS:
          return keycloakMichaelKorsSettings;
        case REALMS.JIMMY_CHOO:
          return keycloakJimmyChooSettings;
        case REALMS.GUESTS:
          return keycloakGuestSettings;
        default:
          return keycloakGuestSettings;
      }
    },
    [
      keycloakGuestSettings,
      keycloakJimmyChooSettings,
      keycloakMichaelKorsSettings,
      keycloakVersaceSettings,
    ],
  );

  const storeKeycloakRealmForLogin = useCallback(async (realm: string) => {
    localStorage.setItem('realm', realm);

    return Promise.resolve();
  }, []);

  const retrieveKeycloakRealmForLogin = useCallback(async () => {
    const realm = localStorage.getItem('realm');

    return Promise.resolve(realm);
  }, []);

  const eraseKeycloakRealmForLogin = useCallback(async () => {
    localStorage.removeItem('realm');

    return Promise.resolve();
  }, []);

  return {
    getRedirectURL,
    getSSOUrl,
    getKeycloakSettings,
    storeKeycloakRealmForLogin,
    retrieveKeycloakRealmForLogin,
    eraseKeycloakRealmForLogin,
  };
};
