import {useCallback, useEffect, useMemo, useState} from 'react';

import {useTranslations} from '@vidiemme/react-i18n';

import useDate from '@/hooks/date';
import {LoadingMessage} from '@/organisms/LoadingMessage';
import {ResponseErrorMessage} from '@/organisms/ResponseErrorMessage';
import {SelectComponent} from '@/organisms/SelectComponent';
import {useGetSlots} from '@/store/slots/hooks';

import {IProps} from './interfaces';

const ClothingSelect = ({
  appointment,
  setSlotIdHandler,
  disableButtonHandler,
}: IProps): JSX.Element => {
  const {t} = useTranslations();
  const {getAvailableSlots, sortAvailableSlots, getAvailableDateSlots} =
    useDate();

  // Define a query for available slots
  const slotsQuery = useMemo(
    () => ({
      eventId: appointment.eventId,
      available: true,
    }),
    [appointment.eventId],
  );

  const [selectedSlotDate, setSelectedSlotDate] = useState<number | undefined>(
    appointment.slot?.eventDay.id,
  );

  const [selectedSlotTime, setSelectedSlotTime] = useState<number | undefined>(
    appointment.slot?.id,
  );

  // Fetch available slots using the query
  const {loading, error, response} = useGetSlots(slotsQuery);

  // Disable submit button if there are empty fields, or during loading time
  useEffect(() => {
    disableButtonHandler(
      loading ||
        !selectedSlotTime ||
        !selectedSlotDate ||
        appointment.slot?.id === selectedSlotTime,
    );
  }, [
    loading,
    selectedSlotDate,
    selectedSlotTime,
    disableButtonHandler,
    appointment.slot,
  ]);

  const dateSlotChangeHandler = useCallback((el: number | undefined) => {
    setSelectedSlotTime(undefined);
    setSelectedSlotDate(el);
  }, []);

  const timeSlotChangeHandler = useCallback(
    (el: number) => {
      setSelectedSlotTime(el);
      setSlotIdHandler(el);
    },
    [setSlotIdHandler],
  );

  // Construct the body of the component based on available slots
  const body = useMemo(() => {
    if (appointment.slot && selectedSlotDate) {
      if (response) {
        let slotsAvailable = [...response];

        /* Add the booking slot if there are no available slots or
         if the current slot is full (so the user can book the same slot) */
        if (
          slotsAvailable.findIndex(slot => slot.id === appointment.slot!.id) < 0
        ) {
          slotsAvailable = slotsAvailable.concat([{...appointment.slot}]);
          sortAvailableSlots(slotsAvailable); // sorting slots by date(startTime)
        }

        const availableDateSlotsOptions = getAvailableDateSlots(
          slotsAvailable,
          appointment.slot.eventDay.event.location.timezone,
        );

        const availableTimeSlots = getAvailableSlots(
          slotsAvailable,
          appointment.slot.eventDay.event.location.timezone,
        );

        const availableTimeSlotsOptions = availableTimeSlots[selectedSlotDate]
          ? availableTimeSlots[selectedSlotDate]
          : [];

        return (
          <>
            {/* Select the appointment date */}
            <SelectComponent
              label={t('INVITATIONS.APPOINTMENT_DATE')}
              menuItems={availableDateSlotsOptions}
              startValue={selectedSlotDate}
              handleFunction={dateSlotChangeHandler}
              isDisabled={loading}
              isClothing={true}
            />
            {/* Select the appointment time */}
            <SelectComponent
              label={t('INVITATIONS.APPOINTMENT_TIME')}
              menuItems={availableTimeSlotsOptions}
              startValue={selectedSlotTime || ''}
              handleFunction={timeSlotChangeHandler}
              isDisabled={loading}
              isClothing={true}
            />
          </>
        );
      }
    }
    return null;
  }, [
    loading,
    appointment.slot,
    dateSlotChangeHandler,
    getAvailableDateSlots,
    getAvailableSlots,
    response,
    selectedSlotDate,
    selectedSlotTime,
    sortAvailableSlots,
    t,
    timeSlotChangeHandler,
  ]);

  if (loading) {
    return <LoadingMessage />;
  }

  if (error) {
    return <ResponseErrorMessage />;
  }

  // Render the component body
  return <>{body}</>;
};

export default ClothingSelect;
