import {useMemo} from 'react';

import {useApi} from '@/store/api';
import {UseGetCategories, Categories} from '@/store/categories/definitions';

export const useGetCategories: UseGetCategories = (
  locationId,
  autoRun = true,
) => {
  const options = useMemo(
    () => ({
      autoRun,
      query: {
        location_id: locationId,
      },
    }),
    [autoRun, locationId],
  );
  const {loading, error, response, run} = useApi<Categories>(
    '/api/v1/categories',
    options,
  );

  return {
    loading,
    error,
    response,
    run,
  };
};
