import {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';

import {AnonymousRoutes, AuthRoutes} from '@/navigation/routes';
import {ServerCategory} from '@/store/categories';
import {ServerLocation} from '@/store/locations';

export const useNavigation = () => {
  const navigate = useNavigate();

  //GENERIC
  const goToPath = useCallback(
    (path: AnonymousRoutes | AuthRoutes) => {
      navigate(path);
    },
    [navigate],
  );
  const goToPreviousPage = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  // EVENTS
  const goToEvents = useCallback(() => {
    navigate(AuthRoutes.EVENTS);
  }, [navigate]);

  const goToEventCalendar = useCallback(
    (eventId: number) => {
      const url = AuthRoutes.EVENT_CALENDAR.replace(/:id/g, eventId.toString());
      navigate(url);
    },
    [navigate],
  );

  const goToEventSlotDetail = useCallback(
    (eventId: number, slotId: number, fromInvitation = false) => {
      const url = (
        fromInvitation
          ? AuthRoutes.INVITATION_SLOT_DIALOG
          : AuthRoutes.EVENT_SLOT_DETAILS
      )
        .replace(':id', eventId.toString())
        .replace(':slotId', slotId.toString());

      navigate(url);
    },
    [navigate],
  );

  const goToEventSlotDelete = useCallback(
    (eventId: number, slotId: number, options?: {}) => {
      const url = AuthRoutes.EVENT_SLOT_DELETE.replace(
        ':id',
        eventId.toString(),
      ).replace(':slotId', slotId.toString());

      navigate(url, options);
    },
    [navigate],
  );

  const goToEventSlotDisable = useCallback(
    (eventId: number, slotId: number, options?: {}) => {
      const url = AuthRoutes.EVENT_SLOT_EDIT.replace(
        ':id',
        eventId.toString(),
      ).replace(':slotId', slotId.toString());

      navigate(url, options);
    },
    [navigate],
  );
  const goToEventBookedPeople = useCallback(
    (eventId: number, slotId: number) => {
      const url = AuthRoutes.EVENT_INVITATIONS.replace(
        /:id/g,
        eventId.toString(),
      ).replace(/:slotId/g, slotId.toString());

      navigate(url);
    },
    [navigate],
  );

  const goToEventBookingDownlod = useCallback(
    (eventId: number) => {
      const url = AuthRoutes.EVENT_BOOKINGS_DOWNLOAD.replace(
        /:id/g,
        eventId.toString(),
      );
      navigate(url);
    },
    [navigate],
  );

  const goToEventBehalfOfBooking = useCallback(
    (eventId: number, slotId: number, fromInvitation = false) => {
      const url = (
        fromInvitation
          ? AuthRoutes.INVITATION_BEHALF_OF_BOOKING
          : AuthRoutes.EVENT_BEHALF_OF_BOOKING
      )
        .replace(/:id/g, eventId.toString())
        .replace(/:slotId/g, slotId.toString());
      navigate(url);
    },
    [navigate],
  );

  const goToEventBookingDelete = useCallback(
    (
      eventId: number,
      slotId: number,
      bookingId: number,
      options?: {},
    ): void => {
      navigate(
        AuthRoutes.EVENT_SLOT_BOOKING_DELETE.replace(/:id/g, eventId.toString())
          .replace(/:slotId/g, slotId.toString())
          .replace(/:bookingId/g, bookingId.toString()),
        options,
      );
    },
    [navigate],
  );

  const goToEventDoorman = useCallback(
    (eventId: number, slotId: number) => {
      const url = AuthRoutes.EVENT_DOORMAN.replace(
        /:id/g,
        eventId.toString(),
      ).replace(/:slotId/g, slotId.toString());

      navigate(url);
    },
    [navigate],
  );

  const goToCreateEvent = useCallback(
    ({
      location,
      category,
    }: {
      location: ServerLocation;
      category: ServerCategory;
    }): void => {
      navigate(AuthRoutes.EVENT_CREATE, {state: {location, category}});
    },
    [navigate],
  );

  const goToEventTypeToCreate = useCallback((): void => {
    navigate(AuthRoutes.EVENT_TYPE_TO_CREATE);
  }, [navigate]);

  const goToEventEdit = useCallback(
    (id: number, options?: {}) => {
      navigate(AuthRoutes.EVENT.replace(':id', id.toString()), options);
    },
    [navigate],
  );

  const goToEventDelete = useCallback(
    (id: number, options?: {}): void => {
      navigate(AuthRoutes.EVENT_DELETE.replace(':id', id.toString()), options);
    },
    [navigate],
  );

  //BOOKING
  const goToBookings = useCallback((): void => {
    navigate(AuthRoutes.BOOKINGS);
  }, [navigate]);

  const goToBookingEdit = useCallback(
    (id: number, options?: {}): void => {
      navigate(AuthRoutes.BOOKING_EDIT.replace(':id', id.toString()), options);
    },
    [navigate],
  );
  const goToBookingDelete = useCallback(
    (id: number, options?: {}): void => {
      navigate(
        AuthRoutes.BOOKING_DELETE.replace(':id', id.toString()),
        options,
      );
    },
    [navigate],
  );

  //INVITATIONS
  const goToInvitationRegister = useCallback(
    (id: number): void => {
      navigate(AuthRoutes.INVITATION_REGISTER.replace(':id', id.toString()));
    },
    [navigate],
  );
  const goToInvitationSlotBooking = useCallback(
    (calendarId: number, slotId: number, options?: {}): void => {
      navigate(
        AuthRoutes.INVITATION_SLOT_BOOKING.replace(
          ':id',
          calendarId.toString(),
        ).replace(':slotId', slotId.toString()),
        options,
      );
    },
    [navigate],
  );
  const goToInviteGuest = useCallback(
    (id: number): void => {
      navigate(AuthRoutes.INVITATION_GUEST.replace(':id', id.toString()));
    },
    [navigate],
  );
  const goToInvitationSlotDetail = useCallback(
    (calendarId: number, slotId: number, bookingId: number): void => {
      navigate(
        AuthRoutes.INVITATION_SLOT_DETAILS.replace(
          ':id',
          calendarId.toString(),
        ).replace(':slotId', slotId.toString()),
        {state: {bookingId}},
      );
    },
    [navigate],
  );

  const goToInvitationEdit = useCallback(
    (
      calendarId: number,
      slotId: number,
      bookingId: number,
      options: {},
    ): void => {
      navigate(
        AuthRoutes.INVITATION_SLOT_DETAILS_EDIT.replace(
          ':id',
          calendarId.toString(),
        )
          .replace(':slotId', slotId.toString())
          .replace(':bookingId', bookingId.toString()),
        options,
      );
    },
    [navigate],
  );

  const goToInvitationFromEmail = useCallback((): void => {
    navigate(AuthRoutes.INVITATION_FROM_EMAIL);
  }, [navigate]);

  const goToInvitation = useCallback(
    (id: number, options?: {}): any => {
      navigate(AuthRoutes.INVITATION.replace(':id', id.toString()), options);
    },
    [navigate],
  );

  const goToInvitations = useCallback(() => {
    navigate(AuthRoutes.INVITATIONS);
  }, [navigate]);

  return {
    goToPath,
    goToPreviousPage,
    goToEventTypeToCreate,
    goToCreateEvent,
    goToEvents,
    goToEventCalendar,
    goToEventEdit,
    goToEventDelete,
    goToEventSlotDetail,
    goToEventSlotDelete,
    goToEventSlotDisable,
    goToEventBehalfOfBooking,
    goToEventBookedPeople,
    goToEventBookingDownlod,
    goToEventBookingDelete,
    goToEventDoorman,
    goToBookings,
    goToBookingEdit,
    goToBookingDelete,
    goToInvitations,
    goToInvitation,
    goToInviteGuest,
    goToInvitationRegister,
    goToInvitationSlotDetail,
    goToInvitationSlotBooking,
    goToInvitationFromEmail,
    goToInvitationEdit,
  };
};
