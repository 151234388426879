import {Box, Typography} from '@mui/material';
import {useTranslations} from '@vidiemme/react-i18n';

import {AnonymousRoutes} from '@/navigation/routes';

import {useStyle} from './style';

const Footer = () => {
  const {container, linkItem} = useStyle();
  const {t} = useTranslations();
  return (
    <Box sx={container}>
      <a href={AnonymousRoutes.PRIVACY_POLICY}>
        <Typography variant={'bodyRegularLink'} sx={linkItem}>
          {t('FOOTER.PRIVACY_POLICY')}
        </Typography>
      </a>
    </Box>
  );
};

export default Footer;
