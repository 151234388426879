import {useMemo} from 'react';

import {SxProps} from '@mui/material';

import {UseStyle} from '@/templates/SideModal/type';
import {mobilePadding, theme} from '@/themes/default';

export const useStyle = (): UseStyle => {
  const sidePageContainerBox: SxProps = useMemo(
    (): SxProps => ({
      overflowY: 'scroll',
      zIndex: 2,
      width: '100%',
      height: '100%',
      position: 'fixed',
      backgroundColor: 'white',
      top: 0,
      right: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      paddingX: mobilePadding,
      paddingY: '24px',
      [theme.breakpoints.up('md')]: {
        paddingX: '30px',
        paddingY: '48px',
        width: '85%',
      },
      [theme.breakpoints.up('lg')]: {
        width: '70%',
      },
    }),
    [],
  );

  const sidePageTitleBox: SxProps = useMemo(
    (): SxProps => ({
      display: 'flex',
      flexDirection: 'column',
      borderBottom: '1px solid #E0E0E0;',
      paddingBottom: '24px',
    }),
    [],
  );

  const sidePageTitleTitleBox: SxProps = useMemo(
    (): SxProps => ({
      marginBottom: '16px',
      paddingRight: '40px',
      [theme.breakpoints.up('md')]: {
        paddingRight: '0',
      },
    }),
    [],
  );

  const sidePageButtonBox: SxProps = useMemo(
    (): SxProps => ({
      display: 'flex',
      flexDirection: 'column',
      borderTop: '1px solid #E0E0E0;',
      paddingTop: '24px',
      justifyContent: 'space-between',
      rowGap: '10px',
      [theme.breakpoints.up('md')]: {
        rowGap: '0',
        flexDirection: 'row',
      },
    }),
    [],
  );

  const sidePageButtonButtonBox: SxProps = useMemo(
    (): SxProps => ({
      width: '100%',
      height: '54px',
      [theme.breakpoints.up('md')]: {
        width: '50%',
      },
    }),
    [],
  );

  const sidePageChildrenBox: SxProps = useMemo(
    (): SxProps => ({
      paddingY: '30px',
      [theme.breakpoints.up('md')]: {
        height: '100%',
        overflowY: 'auto',
      },
    }),
    [],
  );

  const backdropStyle: SxProps = useMemo(
    (): SxProps => ({
      position: 'fixed',
      zIndex: 2,
      backgroundColor: 'rgba(18, 18, 18, 0.15)',
      height: '100%',
      width: '100%',
      top: 0,
      left: 0,
    }),
    [],
  );

  const buttonIconClose: SxProps = useMemo(
    (): SxProps => ({
      height: '30px',
      minHeight: '30px',
      width: '30px',
      minWidth: '30px',
      border: `1px solid ${theme.palette.black}`,
      borderRadius: '30px',
      padding: '0',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      alignSelf: 'flex-end',
      position: 'absolute',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    }),
    [],
  );

  return {
    sidePageContainerBox,
    sidePageTitleBox,
    sidePageTitleTitleBox,
    sidePageButtonBox,
    sidePageButtonButtonBox,
    sidePageChildrenBox,
    backdropStyle,
    buttonIconClose,
  };
};
