import React from 'react';
import {createRoot} from 'react-dom/client';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import CssBaseline from '@mui/material/CssBaseline';
import * as Sentry from '@sentry/react';
import {Integrations} from '@sentry/tracing';

import {Wrapper as WrapperProvider} from '@/contexts/index';
import '@/hooks/toast/Toast.scss';
import DateMoment from '@/i18n/DateMoment/DateMoment';
import {Navigation} from '@/navigation/index';
import {ExternalUserPhoneNumber} from '@/organisms/ExternalUserPhoneNumber';
import {PrivacyPolicyBanner} from '@/organisms/PrivacyPolicyBanner';

import reportWebVitals from './reportWebVitals';

if (process.env.REACT_APP_ENVIRONMENT !== 'local') {
  Sentry.init({
    release: process.env.npm_package_version,
    environment: process.env.REACT_APP_ENVIRONMENT,

    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

const container = document.getElementById('root');
if (container) {
  const root = createRoot(container);

  root.render(
    <WrapperProvider>
      <DateMoment />
      <CssBaseline />
      <Navigation />
      <ToastContainer />
      <ExternalUserPhoneNumber />
      <PrivacyPolicyBanner />
    </WrapperProvider>,
  );
}

if (process.env.REACT_APP_WEB_VITALS === 'true') {
  reportWebVitals();
}
