import {useCallback, useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';

import {Switch, FormControlLabel, FormGroup, Box} from '@mui/material';
import {useTranslations} from '@vidiemme/react-i18n';
import moment from 'moment-timezone';

import {Button} from '@/atoms/Button';
import {useCalendar} from '@/hooks/calendar';
import useDate from '@/hooks/date';
import {AuthRoutes} from '@/navigation/routes';
import {useNavigation} from '@/navigation/useNavigation';
import {useOnPageFocused} from '@/navigation/useOnPageFocused';
import {Calendar} from '@/organisms/Calendar';
import {LoadingMessage} from '@/organisms/LoadingMessage';
import {ResponseErrorMessage} from '@/organisms/ResponseErrorMessage';
import {useGetEvent} from '@/store/events';
import {useGetSlots} from '@/store/slots';
import {Base} from '@/templates/Base';

import {useStyle} from './style';

// Main Event component
const Event = () => {
  // Use translation hook for internationalization
  const {t} = useTranslations();
  const {switchCalendarTimezone, ctaWrapper} = useStyle();

  // Navigation and toast hooks
  const {goToEventSlotDetail, goToEventBookingDownlod} = useNavigation();

  // Date related hooks
  const {isPast, getNow, isSameTimezone, usingTimezone} = useDate();

  // Get event id from URL parameters
  const {id} = useParams();
  const eventId = parseInt(id!);

  const [showTimeLocation, setShowTimeLocation] = useState(true);

  // Fetch event data
  const {
    loading: loadingEvent,
    error: errorEvent,
    response: responseEvent,
    run: runEvent,
  } = useGetEvent(eventId, false);

  // Prepare query for fetching slots
  const slotsQuery = useMemo(
    () => ({
      eventId,
    }),
    [eventId],
  );

  // Fetch slots data
  const {
    loading: loadingSlots,
    error: errorSlots,
    response: responseSlots,
    run: runSlots,
  } = useGetSlots(slotsQuery, false);

  // Callback to run when page is focused
  const onFocus = useCallback(() => {
    if (runEvent && runSlots) {
      runEvent();
      runSlots();
    }
  }, [runEvent, runSlots]);

  // Listen for page focus event
  useOnPageFocused(AuthRoutes.EVENT_CALENDAR, onFocus);

  // Calendar related hooks
  const {convertSlotsToFullCalendarEvents} = useCalendar();

  // Handle slot click event
  const handleSlotClick = useCallback(
    (slotId: number) => {
      if (id) {
        goToEventSlotDetail(parseInt(id), slotId);
      }
    },
    [goToEventSlotDetail, id],
  );

  const getTimezone = useCallback(() => {
    if (responseEvent) {
      return showTimeLocation
        ? responseEvent.location.timezone
        : moment.tz.guess();
    }
    return '';
  }, [responseEvent, showTimeLocation]);

  const switchTimezoneHandler = useCallback(() => {
    setShowTimeLocation(prevShowTimeLocation => !prevShowTimeLocation);
  }, []);

  const handleEventBookingDownlod = useCallback(() => {
    goToEventBookingDownlod(eventId);
  }, [eventId, goToEventBookingDownlod]);

  // Prepare buttons for the page
  const buttons: JSX.Element = useMemo(
    () => (
      <Box sx={ctaWrapper}>
        <Button
          variant="primaryBig"
          color="default"
          onClick={handleEventBookingDownlod}>
          {t('EVENT_MANAGEMENT.BUTTON_DOWNLOAD_BOOKINGS')}
        </Button>
      </Box>
    ),
    [ctaWrapper, handleEventBookingDownlod, t],
  );

  // Prepare event and location names
  const {
    name: eventName = '',
    location: {name: locationName = '', timezone: timezoneEvent = ''} = {},
  } = responseEvent || {};

  // Prepare body of the page
  const body = useMemo(() => {
    if (errorSlots || errorEvent) {
      return <ResponseErrorMessage showToast />;
    }

    if (responseSlots && responseEvent) {
      const events = convertSlotsToFullCalendarEvents({
        slots: responseSlots,
        timezone: getTimezone(),
        minBookingTime: responseEvent.min_booking_time,
      });
      const startDateFirstSlot = usingTimezone(
        responseSlots[0]?.startTime,
        getTimezone(),
      ).format();
      const initialDate = isPast(startDateFirstSlot, getTimezone())
        ? getNow(getTimezone())
        : startDateFirstSlot;

      return (
        <FormGroup>
          {timezoneEvent && !isSameTimezone(timezoneEvent) && (
            <FormControlLabel
              sx={switchCalendarTimezone}
              control={
                <Switch
                  checked={showTimeLocation}
                  onChange={switchTimezoneHandler}
                />
              }
              label={t('TIMEZONE.SWITCH_TIME_LOCATION', {
                location: responseEvent.location.city,
              })}
            />
          )}
          <Calendar
            events={events}
            fullCalendarProps={{initialDate}}
            itemClickHandler={handleSlotClick}
            hasDownloadButton={true}
            timezone={getTimezone()}
          />
        </FormGroup>
      );
    }

    if (loadingSlots || loadingEvent) {
      return <LoadingMessage />;
    }

    return null;
  }, [
    convertSlotsToFullCalendarEvents,
    errorEvent,
    errorSlots,
    getNow,
    getTimezone,
    handleSlotClick,
    isPast,
    isSameTimezone,
    loadingEvent,
    loadingSlots,
    responseEvent,
    responseSlots,
    showTimeLocation,
    switchCalendarTimezone,
    switchTimezoneHandler,
    t,
    timezoneEvent,
    usingTimezone,
  ]);

  // Render the page
  return (
    <Base
      preTitle={locationName}
      breadCrumbs={[
        {label: t('NAVBAR.item_EVENTS'), uri: AuthRoutes.EVENTS},
        {
          label: eventName && `${locationName} - ${eventName}`,
          uri: '',
        },
      ]}
      buttons={buttons}
      title={eventName}>
      {body}
    </Base>
  );
};

export default Event;
