import {useCallback, useEffect, useMemo} from 'react';
import {useParams} from 'react-router-dom';

import {useTranslations} from '@vidiemme/react-i18n';

import {useUserContext} from '@/contexts/user';
import {useQueryString} from '@/hooks/queryString';
import {useNavigation} from '@/navigation/useNavigation';
import {LoadingMessage} from '@/organisms/LoadingMessage';
import {ResponseErrorMessage} from '@/organisms/ResponseErrorMessage';
import {useGetEvent} from '@/store/events';
import {useRegisterInvitation} from '@/store/invitations/hooks';
import {Base} from '@/templates/Base';

const RegisterInvitation = () => {
  const {goToInvitation} = useNavigation();
  const {userInfo} = useUserContext();
  const queryEventId = useQueryString('event_id');
  const {id: pathEventId} = useParams();
  const {t} = useTranslations();

  const eventId = parseInt((queryEventId || pathEventId) as string);

  const {
    loading: loadingRegister,
    error: errorRegister,
    response: responseRegister,
  } = useRegisterInvitation({
    userId: userInfo!.user_id,
    eventId: eventId,
  });

  const {
    loading: loadingGetEvent,
    error: errorGetEvent,
    response: responseGetEvent,
    run: runGetEvent,
  } = useGetEvent(eventId, false);

  useEffect(() => {
    if (
      !loadingRegister &&
      !errorRegister &&
      responseRegister &&
      !loadingGetEvent
    ) {
      runGetEvent!();
    }
  }, [
    errorRegister,
    loadingGetEvent,
    loadingRegister,
    responseRegister,
    runGetEvent,
  ]);

  const handleNavigation = useCallback(
    (_category: string) => {
      return goToInvitation(eventId);
    },
    [eventId, goToInvitation],
  );

  const body = useMemo(() => {
    if (errorRegister || errorGetEvent) {
      return <ResponseErrorMessage showToast />;
    }

    if (responseGetEvent && responseRegister) {
      handleNavigation(responseGetEvent.category.name);
      return null;
    }

    if (loadingRegister || loadingGetEvent) {
      return <LoadingMessage />;
    }

    return null;
  }, [
    errorRegister,
    errorGetEvent,
    loadingRegister,
    loadingGetEvent,
    responseRegister,
    responseGetEvent,
    handleNavigation,
  ]);

  return <Base title={t('INVITATIONS.TITLE')}>{body}</Base>;
};

export default RegisterInvitation;
