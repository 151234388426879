import {useMemo} from 'react';

import {SxProps} from '@mui/material';

import {theme} from '@/themes/default';

import {IPropsStyle} from './type';

export const useStyle = ({
  isBooked = false,
  eventClose = false,
}: {
  isBooked?: boolean;
  eventClose?: boolean;
} = {}): IPropsStyle => {
  /* Table Header - fullCalendar */
  const fullCalendarBoxTableHeader: SxProps = useMemo(
    () => ({
      thead: {
        backgroundColor: 'black',
        color: 'white',
        'tr.fc-scrollgrid-section': {
          th: {
            'div.fc-scroller-harness': {
              'div.fc-scroller': {
                'table.fc-col-header': {
                  thead: {
                    tr: {
                      //Top left Table Corner
                      'th.fc-timegrid-axis': {
                        border: 'none',
                        display: 'none',
                        [theme.breakpoints.up('md')]: {
                          display: 'table-cell',
                        },
                      },
                      //Other headr Cells
                      'th.fc-col-header-cell': {
                        border: 'none',
                        height: '64px',
                        'div.fc-scrollgrid-sync-inner': {
                          paddingY: '2%',
                          height: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    }),
    [],
  );

  /* Table Body - fullCalendar */
  const fullCalendarBoxTableBody: SxProps = useMemo(
    () => ({
      tbody: {
        'tr.fc-scrollgrid-section': {
          td: {
            'div.fc-scroller-harness': {
              'div.fc-scroller': {
                'div.fc-timegrid-body': {
                  'div.fc-timegrid-slots': {
                    table: {
                      tbody: {
                        tr: {
                          'td.fc-timegrid-slot-label': {
                            '.fc-timegrid-slot-label-cushion': {
                              fontFamily: 'AktivGrotesk',
                              fontSize: '12px',
                              fontStyle: 'normal',
                              fontWeight: '400',
                              lineHeight: '18px',
                              textTransform: 'uppercase',
                            },
                            'div.fc-timegrid-slot-label-frame': {
                              display: 'flex',
                              justifyContent: 'center',
                            },
                          },
                        },
                      },
                    },
                  },
                  'div.fc-timegrid-cols': {
                    table: {
                      tbody: {
                        tr: {
                          'td.fc-day-today': {
                            backgroundColor: 'white',
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    }),
    [],
  );

  /* Container Table - fullCalendar */
  const fullCalendarBoxTable: SxProps = useMemo(
    () => ({
      'div.fc-view-harness': {
        'div.fc-timeGridWeek-view, div.fc-timeGridDay-view': {
          'table.fc-scrollgrid': {
            ...fullCalendarBoxTableHeader,
            ...fullCalendarBoxTableBody,
          },
        },
        [theme.breakpoints.down('lg')]: {
          height:
            '65vh !important' /* set calendar height only for mobile viewport */,
        },
      },
    }),
    [fullCalendarBoxTableBody, fullCalendarBoxTableHeader],
  );

  const fullCalendarBox = useMemo(
    (): SxProps => ({
      //Override event style
      '& .fc-v-event': {
        backgroundColor: 'transparent',
        border: 'transparent',
      },
      //Container class="MuiBox-root css-1y99rud"
      marginBottom: '5%',
      'div.fc-theme-standard': {
        //Container switch Weeks
        'div.fc-header-toolbar': {
          '& div.fc-toolbar-chunk': {
            display: 'none',
          },

          '& div.fc-toolbar-chunk:first-of-type': {
            width: '100%',
            display: 'block',

            div: {
              width: '100%',
              display: 'flex',
              alignItems: 'center',

              'button.fc-button': {
                backgroundColor: 'transparent',
                border: 'none',
                color: 'gray',
                marginX: '16px',
                paddingX: 0,
                '&.fc-prev-button': {
                  marginLeft: 0,
                },
              },

              'h2.fc-toolbar-title': {
                fontFamily: 'AktivGrotesk',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: '700',
                lineHeight: '21px',
              },
            },
          },
        },
        ...fullCalendarBoxTable,
      },
    }),
    [fullCalendarBoxTable],
  );

  const headerWrapperContentBox = useMemo(
    (): SxProps => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    }),
    [],
  );

  const headerContentBox = useMemo(
    (): SxProps => ({
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    }),
    [],
  );

  const headerContentBoxIsToday = useMemo(
    (): SxProps => ({
      color: 'black',
      backgroundColor: 'white',
      width: '30px',
      height: '30px',
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }),
    [],
  );

  const headerCellDayString = useMemo(
    (): SxProps => ({
      color: 'white',
      textTransform: 'capitalize',
    }),
    [],
  );

  const headerCellDayNumber = useMemo(
    (): SxProps => ({
      color: 'white',
      marginTop: '4px',
    }),
    [],
  );

  const fullCalendarEventGuestBox = useMemo(
    (): SxProps => ({
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexDirection: 'row',
      marginBottom: '3px',
      [theme.breakpoints.up('md')]: {
        marginBottom: '5px',
      },
    }),
    [],
  );

  const fullCalendarEventBox = useMemo(
    (): SxProps => ({
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '100%',
      padding: '0 8px',
      backgroundColor: 'white',
      color: 'black',
      border: '1px solid black',
    }),
    [],
  );

  const fullCalendarEventBoxClosed = useMemo(
    (): SxProps => ({
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '100%',
      padding: '0px 8px',
      backgroundColor: '#E0E0E0',
      color: '#757575',
      border: 'none',
    }),
    [],
  );

  const fullCalendarEventTimeBox = useMemo(
    (): SxProps => ({
      display: 'flex',
      justifyContent: isBooked ? 'space-between' : 'flex-start',
      alignItems: 'center',
      flexDirection: 'row',
      width: '100%',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      '.icon-booked': {
        marginBottom: '2px',
        [theme.breakpoints.down('md')]: {
          marginBottom: '0',
          position: 'absolute',
          bottom: '5px',
          right: '5px',
        },
      },
    }),
    [isBooked],
  );

  const fullCalendarEventGuestTypography = useMemo(
    (): SxProps => ({
      marginLeft: '5%',
    }),
    [],
  );

  const eventSlotContainer = useMemo((): SxProps => {
    const bookedStyle = isBooked
      ? {
          border: `2px solid ${theme.palette.successGreen}`,
          backgroundColor: theme.palette.gray1,
          color: theme.palette.black,
        }
      : null;
    if (eventClose) {
      return {
        ...fullCalendarEventBoxClosed,
        ...bookedStyle,
      };
    }
    return {
      ...fullCalendarEventBox,
      ...bookedStyle,
    };
  }, [eventClose, fullCalendarEventBox, fullCalendarEventBoxClosed, isBooked]);

  const buttonDownload = useMemo(
    (): SxProps => ({
      svg: {
        color: theme.palette.white,
      },
    }),
    [],
  );

  const downloadIcon = useMemo(
    (): SxProps => ({
      color: theme.palette.white,
    }),
    [],
  );

  return {
    fullCalendarBox,
    headerWrapperContentBox,
    headerContentBox,
    headerContentBoxIsToday,
    headerCellDayString,
    headerCellDayNumber,
    fullCalendarEventGuestBox,
    fullCalendarEventBox,
    fullCalendarEventTimeBox,
    fullCalendarEventGuestTypography,
    fullCalendarEventBoxClosed,
    eventSlotContainer,
    buttonDownload,
    downloadIcon,
  };
};
