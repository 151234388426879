import {Typography, Box, Divider} from '@mui/material';
import {useTranslations} from '@vidiemme/react-i18n';

import {Base} from '@/templates/Base';

import {useStyle} from './style';

const PrivacyPolicy = ({
  hideNavbar = false,
  hideFooter = false,
}: {
  hideNavbar?: boolean;
  hideFooter?: boolean;
}) => {
  const {t} = useTranslations();

  const {h2, p, table, tableCell, dateUpdated, divider} = useStyle();

  return (
    <Base
      title={t('PRIVACY_POLICY.TITLE')}
      hideNavbar={hideNavbar}
      hideFooter={hideFooter}>
      <Typography variant="bodyRegular" component="p" sx={p}>
        Gianni Versace S.r.l. (di seguito, “Versace”), in qualità di titolare
        del trattamento La informa, ai sensi degli artt. 13 e 14 del Regolamento
        Europeo 679/2016 relativo alla protezione dei dati personali (di
        seguito, “Regolamento”), e della normativa nazionale compresi i singoli
        provvedimenti dell'Autorità di controllo (Garante per la protezione dei
        dati personali) ove applicabile, che i dati personali conferiti da Lei a
        Versace in occasione dell’evento in oggetto saranno trattati nel
        rispetto delle disposizioni legislative e contrattuali vigenti per le
        finalità e con le modalità di seguito indicate. Lei, in quanto
        dipendente di Versace, è responsabile di condividere la presente
        Informativa Privacy e Cookie Policy con i Suoi eventuali ospiti (di
        seguito, “Ospiti”) presso l’evento ai fini dell’iscrizione all’evento.
      </Typography>
      <Typography variant="bodyRegular" component="h2" sx={h2}>
        1. TITOLARE DEL TRATTAMENTO
      </Typography>
      <Typography variant="bodyRegular" component="p" sx={p}>
        Il titolare del trattamento è la società Gianni Versace S.r.l., che ha
        sede in Piazza Einaudi 4, 20124 Milano (Italia), nella persona del
        Legale Rappresentante pro tempore.
      </Typography>
      <Typography variant="bodyRegular" component="h2" sx={h2}>
        2. RESPONSABILE DELLA PROTEZIONE DEI DATI
      </Typography>
      <Typography variant="bodyRegular" component="p" sx={p}>
        Ai sensi dell’art. 37 del Regolamento, Versace ha designato il
        Responsabile della Protezione dei Dati, meglio conosciuto come Data
        Protection Officer (di seguito, “DPO”), il quale è raggiungibile al
        seguente indirizzo:{' '}
        <a href="mailto:DPO@versace.it">
          <Typography variant="bodyRegular">DPO@versace.it</Typography>
        </a>
        .
      </Typography>
      <Typography variant="bodyRegular" component="h2" sx={h2}>
        3. FONTE DEI DATI
      </Typography>
      <Typography variant="bodyRegular" component="p" sx={p}>
        I Suoi dati sono da Lei direttamente forniti a Versace e sono trattati
        secondo quanto indicato all’interno dell’informativa privacy, da Lei
        ricevuta nel contesto dell’instaurazione del rapporto di lavoro o di
        collaborazione, per tutti gli aspetti non disciplinati dalla presente
        Informativa Privacy e Cookie Policy. I dati degli Ospiti sono forniti da
        questi ultimi a Lei, che a Sua volta li trasmette a Versace al fine di
        consentire l’inserimento del nominativo degli Ospiti nella lista degli
        iscritti all’evento.
      </Typography>
      <Typography variant="bodyRegular" component="h2" sx={h2}>
        4. FINALITÀ DEL TRATTAMENTO
      </Typography>
      <Typography variant="bodyRegular" component="p" sx={p}>
        Versace tratta i Suoi dati personali identificativi (nome e cognome) e
        di contatto (numero di telefono cellulare e indirizzo e-mail) e i dati
        identificativi (nome e cognome) degli Ospiti per:
      </Typography>
      <ol type="i">
        <li>
          <Typography variant="bodyRegular">
            attività connesse alla gestione e allo svolgimento dell’iscrizione e
            partecipazione all’evento;
          </Typography>
        </li>
        <li>
          <Typography variant="bodyRegular">
            adempimento di obblighi previsti dalla legge, da regolamenti e/o
            dalla normativa comunitaria;
          </Typography>
        </li>
        <li>
          <Typography variant="bodyRegular">
            attività connesse alla tutela del patrimonio di Versace e finalità
            di prevenzione e repressione di attività illecite;
          </Typography>
        </li>
        <li>
          <Typography variant="bodyRegular">
            eventuali attività connesse all’esercizio dei diritti di Versace in
            sede giudiziaria.
          </Typography>
        </li>
      </ol>
      <Typography variant="bodyRegular" component="p" sx={p}>
        I dati raccolti mediante i cookie tecnici (vedi tabella sotto)
        contengono le seguenti informazioni (lingua, e token contenente nome,
        cognome e indirizzo e-mail) per la registrazione all’evento e sono
        trattati per garantire la navigazione e il corretto funzionamento della
        pagina web dedicata.
      </Typography>
      <table style={table}>
        <thead style={{textAlign: 'left'}}>
          <tr>
            <th style={tableCell}>Cookie</th>
            <th style={tableCell}>Funzionalità</th>
            <th style={tableCell}>Durata</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={tableCell}>
              <Typography variant="bodyRegular">user-token</Typography>
            </td>
            <td style={tableCell}>
              <Typography variant="bodyRegular">Sessione utente</Typography>
            </td>
            <td style={tableCell}>
              <Typography variant="bodyRegular">
                Fino al logout utente
              </Typography>
            </td>
          </tr>
          <tr>
            <td style={tableCell}>
              <Typography variant="bodyRegular">refresh-token</Typography>
            </td>
            <td style={tableCell}>
              <Typography variant="bodyRegular">Sessione utente</Typography>
            </td>
            <td style={tableCell}>
              <Typography variant="bodyRegular">
                Fino al logout utente
              </Typography>
            </td>
          </tr>
          <tr>
            <td style={tableCell}>
              <Typography variant="bodyRegular">
                KEYCLOAK_SESSION_LEGACY
              </Typography>
            </td>
            <td style={tableCell}>
              <Typography variant="bodyRegular">Sessione utente</Typography>
            </td>
            <td style={tableCell}>
              <Typography variant="bodyRegular">12 ore</Typography>
            </td>
          </tr>
          <tr>
            <td style={tableCell}>
              <Typography variant="bodyRegular">KEYCLOAK_SESSION</Typography>
            </td>
            <td style={tableCell}>
              <Typography variant="bodyRegular">Sessione utente</Typography>
            </td>
            <td style={tableCell}>
              <Typography variant="bodyRegular">12 ore</Typography>
            </td>
          </tr>
          <tr>
            <td style={tableCell}>
              <Typography variant="bodyRegular">
                KEYCLOAK_IDENTITY_LEGACY
              </Typography>
            </td>
            <td style={tableCell}>
              <Typography variant="bodyRegular">Sessione utente</Typography>
            </td>
            <td style={tableCell}>
              <Typography variant="bodyRegular">
                Sessione, fino alla chiusura del browser
              </Typography>
            </td>
          </tr>
          <tr>
            <td style={tableCell}>
              <Typography variant="bodyRegular">KEYCLOAK_IDENTITY</Typography>
            </td>
            <td style={tableCell}>
              <Typography variant="bodyRegular">Sessione utente</Typography>
            </td>
            <td style={tableCell}>
              <Typography variant="bodyRegular">
                Sessione, fino alla chiusura del browser
              </Typography>
            </td>
          </tr>
          <tr>
            <td style={tableCell}>
              <Typography variant="bodyRegular">
                AUTH_SESSION_ID_LEGACY
              </Typography>
            </td>
            <td style={tableCell}>
              <Typography variant="bodyRegular">Sessione utente</Typography>
            </td>
            <td style={tableCell}>
              <Typography variant="bodyRegular">
                Sessione, fino alla chiusura del browser
              </Typography>
            </td>
          </tr>
          <tr>
            <td style={tableCell}>
              <Typography variant="bodyRegular">AUTH_SESSION_ID</Typography>
            </td>
            <td style={tableCell}>
              <Typography variant="bodyRegular">Sessione utente</Typography>
            </td>
            <td style={tableCell}>
              <Typography variant="bodyRegular">
                Sessione, fino alla chiusura del browser
              </Typography>
            </td>
          </tr>
          <tr>
            <td style={tableCell}>
              <Typography variant="bodyRegular">i18next</Typography>
            </td>
            <td style={tableCell}>
              <Typography variant="bodyRegular">Lingua</Typography>
            </td>
            <td style={tableCell}>
              <Typography variant="bodyRegular">
                Sessione, fino alla chiusura del browser
              </Typography>
            </td>
          </tr>
        </tbody>
      </table>
      <Typography variant="bodyRegular" component="h2" sx={h2}>
        5. NATURA DEL CONFERIMENTO DEI DATI
      </Typography>
      <Typography variant="bodyRegular" component="p" sx={p}>
        Il conferimento dei dati rispetto alle finalità sopra indicate ai punti
        i., ii., iii. e iv. è obbligatorio e l’eventuale rifiuto comporterà
        l’impossibilità per Versace di consentire a Lei e agli Ospiti l’accesso
        all’evento. Per la raccolta dei dati mediante il cookie tecnico non è
        richiesto un esplicito consenso dell’interessato in quanto tali
        strumenti sono necessari a garantire la navigazione e il corretto
        funzionamento del sito. Tale cookie è, quindi, sempre attivo.
      </Typography>
      <Typography variant="bodyRegular" component="h2" sx={h2}>
        6. MODALITÀ DEL TRATTAMENTO
      </Typography>
      <Typography variant="bodyRegular" component="p" sx={p}>
        I dati personali saranno trattati da Versace e dai suoi Incaricati al
        trattamento con sistemi informatici (e manuali) secondo i principi di
        correttezza, lealtà e trasparenza previsti dalla normativa applicabile
        in materia di protezione dei dati personali e tutelando la Sua
        riservatezza e i Suoi diritti, nonché quelli degli Ospiti, mediante
        l’adozione di idonee misure tecniche ed organizzative per garantire un
        livello di sicurezza adeguato al rischio. Specifiche misure di sicurezza
        sono osservate per prevenire la perdita dei dati, usi illeciti o non
        corretti ed accessi non autorizzati.
      </Typography>
      <Typography variant="bodyRegular" component="h2" sx={h2}>
        7. CONSERVAZIONE DEI DATI
      </Typography>
      <Typography variant="bodyRegular" component="p" sx={p}>
        Con riferimento alle finalità di cui ai punti i. – iv. sopra, i Suoi
        dati personali identificativi e di contatto verranno trattati per tutto
        il periodo di durata dell’evento nonché in base a quanto indicato
        nell’informativa privacy da Lei ricevuta nel contesto dell’instaurazione
        del rapporto di lavoro o di collaborazione. I dati personali funzionali
        all’assolvimento di eventuali obblighi di legge saranno conservati anche
        successivamente, in ottemperanza a detti obblighi e nel rispetto delle
        tempistiche di conservazione previste dalle norme di volta in
        applicabili, incluse quelle di natura civilistica, fiscale e tributaria
        vigenti. I dati personali identificativi degli Ospiti saranno conservati
        per 1 mese dopo la fine dell’evento. I dati raccolti tramite cookie
        verranno conservati secondo quanto indicato nella tabella di cui al par.
        4 sopra.
      </Typography>
      <Typography variant="bodyRegular" component="h2" sx={h2}>
        8. BASE GIURIDICA DEL TRATTAMENTO
      </Typography>
      <Typography variant="bodyRegular" component="p" sx={p}>
        Le basi giuridiche del trattamento dei dati per le finalità di cui al
        par. 4 sopra sono le seguenti:
      </Typography>
      <ul>
        <li>
          <Typography variant="bodyRegular">
            per la finalità di cui al punto i., la base giuridica applicabile è
            l’esecuzione di obblighi precontrattuali, al fine di garantire a Lei
            e agli Ospiti l’ingresso e la partecipazione all’evento, ivi inclusa
            la corretta esecuzione da parte di Versace dei servizi ad esso
            connessi;
          </Typography>
        </li>
        <li>
          <Typography variant="bodyRegular">
            per la finalità di cui al punto ii., la base giuridica è costituita
            dall’adempimento di obblighi di legge;
          </Typography>
        </li>
        <li>
          <Typography variant="bodyRegular">
            per la finalità di cui ai punti iii. e iv., la base giuridica è
            costituita dal legittimo interesse di Versace al diritto di azione
            costituzionalmente garantito (art. 24 Cost.) e, in quanto tale, è
            socialmente riconosciuto come prevalente rispetto agli interessi del
            singolo soggetto interessato;
          </Typography>
        </li>
        <li>
          <Typography variant="bodyRegular">
            la base giuridica applicabile al trattamento dei Suoi dati tramite
            cookie è il servizio, in quanto il trattamento è necessario a
            garantire la navigazione e il corretto funzionamento della pagina
            web dedicata.
          </Typography>
        </li>
      </ul>

      <Typography variant="bodyRegular" component="h2" sx={h2}>
        9. COMUNICAZIONE E TRASFERIMENTO DEI DATI
      </Typography>
      <Typography variant="bodyRegular" component="p" sx={p}>
        Il trattamento dei Suoi dati personali e di quelli degli Ospiti è
        effettuato da personale interno di Versace a tal fine nominato Data
        Manager o Incaricato / Addetto al Trattamento. I dati personali saranno
        comunicati a soggetti terzi, per l’esecuzione del rapporto contrattuale
        con Versace, a tale scopo nominati Responsabili del trattamento, ovvero
        quali autonomi titolari del trattamento e per l’adempimento di obblighi
        normativi. In particolare, i dati personali verranno comunicati a:
      </Typography>
      <ol type="a">
        <li>
          <Typography variant="bodyRegular">
            persone, società o studi professionali, che prestino attività di
            assistenza, consulenza o collaborazione a Versace in materia
            contabile, amministrativa, legale, tributaria e finanziaria;
          </Typography>
        </li>
        <li>
          <Typography variant="bodyRegular">
            soggetti delegati e/o Incaricati al Trattamento da Versace per
            l'espletamento delle attività o di parte delle attività correlate
            allo svolgimento dell’evento;
          </Typography>
        </li>
        <li>
          <Typography variant="bodyRegular">
            Enti pubblici cui i dati devono essere forniti per adempiere ad
            obblighi di legge, eventuali Autorità che legittimamente ne facciano
            richiesta, Pubbliche Amministrazioni per lo svolgimento delle
            funzioni istituzionali nei limiti stabiliti dalla legge o dai
            regolamenti.
          </Typography>
        </li>
      </ol>
      <Typography variant="bodyRegular" component="p" sx={p}>
        Alcuni dei soggetti cui vengono comunicati i dati possono essere
        stabiliti in Paesi al di fuori dell'Unione Europea ("UE"). In alcuni
        casi si tratta di Paesi terzi giudicati adeguati in base ad una
        decisione della Commissione europea; in altri casi Versace assicura la
        sussistenza di garanzie adeguate al trasferimento sulla base di clausole
        contrattuali tra Versace e il destinatario dei dati personali presso il
        Paese terzo. Copia di tali documenti è disponibile presso la sede legale
        di Versace. In ogni caso, Versace si impegna a garantire livelli di
        tutela e salvaguardia adeguati secondo le norme applicabili, ivi inclusa
        la previsione di misure di sicurezza tecniche e organizzative.
      </Typography>
      <Typography variant="bodyRegular" component="h2" sx={h2}>
        10. DIRITTI PRIVACY
      </Typography>
      <Typography variant="bodyRegular" component="p" sx={p}>
        Lei e gli Ospiti potrete esercitare i diritti previsti dal Regolamento
        (artt. 15-21), ivi inclusi:
      </Typography>
      <ul>
        <li>
          <Typography variant="bodyRegular">
            ricevere conferma dell’esistenza dei dati personali e accedere al
            loro contenuto (diritto di accesso);
          </Typography>
        </li>
        <li>
          <Typography variant="bodyRegular">
            aggiornare, modificare e/o correggere i dati personali (diritto di
            rettifica);
          </Typography>
        </li>
        <li>
          <Typography variant="bodyRegular">
            chiederne la cancellazione o la limitazione del trattamento dei dati
            trattati in violazione di legge compresi quelli di cui non è
            necessaria la conservazione in relazione agli scopi per i quali i
            dati sono stati raccolti o altrimenti trattati (diritto all'oblio e
            diritto alla limitazione);
          </Typography>
        </li>
        <li>
          <Typography variant="bodyRegular">
            opporsi al trattamento (diritto di opposizione);
          </Typography>
        </li>
        <li>
          <Typography variant="bodyRegular">
            proporre reclamo all'Autorità di controllo in caso di violazione
            della disciplina in materia di protezione dei dati personali;
          </Typography>
        </li>
        <li>
          <Typography variant="bodyRegular">
            ricevere copia dei dati in formato elettronico che lo riguardano e
            chiedere che tali dati siano trasmessi ad un altro titolare del
            trattamento (diritto alla portabilità dei dati).
          </Typography>
        </li>
      </ul>
      <Typography variant="bodyRegular" component="p" sx={p}>
        {' '}
        Le richieste relative all’esercizio dei suddetti diritti vanno rivolte a
        Versace mediante invio di un messaggio e-mail all'indirizzo{' '}
        <a href="mailto:DPO@versace.it">
          <Typography variant="bodyRegular">dpo@versace.it</Typography>
        </a>{' '}
        o tramite posta ordinaria al seguente indirizzo: società Gianni Versace
        S.r.l., Piazza Einaudi 4, 20124 Milano (Italia).
      </Typography>
      <Typography variant="bodyRegular" component="h2" sx={h2}>
        11. MODIFICHE ALL’INFORMATIVA PRIVACY E COOKIE POLICY
      </Typography>
      <Typography variant="bodyRegular" component="p" sx={p}>
        L’eventuale entrata in vigore di nuove normative di settore, come anche
        l’eventuale aggiornamento dei servizi, potrebbe comportare la necessità
        di variare le condizioni di trattamento dei dati personali. È pertanto
        possibile che la presente Informativa Privacy e Cookie Policy subisca
        modifiche nel tempo che Le verranno opportunamente comunicate. Lei è
        responsabile di trasmettere il contenuto delle eventuali modifiche agli
        Ospiti.
      </Typography>
      <Box sx={dateUpdated}>
        <Typography
          variant="bodyRegularItalicSmall"
          component="p"
          sx={{...p, textAlign: 'right'}}>
          Data di entrata in vigore: 22/01/2024
        </Typography>
      </Box>
      <Divider sx={divider}></Divider>
      <Typography variant="bodyRegular" component="p" sx={p}>
        Pursuant to Articles 13 and 14 of EU Regulation 2016/679 on personal
        data protection (hereinafter, the “Regulation”), and in accordance with
        national legislation, including decisions issued by the Italian Data
        Protection Authority (Garante per la Protezione dei Dati Personali),
        where applicable, Gianni Versace S.r.l. (hereinafter, also, “Versace”),
        in its capacity as data controller, informs you that the personal data
        you provide to Versace in connection to the event at stake shall be
        processed in compliance with the applicable legislative and contractual
        regulations for the purposes and in the manners indicated below. As an
        employee of Versace, you shall be responsible for sharing this Privacy &
        Cookie Policy with any of your guests (hereinafter, the “Guests”) for
        the purpose of registration to the event.
      </Typography>
      <Typography variant="bodyRegular" component="h2" sx={h2}>
        1. DATA CONTROLLER
      </Typography>
      <Typography variant="bodyRegular" component="p" sx={p}>
        The data Controller is Gianni Versace S.r.l., a company with registered
        office address at Piazza Einaudi 4, 20124 Milan (Italy), in the person
        of its Legal Representative pro tempore.
      </Typography>
      <Typography variant="bodyRegular" component="h2" sx={h2}>
        2. DATA PROTECTION OFFICER
      </Typography>
      <Typography variant="bodyRegular" component="p" sx={p}>
        Pursuant to Article 37 of the Regulation, Versace has appointed a Data
        Protection Officer (hereinafter, “DPO”), whom you can contact by writing
        to{' '}
        <a href="mailto:DPO@versace.it">
          <Typography variant="bodyRegular">DPO@versace.it</Typography>
        </a>
        .
      </Typography>
      <Typography variant="bodyRegular" component="h2" sx={h2}>
        3. DATA SOURCES
      </Typography>
      <Typography variant="bodyRegular" component="p" sx={p}>
        Your data are provided directly by you to Versace and are processed in
        line with the provisions of the privacy policy, which you have received
        upon the establishment of your employment or collaboration agreement
        with Versace, which shall apply to any aspects not regulated under this
        Privacy & Cookie Policy. The Guests’ data shall be provided by them to
        you, who shall transmit them to Versace for the purpose of enabling the
        Guests’ names to be entered in the list of individuals attending the
        event.
      </Typography>
      <Typography variant="bodyRegular" component="h2" sx={h2}>
        4. PURPOSES OF PROCESSING
      </Typography>
      <Typography variant="bodyRegular" component="p" sx={p}>
        Versace shall process your identifying personal data (name and surname)
        and contact data (mobile phone number and e-mail address) and the
        identifying personal data (name and surname) of Guests for:
      </Typography>
      <ol type="i">
        <li>
          <Typography variant="bodyRegular">
            activities connected to the management and in the course of
            registration and attendance to the event;
          </Typography>
        </li>
        <li>
          <Typography variant="bodyRegular">
            compliance with obligations provided by law, regulations, and/or EU
            legislation;
          </Typography>
        </li>
        <li>
          <Typography variant="bodyRegular">
            activities connected to the protection of Versace's assets and for
            the purpose of preventing and combating unlawful activities;
          </Typography>
        </li>
        <li>
          <Typography variant="bodyRegular">
            any activities connected to the exercise of Versace's rights in
            legal proceedings.
          </Typography>
        </li>
      </ol>
      <Typography variant="bodyRegular" component="p" sx={p}>
        The data collected through technical cookies (see table below) contain
        the following information (language, and token containing name, surname,
        and e-mail address) for registration to the event, and are processed for
        the purpose of ensuring navigation and the proper running of the
        dedicated web page.
      </Typography>
      <table style={table}>
        <thead style={{textAlign: 'left'}}>
          <tr>
            <th style={tableCell}>Cookie</th>
            <th style={tableCell}>Functionalities</th>
            <th style={tableCell}>Duration</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={tableCell}>
              <Typography variant="bodyRegular">user-token</Typography>
            </td>
            <td style={tableCell}>
              <Typography variant="bodyRegular">User session</Typography>
            </td>
            <td style={tableCell}>
              <Typography variant="bodyRegular">Until user logout</Typography>
            </td>
          </tr>
          <tr>
            <td style={tableCell}>
              <Typography variant="bodyRegular">refresh-token</Typography>
            </td>
            <td style={tableCell}>
              <Typography variant="bodyRegular">User session</Typography>
            </td>
            <td style={tableCell}>
              <Typography variant="bodyRegular">Until user logout</Typography>
            </td>
          </tr>
          <tr>
            <td style={tableCell}>
              <Typography variant="bodyRegular">
                KEYCLOAK_SESSION_LEGACY
              </Typography>
            </td>
            <td style={tableCell}>
              <Typography variant="bodyRegular">User session</Typography>
            </td>
            <td style={tableCell}>
              <Typography variant="bodyRegular">12 hours</Typography>
            </td>
          </tr>
          <tr>
            <td style={tableCell}>
              <Typography variant="bodyRegular">KEYCLOAK_SESSION</Typography>
            </td>
            <td style={tableCell}>
              <Typography variant="bodyRegular">User session</Typography>
            </td>
            <td style={tableCell}>
              <Typography variant="bodyRegular">12 hours</Typography>
            </td>
          </tr>
          <tr>
            <td style={tableCell}>
              <Typography variant="bodyRegular">
                KEYCLOAK_IDENTITY_LEGACY
              </Typography>
            </td>
            <td style={tableCell}>
              <Typography variant="bodyRegular">User session</Typography>
            </td>
            <td style={tableCell}>
              <Typography variant="bodyRegular">
                Session, until browser is closed
              </Typography>
            </td>
          </tr>
          <tr>
            <td style={tableCell}>
              <Typography variant="bodyRegular">KEYCLOAK_IDENTITY</Typography>
            </td>
            <td style={tableCell}>
              <Typography variant="bodyRegular">User session</Typography>
            </td>
            <td style={tableCell}>
              <Typography variant="bodyRegular">
                Session, until the user closes the browser
              </Typography>
            </td>
          </tr>
          <tr>
            <td style={tableCell}>
              <Typography variant="bodyRegular">
                AUTH_SESSION_ID_LEGACY
              </Typography>
            </td>
            <td style={tableCell}>
              <Typography variant="bodyRegular">User session</Typography>
            </td>
            <td style={tableCell}>
              <Typography variant="bodyRegular">
                Session, until the user closes the browser
              </Typography>
            </td>
          </tr>
          <tr>
            <td style={tableCell}>
              <Typography variant="bodyRegular">AUTH_SESSION_ID</Typography>
            </td>
            <td style={tableCell}>
              <Typography variant="bodyRegular">User session</Typography>
            </td>
            <td style={tableCell}>
              <Typography variant="bodyRegular">
                Session, until the user closes the browser
              </Typography>
            </td>
          </tr>
          <tr>
            <td style={tableCell}>
              <Typography variant="bodyRegular">i18next</Typography>
            </td>
            <td style={tableCell}>
              <Typography variant="bodyRegular">Language</Typography>
            </td>
            <td style={tableCell}>
              <Typography variant="bodyRegular">
                Session, until the user closes the browser
              </Typography>
            </td>
          </tr>
        </tbody>
      </table>
      <Typography variant="bodyRegular" component="h2" sx={h2}>
        5. MANDATORY NATURE OF THE PROVISION OF PERSONAL DATA
      </Typography>
      <Typography variant="bodyRegular" component="p" sx={p}>
        Provision of personal data with respect to the purposes listed under
        points i., ii., iii., and iv. is mandatory; therefore, should you refuse
        to provide such data, Versace will be unable to allow you and the Guests
        to access the event. The collection of data through technical cookies
        does not require your express consent, as said cookies are necessary to
        guarantee navigation and the proper running of the website. These
        cookies are therefore always active.
      </Typography>
      <Typography variant="bodyRegular" component="h2" sx={h2}>
        6. METHODS OF PROCESSING
      </Typography>
      <Typography variant="bodyRegular" component="p" sx={p}>
        Your personal data shall be processed by Versace and by Persons in
        Charge of Processing appointed by Versace, by using computer (and
        manual) systems, in accordance with the principles of fairness,
        integrity and transparency established under applicable personal data
        protection legislation, protecting your and the Guests’ privacy and
        rights, by adopting appropriate technical and organisational measures to
        ensure a level of security appropriate to the risk presented. Specific
        security measures are implemented to prevent the loss of data, their
        illegal or improper use, and any unauthorised accesses to data.
      </Typography>
      <Typography variant="bodyRegular" component="h2" sx={h2}>
        7. RETENTION OF PERSONAL DATA
      </Typography>
      <Typography variant="bodyRegular" component="p" sx={p}>
        With respect to the purposes under points from i. to – iv. above, your
        identifying personal data and contact data shall be processed for the
        entire period of duration of the event, and based on the provisions of
        the privacy policy you received upon the establishing of your employment
        or collaboration relationship with Versace. The personal data required
        to comply with legal obligations shall be retained including after
        termination of the contract, in compliance with said obligations and in
        accordance with the retention periods established under the legislation
        applicable from time to time, including any civil, tax, and fiscal laws
        and regulations. The Guests’ identifying personal data shall be retained
        for 1 month after the end of the event. The data collected through
        cookies shall be retained for the periods detailed in the table under
        paragraph 4 above.
      </Typography>
      <Typography variant="bodyRegular" component="h2" sx={h2}>
        8. LEGAL BASES FOR PROCESSING
      </Typography>
      <Typography variant="bodyRegular" component="p" sx={p}>
        The legal bases for processing data for the purposes under paragraph 4
        above are:
      </Typography>
      <ul>
        <li>
          <Typography variant="bodyRegular">
            for the purposes under point i., the legal basis is the performance
            of contractual obligations, in order to allow you and the Guests to
            access and take part in the event, including Versace's proper
            performance of the services connected thereto;
          </Typography>
        </li>
        <li>
          <Typography variant="bodyRegular">
            for the purposes under point ii., the legal basis is compliance with
            legal obligations;
          </Typography>
        </li>
        <li>
          <Typography variant="bodyRegular">
            for the purposes under point iii. and iv., the legal basis is the
            legitimate interest of Versace to its constitutional right of action
            under Article 24 of the Italian Constitution; as such, said interest
            is socially recognised to override the interests of single data
            subject;
          </Typography>
        </li>
        <li>
          <Typography variant="bodyRegular">
            the applicable legal basis for the processing of your personal data
            through cookies is the service, as processing is necessary in order
            to enable the navigation and proper running of the dedicated web
            page.
          </Typography>
        </li>
      </ul>
      <Typography variant="bodyRegular" component="h2" sx={h2}>
        9. COMMUNICATION AND TRANSFER OF DATA
      </Typography>
      <Typography variant="bodyRegular" component="p" sx={p}>
        The processing of personal data concerning you and the Guests shall be
        carried out by Versace's internal staff appointed to such end as Data
        Manager / Person in Charge of Processing. The personal data shall be
        communicated to third parties, for the performance of the contract in
        place with Versace, appointed to such end as personal data processors,
        or independent personal data controllers, and for the performance of
        legal obligations. In particular, the personal data shall be
        communicated to:
      </Typography>
      <ol type="a">
        <li>
          <Typography variant="bodyRegular">
            individuals, companies, or professional firms providing assistance
            or advisory services to Versace or collaborating with the same for
            bookkeeping, administrative, legal, tax, and fiscal matters;
          </Typography>
        </li>
        <li>
          <Typography variant="bodyRegular">
            delegated persons and/or Persons in Charge of Processing appointed
            by Versace for the performance of the activities or part of the
            activities in connection to the unfolding of the event;
          </Typography>
        </li>
        <li>
          <Typography variant="bodyRegular">
            public Bodies to whom the data must be provided in order to comply
            with legal obligations, any Authorities lawfully requesting
            communication thereof, and Public Administrations for the purpose of
            their institutional functions within the limits established by law
            or regulation.
          </Typography>
        </li>
      </ol>
      <Typography variant="bodyRegular" component="p" sx={p}>
        Some of the parties to whom the data are communicated may be established
        in Countries outside of the European Union (“EU”). In some cases, they
        are third Countries for which the European Commission issued an adequacy
        decision; in other cases, Versace ensures that appropriate guarantees
        for the transfer of data are in place based on contractual provisions
        between Versace and the recipient of personal data in such non-EU
        Countries. A copy of said documents is available at Versace’s registered
        offices. In any case, Versace undertakes to ensure adequate levels of
        data protection in accordance with applicable laws and regulations,
        including the provision of technical and organisational measures.
      </Typography>
      <Typography variant="bodyRegular" component="h2" sx={h2}>
        10. PRIVACY RIGHTS
      </Typography>
      <Typography variant="bodyRegular" component="p" sx={p}>
        You and the Guests may exercise the rights afforded under the Regulation
        (Articles 15-21), including:
      </Typography>
      <ul>
        <li>
          <Typography variant="bodyRegular">
            the right to receive confirmation as to the existence of personal
            data and to access the content thereof (Right of Access);
          </Typography>
        </li>
        <li>
          <Typography variant="bodyRegular">
            the right to update, modify, and/or rectify personal data (Right to
            Rectification);
          </Typography>
        </li>
        <li>
          <Typography variant="bodyRegular">
            the right to request erasure or restrict processing of data
            processed in violation of the law, including those data which
            storage is not required for the purposes for which they were
            collected or otherwise processed (Right to be Forgotten and Right to
            Restriction of Processing);
          </Typography>
        </li>
        <li>
          <Typography variant="bodyRegular">
            the right to object to processing (Right to Object);
          </Typography>
        </li>
        <li>
          <Typography variant="bodyRegular">
            the right to lodge a complaint with the supervisory Authority in the
            event of infringement the laws and regulations on personal data
            protection;
          </Typography>
        </li>
        <li>
          <Typography variant="bodyRegular">
            the right to receive a copy of the data concerning them in
            electronic format, and to ask that such data be transmitted to
            another controller (Right to Data Portability).
          </Typography>
        </li>
      </ul>
      <Typography variant="bodyRegular" component="p" sx={p}>
        Any requests concerning the exercise of the above rights must be sent to
        Versace via email to:{' '}
        <a href="mailto:DPO@versace.it">
          <Typography variant="bodyRegular">dpo@versace.it</Typography>
        </a>
        , or regular mail to: Società Gianni Versace S.r.l., Piazza Einaudi 4,
        20124 Milan (Italy).
      </Typography>
      <Typography variant="bodyRegular" component="h2" sx={h2}>
        11. CHANGES TO THE PRIVACY & COOKIE POLICY
      </Typography>
      <Typography variant="bodyRegular" component="p" sx={p}>
        The entry into force of any new sector regulations or any changes to the
        services may entail the need to amend the conditions in which personal
        data are processed. This Privacy & Cookie Policy may therefore be
        subject to changes over time, which changes shall be appropriately
        communicated to you. You shall be responsible for conveying the content
        of any such changes to the Guests.
      </Typography>
      <Box sx={dateUpdated}>
        <Typography
          variant="bodyRegularItalicSmall"
          component="p"
          sx={{...p, textAlign: 'right'}}>
          Effective date: January 22
          <sup>nd</sup>, 2024.
        </Typography>
      </Box>
    </Base>
  );
};

export default PrivacyPolicy;
