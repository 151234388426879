import {useMemo} from 'react';

import {SxProps} from '@mui/material';

import {theme} from '@/themes/default';

import {IPropsStyle} from './type';

export const useStyle = (): IPropsStyle => {
  const modalContainerBox: SxProps = useMemo(
    (): SxProps => ({
      width: 'calc(100vw - 100px)', // 110 = 20*2 padding + 30*2 boxshadow
      minHeight: '260px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',

      [theme.breakpoints.up('md')]: {
        width: '450px',
      },
    }),
    [],
  );

  const modalEventBox: SxProps = useMemo(
    (): SxProps => ({
      display: 'flex',
      flexDirection: 'column',
    }),
    [],
  );

  const modalNoteBox: SxProps = useMemo(
    (): SxProps => ({
      display: 'flex',
      flexDirection: 'column',
    }),
    [],
  );

  const modalNoteTitleBox: SxProps = useMemo(
    (): SxProps => ({
      display: 'flex',
      alignItems: 'center',
      svg: {marginRight: '8px'},
    }),
    [],
  );

  const modalButtonsBoxContainer: SxProps = useMemo(
    () => ({
      marginTop: '16px',
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
    }),
    [],
  );

  const modalButtonsBox: SxProps = useMemo(
    (): SxProps => ({
      display: 'flex',
      alignItems: 'row',
      justifyContent: 'center',
    }),
    [],
  );

  const modalButton: SxProps = useMemo(
    (): SxProps => ({
      width: '100%',
      maxWidth: '300px',
      height: '50px',
      fontSize: '13px',
    }),
    [],
  );

  const modalDeleteSlotButtonBox = useMemo(
    () => ({
      marginY: '16px',
      textAlign: 'center',
      [theme.breakpoints.up('sm')]: {
        position: 'absolute',
        right: '40px',
        margin: '0',
      },
    }),
    [],
  );

  const modalDeleteSlotButton = useMemo(
    () => ({
      width: '100%',
      maxWidth: '300px',
      [theme.breakpoints.up('sm')]: {
        width: '34px',
        padding: '0',
      },
    }),
    [],
  );

  return {
    modalContainerBox,
    modalEventBox,
    modalNoteBox,
    modalNoteTitleBox,
    modalButtonsBoxContainer,
    modalButtonsBox,
    modalButton,
    modalDeleteSlotButtonBox,
    modalDeleteSlotButton,
  };
};
