import {FunctionComponent, useCallback, useEffect} from 'react';
import {useLocation, useParams} from 'react-router-dom';

import {Box, Typography} from '@mui/material';
import {useTranslations} from '@vidiemme/react-i18n';

import {Button} from '@/atoms/Button';
import {useToast} from '@/hooks/toast';
import {useNavigation} from '@/navigation/useNavigation';
import {useDeleteSlot} from '@/store/slots';
import {Modal} from '@/templates/Modal';

import {useStyle} from './style';

const EventSlotDelete: FunctionComponent = () => {
  const {showSuccessToast, showErrorToast} = useToast(); // Get functions for displaying success and error toasts

  const {
    deleteSlotBoxContainer,
    deleteSlotTextBox,
    deleteSlotTitle,
    deleteSlotButtonsBox,
    deleteSlotButton,
  } = useStyle(); // Get style-related functions

  const {t} = useTranslations(); // Get the translation function
  const {goToPreviousPage, goToEventCalendar} = useNavigation(); // Get the function for navigating back
  const {id: eventId, slotId} = useParams();

  const {
    state: {bookedSpots},
  } = useLocation();

  const cancelButtonHandler = goToPreviousPage;

  const successButtonHandler = useCallback(() => {
    if (eventId) {
      goToEventCalendar(parseInt(eventId));
    }
  }, [eventId, goToEventCalendar]);

  const {
    loading: loadingDeleteSlot,
    error: errorDeleteSlot,
    response: responseDeleteSlot,
    run: runDeleteSlot,
  } = useDeleteSlot(parseInt(slotId!));

  const deleteButtonHandler = useCallback(() => {
    if (runDeleteSlot) {
      return runDeleteSlot();
    }
    return null;
  }, [runDeleteSlot]);

  useEffect(() => {
    if (errorDeleteSlot) {
      showErrorToast({message: t('EVENT_SLOT_DELETE.DELETE_RESPONSE_ERROR')});
    }

    if (responseDeleteSlot) {
      showSuccessToast({
        message: t('EVENT_SLOT_DELETE.DELETE_RESPONSE_SUCCESS'),
      });
      successButtonHandler();
    }
  }, [
    cancelButtonHandler,
    errorDeleteSlot,
    goToPreviousPage,
    responseDeleteSlot,
    showErrorToast,
    showSuccessToast,
    successButtonHandler,
    t,
  ]);

  return (
    <Modal>
      <Box sx={deleteSlotBoxContainer}>
        <Box>
          <Typography variant={'titleAktive1'} sx={deleteSlotTitle}>
            {t('EVENT_SLOT_DELETE.MODAL_TITLE')}
          </Typography>
          {!!bookedSpots && (
            <Box sx={deleteSlotTextBox}>
              <Box>
                <Typography variant={'bodyRegular'}>
                  {t('EVENT_SLOT_DELETE.MODAL_TEXT', {bookedSpots})}
                </Typography>
              </Box>
              <Box>
                <Typography variant={'bodyBold'}>
                  {t('EVENT_SLOT_DELETE.MODAL_WARNING')}
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
        <Box sx={deleteSlotButtonsBox}>
          <Button
            variant={'secondaryBig'}
            color={'default'}
            sx={deleteSlotButton}
            onClick={cancelButtonHandler}>
            {t('EVENT_SLOT_DELETE.BUTTON_CANCEL')}
          </Button>
          <Button
            variant={'primaryBig'}
            color={'default'}
            sx={deleteSlotButton}
            onClick={deleteButtonHandler}
            disabled={loadingDeleteSlot}
            loading={loadingDeleteSlot}>
            {t('EVENT_SLOT_DELETE.BUTTON_DELETE')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default EventSlotDelete;
