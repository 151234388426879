import {useMemo} from 'react';

import {SxProps} from '@mui/material';

import {theme} from '@/themes/default';

import {IUseStyle} from './type';

export const useStyle = (): IUseStyle => {
  const boxContainer: SxProps = useMemo(
    (): SxProps => ({
      display: 'flex',
      flexDirection: 'column',
      rowGap: '10px',
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        columnGap: '20px',
        alignItems: 'center',
      },
    }),
    [],
  );

  const boxContent: SxProps = useMemo(
    (): SxProps => ({
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      rowGap: '10px',
      [theme.breakpoints.up('md')]: {
        width: '75%',
      },
    }),
    [],
  );

  const day: SxProps = useMemo(
    (): SxProps => ({
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '25%',
        alignSelf: 'flex-start',
      },
    }),
    [],
  );

  const boxWrapperSlotClosed: SxProps = useMemo(
    (): SxProps => ({
      display: 'flex',
      alignItems: 'center',
      columnGap: '10px',
    }),
    [],
  );

  const slotClosed: SxProps = useMemo(
    (): SxProps => ({
      backgroundColor: theme.palette.common.white,
      color: theme.palette.gray4,
      paddingY: '10px',
      width: '100%',
      textAlign: 'center',
      [theme.breakpoints.up('md')]: {
        width: 'calc(50% + 20px)',
      },
    }),
    [],
  );

  const timePicker: SxProps = useMemo(
    (): SxProps => ({
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '33.3333333333%',
      },
    }),
    [],
  );

  const wrapperButtons: SxProps = useMemo(
    (): SxProps => ({
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      columnGap: '10px',
      [theme.breakpoints.up('md')]: {
        width: '33.3333333333%',
        justifyContent: 'flex-end',
      },
    }),
    [],
  );

  const boxWrapperSlotTimes: SxProps = useMemo(
    (): SxProps => ({
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      rowGap: '10px',
      [theme.breakpoints.up('md')]: {
        rowGap: '0',
        columnGap: '10px',
        flexDirection: 'row',
      },
    }),
    [],
  );

  const addSlot: SxProps = useMemo(
    (): SxProps => ({
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      width: '34px',
      minWidth: '34px',
      height: '34px',
      svg: {
        fill: theme.palette.common.white,
      },
      '&:hover': {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        svg: {
          fill: theme.palette.common.black,
        },
      },
      '&:disabled': {
        backgroundColor: theme.palette.gray4,
      },
    }),
    [],
  );

  const deleteSlot: SxProps = useMemo(
    (): SxProps => ({
      padding: 0,
      width: 'calc(100% - 44px)',
    }),
    [],
  );

  const deleteSlotFullsize: SxProps = useMemo(
    (): SxProps => ({
      padding: 0,
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: 'calc(100% - 44px)',
      },
    }),
    [],
  );

  return {
    boxContainer,
    boxContent,
    boxWrapperSlotClosed,
    slotClosed,
    addSlot,
    deleteSlot,
    deleteSlotFullsize,
    day,
    wrapperButtons,
    timePicker,
    boxWrapperSlotTimes,
  };
};
