import {useCallback, useEffect, useMemo, useState} from 'react';

import type {LabelDisplayedRowsArgs} from '@mui/material';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import {useTranslations} from '@vidiemme/react-i18n';

import {useToast} from '@/hooks/toast';
import {LoadingMessage} from '@/organisms/LoadingMessage';
import {BrandFilter} from '@/pages/Users/BrandFilter';
import {RoleCells} from '@/pages/Users/RoleCells';
import {TableFilter} from '@/pages/Users/TableFilter';
import {useStyle} from '@/pages/Users/style';
import {REALMS} from '@/store/keycloak';
import {CompleteRolesData, ROLES, useGetRoles} from '@/store/roles';
import {useGetTableColumns, useGetUsers} from '@/store/userManagement';
import {Base} from '@/templates/Base';

const FIRST_PAGE = 0;
const INITIAL_PER_PAGE = 50;

const Users = () => {
  const {searchContainerBox, tableContainer, table} = useStyle();
  const {t} = useTranslations();
  const {showErrorToast} = useToast();

  const [realm, setRealm] = useState<REALMS>(REALMS.VERSACE);
  const [currentPage, setCurrentPage] = useState<number>(FIRST_PAGE);
  const [fullText, setFullText] = useState<string>('');
  const [perPage, setPerPage] = useState<number>(INITIAL_PER_PAGE);

  const [totalCount, setTotalCount] = useState<number>(0);

  const {loading: columnsLoading, response: columns} = useGetTableColumns();

  const {loading: rolesLoading, response: responseRoles} = useGetRoles();

  const {
    loading: usersLoading,
    response: usersPage,
    error,
    run,
  } = useGetUsers(realm, currentPage, fullText, perPage);

  const roles: CompleteRolesData | null = useMemo(() => {
    if (responseRoles === null) {
      return null;
    }

    return responseRoles.filter(({name}) => {
      switch (name) {
        case ROLES.ADMIN:
        case ROLES.OPERATOR:
        case ROLES.EMPLOYEE:
          return true;
        default:
          return false;
      }
    });
  }, [responseRoles]);

  const handleChangeRealm = setRealm;
  const handleChangeFullText = setFullText;

  const handleChangePerPage = useCallback(
    ({target: {value}}: {target: {value: string}}) => {
      setPerPage(parseInt(value));
    },
    [],
  );

  const renderDisplayedRows = useCallback(
    ({from, to, count}: LabelDisplayedRowsArgs): string =>
      t('USER_MANAGEMENT.DIPLAYED_ROWS', {
        from,
        to,
        count,
      }),
    [t],
  );

  const handlePageChange = useCallback((_e: any, page: number) => {
    setCurrentPage(Math.max(FIRST_PAGE, page));
  }, []);

  useEffect(() => {
    if (usersPage?.totalCount) {
      setTotalCount(usersPage.totalCount);
    }
  }, [perPage, usersPage]);

  useEffect(() => {
    setCurrentPage(0);
  }, [realm, fullText, perPage]);

  useEffect(() => {
    if (run) {
      run();
    }
  }, [run, currentPage, realm, fullText, perPage]);

  useEffect(() => {
    if (error) {
      showErrorToast({message: t('RESPONSE_ERROR_MESSAGE.MESSAGE')});
    }
  }, [error, showErrorToast, t]);

  if (
    columnsLoading ||
    columns === null ||
    rolesLoading ||
    roles === null ||
    (usersLoading && usersPage === null)
  ) {
    return <LoadingMessage />;
  }

  return (
    <Base title={t('USER_MANAGEMENT.PAGE_TITLE')}>
      <Box sx={searchContainerBox}>
        <BrandFilter onChange={handleChangeRealm} />
        <TableFilter onChange={handleChangeFullText} />
      </Box>
      <Box sx={tableContainer}>
        <Table sx={table}>
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell key={column}>
                  {t(`USER_MANAGEMENT.${column}`)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {usersPage?.content.map(({contactName, email, role}) => (
              <TableRow key={email}>
                <TableCell>{contactName}</TableCell>
                <TableCell>{email}</TableCell>
                <TableCell>
                  <RoleCells
                    roles={roles}
                    realm={realm}
                    email={email}
                    role={role}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                labelRowsPerPage={t('USER_MANAGEMENT.ROW_PER_PAGE')}
                rowsPerPage={perPage}
                onRowsPerPageChange={handleChangePerPage}
                count={totalCount}
                page={currentPage}
                labelDisplayedRows={renderDisplayedRows}
                onPageChange={handlePageChange}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </Box>
    </Base>
  );
};

export default Users;
