import {useMemo} from 'react';

import {SxProps} from '@mui/material';

import {IPropsStyle} from '@/organisms/PageNotFound/type';

export const useStyle = (): IPropsStyle => {
  const notFoundBox: SxProps = useMemo(
    (): SxProps => ({
      height: '100%',
      width: '100%',
      top: 0,
      left: 0,
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
    }),
    [],
  );

  const notFoundMessage: SxProps = useMemo(
    (): SxProps => ({
      marginTop: '40px',
    }),
    [],
  );
  return {
    notFoundBox,
    notFoundMessage,
  };
};
