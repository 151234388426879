import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';

import {colors, Typography} from '@mui/material';
import {useTranslations} from '@vidiemme/react-i18n';
import moment from 'moment/moment';

import useDate from '@/hooks/date';
import {useToast} from '@/hooks/toast';
import {AccordionComponent} from '@/molecules/AccordionComponent';
import {useNavigation} from '@/navigation/useNavigation';
import {EventExtraordinarySchedule} from '@/organisms/EventExtraordinarySchedule';
import {EventGeneralInfo} from '@/organisms/EventGeneralInfo';
import {ReservationOpenings} from '@/organisms/EventReservationOpenings';
import {EventSlotSize} from '@/organisms/EventSlotSize';
import {EventWeeklySchedule} from '@/organisms/EventWeeklySchedule';
import {EventWeekDaySchedule} from '@/organisms/EventWeeklySchedule/type';
import {LoadingMessage} from '@/organisms/LoadingMessage';
import {EventTemplateProps} from '@/pages/EventCreate/type';
import {Category} from '@/store/categories';
import {useGetEvent, useUpdateEvent} from '@/store/events/hooks';
import {SideModal} from '@/templates/SideModal';

const EventEdit = () => {
  const {goToEvents} = useNavigation();
  const {t} = useTranslations();
  const {showErrorToast, showSuccessToast} = useToast();
  const {
    dateToDateFormat,
    dateToHoursAndFormat,
    hoursToDate,
    dateStringToMoment,
    formatTime,
  } = useDate();

  const [eventTemplate, setEventTemplate] = useState<EventTemplateProps>({});

  // Get event id from URL parameters
  const {id} = useParams();
  const eventId = parseInt(id!);

  // Fetch event data
  const {
    loading: loadingEventDetail,
    error: errorEventDetail,
    response: responseEventDetail,
  } = useGetEvent(eventId);

  /*
   * After get event detail, set event template model with all information (it's executed only one time when responseEventDetail is triggered)
   * NOTE: the opening hours are in the timezone of the event!
   * */
  useEffect(() => {
    if (responseEventDetail) {
      const weeklySchedules = [
        ...responseEventDetail.opening_hours.ordinary.map(item => ({
          ...item,
          intervals: item.intervals.map(itemInterval => ({
            start_time: hoursToDate(itemInterval.start_time),
            end_time: hoursToDate(itemInterval.end_time),
          })),
        })),
      ].concat(
        responseEventDetail.calendar.closures.week_day_numbers.map(item => ({
          week_day_number: item,
          intervals: [],
        })),
      );

      const extraordinarySchedules = [
        ...responseEventDetail.opening_hours.extraordinary,
      ].concat(
        responseEventDetail.calendar.closures.dates.map(item => ({
          date: item,
          intervals: [],
        })),
      );

      setEventTemplate({
        generalInfo: {
          eventName: responseEventDetail.name,
          startDate: responseEventDetail.calendar.start_date,
          endDate: responseEventDetail.calendar.end_date,
          slotDuration: responseEventDetail.slot_duration,
          guestPerPerson: responseEventDetail.guests_limit,
          allowMultipleBookings: responseEventDetail.allow_multiple_bookings,
          minBookingTime: responseEventDetail.min_booking_time,
        },
        weeklySchedules: weeklySchedules.sort(
          (a, b) => a.week_day_number - b.week_day_number,
        ),
        slotLimits: responseEventDetail.slot_limits
          ? [...responseEventDetail.slot_limits]
          : [],
        reservationOpenings: responseEventDetail.reservation_openings,
        extraordinarySchedules: extraordinarySchedules.sort((a, b): number => {
          return dateStringToMoment(a.date).isAfter(dateStringToMoment(b.date))
            ? 1
            : -1;
        }),
      });
    }
  }, [dateStringToMoment, hoursToDate, responseEventDetail]);

  const locationSelected = responseEventDetail?.location ?? null;

  const categorySelected = responseEventDetail?.category ?? null;

  /* Get date closures list from extraordinary schedule with empty intervals */
  const getDatesClosures = useMemo(() => {
    if (eventTemplate.extraordinarySchedules) {
      return eventTemplate.extraordinarySchedules
        .filter(
          extraordinarySchedule =>
            extraordinarySchedule.date &&
            extraordinarySchedule.intervals.length === 0,
        )
        .map(item => dateToDateFormat(item.date!));
    }
    return [];
  }, [dateToDateFormat, eventTemplate.extraordinarySchedules]);

  /* Get weekly closures list from weekly schedule with empty intervals */
  const getWeeklyClosures = useMemo(() => {
    if (eventTemplate.weeklySchedules) {
      return eventTemplate.weeklySchedules
        .filter(weeklySchedule => weeklySchedule.intervals.length === 0)
        .map(item => item.week_day_number);
    }
    return [];
  }, [eventTemplate.weeklySchedules]);

  /* Get opening hours type ordinary with start/end time intervals */
  const getOrdinaryOpeningHours = useMemo(() => {
    if (eventTemplate.weeklySchedules) {
      return eventTemplate.weeklySchedules?.reduce(
        (accumulator: any, weeklySchedule: EventWeekDaySchedule) => {
          let weeklyScheduledFiltered = {
            ...weeklySchedule,
            intervals: weeklySchedule.intervals.filter(
              itemIntervals =>
                itemIntervals.start_time && itemIntervals.end_time,
            ),
          };

          if (weeklyScheduledFiltered.intervals.length > 0) {
            weeklyScheduledFiltered.intervals =
              weeklyScheduledFiltered.intervals.map(item => ({
                start_time: formatTime(item.start_time!),
                end_time: formatTime(item.end_time!),
              }));
            return [...accumulator, weeklyScheduledFiltered];
          }
          return accumulator;
        },
        [],
      );
    }
    return [];
  }, [dateToHoursAndFormat, eventTemplate.weeklySchedules]);

  /* Get opening hours type extraordinary with start/end time intervals */
  const getExtraordinaryOpeningHours = useMemo(() => {
    if (eventTemplate.extraordinarySchedules) {
      return eventTemplate.extraordinarySchedules.reduce(
        (accumulator: any, dateSchedule) => {
          let dateScheduledFiltered = {
            ...dateSchedule,
            date: dateToDateFormat(dateSchedule.date!),
            intervals: dateSchedule.intervals.filter(
              itemIntervals =>
                itemIntervals.start_time && itemIntervals.end_time,
            ),
          };
          if (dateScheduledFiltered.intervals.length > 0) {
            dateScheduledFiltered.intervals =
              dateScheduledFiltered.intervals.map(item => {
                //todo: orario viene convertito
                return {
                  start_time: formatTime(item.start_time!),
                  end_time: formatTime(item.end_time!),
                };
              });

            return [...accumulator, dateScheduledFiltered];
          }

          return accumulator;
        },
        [],
      );
    }
    return [];
  }, [
    dateToDateFormat,
    dateToHoursAndFormat,
    eventTemplate.extraordinarySchedules,
  ]);

  /* Get slot limits for each start/end date occurences */
  const getSlotLimits = useMemo(() => {
    if (eventTemplate.slotLimits) {
      return eventTemplate.slotLimits
        .filter(slot => slot.start_date && slot.end_date && slot.limit > 0)
        .map(slotFiltered => ({
          ...slotFiltered,
          start_date: dateToDateFormat(slotFiltered.start_date!),
          end_date: dateToDateFormat(slotFiltered.end_date!),
        }));
    }
    return [];
  }, [dateToDateFormat, eventTemplate.slotLimits]);

  /* Get reservation opening dates for each brand type */
  const getReservationOpenings = useMemo(() => {
    if (eventTemplate.reservationOpenings) {
      const {versace, capri, guests} = eventTemplate.reservationOpenings;
      return {
        capri: {
          start_date: capri?.start_date
            ? dateToDateFormat(capri.start_date)
            : '',
          end_date: capri?.end_date ? dateToDateFormat(capri.end_date) : '',
        },
        versace: {
          start_date: versace?.start_date
            ? dateToDateFormat(versace.start_date)
            : '',
          end_date: versace?.end_date ? dateToDateFormat(versace.end_date) : '',
        },
        guests: {
          start_date: guests?.start_date
            ? dateToDateFormat(guests.start_date)
            : '',
          end_date: guests?.end_date ? dateToDateFormat(guests.end_date) : '',
        },
      };
    }
    return undefined;
  }, [dateToDateFormat, eventTemplate.reservationOpenings]);

  /** Join all fields of event template for each accordion section
   * ('general-info'|'weekly-schedule'|'slot-size'|'reservation-openings'|'extraordinary-schedule') */
  const changeInfoHandler = useCallback(
    (fields: any) => {
      setEventTemplate({
        ...eventTemplate,
        ...fields,
      });
    },
    [eventTemplate],
  );

  /* Build event object template for server API */
  const eventRequest: any = useMemo(() => {
    return {
      id: responseEventDetail?.id,
      location_id: locationSelected?.id,
      category_id: categorySelected?.id,
      name: eventTemplate.generalInfo?.eventName || '',
      slot_duration: eventTemplate.generalInfo?.slotDuration,
      allow_multiple_bookings: eventTemplate.generalInfo?.allowMultipleBookings,
      guest_per_person: eventTemplate.generalInfo?.guestPerPerson,
      min_booking_time: eventTemplate.generalInfo?.minBookingTime,
      calendar: {
        start_date: eventTemplate.generalInfo?.startDate
          ? dateToDateFormat(eventTemplate.generalInfo.startDate)
          : '',
        end_date: eventTemplate.generalInfo?.endDate
          ? dateToDateFormat(eventTemplate.generalInfo.endDate)
          : '',
        closures: {
          dates: getDatesClosures,
          week_day_numbers: getWeeklyClosures,
        },
      },
      opening_hours: {
        ordinary: getOrdinaryOpeningHours,
        extraordinary: getExtraordinaryOpeningHours,
      },
      slot_limits: getSlotLimits,
      reservation_openings: getReservationOpenings,
    };
  }, [
    categorySelected?.id,
    dateToDateFormat,
    eventTemplate.generalInfo?.allowMultipleBookings,
    eventTemplate.generalInfo?.endDate,
    eventTemplate.generalInfo?.eventName,
    eventTemplate.generalInfo?.guestPerPerson,
    eventTemplate.generalInfo?.minBookingTime,
    eventTemplate.generalInfo?.slotDuration,
    eventTemplate.generalInfo?.startDate,
    getDatesClosures,
    getExtraordinaryOpeningHours,
    getOrdinaryOpeningHours,
    getReservationOpenings,
    getSlotLimits,
    getWeeklyClosures,
    locationSelected?.id,
    responseEventDetail?.id,
  ]);

  /* Call PUT update event api */
  const {
    loading: loadingUpdateEvent,
    error: errorUpdateEvent,
    response: responseUpdateEvent,
    run: runUpdateEventRequest,
  } = useUpdateEvent(eventRequest);

  /* Show toast message if error occured with get event or when editing, if event created go back to events list */
  useEffect(() => {
    if (errorEventDetail) {
      showErrorToast({message: t('EVENT_CREATE_EDIT.EDIT_RESPONSE_ERROR')});
    } else if (errorUpdateEvent) {
      showErrorToast({
        message:
          errorUpdateEvent.userMessage ??
          t('EVENT_CREATE_EDIT.EDIT_RESPONSE_ERROR'),
      });
    } else if (responseUpdateEvent) {
      showSuccessToast({message: t('EVENT_CREATE_EDIT.EDIT_RESPONSE_SUCCESS')});
      goToEvents();
    }
  }, [
    errorEventDetail,
    errorUpdateEvent,
    goToEvents,
    responseUpdateEvent,
    showErrorToast,
    showSuccessToast,
    t,
  ]);

  /* Enable submit button only if all fields required is filled */
  const isEnableSubmit: boolean = useMemo(() => {
    if (!categorySelected) {
      return false;
    }
    /* Required fields for each category events */
    if (
      !(
        eventRequest &&
        eventRequest.name.length > 0 &&
        eventRequest.calendar.start_date &&
        eventRequest.calendar.end_date &&
        eventRequest.slot_duration &&
        eventRequest.opening_hours.ordinary.length > 0
      )
    ) {
      return false;
    }

    /* If category is not clothing slot limits are required */
    if (
      categorySelected.name !== Category.CLOTHING &&
      eventRequest.slot_limits.length === 0
    ) {
      return false;
    }

    /* If category is special sale reservation opernings dates are required */
    if (categorySelected.name === Category.SPECIAL_SALE) {
      if (
        !(
          eventRequest.reservation_openings?.versace.start_date &&
          eventRequest.reservation_openings?.versace.end_date &&
          /**
           * TODO: if capri brand will add into this platform, restore this piece of code
           * eventRequest.reservation_openings?.capri.start_date &&
           * eventRequest.reservation_openings?.capri.end_date &&
           * */
          eventRequest.reservation_openings?.guests.start_date &&
          eventRequest.reservation_openings?.guests.end_date
        )
      ) {
        return false;
      }
    }
    return true;
  }, [categorySelected, eventRequest]);

  /* Create accordions section content ('general-info'|'weekly-schedule'|'slot-size'|'reservation-openings'|'extraordinary-schedule') */
  const accordionListItems = useMemo(
    () => [
      {
        id: 'general-info',
        title: t('EVENT_CREATE_EDIT.GENERAL_INFO.TITLE'),
        content: (
          <EventGeneralInfo
            isLoading={loadingEventDetail || loadingUpdateEvent}
            eventCategory={categorySelected?.name}
            changeInfoHandler={changeInfoHandler}
            fields={eventTemplate.generalInfo ?? undefined}
            timezone={locationSelected?.timezone}
          />
        ),
      },
      {
        id: 'weekly-schedule',
        title: t('EVENT_CREATE_EDIT.WEEKLY_SCHEDULE.TITLE'),
        subtitle: t('EVENT_CREATE_EDIT.WEEKLY_SCHEDULE.EDIT_SUBTITLE'),
        content: (
          <EventWeeklySchedule
            isLoading={loadingEventDetail || loadingUpdateEvent}
            changeInfoHandler={changeInfoHandler}
            days={eventTemplate.weeklySchedules ?? undefined}
            disabled={true}
            timezone={locationSelected?.timezone}
            applyTimezone={false} // The opening hours are already in the timezone of the event
          />
        ),
      },
      {
        id: 'slot-size',
        title: t('EVENT_CREATE_EDIT.SLOT_SIZE.TITLE'),
        subtitle: t('EVENT_CREATE_EDIT.SLOT_SIZE.SUB_TITLE'),
        hidden: categorySelected?.name === Category.CLOTHING,
        content: (
          <EventSlotSize
            isLoading={loadingEventDetail || loadingUpdateEvent}
            changeInfoHandler={changeInfoHandler}
            eventStartDate={eventTemplate.generalInfo?.startDate}
            eventEndDate={eventTemplate.generalInfo?.endDate}
            slotsLimit={eventTemplate.slotLimits ?? undefined}
            timezone={locationSelected?.timezone}
          />
        ),
      },
      {
        id: 'reservation-openings',
        title: t('EVENT_CREATE_EDIT.RESERVATION_OPENINGS.TITLE'),
        hidden: categorySelected?.name !== Category.SPECIAL_SALE,
        content: (
          <ReservationOpenings
            isLoading={loadingEventDetail || loadingUpdateEvent}
            changeInfoHandler={changeInfoHandler}
            eventStartDate={eventTemplate.generalInfo?.startDate}
            eventEndDate={eventTemplate.generalInfo?.endDate}
            versace={eventTemplate.reservationOpenings?.versace ?? undefined}
            capri={eventTemplate.reservationOpenings?.capri ?? undefined}
            guests={eventTemplate.reservationOpenings?.guests ?? undefined}
            timezone={locationSelected?.timezone}
          />
        ),
      },
      {
        id: 'extraordinary-schedule',
        title: t('EVENT_CREATE_EDIT.EXTRAORDINARY_SCHEDULE.TITLE'),
        subtitle: t('EVENT_CREATE_EDIT.EXTRAORDINARY_SCHEDULE.EDIT_SUBTITLE'),
        content: (
          <EventExtraordinarySchedule
            isLoading={loadingEventDetail || loadingUpdateEvent}
            changeInfoHandler={changeInfoHandler}
            eventStartDate={eventTemplate.generalInfo?.startDate}
            eventEndDate={eventTemplate.generalInfo?.endDate}
            schedules={eventTemplate.extraordinarySchedules ?? undefined}
            timezone={locationSelected?.timezone}
            applyTimezone={false} // The extraordinary schedules are already in the timezone of the event
          />
        ),
      },
    ],
    [
      categorySelected?.name,
      changeInfoHandler,
      eventTemplate.extraordinarySchedules,
      eventTemplate.generalInfo,
      eventTemplate.reservationOpenings?.capri,
      eventTemplate.reservationOpenings?.guests,
      eventTemplate.reservationOpenings?.versace,
      eventTemplate.slotLimits,
      eventTemplate.weeklySchedules,
      loadingEventDetail,
      loadingUpdateEvent,
      locationSelected?.timezone,
      t,
    ],
  );

  return (
    <SideModal
      title={t('EVENT_CREATE_EDIT.EDIT_TITLE', {
        location: locationSelected?.name,
        category: categorySelected?.name,
      })}
      subTitle={
        <>
          {t('EVENT_CREATE_EDIT.EDIT_SUB_TITLE')}
          &nbsp;&nbsp;|&nbsp;&nbsp;
          <Typography variant={'bodyBold'}>
            {t('EVENT_CREATE_EDIT.EDIT_SUB_TITLE_REQUIRED_FIELDS')}
          </Typography>
        </>
      }
      closeHandler={goToEvents}
      textButtonOne={t('EVENT_CREATE_EDIT.TEXT_BUTTON_ONE')}
      textButtonTwo={t('EVENT_CREATE_EDIT.EDIT_TEXT_BUTTON_TWO')}
      CTAButtonOne={goToEvents}
      CTAButtonTwo={runUpdateEventRequest}
      buttonOneDisabled={false}
      buttonTwoDisabled={!isEnableSubmit}
      buttonTwoLoading={loadingEventDetail || loadingUpdateEvent}>
      {responseEventDetail && Object.keys(eventTemplate).length > 0 ? (
        <AccordionComponent
          expandedDefaultItem={'general-info'}
          items={accordionListItems}
        />
      ) : (
        <LoadingMessage />
      )}
    </SideModal>
  );
};
export default EventEdit;
