import {useMemo} from 'react';

import {SxProps} from '@mui/material';

import {theme} from '@/themes/default';

export const useStyle = (): {[k: string]: SxProps} => {
  const currentBreadcrumb = useMemo(
    () => ({
      opacity: 1,
    }),
    [],
  );

  const separator = useMemo(
    () => ({
      '.MuiBreadcrumbs-separator': {
        fontSize: '14px',
        color: theme.palette.gray3,
      },
    }),
    [],
  );

  return {
    currentBreadcrumb,
    separator,
  };
};
