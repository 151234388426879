import {useCallback, useEffect, useMemo} from 'react';

import {useApi} from '@/store/api';

import type {
  ServerBooking,
  UseDeleteBooking,
  UseGetBookingDetail,
  UseGetBookings,
  UseSetBooking,
  PutBookingOptions,
  ServerPutBookingOptions,
  UseDownloadBookedPeople,
  UsePutBooking,
  Booking,
  ServerPaginationMeta,
} from './definitions';
import {normalizeBooking} from './normalizers';

export const useGetBookings: UseGetBookings = (
  {slotId, search, isPresent, page, perPage},
  autoRun = true,
) => {
  const options = useMemo(() => {
    return {
      autoRun,
      query: {
        slot_id: slotId,
        full_text: search,
        is_present: isPresent,
        per_page: perPage,
        page,
      },
    };
  }, [slotId, search, isPresent, autoRun, perPage, page]);

  const {loading, error, response, run} = useApi<{
    data: ServerBooking[];
    meta: ServerPaginationMeta;
  }>('/api/v1/bookings', options);

  return {
    loading,
    error,
    response:
      response && response.data
        ? response.data.map(booking => normalizeBooking(booking) as Booking)
        : null,
    meta: response?.meta,
    run,
  };
};

export const useSetBooking: UseSetBooking = ({
  eventId,
  slotId,
  note,
  presenceType,
  phoneNumber,
  onBehalfOf,
  guests,
}) => {
  const options = useMemo(() => {
    return {
      autoRun: false,
      req: {
        method: 'POST',
      },
      body: {
        event_id: eventId,
        slot_id: slotId,
        note,
        presence_type: presenceType,
        phone_number: phoneNumber,
        ...(onBehalfOf
          ? {
              on_behalf_of: {
                first_name: onBehalfOf.firstName,
                last_name: onBehalfOf.lastName,
                email: onBehalfOf.email,
                phone_number: onBehalfOf.phoneNumber,
              },
            }
          : {}),
        ...(guests
          ? {
              guests: guests.map(guest => ({
                first_name: guest.firstName,
                last_name: guest.lastName,
              })),
            }
          : {}),
      },
    };
  }, [eventId, slotId, note, presenceType, phoneNumber, onBehalfOf, guests]);

  const {loading, error, response, run} = useApi<ServerBooking>(
    '/api/v1/bookings',
    options,
  );

  return {
    loading,
    error,
    response: normalizeBooking(response),
    run,
  };
};

export const useDeleteBooking: UseDeleteBooking = (id: number) => {
  const {loading, error, response, run} = useApi<any>(
    `/api/v1/bookings/${id}`,
    {
      autoRun: false,
      req: {
        method: 'DELETE',
      },
    },
  );

  return {loading, error, response, run};
};

export const useGetBookingDetail: UseGetBookingDetail = (bookingId: number) => {
  const {loading, error, response} = useApi<ServerBooking>(
    `/api/v1/bookings/${bookingId}`,
  );
  return {
    loading,
    error,
    response: normalizeBooking(response),
  };
};

export const usePutBooking: UsePutBooking = (
  id: number,
  bodyOptions: PutBookingOptions,
) => {
  const options: any = useMemo(() => {
    return {
      autoRun: false,
      req: {
        method: 'PUT',
      },
      body: {
        event_id: bodyOptions.event_id,
        slot_id: bodyOptions.slot_id,
        note: bodyOptions.note,
        is_present: bodyOptions.is_present,
        phone_number: bodyOptions.phone_number,
        guests: bodyOptions.guests
          ? bodyOptions.guests.map(guest => ({
              first_name: guest.firstName,
              last_name: guest.lastName,
            }))
          : null,
      },
    };
  }, [
    bodyOptions.event_id,
    bodyOptions.slot_id,
    bodyOptions.note,
    bodyOptions.is_present,
    bodyOptions.phone_number,
    bodyOptions.guests,
  ]);

  const {loading, error, response, run} = useApi<ServerPutBookingOptions>(
    `/api/v1/bookings/${id}`,
    options,
  );

  return {loading, error, response, run};
};

export const useDownloadBookedPeople: UseDownloadBookedPeople = ({
  eventDayId,
  slotId,
  eventId,
  startDate,
  endDate,
}) => {
  const responseParser = useCallback(async (response: Response) => {
    return await response.blob();
  }, []);

  const options = useMemo(
    () => ({
      autoRun: false,
      query: {
        ...(eventDayId ? {event_day_id: eventDayId} : {}),
        ...(slotId ? {slot_id: slotId} : {}),
        ...(startDate ? {from_date: startDate} : {}),
        ...(endDate ? {to_date: endDate} : {}),
        ...(eventId ? {event_id: eventId} : {}),
      },
      responseParser,
    }),
    [eventDayId, slotId, startDate, endDate, eventId, responseParser],
  );

  const {response, ...rest} = useApi(`/api/v1/bookings/download`, options);

  useEffect(() => {
    if (response) {
      const fileURL = window.URL.createObjectURL(response as Blob);

      const newWindow = window.open(fileURL, '_blank');

      if (newWindow) {
        newWindow.focus();
      }
    }
  }, [response]);

  return {
    ...rest,
    response: null,
  };
};
