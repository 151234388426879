import {useCallback} from 'react';
import {Slide, toast} from 'react-toastify';

import {Typography} from '@mui/material';

import {IconSVG} from '@/atoms/IconSVG';

import {IProps} from './interfaces';

const commonProps = {
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  transition: Slide,
};

export const useToast = () => {
  /**
   * @brief Show error
   */
  const showErrorToast = useCallback(({message}: IProps) => {
    toast.error(
      <span className="toastify__icon-container">
        <>
          <IconSVG
            icon="warning-circle"
            size={17}
            className="toastify__icon"
            color="#000000"
          />
          <Typography variant="bodyBold" color="black">
            {message}
          </Typography>
        </>
      </span>,
      {
        position: 'top-right',
        ...commonProps,
      },
    );
  }, []);

  /**
   * @brief Show message
   */
  const showSuccessToast = useCallback(({message}: IProps) => {
    toast.success(
      <span className="toastify__icon-container">
        <>
          <IconSVG icon="check-circle" size={20} className="toastify__icon" />
          <Typography variant="bodyBold" color="black">
            {message}
          </Typography>
        </>
      </span>,
      {
        position: 'top-right',
        ...commonProps,
      },
    );
  }, []);

  return {
    showErrorToast,
    showSuccessToast,
  };
};
