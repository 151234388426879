import {useMemo} from 'react';

import {Box, Typography} from '@mui/material';
import {useTranslations} from '@vidiemme/react-i18n';

import {useStyle} from '@/pages/InvitationGuest/InvitedGuestComponent/style';
import {IProps} from '@/pages/InvitationGuest/InvitedGuestComponent/type';

const InvitedGuestComponent = ({guest, index}: IProps) => {
  // Styles
  const {inviteGuestComponentContentBox, inviteGuestComponentEmail} =
    useStyle();

  // Translation
  const {t} = useTranslations();

  // Guest Name Number
  const guestNameNumber = useMemo(
    () => `${t('INVITE_GUEST.GUEST')} ${index + 1}`,
    [index, t],
  );

  return (
    <Box sx={inviteGuestComponentContentBox}>
      <Typography variant={'titleVersace2'}>{guestNameNumber}</Typography>

      <Box sx={inviteGuestComponentEmail}>
        <Typography variant={'bodyRegular'}>
          {guest.first_name} {guest.last_name}
        </Typography>
      </Box>

      <Box sx={inviteGuestComponentEmail}>
        <Typography variant={'bodyRegular'}>{guest.email}</Typography>
      </Box>
    </Box>
  );
};

export default InvitedGuestComponent;
