import {useCallback} from 'react';

import type {EventInput} from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import momentPlugin from '@fullcalendar/moment';
import timeGridPlugin from '@fullcalendar/timegrid';
import moment from 'moment-timezone';

import {useUserContext} from '@/contexts/user';
import useDate from '@/hooks/date';
import useEvent from '@/hooks/event';
import DayHeaderContent from '@/organisms/Calendar/DayHeaderContent/DayHeaderContent';
import EventContent from '@/organisms/Calendar/EventContent/EventContent';
import {Booking} from '@/store/bookings';
import type {Slots} from '@/store/slots';

export const useCalendar = (timezone = moment.tz.guess()) => {
  const {usingTimezone} = useDate();
  const {canNotEdit} = useEvent();
  const {lang} = useUserContext();

  /* Get min ora max date time from slots list */
  const getSlotTimeLimit = useCallback(
    (events: EventInput, type: 'min' | 'max') => {
      const date = moment();
      const list = events.map((el: EventInput) => {
        const dateTZ = usingTimezone(
          type === 'min'
            ? el.extendedProps!.slot.startTime
            : el.extendedProps!.slot.endTime,
          timezone,
        );
        return date.clone().set({
          hours: dateTZ.hours(),
          minutes: dateTZ.minutes(),
          seconds: dateTZ.seconds(),
        });
      });
      if (type === 'min') {
        return moment.min(list).format('HH:mm:ss');
      }
      // remove 1 second from max date to handle 00:00 case
      return moment.max(list).subtract(1, 'seconds').format('HH:mm:ss');
    },
    [timezone, usingTimezone],
  );

  const getConfiguration = useCallback(
    (hasDownloadButton: boolean, events: EventInput, isTablet: boolean) => ({
      allDaySlot: false,
      weekends: true,
      slotMinTime: getSlotTimeLimit(events, 'min') ?? '00:00:00',
      slotMaxTime: getSlotTimeLimit(events, 'max') ?? '24:00:00',
      slotDuration: '00:30:00',
      titleFormat: {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      },
      plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin, momentPlugin],
      initialView: isTablet ? 'timeGridWeek' : 'timeGridDay',
      headerToolbar: {
        left: 'prev,title,next',
        right: '',
      },
      expandRows: true,
      aspectRatio: 1.25,
      firstDay: 1,
      timeZone: timezone, // 'UTC',
      eventTimeFormat: {
        hour: 'numeric',
        minute: '2-digit',
        meridiem: false,
      },
      slotLabelFormat:
        lang === 'it'
          ? [
              {
                hour: '2-digit',
                minute: '2-digit',
                hour12: false,
              },
            ]
          : [
              {
                hour: 'numeric',
                minute: '2-digit',
                omitZeroMinute: true,
                meridiem: 'short',
              },
            ],
      slotEventOverlap: false,
      dayMaxEvents: 2,
      eventBorderColor: '#fff',
      eventContent: (event: any) => {
        return (
          <EventContent
            slot={event.event.extendedProps.slot}
            timezone={timezone}
          />
        );
      },
      dayHeaderContent: (date: any) => {
        /* Get event day from event list comparing date */
        const eventDay = events.find(
          (event: any) =>
            usingTimezone(date.date, timezone).format() ===
            usingTimezone(
              event.extendedProps.slot.eventDay.date,
              timezone,
            ).format(),
        );

        return (
          <DayHeaderContent
            date={date}
            hasDownloadButton={hasDownloadButton}
            eventDayId={eventDay?.extendedProps.slot.eventDay.id}
          />
        );
      },
    }),
    [getSlotTimeLimit, lang, timezone, usingTimezone],
  );

  const convertSlotsToFullCalendarEvents = useCallback(
    ({
      slots,
      bookings,
      timezone,
      minBookingTime,
    }: {
      slots: Slots;
      bookings?: Booking[];
      timezone: string;
      minBookingTime?: number;
    }): EventInput => {
      return slots.map(({id, startTime, endTime, eventDay, ...slot}) => {
        const start = usingTimezone(startTime, timezone).format();
        const end = usingTimezone(endTime, timezone).format();

        const slotLimit = slot.slotLimit;
        const bookedSpots = slot.bookedSpots;

        const bookFound = bookings?.find(
          (booking: Booking): boolean => booking.slotId === id,
        );

        return {
          id,
          start,
          end,
          extendedProps: {
            slot: {
              ...slot,
              id,
              startTime: start,
              endTime: end,
              eventDay,
              isDisabled: canNotEdit({
                eventStartDate: start,
                slotLimit,
                bookedSpots,
                isBookFound: !!bookFound,
                timezone,
                minBookingTime,
              }),
              isBooked: !!bookFound,
              minBookingTime,
            },
          },
        };
      });
    },
    [canNotEdit, usingTimezone],
  );

  return {getConfiguration, convertSlotsToFullCalendarEvents};
};
