import {useCallback, useEffect, useMemo, useState} from 'react';

import {FormControl, InputLabel, MenuItem, Select} from '@mui/material';
import {useTranslations} from '@vidiemme/react-i18n';

import {useToast} from '@/hooks/toast';
import {ROLES} from '@/store/roles';
import {useUpdateUserRole} from '@/store/userManagement';

import {IProps} from './definitions';
import {useStyle} from './style';

const RoleCells = ({roles, realm, email, role}: IProps) => {
  const {t} = useTranslations();

  const {formControlTableBox} = useStyle();

  const {showErrorToast} = useToast();

  const [selectedRole, setSelectedRole] = useState<ROLES>(role);

  const selectedRoleId = useMemo(() => {
    const found = roles.find(({name}) => selectedRole === name);
    if (found) {
      return found.id;
    }

    return -1;
  }, [roles, selectedRole]);

  const {loading, error, run} = useUpdateUserRole(realm, email, selectedRoleId);

  const handleChange = useCallback(
    ({target: {value: roleName}}: {target: {value: string}}) => {
      setSelectedRole(roleName as ROLES);
      if (run) {
        run();
      }
    },
    [run],
  );

  useEffect(() => {
    if (error) {
      showErrorToast({message: t('RESPONSE_ERROR_MESSAGE.MESSAGE')});
    }
  }, [error, showErrorToast, t]);

  return (
    <FormControl sx={formControlTableBox}>
      <InputLabel id="roleCellSelectLabel">
        {t('USER_MANAGEMENT.ROLE')}
      </InputLabel>
      <Select
        labelId="roleCellSelectLabel"
        id="roleCellSelect"
        value={selectedRole}
        label={t('USER_MANAGEMENT.ROLE')}
        onChange={handleChange}
        disabled={loading}>
        {roles.map(({name}) => (
          <MenuItem key={name} value={name}>
            {t(`ROLE.${name.toUpperCase()}`)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default RoleCells;
