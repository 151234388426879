import React, {useEffect, useState} from 'react';

import {Box, Container, Fade, Typography} from '@mui/material';
import {useTranslations} from '@vidiemme/react-i18n';

import {IconSVG} from '@/atoms/IconSVG';
import {useUserContext} from '@/contexts/user';
import {useIsTablet} from '@/hooks/viewport';
import {useStyle} from '@/organisms/SplashPage/style';

const SplashPage = () => {
  const {t} = useTranslations();
  const {splashPageContainer, splashPageBox, splashPageTitle} = useStyle();
  const [checked, setChecked] = useState(true);
  const isTablet = useIsTablet();

  const {lang} = useUserContext();
  const {setLanguage} = useTranslations();
  useEffect(() => {
    if (lang) {
      setLanguage(lang);
    }
  }, [lang, setLanguage]);

  useEffect(() => {
    setTimeout(() => {
      setChecked(false);
    }, 800);
  }, []);

  return (
    <>
      <Fade appear={false} in={checked} timeout={2000}>
        <Container sx={splashPageContainer} maxWidth={false}>
          <Container maxWidth={false}>
            <Box sx={splashPageBox}>
              <Box>
                <IconSVG icon={'logo-versace'} size={isTablet ? 250 : 170} />
              </Box>
              <Typography variant={'titleVersace1'} sx={splashPageTitle}>
                {t('SPLASH_PAGE.E_PLANNING_TOOL')}
              </Typography>
            </Box>
          </Container>
        </Container>
      </Fade>
    </>
  );
};
export default SplashPage;
