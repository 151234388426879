import {useMemo} from 'react';

import {SxProps} from '@mui/material';

import {IUseStyle} from '@/pages/Invitations/InvitationsLocation/Invitation/definitions';
import {theme} from '@/themes/default';

export const useStyle = (): IUseStyle => {
  const invitationBoxContainer: SxProps = useMemo(
    (): SxProps => ({
      backgroundColor: theme.palette.gray1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: '100%',
      padding: '25px',
      [theme.breakpoints.down('md')]: {
        minWidth: '90%',
      },
      [theme.breakpoints.up('md')]: {
        padding: '40px',
        width: '400px',
      },
      [theme.breakpoints.up('lg')]: {
        width: '552px',
      },
    }),
    [],
  );

  const invitationTitleBoxContainer: SxProps = useMemo(
    (): SxProps => ({
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: '16px',
    }),
    [],
  );

  const invitationTitleBox: SxProps = useMemo(
    (): SxProps => ({display: 'flex', flexDirection: 'column'}),
    [],
  );

  const invitationTitle: SxProps = useMemo(
    (): SxProps => ({
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: '4',
      WebkitBoxOrient: 'vertical',
    }),
    [],
  );

  const invitationDateBox: SxProps = useMemo(
    (): SxProps => ({
      marginBottom: '40px',
    }),
    [],
  );

  const invitationLocationBox: SxProps = useMemo(
    (): SxProps => ({
      marginBottom: '40px',
      svg: {
        marginRight: '8px',
      },
    }),
    [],
  );

  const invitationButton: SxProps = useMemo(
    (): SxProps => ({width: '100%'}),
    [],
  );

  return {
    invitationBoxContainer,
    invitationTitleBoxContainer,
    invitationTitleBox,
    invitationTitle,
    invitationDateBox,
    invitationLocationBox,
    invitationButton,
  };
};
