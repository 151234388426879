import {useMemo} from 'react';

import {SxProps} from '@mui/material';

import {IPropsStyle} from '@/molecules/PageTitle/type';
import {desktopPadding, mobilePadding, theme} from '@/themes/default';

export const useStyle = (): IPropsStyle => {
  const pageTitleBoxContainer: SxProps = useMemo(
    (): SxProps => ({
      display: 'flex',
      flexDirection: 'column',
      margin: '24px 0',
      paddingX: mobilePadding,
      [theme.breakpoints.up('md')]: {
        paddingX: desktopPadding,
      },
    }),
    [],
  );

  const pretitleBox: SxProps = useMemo(
    (): SxProps => ({
      marginBottom: '8px',
    }),
    [],
  );

  const titlesBox: SxProps = useMemo(
    (): SxProps => ({
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      marginTop: '24px',
    }),
    [],
  );

  const titleBox: SxProps = useMemo(
    (): SxProps => ({
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    }),
    [],
  );

  const buttonsBox: SxProps = useMemo(
    (): SxProps => ({
      [theme.breakpoints.down('md')]: {
        paddingBottom: '16px',
      },
    }),
    [],
  );

  return {
    pageTitleBoxContainer,
    pretitleBox,
    titlesBox,
    titleBox,
    buttonsBox,
  };
};
