import {useMemo} from 'react';

import {SxProps} from '@mui/material';

import {theme} from '@/themes/default';

import {IPropsStyle} from './type';

export const useStyle = (): IPropsStyle => {
  const switchCalendarTimezone: SxProps = useMemo(
    (): SxProps => ({
      [theme.breakpoints.up('md')]: {
        alignSelf: 'flex-end',
        position: 'absolute',
      },
    }),
    [],
  );

  const ctaWrapper: SxProps = useMemo(
    (): SxProps => ({
      display: 'flex',
      flexDirection: 'column',
      rowGap: '10px',
    }),
    [],
  );

  return {
    switchCalendarTimezone,
    ctaWrapper,
  };
};
