// Import the useTranslations hook for internationalization
import {FunctionComponent, useCallback, useEffect} from 'react';
// Import the FunctionComponent type from React
import {useLocation} from 'react-router-dom';

// Import the Modal component
import {Box, Typography} from '@mui/material';
// Import Box, Button, and Typography components from Material-UI
import {useTranslations} from '@vidiemme/react-i18n';

import {Button} from '@/atoms/Button';
import {PrintDate} from '@/atoms/PrintDate';
import {useToast} from '@/hooks/toast';
// Import the useToast hook for displaying toasts
import {useNavigation} from '@/navigation/useNavigation';
// Import the useNavigation hook for navigation handling
import {useStyle} from '@/pages/BookingDelete/styles';
// Import style-related functions
import {useDeleteBooking} from '@/store/bookings';
// Import functions for deleting bookings
import {Modal} from '@/templates/Modal';

// Import the useLocation hook for accessing URL location

const BookingDelete: FunctionComponent = () => {
  const {showSuccessToast, showErrorToast} = useToast(); // Get functions for displaying success and error toasts

  const {
    deleteBookingBoxContainer,
    deleteBookingTitleBox,
    deleteBookingTitle,
    deleteBookingDetailsBox,
    deleteBookingButtonsBox,
    deleteBookingButton,
  } = useStyle(); // Get style-related functions

  const {t} = useTranslations(); // Get the translation function
  const {goToPreviousPage, goToInvitationSlotDetail, goToInvitation} =
    useNavigation(); // Get the function for navigating back
  const {state} = useLocation(); // Get the current URL location's state

  let cancelButtonHandler = useCallback(() => {
    // if starting point is "invitation slot detail" will return in that path
    if (state.invitationInfo) {
      goToInvitationSlotDetail(
        state.invitationInfo.eventId,
        state.invitationInfo.slotId,
        state.invitationInfo.bookingId,
      );
    } else {
      goToPreviousPage();
    }
  }, [goToInvitationSlotDetail, goToPreviousPage, state.invitationInfo]);

  let successButtonHandler = useCallback(() => {
    // if starting point is "invitation slot detail", after remove booking will go in invitations list page
    if (state.invitationInfo) {
      goToInvitation(state.invitationInfo.eventId);
    } else {
      // come back in bookings page
      goToPreviousPage();
    }
  }, [goToInvitation, goToPreviousPage, state.invitationInfo]);

  const {
    loading: loadingDeleteBooking,
    error: errorDeleteBooking,
    response: responseDeleteBooking,
    run: runDeleteBooking,
  } = useDeleteBooking(state.appointment.id); // Use the useDeleteBooking hook to delete the booking

  const deleteButtonHandler = useCallback(() => {
    if (runDeleteBooking) {
      return runDeleteBooking();
    }
    return null;
  }, [runDeleteBooking]);

  useEffect(() => {
    if (errorDeleteBooking) {
      showErrorToast({message: t('BOOKING_MODAL.DELETE_RESPONSE_ERROR')});
    }

    if (responseDeleteBooking) {
      showSuccessToast({message: t('BOOKING_MODAL.DELETE_RESPONSE_SUCCESS')});
      successButtonHandler();
    }
    return;
  }, [
    cancelButtonHandler,
    errorDeleteBooking,
    goToPreviousPage,
    responseDeleteBooking,
    showErrorToast,
    showSuccessToast,
    successButtonHandler,
    t,
  ]);

  return (
    <Modal>
      <Box sx={deleteBookingBoxContainer}>
        <Box sx={deleteBookingTitleBox}>
          <Typography variant={'titleAktive1'} sx={deleteBookingTitle}>
            {t('APPOINTMENTS.DELETE_MODAL_TITLE')}
          </Typography>
          <Typography variant={'bodyRegular'}>
            {t('APPOINTMENTS.DELETE_MODAL_TEXT')}
          </Typography>
        </Box>
        <Box sx={deleteBookingDetailsBox}>
          <Typography>{state.appointment.eventName}</Typography>
          <PrintDate
            dateProps={{
              date: state.date,
              startHour: state.startHour,
              endHour: state.endHour,
              timezoneLocation:
                state.appointment.slot.eventDay.event.location.timezone,
            }}
            variant={'inherit'}
          />
        </Box>
        <Box sx={deleteBookingButtonsBox}>
          <Button
            variant={'secondaryBig'}
            color={'default'}
            sx={deleteBookingButton}
            onClick={cancelButtonHandler}>
            {t('APPOINTMENTS.DELETE_MODAL_BUTTON_CANCEL')}
          </Button>
          <Button
            variant={'primaryBig'}
            color={'default'}
            sx={deleteBookingButton}
            onClick={deleteButtonHandler}
            disabled={loadingDeleteBooking}
            loading={loadingDeleteBooking}>
            {t('APPOINTMENTS.DELETE_MODAL_BUTTON_DELETE')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default BookingDelete;
