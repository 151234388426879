import React, {useMemo} from 'react';

import {Box, TextField} from '@mui/material';
import {useTranslations} from '@vidiemme/react-i18n';

import {Button} from '@/atoms/Button';
import {AddButton} from '@/molecules/WeeklySchedule/AddButton';
import {useStyle} from '@/molecules/WeeklySchedule/EditContent/style';
import {EditContentProps} from '@/molecules/WeeklySchedule/EditContent/type';

const EditContent = ({
  disabled = false,
  isLoading,
  day,
  dayInterval,
  index,
}: EditContentProps) => {
  const {t, currentLanguage} = useTranslations();
  const {
    boxWrapperSlotTimes: boxWrapperSlotTimesStyle,
    wrapperButtons: wrapperButtonsStyle,
    deleteSlot: deleteSlotStyle,
    deleteSlotFullsize: deleteSlotFullsizeStyle,
  } = useStyle();

  const endTime = useMemo(() => {
    // we don't want to apply any timezone, the opening hours are always in the timezone of the event
    if (!dayInterval.end_time) return;

    if (currentLanguage === 'en') {
      return dayInterval.end_time.utc().format('hh:mm A');
    }

    return dayInterval.end_time.utc().format('HH:mm');
  }, [currentLanguage, dayInterval.end_time]);

  const startTime = useMemo(() => {
    // we don't want to apply any timezone, the opening hours are always in the timezone of the event
    if (!dayInterval.start_time) return;

    if (currentLanguage === 'en') {
      return dayInterval.start_time.utc().format('hh:mm A');
    }

    return dayInterval.start_time.utc().format('HH:mm');
  }, [currentLanguage, dayInterval.start_time]);

  return (
    <Box key={index} sx={boxWrapperSlotTimesStyle}>
      {dayInterval.start_time && (
        <TextField
          defaultValue={startTime}
          disabled={true}
          label={t('EVENT_CREATE_EDIT.WEEKLY_SCHEDULE.START_TIME')}></TextField>
      )}

      {dayInterval.end_time && (
        <TextField
          defaultValue={endTime}
          disabled={true}
          label={t('EVENT_CREATE_EDIT.WEEKLY_SCHEDULE.END_TIME')}></TextField>
      )}
      <Box sx={wrapperButtonsStyle}>
        {index === day.intervals.length - 1 && (
          <AddButton disabled={isLoading || disabled} />
        )}
        {/* DELETE BUTTON */}
        <Button
          disabled={isLoading || disabled}
          sx={
            index === day.intervals.length - 1
              ? deleteSlotStyle
              : deleteSlotFullsizeStyle
          }
          variant={'secondary'}
          color={'default'}>
          {t('EVENT_CREATE_EDIT.WEEKLY_SCHEDULE.DELETE_SLOT')}
        </Button>
      </Box>
    </Box>
  );
};

export default EditContent;
