import React from 'react';
import {Navigate, Outlet} from 'react-router-dom';

import {useUserContext} from '@/contexts/user';
import {useQueryString} from '@/hooks/queryString';
import {AnonymousRoutes} from '@/navigation/routes';

const Authenticated = () => {
  const {isAuthenticated} = useUserContext();
  const qsEventID = useQueryString('event_id');

  if (isAuthenticated) {
    return <Outlet />;
  } else {
    const redirectURI = qsEventID
      ? `${AnonymousRoutes.LOGIN_EMPLOYEE}?event_id=${qsEventID}`
      : AnonymousRoutes.LOGIN_EMPLOYEE;
    return <Navigate to={redirectURI} replace />;
  }
};

export default Authenticated;
