import {useCallback} from 'react';

const useUtils = () => {
  const capitalize = useCallback((str: string) => {
    return str.charAt(0).toUpperCase().concat(str.slice(1));
  }, []);

  return {capitalize};
};

export default useUtils;
