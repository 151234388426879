import {useMemo} from 'react';

import {Typography, TypographyVariant} from '@mui/material';
import {useTranslations} from '@vidiemme/react-i18n';

import useDate from '@/hooks/date';

import type {IProps} from './type';

/*
  Calculate some variables based on the  date, startHour, endHour and  timezoneLocation  props.
  It checks if the timezoneLocation are different as the local time zone, and then calculates the date and time
  range in both time zones
*/
const PrintDate = ({
  dateProps: {date, startHour, endHour, timezoneLocation},
  variant,
}: IProps) => {
  const {isSameTimezone, dateToFullDate, dateToHours} = useDate();
  const {t} = useTranslations(); // Get the translation function from useTranslations
  const {
    dateLocation,
    startHourLocation,
    endHourLocation,
    dateLocal,
    startHourLocal,
    endHourLocal,
  } = useMemo(() => {
    const sameTimezone = isSameTimezone(timezoneLocation);

    return {
      dateLocation: dateToFullDate(date, timezoneLocation),
      startHourLocation: dateToHours(startHour, timezoneLocation),
      endHourLocation: dateToHours(endHour, timezoneLocation),
      sameTimezone,
      dateLocal: sameTimezone ? '' : dateToFullDate(date),
      startHourLocal: sameTimezone ? '' : dateToHours(startHour),
      endHourLocal: sameTimezone ? '' : dateToHours(endHour),
    };
  }, [
    date,
    dateToFullDate,
    dateToHours,
    endHour,
    isSameTimezone,
    startHour,
    timezoneLocation,
  ]);

  return (
    <>
      <Typography variant={variant as TypographyVariant}>
        {dateLocation}{' '}
        {dateLocal && dateLocal !== dateLocation && t('TIMEZONE.LOCATION')}
      </Typography>
      <Typography variant={variant as TypographyVariant}>
        {`${startHourLocation} - ${endHourLocation}`}{' '}
        {startHourLocal &&
          startHourLocal !== startHourLocation &&
          t('TIMEZONE.LOCATION')}
      </Typography>
      {dateLocal && dateLocal !== dateLocation && (
        <Typography variant={variant as TypographyVariant}>
          {dateLocation} {t('TIMEZONE.LOCAL')}
        </Typography>
      )}
      {startHourLocal && (
        <Typography variant={variant as TypographyVariant}>
          {`${startHourLocal} - ${endHourLocal}`} {t('TIMEZONE.LOCAL')}
        </Typography>
      )}
    </>
  );
};

export default PrintDate;
