import {FunctionComponent, useCallback, useEffect} from 'react';
import {useLocation, useParams} from 'react-router-dom';

import {Box, Typography} from '@mui/material';
import {useTranslations} from '@vidiemme/react-i18n';

import {Button} from '@/atoms/Button';
import {PrintDate} from '@/atoms/PrintDate';
import {useToast} from '@/hooks/toast';
import {useNavigation} from '@/navigation/useNavigation';
import {useDeleteBooking} from '@/store/bookings';
import {Modal} from '@/templates/Modal';

import {useStyle} from './style';

const EventSlotBookingDelete: FunctionComponent = () => {
  // Hooks for translation and navigation
  const {t} = useTranslations(); // Get the translation function
  const {goToEventCalendar} = useNavigation(); // Get the function for navigating back

  // Functions to display success and error toasts
  const {showSuccessToast, showErrorToast} = useToast();

  // Styles
  const {
    deleteBookingBoxContainer,
    deleteBookingTitleBox,
    deleteBookingTitle,
    deleteBookingDetailsBox,
    deleteBookingButtonsBox,
    deleteBookingButton,
    eventDetailsBox,
  } = useStyle();

  // Get the current URL location's state
  const {
    state: {user, note, eventName, endTime, startTime, timezone},
  } = useLocation();

  // Get params from URL
  const {bookingId, id: eventId} = useParams();

  // Close the modal and return to the previous page
  const closeModalHandler = useCallback(() => {
    goToEventCalendar(parseInt(eventId!));
  }, [goToEventCalendar, eventId]);

  // Use the useDeleteBooking hook to delete the booking
  const {
    loading: loadingDeleteBooking,
    error: errorDeleteBooking,
    response: responseDeleteBooking,
    run: runDeleteBooking,
  } = useDeleteBooking(bookingId ? parseInt(bookingId) : -1);

  const deleteButtonHandler = useCallback(() => {
    if (runDeleteBooking) {
      return runDeleteBooking();
    }
    return null;
  }, [runDeleteBooking]);

  // Show success/error toasts
  useEffect(() => {
    if (errorDeleteBooking) {
      showErrorToast({message: t('BOOKING_MODAL.DELETE_RESPONSE_ERROR')});
    }

    if (responseDeleteBooking) {
      showSuccessToast({message: t('BOOKING_MODAL.DELETE_RESPONSE_SUCCESS')});
      closeModalHandler();
    }
    return;
  }, [
    errorDeleteBooking,
    goToEventCalendar,
    responseDeleteBooking,
    showErrorToast,
    showSuccessToast,
    closeModalHandler,
    t,
  ]);

  return (
    <Modal>
      <Box sx={deleteBookingBoxContainer}>
        <Box sx={deleteBookingTitleBox}>
          <Typography variant={'titleAktive1'} sx={deleteBookingTitle}>
            {t('APPOINTMENTS.ADMIN_DELETE_MODAL_TITLE')}
          </Typography>
          <Typography variant={'bodyRegular'}>
            {t('APPOINTMENTS.ADMIN_DELETE_MODAL_TEXT')}
          </Typography>
        </Box>
        <Box sx={deleteBookingDetailsBox}>
          <Typography variant={'bodyRegular'}>
            <Typography variant={'bodyBold'}>{user?.name}</Typography>
            {' • '}
            {user?.email}
            {' • '}
            {user?.phone_number}
          </Typography>
          <Box>
            <Typography variant={'bodyRegular'}>
              {t('EVENT_PEOPLE_BOOKED.NOTE')} {note || '-'}
            </Typography>
          </Box>
          <Box sx={eventDetailsBox}>
            <Typography variant={'titleVersace2'}>{eventName}</Typography>
            <PrintDate
              dateProps={{
                date: startTime,
                startHour: startTime,
                endHour: endTime,
                timezoneLocation: timezone,
              }}
              variant={'bodyRegular'}
            />
          </Box>
        </Box>
        <Box sx={deleteBookingButtonsBox}>
          <Button
            variant={'secondaryBig'}
            color={'default'}
            sx={deleteBookingButton}
            onClick={closeModalHandler}>
            {t('APPOINTMENTS.DELETE_MODAL_BUTTON_CANCEL')}
          </Button>
          <Button
            variant={'primaryBig'}
            color={'default'}
            sx={deleteBookingButton}
            onClick={deleteButtonHandler}
            disabled={loadingDeleteBooking}
            loading={loadingDeleteBooking}>
            {t('APPOINTMENTS.DELETE_MODAL_BUTTON_DELETE')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default EventSlotBookingDelete;
