import {useMemo} from 'react';

import {SxProps} from '@mui/material';

import {IUseStyle} from './type';

export const useStyle = (): IUseStyle => {
  const bookingModalContainerBox: SxProps = useMemo(
    (): SxProps => ({
      backgroundColor: '#F4F4F4',
      display: 'flex',
      flexDirection: 'column',
      padding: '40px 18px',
      marginBottom: '32px',
      rowGap: '24px',
    }),
    [],
  );

  const dateFieldStyle: SxProps = useMemo(
    (): SxProps => ({
      width: '100%',
    }),
    [],
  );

  return {
    bookingModalContainerBox,
    dateFieldStyle,
  };
};
