import {useMemo} from 'react';

import {SxProps} from '@mui/material';

import {IUseStyle} from '@/pages/Bookings/AppointmentsLocation/Appointment/definitions';
import {theme} from '@/themes/default';

export const useStyle = (): IUseStyle => {
  const appointmentBoxContainer: SxProps = useMemo(
    (): SxProps => ({
      minHeight: '364px',
      width: '100%',
      backgroundColor: theme.palette.gray1,
      padding: '25px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      rowGap: '16px',
      [theme.breakpoints.down('md')]: {
        minWidth: '90%',
      },
      [theme.breakpoints.up('md')]: {
        width: '451px',
        padding: '40px',
      },
    }),
    [],
  );

  const appointmentEventName: SxProps = useMemo(
    (): SxProps => ({
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: '4',
      WebkitBoxOrient: 'vertical',
    }),
    [],
  );

  const appointmentDateBox: SxProps = useMemo(
    (): SxProps => ({
      display: 'flex',
      flexDirection: 'column',
    }),
    [],
  );

  const appointmentLocationBox: SxProps = useMemo(
    (): SxProps => ({
      display: 'flex',
      alignItems: 'center',
      marginTop: '16px',
      svg: {
        marginRight: '8px',
      },
    }),
    [],
  );

  const appointmentNoteBox: SxProps = useMemo(
    (): SxProps => ({
      display: 'flex',
      alignItems: 'center',
      svg: {
        marginRight: '8px',
      },
    }),
    [],
  );

  const appointmentButtonBox: SxProps = useMemo(
    (): SxProps => ({
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
    }),
    [],
  );

  const button: SxProps = useMemo(
    (): SxProps => ({
      height: '34px',
      width: '50%',
      [theme.breakpoints.up('md')]: {
        width: '174px',
      },
    }),
    [],
  );

  return {
    appointmentBoxContainer,
    appointmentEventName,
    appointmentDateBox,
    appointmentLocationBox,
    appointmentNoteBox,
    appointmentButtonBox,
    button,
  };
};
