import {useMemo} from 'react';

import {SxProps} from '@mui/material';

export const useStyle = () => {
  const container: SxProps = useMemo(
    (): SxProps => ({
      backgroundColor: 'rgba(255,170,170,0.1)',
      width: '100%',
      paddingLeft: '16px',
      paddingY: '16px',
      borderRadius: '5px',
      display: 'flex',
      flexDirection: 'column',
    }),
    [],
  );

  return {container};
};
