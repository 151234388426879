import {useMemo} from 'react';

import {SxProps} from '@mui/material';

import {IUseStyle} from '@/organisms/MessagePage/type';
import {desktopPadding, mobilePadding, theme} from '@/themes/default';

export const useStyle = (): IUseStyle => {
  const messagePageBox: SxProps = useMemo(
    (): SxProps => ({
      marginTop: '100px',
      flexDirection: 'column',
      justifyContent: 'center',
      display: 'flex',
      alignItems: 'center',
      paddingX: mobilePadding,
      paddingY: '24px',
      [theme.breakpoints.up('md')]: {
        paddingX: desktopPadding,
      },
    }),
    [],
  );

  const messagePageMessage: SxProps = useMemo(
    (): SxProps => ({
      marginTop: '40px',
      minWidth: '300px',
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '25%',
      },
    }),
    [],
  );

  return {
    messagePageBox,
    messagePageMessage,
  };
};
