import React, {useCallback, useState} from 'react';

import {Visibility, VisibilityOff} from '@mui/icons-material';
import {
  Box,
  Container,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from '@mui/material';
import {useTranslations} from '@vidiemme/react-i18n';
import validate from 'validate.js';

import {Button} from '@/atoms/Button';
import {useUserContext} from '@/contexts/user';
import {useToast} from '@/hooks/toast';
import {useStyle} from '@/pages/LoginExternal/LoginExternalForm/style';
import {REALMS} from '@/store/keycloak';

const LoginExternalForm = () => {
  const {t} = useTranslations();
  const {showErrorToast} = useToast();

  const {
    externalFormInputEmail,
    externalFormInputPassword,
    externalFormButton,
    loginErrorMessage,
    formContainer,
  } = useStyle();

  const [showPassword, setShowPassword] = React.useState(false);
  const [loginLoading, setLoginLoading] = useState<boolean>(false);
  const [loginError, setLoginError] = useState(false);
  const [emailInputValue, setEmailInputValue] = useState('');
  const [pswInputValue, setPswInputValue] = useState('');

  const {login, isAuthenticated} = useUserContext();

  const handleClickToggleShowPassword = useCallback(() => {
    setShowPassword(show => !show);
  }, []);

  const emailChangeHandler = useCallback((event: any) => {
    setLoginError(false);
    setEmailInputValue(event.target.value);
  }, []);

  const pswChangeHandler = useCallback((event: any) => {
    setLoginError(false);
    setPswInputValue(event.target.value);
  }, []);

  const handleMouseDownPassword = useCallback((event: any) => {
    event.preventDefault();
  }, []);

  const submitHandler = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      const validationErrors = validate(
        {email: emailInputValue, password: pswInputValue},
        {
          email: {
            email: true,
          },
          password: {
            presence: true,
          },
        },
      );

      if (validationErrors) {
        setLoginError(true);
      } else {
        try {
          setLoginLoading(true);

          await login(REALMS.GUESTS, emailInputValue, pswInputValue);

          if (!isAuthenticated) {
            setLoginError(true);
          }
        } catch (e: any) {
          showErrorToast({
            message:
              e?.error === 'invalid_grant'
                ? t('LOGIN_EXTERNAL.ERROR')
                : t('RESPONSE_ERROR_MESSAGE.MESSAGE'),
          });
        } finally {
          setLoginLoading(false);
        }
      }
    },
    [emailInputValue, isAuthenticated, login, pswInputValue, showErrorToast, t],
  );

  return (
    <Container sx={formContainer}>
      <form onSubmit={submitHandler}>
        <TextField
          error={loginError}
          id="outlined-basic"
          label={t('LOGIN_EXTERNAL.EMAIL_ADDRESS')}
          variant="outlined"
          sx={externalFormInputEmail}
          value={emailInputValue}
          onChange={emailChangeHandler}
        />
        <FormControl variant="outlined" sx={externalFormInputPassword}>
          <InputLabel htmlFor="outlined-adornment-password" error={loginError}>
            {t('LOGIN_EXTERNAL.PASSWORD')}
          </InputLabel>
          <OutlinedInput
            error={loginError}
            value={pswInputValue}
            onChange={pswChangeHandler}
            id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickToggleShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
        </FormControl>

        {loginError && (
          <Box sx={loginErrorMessage}>
            <Typography variant={'bodyRegularSmall'}>
              {t('LOGIN_EXTERNAL.ERROR')}
            </Typography>
          </Box>
        )}

        <Button
          type={'submit'}
          variant={'primaryBig'}
          color={'default'}
          sx={externalFormButton}
          disabled={loginError || loginLoading}
          loading={loginLoading}>
          {t('LOGIN_EXTERNAL.LOGIN')}
        </Button>
      </form>
    </Container>
  );
};
export default LoginExternalForm;
