import type {ReactNode} from 'react';

import {ThemeProvider} from '@mui/material/styles';
import {TranslationsProvider} from '@vidiemme/react-i18n';

import {UserProvider} from '@/contexts/user';
import {en} from '@/i18n/en';
import {it} from '@/i18n/it';
import {LocalizationMoment} from '@/store/locale';
import {defaultTheme as theme} from '@/themes/index';

const ProviderWrapper = ({children}: {children: ReactNode}) => {
  return (
    <UserProvider>
      <LocalizationMoment>
        <ThemeProvider theme={theme}>
          <TranslationsProvider
            params={{
              resources: {
                en: {
                  translation: en,
                },
                it: {
                  translation: it,
                },
              },
            }}>
            {children}
          </TranslationsProvider>
        </ThemeProvider>
      </LocalizationMoment>
    </UserProvider>
  );
};

export default ProviderWrapper;
