import {useMemo} from 'react';

import {SxProps} from '@mui/material';

import {theme} from '@/themes/default';

import {IPropsStyle} from './type';

export const useStyle = (): IPropsStyle => {
  const switchBox: SxProps = useMemo(
    (): SxProps => ({
      [theme.breakpoints.up('md')]: {
        alignSelf: 'flex-end',
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
      },
    }),
    [],
  );

  const minBookingTimeInfoBox = useMemo(
    (): SxProps => ({
      marginBottom: '10px',
    }),
    [],
  );
  return {
    switchBox,
    minBookingTimeInfoBox,
  };
};
