import {useMemo} from 'react';

import {Box, Typography} from '@mui/material';

import {IconSVG} from '@/atoms/IconSVG';
import useDate from '@/hooks/date';
import useEvent from '@/hooks/event';
import {useIsDesktop} from '@/hooks/viewport';
import {useStyle} from '@/organisms/Calendar/style';
import {theme} from '@/themes/default';

import {IProps} from './type';

const EventContent = ({slot, timezone}: IProps) => {
  const {dateToHours} = useDate();
  const {canNotEdit} = useEvent();
  const isDesktop = useIsDesktop();

  const [eventTitle, eventClose] = useMemo(() => {
    const start = slot.startTime;
    const end = slot.endTime;
    const startHours = dateToHours(start, timezone, true);
    const endHours = dateToHours(end, timezone, true);

    const eventTitle = `${startHours} - ${endHours}`.toUpperCase();

    const eventClose = canNotEdit({
      eventStartDate: start,
      slotLimit: slot.slotLimit,
      bookedSpots: slot.bookedSpots,
      minBookingTime: slot.minBookingTime,
    });

    return [eventTitle, eventClose];
  }, [
    canNotEdit,
    dateToHours,
    slot.bookedSpots,
    slot.endTime,
    slot.slotLimit,
    slot.startTime,
    slot.minBookingTime,
    timezone,
  ]);

  const {
    fullCalendarEventGuestBox,
    fullCalendarEventTimeBox,
    fullCalendarEventGuestTypography,
    eventSlotContainer,
  } = useStyle({isBooked: slot.isBooked, eventClose});

  const getColor = useMemo(() => {
    return slot.isBooked
      ? theme.palette.black
      : eventClose
      ? theme.palette.gray4
      : theme.palette.black;
  }, [eventClose, slot.isBooked]);

  return (
    <Box sx={eventSlotContainer}>
      <Box sx={fullCalendarEventGuestBox}>
        <IconSVG icon={'user'} size={20} color={getColor} />
        <Typography
          variant="body100Bold"
          color={getColor}
          sx={fullCalendarEventGuestTypography}>
          {`${slot.bookedSpots}/${slot.slotLimit}`}
        </Typography>
      </Box>
      <Box sx={fullCalendarEventTimeBox}>
        <Typography variant="bodyRegular" color={getColor}>
          {eventTitle}
        </Typography>
        {slot.isBooked && (
          <IconSVG
            icon={'calendar-checked'}
            size={isDesktop ? 24 : 20}
            color={theme.palette.successGreen}
            className="icon-booked"
          />
        )}
      </Box>
    </Box>
  );
};
export default EventContent;
