// Import the useTranslations hook
import {useCallback} from 'react';

import {Box, Typography} from '@mui/material';
// Import the IconSVG component
import {useTranslations} from '@vidiemme/react-i18n';

// Import the useStyle function
import {Button} from '@/atoms/Button';
import {IconSVG} from '@/atoms/IconSVG';
import {PrintDate} from '@/atoms/PrintDate';
// Import the useCallback and useMemo hooks from React
import {useNavigation} from '@/navigation/useNavigation';
// Import Box, Button, and Typography components from Material-UI
import {IProps} from '@/pages/Bookings/AppointmentsLocation/Appointment/definitions';
// Import the IProps interface
import {useStyle} from '@/pages/Bookings/AppointmentsLocation/Appointment/style';

// Import the useNavigation hook
const Appointment = ({appointment}: IProps) => {
  const {t} = useTranslations(); // Get the translation function from the hook

  const {
    appointmentBoxContainer,
    appointmentEventName,
    appointmentDateBox,
    appointmentLocationBox,
    appointmentNoteBox,
    appointmentButtonBox,
    button,
  } = useStyle(); // Destructure style classes from useStyle hook

  const {goToBookingDelete, goToBookingEdit} = useNavigation(); // Destructure navigation functions

  const deleteButtonHandler = useCallback((): void => {
    if (appointment && appointment.slot) {
      goToBookingDelete(appointment.id, {
        state: {
          appointment,
          date: appointment.slot.startTime,
          startHour: appointment.slot.startTime,
          endHour: appointment.slot.endTime,
        },
      });
    }
  }, [appointment, goToBookingDelete]); // Define a callback to handle deletion

  const editButtonHandler = useCallback((): void => {
    if (appointment && appointment.slot) {
      goToBookingEdit(appointment.id, {
        state: {
          appointment,
          date: appointment.slot.startTime,
          startHour: appointment.slot.startTime,
          endHour: appointment.slot.endTime,
        },
      });
    }
  }, [appointment, goToBookingEdit]); // Define a callback to handle editing

  if (appointment.slot) {
    return (
      <Box sx={appointmentBoxContainer}>
        <Typography variant={'titleVersace2'} sx={appointmentEventName}>
          {appointment.slot.eventDay.event.category.name}
          {' - '}
          {appointment.slot.eventDay.event.name}
        </Typography>
        <Box sx={appointmentDateBox}>
          <PrintDate
            dateProps={{
              date: appointment.slot.startTime,
              startHour: appointment.slot.startTime,
              endHour: appointment.slot.endTime,
              timezoneLocation:
                appointment.slot.eventDay.event.location.timezone,
            }}
            variant={'bodyRegular'}
          />
        </Box>
        <Box>
          <Box sx={appointmentLocationBox}>
            <IconSVG icon={'location-pin'} size={20} />
            <Typography variant={'bodyBoldSmall'}>
              {appointment.slot.eventDay.event.location.name}
            </Typography>
          </Box>
          <Typography variant={'bodyRegularSmall'}>
            {appointment.slot.eventDay.event.location.full_address}
          </Typography>
        </Box>
        <Box>
          <Box sx={appointmentNoteBox}>
            <IconSVG icon={'note'} size={20} />
            <Typography variant={'bodyBoldSmall'}>
              {t('APPOINTMENTS.NOTE')}
            </Typography>
          </Box>
          <Typography variant={'bodyRegularSmall'}>
            {appointment.note || '-'}
          </Typography>
        </Box>
        <Box sx={appointmentButtonBox}>
          <Button
            variant={'primary'}
            color={'default'}
            sx={button}
            onClick={editButtonHandler}>
            {t('APPOINTMENTS.EDIT_BUTTON')}
          </Button>
          <Button
            variant={'secondary'}
            color={'default'}
            sx={button}
            onClick={deleteButtonHandler}>
            {t('APPOINTMENTS.DELETE_BUTTON')}
          </Button>
        </Box>
      </Box>
    );
  }
  return null;
};

export default Appointment;
