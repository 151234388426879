import {useMemo} from 'react';

import {SxProps} from '@mui/material';

export const useStyle = () => {
  const formControlTableBox: SxProps = useMemo(
    (): SxProps => ({
      width: '100%',
    }),
    [],
  );

  return {
    formControlTableBox,
  };
};
