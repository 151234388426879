import React from 'react';
import {Navigate} from 'react-router-dom';

import {useQueryString} from '@/hooks/queryString';
import {AuthRoutes} from '@/navigation/routes';

const InvitationsFromEmailRedirect = () => {
  const qsEventId = useQueryString('event_id');
  const redirectURI = `${AuthRoutes.INVITATION_REGISTER.replace(
    ':id',
    qsEventId,
  )}`;
  return <Navigate to={redirectURI} replace />;
};
export default InvitationsFromEmailRedirect;
