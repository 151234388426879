import {Link} from 'react-router-dom';

import {Box, Container, Typography} from '@mui/material';
import {useTranslations} from '@vidiemme/react-i18n';

import {IconSVG} from '@/atoms/IconSVG';
import {useQueryString} from '@/hooks/queryString';
import {AnonymousRoutes} from '@/navigation/routes';
import {SplashPage} from '@/organisms/SplashPage';
import {LoginExternalForm} from '@/pages/LoginExternal/LoginExternalForm';
import {useStyle} from '@/pages/LoginExternal/style';

const LoginExternal = () => {
  const {t} = useTranslations();

  const {
    loginExternalMainBox,
    loginExternalTitleBox,
    loginExternalTextBox,
    loginExternalFormBox,
    loginExternalContainer,
    switchLoginBox,
    loginExternalTitleTypography,
    privacyBox,
  } = useStyle();

  const qsEventID = useQueryString('event_id');

  return (
    <Container sx={loginExternalContainer} maxWidth={false}>
      <SplashPage />
      <Box sx={loginExternalMainBox}>
        <Box sx={loginExternalTitleBox}>
          <IconSVG icon={'logo-versace'} size={60} />
          <Typography
            variant={'titleVersace1'}
            sx={loginExternalTitleTypography}>
            {t('LOGIN_EXTERNAL.E_PLANNING_TOOL')}
          </Typography>
          <Typography variant={'titleAktive2'}>
            {t('LOGIN_EXTERNAL.LOGIN')}
          </Typography>
        </Box>
        <Box sx={loginExternalTextBox}>
          <Typography variant={'bodyRegular'}>
            {t('LOGIN_EXTERNAL.TEXT')}
          </Typography>
        </Box>
        <Box sx={loginExternalFormBox}>
          <LoginExternalForm />
        </Box>
        {/* TODO: waiting for know how to implement it
        <Box sx={loginExternalLinkBox}>
          <Link to={AnonymousRoutes.LOGIN_EXTERNAL}>
            <Typography variant={'bodyRegularLink'}>
              {t('LOGIN_EXTERNAL.CONTACT')}
            </Typography>
          </Link>
        </Box>*/}
        <Box sx={switchLoginBox}>
          <Link
            to={
              qsEventID
                ? `${AnonymousRoutes.LOGIN_EMPLOYEE}?event_id=${qsEventID}`
                : AnonymousRoutes.LOGIN_EMPLOYEE
            }>
            <Typography variant={'bodyRegularLink'}>
              {t('LOGIN_EXTERNAL.LOGIN_EMPLOYEE')}
            </Typography>
          </Link>
        </Box>
        <Box sx={privacyBox}>
          <Link to={AnonymousRoutes.PRIVACY_POLICY}>
            <Typography variant={'bodyRegularLink'}>
              {t('NAVBAR.item_PRIVACY_POLICY')}
            </Typography>
          </Link>
        </Box>
      </Box>
    </Container>
  );
};

export default LoginExternal;
