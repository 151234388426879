import {Button as MUIButton, CircularProgress} from '@mui/material';

import type {Props} from './definitions';

const Button = ({loading = false, children, ...props}: Props) => {
  if (loading) {
    return (
      <MUIButton {...props}>
        <CircularProgress />
      </MUIButton>
    );
  }

  return <MUIButton {...props}>{children}</MUIButton>;
};

export default Button;
