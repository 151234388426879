import {useMemo} from 'react';

import {SxProps} from '@mui/material';

import {IPropsStyle} from '@/organisms/SplashPage/type';
import {theme} from '@/themes/default';

export const useStyle = (): IPropsStyle => {
  const splashPageContainer: SxProps = useMemo(
    (): SxProps => ({
      position: 'fixed',
      zIndex: 2,
      backgroundColor: 'rgb(255,255,255)',
      display: 'flex',
      alignItems: 'center',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
    }),
    [],
  );

  const splashPageBox: SxProps = useMemo(
    (): SxProps => ({
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  );

  const splashPageTitle: SxProps = useMemo(
    (): SxProps => ({
      marginTop: '32px',
      fontSize: '60px',
      textAlign: 'center',
      [theme.breakpoints.up('md')]: {
        fontSize: '80px',
      },
    }),
    [],
  );
  return {
    splashPageContainer,
    splashPageBox,
    splashPageTitle,
  };
};
