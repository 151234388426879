import {useCallback, useEffect, useState} from 'react';

import {useUserContext} from '@/contexts/user';
import type {UseAPI} from '@/store/api/definitions';

import {api} from './functions';

const DEFAULT_REQ = {headers: {}};
const DEFAULT_QUERY = {};
const DEFAULT_OPTIONS = {autoRun: true, req: DEFAULT_REQ, query: DEFAULT_QUERY};

export const useApi: UseAPI = (
  url,
  {
    autoRun = true,
    req = DEFAULT_REQ,
    query = DEFAULT_QUERY,
    body,
    responseParser,
  } = DEFAULT_OPTIONS,
) => {
  const {isAuthenticated, logout, token} = useUserContext();

  const [loading, setLoading] = useState<boolean>(autoRun);
  const [response, setResponse] = useState<null | any>(null);
  const [error, setError] = useState<null | any>(null);

  const request = useCallback(async () => {
    try {
      if (!loading) {
        setLoading(true);
      }

      const options: {
        token?: string;
        query?: {[k: string]: any};
        body?: {[k: string]: any} | string;
        responseParser?: (response: Response) => Promise<any>;
      } = {
        query,
        body,
        responseParser,
      };

      if (isAuthenticated) {
        options.token = token as string;
      }

      const {ok, status, data} = await api(url, req, options);

      if (ok) {
        setResponse(data);
        setError(null);
      } else {
        // @TODO: handle HTTP errors
        if (status === 401) {
          setError({message: data.message});

          void logout();
        } else {
          const userMessage = data.userMessage;
          const serverMessage = data.message || data;
          const errorMessage = userMessage
            ? {
                userMessage,
                message: serverMessage,
              }
            : {message: serverMessage};
          setError(errorMessage);
        }
      }
    } catch (e: any) {
      setError({message: e});
    } finally {
      setLoading(false);
    }
  }, [
    body,
    isAuthenticated,
    loading,
    logout,
    query,
    req,
    responseParser,
    token,
    url,
  ]);

  const runRequest = useCallback(() => {
    setLoading(true);
  }, []);

  useEffect(() => {
    if (loading) {
      void request();
    }
  }, [request, loading]);

  if (autoRun) {
    return {
      loading,
      response,
      error,
    };
  } else {
    return {
      loading,
      response,
      error,
      run: runRequest,
    };
  }
};
