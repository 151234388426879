import React from 'react';
import {Navigate, Outlet} from 'react-router-dom';

import {useUserContext} from '@/contexts/user';
import {useQueryString} from '@/hooks/queryString';
import {AuthRoutes} from '@/navigation/routes';

const Unauthenticated = () => {
  const {isAuthenticated} = useUserContext();
  const qsEventID = useQueryString('event_id');

  if (isAuthenticated) {
    if (qsEventID) {
      const redirectURI = `${AuthRoutes.INVITATION_REGISTER.replace(
        ':id',
        qsEventID,
      )}?event_id=${qsEventID}`;
      return <Navigate to={redirectURI} replace />;
    }
    return <Navigate to={AuthRoutes.BOOKINGS} replace />;
  } else {
    return <Outlet />;
  }
};

export default Unauthenticated;
