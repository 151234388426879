import {useMemo} from 'react';

import {SxProps} from '@mui/material';

import {IUseStyle} from './type';

export const useStyle = (): IUseStyle => {
  const detailContent: SxProps = useMemo(
    (): SxProps => ({
      flexDirection: 'column',
      display: 'flex',
      rowGap: '30px',
    }),
    [],
  );

  return {
    detailContent,
  };
};
