import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  ReactNode,
} from 'react';

import {
  Box,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import {useTranslations} from '@vidiemme/react-i18n';

import {Button} from '@/atoms/Button';
import {useToast} from '@/hooks/toast';
import {useNavigation} from '@/navigation/useNavigation';
import {useGetCategories} from '@/store/categories/hooks';
import {ServerLocation} from '@/store/locations';
import {useGetLocations} from '@/store/locations/hooks';
import {Modal} from '@/templates/Modal';

import {useStyle} from './style';

const EventTypeToCreate = () => {
  const {goToPreviousPage, goToCreateEvent} = useNavigation();
  const {t} = useTranslations();
  const {showErrorToast} = useToast();
  const {
    modalContainerBox: modalContainerBoxStyle,
    title: titleStyle,
    text: textStyle,
    location: locationStyle,
    category: categoryStyle,
    buttonsBox: buttonsBoxStyle,
    modalButton: modalButtonStyle,
  } = useStyle();

  const [locationId, setLocationId] = useState(-1);
  const [categoryId, setCategoryId] = useState(-1);

  let {
    loading: loadingLocations,
    error: errorLocations,
    response: responseLocations,
  } = useGetLocations();

  const {
    loading: loadingCategories,
    error: errorCategories,
    response: responseCategories,
    run: runGetCategories,
  } = useGetCategories(locationId, false);

  const locationsSorted = useMemo((): ReactNode => {
    if (responseLocations) {
      return responseLocations
        .sort((a: ServerLocation, b: ServerLocation) => {
          return a.name.localeCompare(b.name);
        })
        .map(({id, name}: {id: number; name: string}) => (
          <MenuItem key={id} value={id}>
            {name}
          </MenuItem>
        ));
    }
    return [];
  }, [responseLocations]);

  const locationHandler = useCallback(
    (event: any) => {
      setLocationId(event.target.value);
      /* Reset category seletected only if is not present in location categories list */
      if (responseLocations && categoryId > -1) {
        const locationSelected = responseLocations.find(
          location => location.id === event.target.value,
        );
        const isCategorySelectedFound = locationSelected?.categories.find(
          el => el.id === categoryId,
        );
        if (!isCategorySelectedFound) {
          setCategoryId(-1);
        }
      } else {
        setCategoryId(-1);
      }
    },
    [categoryId, responseLocations],
  );

  useEffect(() => {
    if (locationId > -1 && runGetCategories) {
      runGetCategories();
    }
  }, [locationId, runGetCategories]);

  const categoryHandler = useCallback(
    (event: any) => setCategoryId(event.target.value),
    [],
  );

  const submitButtonHandler = useCallback(() => {
    const locationSelected = responseLocations?.find(
      location => location.id === locationId,
    );
    const categorySelected = responseCategories?.find(
      category => category.id === categoryId,
    );
    if (locationSelected && categorySelected) {
      goToCreateEvent({
        location: locationSelected,
        category: categorySelected,
      });
    }
  }, [
    categoryId,
    goToCreateEvent,
    locationId,
    responseCategories,
    responseLocations,
  ]);

  if (responseLocations) {
    return (
      <Modal closeModalHandler={goToPreviousPage}>
        <Box sx={modalContainerBoxStyle}>
          <Typography variant="titleAktive1" sx={titleStyle}>
            {t('EVENT_TYPE_TO_CREATE.TITLE')}
          </Typography>
          <Typography variant="bodyRegular" sx={textStyle}>
            {t('EVENT_TYPE_TO_CREATE.TEXT')}
          </Typography>

          <FormControl sx={locationStyle}>
            <InputLabel id="locationSelectLabel">
              {t('EVENT_TYPE_TO_CREATE.LOCATION_TITLE')}
            </InputLabel>
            <Select
              labelId="locationSelectLabel"
              id="locationSelect"
              value={locationId === -1 ? '' : locationId}
              label={t('EVENT_TYPE_TO_CREATE.LOCATION_TITLE')}
              onChange={locationHandler}
              disabled={loadingLocations || !responseLocations}>
              {locationsSorted}
            </Select>
          </FormControl>

          <FormControl sx={categoryStyle}>
            <InputLabel id="categorySelectLabel">
              {t('EVENT_TYPE_TO_CREATE.CATEGORY_TITLE')}
            </InputLabel>
            <Select
              labelId="categorySelectLabel"
              id="categorySelect"
              value={categoryId === -1 ? '' : categoryId}
              label={t('EVENT_TYPE_TO_CREATE.CATEGORY_TITLE')}
              onChange={categoryHandler}
              disabled={loadingCategories || !responseCategories}>
              {responseCategories?.map(
                ({id, name}: {id: number; name: string}) => (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                ),
              )}
            </Select>
          </FormControl>
          <Box sx={buttonsBoxStyle}>
            <Button
              variant={'secondary'}
              color={'default'}
              onClick={goToPreviousPage}
              sx={modalButtonStyle}>
              {t('EVENT_TYPE_TO_CREATE.BUTTON_ONE_TEXT')}
            </Button>
            <Button
              variant={'primary'}
              color={'default'}
              onClick={submitButtonHandler}
              disabled={
                loadingLocations ||
                loadingCategories ||
                locationId === -1 ||
                categoryId === -1
              }
              loading={false} //TODO: develop it
              sx={modalButtonStyle}>
              {t('EVENT_TYPE_TO_CREATE.BUTTON_TWO_TEXT')}
            </Button>
          </Box>
        </Box>
      </Modal>
    );
  }

  if (errorLocations || errorCategories) {
    showErrorToast({message: t('RESPONSE_ERROR_MESSAGE.MESSAGE')});
  }
  return null;
};
export default EventTypeToCreate;
