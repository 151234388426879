import {useMemo} from 'react';

import {SxProps} from '@mui/material';

import {IPropsStyle} from '@/pages/LoginExternal/type';
import {theme} from '@/themes/default';

export const useStyle = (): IPropsStyle => {
  const loginExternalContainer: SxProps = useMemo(
    (): SxProps => ({
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      overflowY: 'scroll',
      overflowX: 'hidden',
      alignItems: 'center',
      minHeight: '100vh',
    }),
    [],
  );

  const loginExternalMainBox: SxProps = useMemo(
    (): SxProps => ({
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      [theme.breakpoints.up('md')]: {
        width: 'calc(100%/3);', //TODO: da cambiare una volta che verrà aggiunto il quarto brand
      },
    }),
    [],
  );

  const loginExternalTitleBox: SxProps = useMemo(
    (): SxProps => ({
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: '32px',
      marginBottom: '64px',
      svg: {
        height: '22px',
        marginBottom: '8px',
      },
    }),
    [],
  );

  const loginExternalTitleTypography: SxProps = useMemo(
    (): SxProps => ({marginBottom: '20px'}),
    [],
  );

  const loginExternalTextBox: SxProps = useMemo(
    (): SxProps => ({
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: '40px',
      textAlign: 'justify',
    }),
    [],
  );

  const loginExternalFormBox: SxProps = useMemo(
    (): SxProps => ({
      marginBottom: '40px',
    }),
    [],
  );

  const loginExternalLinkBox: SxProps = useMemo(
    (): SxProps => ({
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: '48px',
    }),
    [],
  );

  const switchLoginBox: SxProps = useMemo(
    (): SxProps => ({
      display: 'flex',
      justifyContent: 'center',
    }),
    [],
  );

  const privacyBox: SxProps = useMemo(
    (): SxProps => ({
      marginTop: '16px',
      marginBottom: '32px',
      display: 'flex',
      justifyContent: 'center',
      a: {
        span: {
          marginX: '12px',
        },
      },
    }),
    [],
  );

  return {
    loginExternalMainBox,
    loginExternalTitleBox,
    loginExternalTextBox,
    loginExternalFormBox,
    loginExternalLinkBox,
    loginExternalContainer,
    switchLoginBox,
    loginExternalTitleTypography,
    privacyBox,
  };
};
