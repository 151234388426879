import {useMemo} from 'react';

import {SxProps} from '@mui/material';

import {IUseStyle} from '@/pages/EventSlotBookingDelete/type';
import {theme} from '@/themes/default';

export const useStyle = (): IUseStyle => {
  const deleteBookingBoxContainer: SxProps = useMemo(
    (): SxProps => ({
      display: 'flex',
      flexDirection: 'column',
      width: 'calc(100vw - 100px)', // 110 = 20*2 padding + 30*2 boxshadow
      [theme.breakpoints.up('md')]: {
        width: '450px',
      },
    }),
    [],
  );

  const deleteBookingTitleBox: SxProps = useMemo(
    (): SxProps => ({display: 'flex', flexDirection: 'column'}),
    [],
  );

  const deleteBookingTitle: SxProps = useMemo(
    (): SxProps => ({marginBottom: '16px'}),
    [],
  );

  const deleteBookingDetailsBox: SxProps = useMemo(
    (): SxProps => ({
      width: '100%',
      margin: '40px 0',
      padding: '16px',
      backgroundColor: theme.palette.gray1,
      display: 'flex',
      flexDirection: 'column',
    }),
    [],
  );

  const deleteBookingButtonsBox: SxProps = useMemo(
    (): SxProps => ({
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      rowGap: '10px',
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        rowGap: '0px',
      },
    }),
    [],
  );

  const deleteBookingButton: SxProps = useMemo(
    (): SxProps => ({
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '50%',
      },
    }),
    [],
  );

  const eventDetailsBox: SxProps = useMemo(
    (): SxProps => ({
      display: 'flex',
      flexDirection: 'column',
      borderTop: `1px solid ${theme.palette.gray2}`,
      paddingTop: '10px',
      marginTop: '10px',
    }),
    [],
  );

  return {
    deleteBookingBoxContainer,
    deleteBookingTitleBox,
    deleteBookingTitle,
    deleteBookingDetailsBox,
    deleteBookingButtonsBox,
    deleteBookingButton,
    eventDetailsBox,
  };
};
