import {useMemo} from 'react';

import {SxProps} from '@mui/material';

import {IUseStyle} from '@/pages/Events/EventLocation/Event/interfaces';
import {theme} from '@/themes/default';

const useStyle = (): IUseStyle => {
  const eventBoxContainer: SxProps = useMemo(
    (): SxProps => ({
      height: '100%',
      padding: '25px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      [theme.breakpoints.up('md')]: {
        padding: '40px',
        width: '400px',
      },
      [theme.breakpoints.up('lg')]: {
        width: '552px',
      },
    }),
    [],
  );

  const eventTitleBoxContainer: SxProps = useMemo(
    (): SxProps => ({
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: '16px',
    }),
    [],
  );

  const eventTitleBox: SxProps = useMemo(
    (): SxProps => ({display: 'flex', flexDirection: 'column'}),
    [],
  );

  const eventName: SxProps = useMemo(
    (): SxProps => ({
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: '4',
      WebkitBoxOrient: 'vertical',
    }),
    [],
  );

  const eventDateBox: SxProps = useMemo(
    (): SxProps => ({
      marginBottom: '40px',
      color: theme.palette.black,
    }),
    [],
  );

  const eventButtonsBox: SxProps = useMemo(
    (): SxProps => ({
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      rowGap: '10px',
      alignItems: 'center',
      [theme.breakpoints.up('lg')]: {
        rowGap: '0px',
        flexDirection: 'row',
      },
    }),
    [],
  );

  const eventActionsContainer: SxProps = useMemo(
    () => ({
      textAlign: 'end',
    }),
    [],
  );

  const eventButton: SxProps = useMemo(
    (): SxProps => ({height: '34px', width: '226px'}),
    [],
  );

  return {
    eventBoxContainer,
    eventTitleBoxContainer,
    eventTitleBox,
    eventName,
    eventDateBox,
    eventButtonsBox,
    eventButton,
    eventActionsContainer,
  };
};

export default useStyle;
