import {useMemo} from 'react';

import {SxProps} from '@mui/material';

import {theme} from '@/themes/default';

import {IPropsStyle} from './type';

export const useStyle = (): IPropsStyle => {
  const modalContainerBox: SxProps = useMemo(
    (): SxProps => ({
      width: '100%',
      height: 'auto',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      gap: '24px',
      [theme.breakpoints.up('md')]: {
        width: '450px',
      },
    }),
    [],
  );

  const modalFilterBox: SxProps = useMemo(
    (): SxProps => ({
      display: 'flex',
      flexDirection: 'column',
      borderBottom: `1px solid ${theme.palette.gray2}`,
      paddingBottom: '24px',
    }),
    [],
  );

  const modalChecklistBox: SxProps = useMemo(
    (): SxProps => ({
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.up('md')]: {
        padding: '12px 0',
      },
    }),
    [],
  );

  const searchFilterBox: SxProps = useMemo(
    (): SxProps => ({
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    }),
    [],
  );

  const filterButton: SxProps = useMemo(
    (): SxProps => ({
      width: '34px',
      padding: '0',
      marginLeft: '8px',
    }),
    [],
  );

  const loadMoreButtonBox: SxProps = useMemo(
    (): SxProps => ({
      marginTop: '16px',
    }),
    [],
  );

  return {
    modalContainerBox,
    modalFilterBox,
    modalChecklistBox,
    searchFilterBox,
    filterButton,
    loadMoreButtonBox,
  };
};
