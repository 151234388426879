import {Backdrop, Box} from '@mui/material';

import {useStyle} from '@/templates/Modal/style';
import {IProps} from '@/templates/Modal/type';

const Modal = ({
  children,
  backdropVisible = true,
  closeModalHandler,
}: IProps) => {
  const {modalBoxContainer, modalBox, backdropStyle} = useStyle({
    backdropVisible,
  });

  return (
    <>
      <Backdrop open={true} sx={backdropStyle} onClick={closeModalHandler} />
      <Box sx={modalBoxContainer}>
        <Box sx={modalBox}>{children}</Box>
      </Box>
    </>
  );
};
export default Modal;
