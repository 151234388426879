import {FunctionComponent, useCallback} from 'react';
import {useMemo} from 'react';

import {useTranslations} from '@vidiemme/react-i18n';

import {Button} from '@/atoms/Button';
import {useUserContext} from '@/contexts/user';
import {AuthRoutes} from '@/navigation/routes';
import {useNavigation} from '@/navigation/useNavigation';
import {useOnPageFocused} from '@/navigation/useOnPageFocused';
import {LoadingMessage} from '@/organisms/LoadingMessage';
import {MessagePage} from '@/organisms/MessagePage';
import {ResponseErrorMessage} from '@/organisms/ResponseErrorMessage';
import {EventLocation} from '@/pages/Events/EventLocation';
import {useGetEvents} from '@/store/events';
import {ROLES} from '@/store/roles';
import {Base} from '@/templates/Base';

const Events: FunctionComponent = () => {
  const {t} = useTranslations();
  const {goToEventTypeToCreate} = useNavigation();
  const {loading, error, response, run} = useGetEvents(false);
  const {role} = useUserContext();

  const createEventButtonHandler = goToEventTypeToCreate;

  const onFocus = useCallback(() => {
    if (run) {
      run();
    }
  }, [run]);

  useOnPageFocused(AuthRoutes.EVENTS, onFocus);

  const buttons: JSX.Element = useMemo(() => {
    if (role === ROLES.ADMIN) {
      return (
        <Button
          variant={'primaryBig'}
          color="default"
          onClick={createEventButtonHandler}>
          {t('EVENT_MANAGEMENT.BUTTON_TEXT')}
        </Button>
      );
    }

    return <></>;
  }, [createEventButtonHandler, role, t]);

  const body = useMemo(() => {
    if (error) {
      return <ResponseErrorMessage showToast />;
    }

    if (response) {
      if (Object.keys(response).length === 0) {
        return (
          <MessagePage
            title={t('EVENT_MANAGEMENT.NO_TITLE')}
            message={t('EVENT_MANAGEMENT.NO_MESSAGE')}></MessagePage>
        );
      }

      return Object.keys(response!).map(locationKey => (
        <EventLocation key={locationKey} events={response[locationKey]} />
      ));
    }

    if (loading) {
      return <LoadingMessage />;
    }

    return null;
  }, [error, loading, response, t]);

  return (
    <Base
      title={t('EVENT_MANAGEMENT.TITLE')}
      buttons={buttons}
      disableGutters={true}>
      {body}
    </Base>
  );
};

export default Events;
