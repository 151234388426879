import React from 'react';
import {Navigate, Outlet} from 'react-router-dom';

import {useUserContext} from '@/contexts/user';
import {AuthRoutes} from '@/navigation/routes';
import {ROLES} from '@/store/roles';

const AtLeastOperator = () => {
  const {role} = useUserContext();

  switch (role) {
    case ROLES.ADMIN:
    case ROLES.OPERATOR:
      return <Outlet />;
    default:
      return <Navigate to={AuthRoutes.BOOKINGS} replace />;
  }
};

export default AtLeastOperator;
