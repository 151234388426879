import {createTheme} from '@mui/material/styles';

import {FontFace} from './fonts';

const fontFamily = [
  'Aktiv Grotesk',
  'Versace',
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  'Roboto',
  'Oxygen',
  'Oxygen-Sans',
  'Ubuntu',
  'Cantarell',
  '"Fira Sans"',
  '"Droid Sans"',
  '"Helvetica Neue"',
  'Helvetica',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
].join(',');

const basicTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1000,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    mode: 'light',
    white: '#ffffff',
    gray1: '#F4F4F4',
    gray2: '#E0E0E0',
    gray3: '#BDBDBD',
    gray4: '#757575',
    black: '#000000',
    errorRed: '#BF2D2D',
    successGreen: '#14A470',
    black45: '#EAE6E1',
  },
});

export const mobilePadding = '20px';
export const desktopPadding = '24px';
export const theme = createTheme(basicTheme, {
  components: {
    MuiCssBaseline: {
      styleOverrides: `${FontFace} *, * > * {font-family: ${fontFamily};}, a {text-decoration: none;}`,
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: mobilePadding,
          paddingRight: mobilePadding,
          [basicTheme.breakpoints.up('sm')]: {
            paddingLeft: mobilePadding,
            paddingRight: mobilePadding,
          },
          [basicTheme.breakpoints.up('md')]: {
            paddingLeft: desktopPadding,
            paddingRight: desktopPadding,
          },
          '&.MuiContainer-disableGutters': {
            paddingLeft: 0,
            paddingRight: 0,
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: fontFamily,
        },
      },
      variants: [
        {
          props: {variant: 'titleVersace1'},
          style: {
            color: basicTheme.palette.black,
            fontFamily: 'Versace',
            fontSize: '42px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '120%',
            textTransform: 'uppercase',
            [basicTheme.breakpoints.down('md')]: {
              fontSize: '32px',
            },
          },
        },
        {
          props: {variant: 'titleAktive1'},
          style: {
            color: basicTheme.palette.black,
            fontFamily: 'AktivGrotesk',
            fontSize: '28px',
            fontStyle: 'normal',
            fontWeight: '700',
            lineHeight: '120%',
            textTransform: 'uppercase',
            [basicTheme.breakpoints.down('md')]: {
              fontSize: '24px',
            },
          },
        },
        {
          props: {variant: 'titleVersace2'},
          style: {
            color: basicTheme.palette.black,
            fontFamily: 'Versace',
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '120%',
            textTransform: 'uppercase',
          },
        },
        {
          props: {variant: 'titleAktive2'},
          style: {
            color: basicTheme.palette.black,
            fontFamily: 'AktivGrotesk',
            fontSize: '20px',
            fontStyle: 'normal',
            fontWeight: '700',
            lineHeight: '120%',
            textTransform: 'uppercase',
          },
        },
        {
          props: {variant: 'titleAktive3'},
          style: {
            color: basicTheme.palette.black,
            fontFamily: 'AktivGrotesk',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: '700',
            lineHeight: '120%',
            textTransform: 'uppercase',
          },
        },
        {
          props: {variant: 'bodyRegular'},
          style: {
            color: basicTheme.palette.black,
            fontFamily: 'AktivGrotesk',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '150%',
          },
        },
        {
          props: {variant: 'bodyBold'},
          style: {
            color: basicTheme.palette.black,
            fontFamily: 'AktivGrotesk',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '700',
            lineHeight: '150%',
          },
        },
        {
          props: {variant: 'bodyRegularLink'},
          style: {
            color: basicTheme.palette.black,
            fontFamily: 'AktivGrotesk',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '150%',
            textDecorationLine: 'underline',
          },
        },
        {
          props: {variant: 'body100Regular'},
          style: {
            color: basicTheme.palette.black,
            fontFamily: 'AktivGrotesk',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '100%',
          },
        },
        {
          props: {variant: 'body100Bold'},
          style: {
            color: basicTheme.palette.black,
            fontFamily: 'AktivGrotesk',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '700',
            lineHeight: '100%',
          },
        },
        {
          props: {variant: 'bodyRegularSmall'},
          style: {
            color: basicTheme.palette.black,
            fontFamily: 'AktivGrotesk',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '150%',
          },
        },
        {
          props: {variant: 'bodyRegularItalicSmall'},
          style: {
            color: basicTheme.palette.black,
            fontFamily: 'AktivGrotesk',
            fontSize: '12px',
            fontStyle: 'italic',
            fontWeight: '400',
            lineHeight: '150%',
          },
        },
        {
          props: {variant: 'bodyBoldSmall'},
          style: {
            color: basicTheme.palette.black,
            fontFamily: 'AktivGrotesk',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: '700',
            lineHeight: '150%',
          },
        },
        {
          props: {variant: 'bodyRegularXSmall'},
          style: {
            color: basicTheme.palette.black,
            fontFamily: 'AktivGrotesk',
            fontSize: '10px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '100%',
          },
        },
        {
          props: {variant: 'bodyBoldXSmall'},
          style: {
            color: basicTheme.palette.black,
            fontFamily: 'AktivGrotesk',
            fontSize: '10px',
            fontStyle: 'normal',
            fontWeight: '700',
            lineHeight: '100%',
          },
        },
        {
          props: {variant: 'buttonNav'},
          style: {
            color: basicTheme.palette.black,
            fontFamily: 'AktivGrotesk',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '700',
            lineHeight: '150%',
            textTransform: 'uppercase',
          },
        },
        {
          props: {variant: 'pretitle'},
          style: {
            color: basicTheme.palette.black,
            fontFamily: 'AktivGrotesk',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '150%',
            textTransform: 'uppercase',
          },
        },
      ],
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: () => ({
          display: 'inline-flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '8px',
          borderRadius: '0px',
          minWidth: '0px',
        }),
      },
      variants: [
        {
          props: {variant: 'primaryBig', color: 'default'},
          style: {
            //button
            backgroundColor: basicTheme.palette.black,
            display: 'inline-flex',
            height: '54px',
            padding: '22px 44px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '8px',
            flexShrink: '0',
            //button label
            color: basicTheme.palette.white,
            leadingTrim: 'both',
            textEdge: 'cap',
            fontFamily: 'AktivGrotesk',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '700',
            lineHeight: '150%',
            textTransform: 'uppercase',
            ':hover': {
              //button
              backgroundColor: basicTheme.palette.white,
              border: `1px solid ${basicTheme.palette.black}`,
              //button label
              color: basicTheme.palette.black,
            },
            ':active': {
              //button
              backgroundColor: basicTheme.palette.white,
              border: `1px solid ${basicTheme.palette.black}`,
              boxShadow: `0px 0px 0px 2px ${basicTheme.palette.gray4}`,
              //button label
              color: basicTheme.palette.black,
            },
            ':focus': {
              //button
              boxShadow: `inset 0px 0px 0px 2px ${basicTheme.palette.white}`,
              border: `1px solid ${basicTheme.palette.black}`,
            },
            ':disabled': {
              //button
              backgroundColor: basicTheme.palette.gray2,

              //button label
              color: basicTheme.palette.gray4,
            },
          },
        },
        {
          props: {variant: 'primary', color: 'default'},
          style: {
            //button
            backgroundColor: basicTheme.palette.black,
            display: 'inline-flex',
            height: '34px',
            padding: '10px 44px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '8px',
            flexShrink: '0',
            //button label
            color: basicTheme.palette.white,
            leadingTrim: 'both',
            textEdge: 'cap',
            fontFamily: 'AktivGrotesk',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '700',
            lineHeight: '150%',
            textTransform: 'uppercase',
            ':hover': {
              //button
              backgroundColor: basicTheme.palette.white,
              border: `1px solid ${basicTheme.palette.black}`,
              //button label
              color: basicTheme.palette.black,
            },
            ':active': {
              //button
              backgroundColor: basicTheme.palette.white,
              border: `1px solid ${basicTheme.palette.black}`,
              boxShadow: `0px 0px 0px 2px ${basicTheme.palette.gray4}`,
              //button label
              color: basicTheme.palette.black,
            },
            ':focus': {
              //button
              boxShadow: `inset 0px 0px 0px 2px ${basicTheme.palette.white}`,
              border: `1px solid ${basicTheme.palette.black}`,
            },
            ':disabled': {
              //button
              backgroundColor: basicTheme.palette.gray2,
              //button label
              color: basicTheme.palette.gray4,
            },
          },
        },
        {
          props: {variant: 'primarySmall', color: 'default'},
          style: {
            //button
            backgroundColor: basicTheme.palette.black,
            display: 'inline-flex',
            padding: '8px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '8px',
            //button label
            color: basicTheme.palette.white,
            width: '24px',
            height: '24px',
            ':hover': {
              //button
              backgroundColor: basicTheme.palette.white,
              border: `1px solid ${basicTheme.palette.black}`,
              //button label
              color: basicTheme.palette.black,
            },
            ':active': {
              //button
              backgroundColor: basicTheme.palette.white,
              border: `1px solid ${basicTheme.palette.black}`,
              boxShadow: `0px 0px 0px 2px ${basicTheme.palette.gray4}`,
              //button label
              color: basicTheme.palette.black,
            },
            ':focus': {
              //button
              display: 'inline-flex',
              boxShadow: `inset 0px 0px 0px 2px ${basicTheme.palette.white}`,
              border: `1px solid ${basicTheme.palette.black}`,
            },
            ':disabled': {
              //button
              backgroundColor: basicTheme.palette.gray2,
              //button label
              color: basicTheme.palette.gray4,
            },
          },
        },
        {
          props: {variant: 'secondaryBig', color: 'default'},
          style: {
            //button
            backgroundColor: basicTheme.palette.white,
            display: 'inline-flex',
            height: '54px',
            padding: '22px 44px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '8px',
            flexShrink: '0',
            border: `1px solid ${basicTheme.palette.black}`,
            //button label
            color: basicTheme.palette.black,
            leadingTrim: 'both',
            textEdge: 'cap',
            fontFamily: 'AktivGrotesk',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '700',
            lineHeight: '150%',
            textTransform: 'uppercase',
            ':hover': {
              //button
              backgroundColor: basicTheme.palette.black,
              //button label
              color: basicTheme.palette.white,
            },
            ':active': {
              //button
              backgroundColor: basicTheme.palette.black,
              boxShadow: `0px 0px 0px 2px ${basicTheme.palette.gray4}`,
              //button label
              color: basicTheme.palette.white,
            },
            ':focus': {
              //button
              border: '3px solid black',
              borderStyle: 'double',
            },
            ':disabled': {
              //button
              backgroundColor: basicTheme.palette.gray2,
              border: '1.5px solid #757575',
              //button label
              color: basicTheme.palette.gray4,
            },
          },
        },
        {
          props: {variant: 'secondary', color: 'default'},
          style: {
            //button
            backgroundColor: basicTheme.palette.white,
            display: 'inline-flex',
            height: '34px',
            padding: '10px 44px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '8px',
            flexShrink: '0',
            border: `1px solid ${basicTheme.palette.black}`,
            //button label
            color: basicTheme.palette.black,
            leadingTrim: 'both',
            textEdge: 'cap',
            fontFamily: 'AktivGrotesk',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '700',
            lineHeight: '150%',
            textTransform: 'uppercase',
            ':hover': {
              //button
              backgroundColor: basicTheme.palette.black,
              //button label
              color: basicTheme.palette.white,
            },
            ':active': {
              //button
              backgroundColor: basicTheme.palette.black,
              boxShadow: `0px 0px 0px 2px ${basicTheme.palette.gray4}`,
              //button label
              color: basicTheme.palette.white,
            },
            ':focus': {
              //button
              border: '3px solid black',
              borderStyle: 'double',
            },
            ':disabled': {
              //button
              backgroundColor: basicTheme.palette.gray2,
              border: '1.5px solid #757575',
              //button label
              color: basicTheme.palette.gray4,
            },
          },
        },
      ],
    },
    MuiCircularProgress: {
      variants: [
        {
          props: {color: 'primary'},
          style: {
            color: basicTheme.palette.black,
          },
        },
      ],
    },
    MuiFormControl: {
      styleOverrides: {
        root: () => ({
          backgroundColor: basicTheme.palette.white,
          '.MuiInputBase-input': {
            paddingTop: '13px',
            paddingBottom: '13px',
          },
        }),
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: () => ({
          backgroundColor: basicTheme.palette.gray1,
          '&.MuiAccordion-root.MuiPaper-root': {
            borderRadius: 0,
            boxShadow: 'none',
          },
          '.MuiAccordionSummary-root': {
            paddingLeft: '20px',
            paddingRight: '20px',
          },
          '.MuiAccordionSummary-content': {
            marginTop: '20px',
            marginBottom: '20px',
          },
        }),
      },
    },
    /*MuiSelect: {
      styleOverrides: {
        root: {
          '.MuiInputBase-input': {
            fontFamily: 'AktivGrotesk',
            fontSize: '14px',
          },
        },
      },
    },*/
  },
});
