import {useMemo} from 'react';

import {SxProps} from '@mui/material';

import {IUseStyle} from './type';

export const useStyle = (): IUseStyle => {
  const boxContainer: SxProps = useMemo(
    (): SxProps => ({
      display: 'flex',
      flexDirection: 'column',
      rowGap: '40px',
      alignItems: 'center',
    }),
    [],
  );

  return {
    boxContainer,
  };
};
