import {Box, Typography} from '@mui/material';

import {useStyle} from '@/organisms/MessagePage/style';
import {IProps} from '@/organisms/MessagePage/type';

const MessagePage = ({title, message}: IProps) => {
  const {messagePageBox, messagePageMessage} = useStyle();

  return (
    <Box sx={messagePageBox}>
      <Typography variant={'titleVersace1'}>{title}</Typography>
      <Typography variant={'bodyRegular'} sx={messagePageMessage}>
        {message}
      </Typography>
    </Box>
  );
};

export default MessagePage;
