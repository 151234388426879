import {FunctionComponent, useCallback, useMemo} from 'react';

import {Box, Stack, Typography} from '@mui/material';
import {useTranslations} from '@vidiemme/react-i18n';

import {Button} from '@/atoms/Button';
import {IconSVG} from '@/atoms/IconSVG';
import useDate from '@/hooks/date';
import {useNavigation} from '@/navigation/useNavigation';
import {IProps} from '@/pages/Invitations/InvitationsLocation/Invitation/definitions';
import {useStyle} from '@/pages/Invitations/InvitationsLocation/Invitation/style';

const Invitation: FunctionComponent<IProps> = ({invitation}: IProps) => {
  const {t} = useTranslations();
  const {goToInvitation} = useNavigation();

  const {
    invitationBoxContainer,
    invitationTitleBoxContainer,
    invitationTitleBox,
    invitationTitle,
    invitationDateBox,
    invitationLocationBox,
    invitationButton,
  } = useStyle();

  const {getEventPeriodString} = useDate();

  const viewCalendarButtonHandler = useCallback(() => {
    return goToInvitation(invitation.eventId);
  }, [goToInvitation, invitation.eventId]);

  /* Show always this period with location timezone */
  const printPeriod = useMemo(() => {
    const locationTimezone = invitation.location.timezone;
    const periodLocation = getEventPeriodString(
      invitation.startDate,
      invitation.endDate,
      locationTimezone,
    );

    return (
      <Stack flexDirection="column">
        <Typography variant="bodyRegular">
          {periodLocation} {t('TIMEZONE.LOCATION')}
        </Typography>
      </Stack>
    );
  }, [
    getEventPeriodString,
    invitation.endDate,
    invitation.location.timezone,
    invitation.startDate,
    t,
  ]);

  return (
    <Box sx={invitationBoxContainer}>
      <Box sx={invitationTitleBoxContainer}>
        <Box sx={invitationTitleBox}>
          <Typography variant={'pretitle'}>
            {invitation.location.name}
          </Typography>
          <Typography variant={'titleVersace2'} sx={invitationTitle}>
            {invitation.name}
          </Typography>
        </Box>
      </Box>
      <Box sx={invitationDateBox}>{printPeriod}</Box>
      <Box sx={invitationLocationBox}>
        <IconSVG icon={'location-pin'} size={20} />
        <Typography variant={'bodyRegular'}>
          {invitation.location.full_address}
        </Typography>
      </Box>
      <Button
        variant={'primary'}
        color={'default'}
        sx={invitationButton}
        onClick={viewCalendarButtonHandler}>
        {t('INVITATIONS.VIEW_CALENDAR')}
      </Button>
    </Box>
  );
};

export default Invitation;
