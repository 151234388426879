import {useCallback} from 'react';
import {useParams} from 'react-router-dom';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {Box, Typography, Stack} from '@mui/material';
import {useTranslations} from '@vidiemme/react-i18n';

import {Button} from '@/atoms/Button';
import {IconSVG} from '@/atoms/IconSVG';
import {PrintDate} from '@/atoms/PrintDate';
import {useToast} from '@/hooks/toast';
import {useNavigation} from '@/navigation/useNavigation';
import {LoadingMessage} from '@/organisms/LoadingMessage';
import {Booking} from '@/store/bookings';
import {useGetSlot} from '@/store/slots';
import {SideModal} from '@/templates/SideModal';

import {useStyle} from './style';
import {EventProps} from './type';

// Main component
const EventSlotBookedPeople = () => {
  // Hooks for translations, navigation, date formatting and fetching slot data
  const {t} = useTranslations();
  const {showErrorToast} = useToast();
  const {goToPreviousPage, goToEventBookingDelete} = useNavigation();

  // Styles for the component
  const {
    modalContainerBox,
    modalEventBox,
    modalNoteBox,
    modalNoteTitleBox,
    modalGuestBox,
    modalGuestInfoBox,
    deleteButton,
  } = useStyle();

  // Get eventId and slotId from URL parameters
  const {id: eventId, slotId} = useParams();

  // Fetch slot data
  const {loading, error, response} = useGetSlot(slotId ? parseInt(slotId) : -1);

  // Define a callback to open delete confirmation modal
  const deleteBookingHandler = useCallback(
    (
      {id: bookingId, user, note}: Partial<Booking>,
      {name: eventName, timezone, startTime, endTime}: EventProps,
    ): void => {
      if (bookingId && eventId && slotId) {
        goToEventBookingDelete(parseInt(eventId), parseInt(slotId), bookingId, {
          state: {user, note, eventName, startTime, endTime, timezone},
        });
      }
    },
    [eventId, slotId, goToEventBookingDelete],
  );

  // Show loading message while fetching data
  if (loading) {
    return <LoadingMessage />;
  }

  // Show error message if there is an error while fetching data
  if (error) {
    showErrorToast({message: t('RESPONSE_ERROR_MESSAGE.MESSAGE')});
    return <></>;
  }

  // Destructure response data
  const {
    eventDay: {
      event: {name, location},
    },
    startTime,
    endTime,
    bookedSpots,
    slotLimit,
    bookings,
  } = response!;

  // Prepare data for the modal
  const modalTitle = name;

  // Render the component
  return (
    <SideModal
      title={t('EVENT_PEOPLE_BOOKED.TITLE')}
      subTitle={t('EVENT_PEOPLE_BOOKED.SUB_TITLE')}
      textButtonOne={t('EVENT_PEOPLE_BOOKED.CLOSE')}
      CTAButtonOne={goToPreviousPage}
      closeHandler={goToPreviousPage}>
      <Box sx={modalContainerBox}>
        <Box sx={modalEventBox}>
          <Typography variant={'titleVersace2'}>{modalTitle}</Typography>
          <PrintDate
            dateProps={{
              date: startTime,
              startHour: startTime,
              endHour: endTime,
              timezoneLocation: location.timezone,
            }}
            variant={'bodyRegular'}
          />
        </Box>
        <Box sx={modalNoteBox}>
          <Box sx={modalNoteTitleBox}>
            <IconSVG icon="user" size={20} />
            <Typography variant={'titleAktive3'}>
              {t('EVENT_SLOT_DETAIL.PEOPLE_BOOKED', {
                bookedSpots,
                slotLimit,
              })}
            </Typography>
          </Box>
        </Box>
        <Box>
          {bookings?.map(({id, user, note, invitedBy}) => {
            return (
              <Box key={id} sx={modalGuestBox}>
                <Box>
                  <Stack
                    sx={modalGuestInfoBox}
                    divider={<Typography variant={'bodyRegular'}>•</Typography>}
                    useFlexGap>
                    <Typography key={id} variant={'bodyBold'}>
                      {user?.name}
                    </Typography>
                    {user?.email && (
                      <Typography variant={'bodyRegular'}>
                        {user.email}
                      </Typography>
                    )}
                    {user?.phone_number && (
                      <Typography variant={'bodyRegular'}>
                        {user.phone_number}
                      </Typography>
                    )}
                  </Stack>
                  <Box>
                    <Typography key={id} variant={'bodyRegular'}>
                      {t('EVENT_PEOPLE_BOOKED.NOTE')} {note || '-'}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography key={id} variant={'bodyRegular'}>
                      {t('EVENT_PEOPLE_BOOKED.BOOKED_BY')}{' '}
                      {invitedBy?.name || '-'}
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <Button
                    sx={deleteButton}
                    variant={'secondary'}
                    color={'default'}
                    onClick={() =>
                      deleteBookingHandler(
                        {id, user, note},
                        {
                          name,
                          startTime,
                          endTime,
                          timezone: location.timezone,
                        },
                      )
                    }>
                    <DeleteOutlineIcon />
                  </Button>
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
    </SideModal>
  );
};

// Export the component
export default EventSlotBookedPeople;
