import {useCallback, useMemo} from 'react';

import type {
  InvitedGuests,
  ServerInvitedGuests,
} from '@/pages/InvitationGuest/type';
import {api, useApi} from '@/store/api';
import type {ServerBookingsByLocation} from '@/store/bookings';
import {normalizeBookingsByLocation} from '@/store/bookings/normalizers';
import {REALMS, useKeycloak} from '@/store/keycloak';
import {ROLES} from '@/store/roles';
import type {
  LoginFn,
  LoginSSOFn,
  LogoutParamsFn,
  PutUserOptions,
  ServerPutUserOptions,
  UseGetUserBookings,
  UseGetUsersMeGuest,
  UsePutUser,
  UsePutUserInvite,
  User,
} from '@/store/user';
import {UserToken} from '@/store/user';
import {normalizerUser} from '@/store/user/normalizer';

export const useUser = () => {
  const {getKeycloakSettings, getRedirectURL} = useKeycloak();

  const retrieveToken = useCallback(async () => {
    const accessToken = localStorage.getItem(UserToken.NAME);

    return Promise.resolve(accessToken);
  }, []);

  const storeToken = useCallback(async (token: string) => {
    localStorage.setItem(UserToken.NAME, token);

    return Promise.resolve();
  }, []);

  const eraseToken = useCallback(async () => {
    localStorage.removeItem(UserToken.NAME);

    return Promise.resolve();
  }, []);

  const storeRefreshToken = useCallback(async (token: string) => {
    localStorage.setItem(UserToken.REFRESH_TOKEN, token);

    return Promise.resolve();
  }, []);

  const eraseRefreshToken = useCallback(async () => {
    localStorage.removeItem(UserToken.REFRESH_TOKEN);

    return Promise.resolve();
  }, []);

  const retrieveRefreshToken = useCallback(async () => {
    const refreshToken = localStorage.getItem(UserToken.REFRESH_TOKEN);

    return Promise.resolve(refreshToken);
  }, []);

  const getKeycloakToken = useCallback(
    async (tokenUrl: string, body: string) => {
      try {
        const {ok, data} = await api(
          tokenUrl,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          },
          {
            body,
          },
        );

        if (ok) {
          const {access_token: accessToken, refresh_token: refreshToken} = data;
          void storeToken(accessToken);
          void storeRefreshToken(refreshToken);
          return accessToken;
        } else {
          throw data;
        }
      } catch (e) {
        // @TODO: Sentry
        console.error(e);
        throw e;
      }
    },
    [storeRefreshToken, storeToken],
  );

  const getKeycloakLogout = useCallback(
    async (logoutUrl: string, body: string) => {
      try {
        const {ok, data} = await api(
          logoutUrl,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          },
          {
            body,
          },
        );

        if (ok) {
          return;
        } else {
          throw data;
        }
      } catch (e) {
        // @TODO: Sentry
        console.error(e);
        throw e;
      }
    },
    [],
  );

  const login: LoginFn = useCallback(
    async (realm: REALMS, username: string, password: string) => {
      const keycloakSettings = getKeycloakSettings(realm);

      const body = [
        ['client_id', keycloakSettings.client_id].join('='),
        ['grant_type', keycloakSettings.grant_type].join('='),
        ['username', username].join('='),
        ['password', password].join('='),
      ].join('&');

      return await getKeycloakToken(keycloakSettings.URL_TOKEN!, body);
    },
    [getKeycloakSettings, getKeycloakToken],
  );

  const loginSSO: LoginSSOFn = useCallback(
    async (realm: REALMS, code: string, eventID?: number) => {
      const keycloakSettings = getKeycloakSettings(realm);

      const body = [
        ['client_id', keycloakSettings.client_id].join('='),
        ['grant_type', keycloakSettings.grant_type].join('='),
        ['code', code].join('='),
        ['redirect_uri', getRedirectURL(eventID)].join('='),
      ].join('&');

      return await getKeycloakToken(keycloakSettings.URL_TOKEN!, body);
    },
    [getKeycloakSettings, getKeycloakToken, getRedirectURL],
  );

  const logout: LogoutParamsFn = useCallback(
    async realm => {
      if (realm) {
        const keycloakSettings = getKeycloakSettings(realm);
        const refreshToken = await retrieveRefreshToken();
        if (refreshToken) {
          const body = [
            ['client_id', keycloakSettings.client_id].join('='),
            ['refresh_token', refreshToken].join('='),
          ].join('&');

          await getKeycloakLogout(keycloakSettings.URL_LOGOUT!, body);
          void eraseToken();
          void eraseRefreshToken();
          return Promise.resolve();
        }
      }
      void eraseToken();
      void eraseRefreshToken();
      return Promise.reject();
    },
    [
      eraseRefreshToken,
      eraseToken,
      getKeycloakLogout,
      getKeycloakSettings,
      retrieveRefreshToken,
    ],
  );

  const getUserInfo = useCallback(async (token: string): Promise<User> => {
    try {
      const {ok, data: serverUser} = await api('/api/v1/users/me', {}, {token});

      if (ok) {
        return normalizerUser(serverUser);
      }

      throw new Error('Something went wrong');
    } catch (error) {
      console.error(error);
      throw error;
    }
  }, []);

  const navigationItems = useCallback(
    (role: ROLES = ROLES.UNAUTHENTICATED): string[] => {
      switch (role) {
        case ROLES.ADMIN:
          return ['USERS', 'EVENTS', 'INVITATIONS', 'BOOKINGS'];
        case ROLES.OPERATOR:
          return ['EVENTS', 'INVITATIONS', 'BOOKINGS'];
        case ROLES.EMPLOYEE:
        case ROLES.GUEST:
          return ['INVITATIONS', 'BOOKINGS'];
        case ROLES.UNAUTHENTICATED:
        default:
          return ['LOGIN_EMPLOYEE', 'PRIVACY_POLICY'];
      }
    },
    [],
  );

  return {
    retrieveToken,
    login,
    loginSSO,
    logout,
    navigationItems,
    getUserInfo,
  };
};

export const useGetUserBookings: UseGetUserBookings = (
  {onlyFuture = false},
  autoRun = false,
) => {
  const options = useMemo(
    () => ({
      autoRun,
      query: {
        ...(onlyFuture ? {only_future: onlyFuture} : {}),
      },
    }),
    [autoRun, onlyFuture],
  );
  const {loading, error, response, run} = useApi<ServerBookingsByLocation>(
    '/api/v1/users/me/bookings',
    options,
  );

  return {
    loading,
    error,
    response: normalizeBookingsByLocation(response),
    run,
  };
};

export const usePutUserInvite: UsePutUserInvite = (
  invitedGuests: InvitedGuests,
) => {
  const options = useMemo(
    () => ({
      autoRun: false,
      req: {
        method: 'POST',
      },
      body: {
        external_user_input: invitedGuests,
      },
    }),
    [invitedGuests],
  );
  const {loading, error, response, run} = useApi<ServerInvitedGuests>(
    `/api/v1/users/invite`,
    options,
  );

  return {loading, error, response, run};
};

export const useGetUsersMeGuest: UseGetUsersMeGuest = eventId => {
  const options = useMemo(
    () => ({
      query: {
        event_id: eventId,
      },
    }),
    [eventId],
  );

  const {loading, error, response} = useApi<any>(
    `/api/v1/users/me/guests`,
    options,
  );

  return {
    loading,
    error,
    response,
  };
};

export const usePutUser: UsePutUser = (bodyOptions: PutUserOptions) => {
  const options: any = useMemo(() => {
    return {
      autoRun: false,
      req: {
        method: 'PUT',
      },
      body: {
        role_id: bodyOptions.role_id,
        event_ids: bodyOptions.event_ids,
        realm: bodyOptions.realm,
        email: bodyOptions.email,
        phone_number: bodyOptions.phone_number,
      },
    };
  }, [
    bodyOptions.email,
    bodyOptions.event_ids,
    bodyOptions.phone_number,
    bodyOptions.realm,
    bodyOptions.role_id,
  ]);

  const {loading, error, response, run} = useApi<ServerPutUserOptions>(
    `/api/v1/users/update`,
    options,
  );

  return {loading, error, response: response as Partial<User>, run};
};
