import {InvitedGuests, ServerInvitedGuests} from '@/pages/InvitationGuest/type';
import type {HookResponse} from '@/store/api';
import type {GetMyBookingsResponse} from '@/store/bookings/definitions';
import {REALMS} from '@/store/keycloak';
import {ROLES} from '@/store/roles';

export type AccessToken = null | string;

export enum UserToken {
  NAME = 'user-token',
  REFRESH_TOKEN = 'refresh-token',
}

export type LoginFn = (
  realm: REALMS,
  email: string,
  password: string,
) => Promise<AccessToken>;

export type LoginSSOFn = (
  realm: REALMS,
  code: string,
  eventID?: number,
) => Promise<AccessToken>;

export type LogoutFn = () => Promise<void>;
export type LogoutParamsFn = (realm: REALMS) => Promise<void>;

export interface UseGetUserBookingsOptions {
  onlyFuture?: boolean;
}

export type UseGetUserBookings = (
  filter: UseGetUserBookingsOptions,
  autoRun?: boolean,
) => GetMyBookingsResponse;

export type UsePutUserInviteResponse = HookResponse<ServerInvitedGuests | null>;

export type UsePutUserInvite = (
  invitedGuests: InvitedGuests,
) => UsePutUserInviteResponse;

export type UseGetUsersMeGuestResponse =
  HookResponse<UsersMeGuestResponse | null>;

export type UseGetUsersMeGuest = (
  event_id: number,
) => UseGetUsersMeGuestResponse;

export interface UsersMeGuestResponse {
  data: InvitedGuests;
}

export interface ServerUser {
  first_name: string;
  last_name: string;
  realm_name: REALMS;
  role_name: ROLES;
  permission_names: any;
  phone_number: string;
  email: string;
  id: number;
}

export interface User {
  user_id: number;
  firstName: string;
  lastName: string;
  name: string;
  realm: REALMS;
  role: ROLES;
  permission: any;
  phone_number: string;
  email: string;
}

export type EditUserResponse = HookResponse<Partial<User> | null>;

export type UsePutUser = (bodyOptions: PutUserOptions) => EditUserResponse;

export interface PutUserOptions {
  role_id?: number;
  event_ids?: number[];
  realm: REALMS;
  email: string | undefined;
  phone_number: string;
}

export interface ServerPutUserOptions {
  role_id?: number;
  event_ids?: number[];
  realm: REALMS;
  email: string | undefined;
  phone_number: string;
}
