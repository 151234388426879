import {useMemo} from 'react';

import {SxProps} from '@mui/material';

import {UseStyle} from '@/pages/InvitationGuest/type';

export const useStyle = (): UseStyle => {
  const inviteGuestAddInputBox: SxProps = useMemo(
    (): SxProps => ({
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }),
    [],
  );

  const inviteGuestContentBox: SxProps = useMemo(
    (): SxProps => ({
      height: '100%',
    }),
    [],
  );

  return {
    inviteGuestAddInputBox,
    inviteGuestContentBox,
  };
};
