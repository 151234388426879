import {FunctionComponent, useMemo} from 'react';

// Import the useStyle function
import {Box, Typography} from '@mui/material';

// Import Box and Typography components from Material-UI
import {Appointment} from '@/pages/Bookings/AppointmentsLocation/Appointment';
// Import the FunctionComponent type from React
import {IProps} from '@/pages/Bookings/AppointmentsLocation/definitions';
// Import the IProps interface
import {useStyle} from '@/pages/Bookings/AppointmentsLocation/style';
// Import the Appointment component
import {Booking as IBooking} from '@/store/bookings';

// Import the Booking type from the store

// Define the AppointmentsLocations component
const AppointmentsLocations: FunctionComponent<IProps> = ({
  singleLocation,
}: IProps) => {
  // Use the useStyle function to get the styles for this component
  const {
    appointmentLocationContainerBox,
    appointmentLocation,
    appointmentLocationEventsBox,
  } = useStyle();

  // Use the useMemo hook to optimize performance by memoizing the location
  const location = useMemo(() => singleLocation[0]?.slot, [singleLocation]);

  // If the location exists, render the component
  if (location) {
    return (
      <Box sx={appointmentLocationContainerBox}>
        <Typography variant={'titleAktive2'} sx={appointmentLocation}>
          {location.eventDay.event.location.name}
        </Typography>

        <Box sx={appointmentLocationEventsBox}>
          {/* Map over the singleLocation array and render an Appointment component for each item */}
          {singleLocation.map((appointment: IBooking) => (
            <Appointment key={appointment.id} appointment={appointment} />
          ))}
        </Box>
      </Box>
    );
  }

  // If the location doesn't exist, don't render anything
  return null;
};

// Export the AppointmentsLocations component as the default export
export default AppointmentsLocations;
