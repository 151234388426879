import {useMemo} from 'react';

import {SxProps} from '@mui/material';

import {theme} from '@/themes/default';

import {IUseStyle} from './type';

export const useStyle = (): IUseStyle => {
  const boxContainer: SxProps = useMemo(
    (): SxProps => ({
      display: 'flex',
      flexDirection: 'column',
      rowGap: '24px',
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        rowGap: '0',
        columnGap: '48px',
      },
    }),
    [],
  );
  const boxBrandWrapper: SxProps = useMemo(
    (): SxProps => ({
      display: 'flex',
      flexDirection: 'column',
      rowGap: '16px',
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '33.3333333333%',
      },
    }),
    [],
  );
  const field: SxProps = useMemo((): SxProps => ({}), []);

  return {
    boxContainer,
    boxBrandWrapper,
    field,
  };
};
