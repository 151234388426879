// Import the moment.js library for date and time handling
import {useCallback} from 'react';

// Import Slot and Slots types from the store
import {useTranslations} from '@vidiemme/react-i18n';
// Import the useTranslations hook for internationalization
import moment from 'moment-timezone';

import useUtils from '@/hooks/utils';
// Import the useUtils custom hook for utility functions
import {AvailableSlotsPerDays} from '@/pages/BookingEdit/interfaces';
// Import AvailableSlotsPerDays type for available slots
import {Slot, Slots} from '@/store/slots';

// Import the useCallback hook from React
import {UseDate} from './type';

// Import the UseDate type

const useDate = (): UseDate => {
  const {t} = useTranslations(); // Get the translation function from useTranslations

  const {capitalize} = useUtils(); // Get the capitalize function from useUtils

  // convert date with a timezone passed, otherwise using local timezone
  const usingTimezone = useCallback(
    (date: string, timezone = moment.tz.guess()): moment.Moment => {
      return moment.tz(date, timezone);
    },
    [],
  );

  // Format the time without applying any timezone
  const formatTime = useCallback((time: string): string => {
    return moment.parseZone(time).format('HH:mm:ss');
  }, []);

  const isSameTimezone = useCallback(
    (timezone: string): boolean => {
      const currentDate = moment().format();
      //timezone === moment.tz.guess();
      return (
        usingTimezone(currentDate, timezone).format() ===
        usingTimezone(currentDate).format()
      );
    },
    [usingTimezone],
  );

  const getNowToMoment = useCallback((timezone = moment.tz.guess()) => {
    return moment.tz(new Date(), timezone);
  }, []);

  const getNow = useCallback(
    (timezone = moment.tz.guess()) => {
      return getNowToMoment(timezone).format();
    },
    [getNowToMoment],
  );

  const getUTCNowToMoment = useCallback((): moment.Moment => {
    return moment().utc(true);
  }, []);

  // Combine a date and time string into a single datetime string
  const dateAndTimeToISOString = useCallback(
    (date: string, time: string): string => {
      return `${date}T${time}Z`;
    },
    [],
  );

  const hoursToDate = useCallback(
    (time: string): string => {
      const currentDate = getUTCNowToMoment();
      const momentTime = moment(time, 'HH:mm:ss');
      currentDate.set({
        hours: momentTime.hours(),
        minutes: momentTime.minutes(),
        seconds: momentTime.seconds(),
      });
      return currentDate.format();
    },
    [getUTCNowToMoment],
  );

  // Create a moment object from the input date string
  const dateStringToMoment = useCallback((date: string): moment.Moment => {
    return moment(date).utc();
  }, []);

  // Check if a given date is in the past
  const isPast = useCallback(
    (date: string, timezone = moment.tz.guess(), hoursOffset = 0): boolean => {
      const now = getNow(timezone);
      let deadlineDate = usingTimezone(date, timezone);

      if (hoursOffset) {
        deadlineDate.subtract(hoursOffset, 'hours');
      }

      return usingTimezone(now, timezone).isAfter(deadlineDate);
    },
    [getNow, usingTimezone],
  );

  // Check if a given date is today
  const isToday = useCallback(
    (date: string, timezone = moment.tz.guess()): boolean => {
      const now = getNow(timezone);

      return usingTimezone(now, timezone).isSame(
        usingTimezone(date, timezone),
        'day',
      );
    },
    [getNow, usingTimezone],
  );

  // Format a moment date with optional capitalization
  const dateFormatter = useCallback(
    (
      date: string,
      format: string,
      isCapitalize: boolean = false,
      timezone?: string,
    ) => {
      const formattedDate = usingTimezone(date, timezone).format(format);

      if (isCapitalize) {
        return formattedDate
          .split(' ')
          .map((el: string) => capitalize(el))
          .join(' ');
      }

      return formattedDate;
    },
    [capitalize, usingTimezone],
  );

  // Use the `dateFormatter` function to format the date and retrieve the year-month-day, it's independently of timezone and locale!
  const dateToDateFormat = useCallback((date: string): string => {
    return moment(date).format('YYYY-MM-DD');
  }, []);

  // Use the `dateFormatter` function to format the date and retrieve the day of the week
  const dateToDayName = useCallback(
    (date: string): string => {
      return dateFormatter(date, t('DATE_MOMENT.DAY_STRING_FORMAT'));
    },
    [dateFormatter, t],
  );

  // Use the `dateFormatter` function to format the date and extract the day of the month
  const dateToDayNumber = useCallback(
    (date: string): string => {
      return dateFormatter(date, t('DATE_MOMENT.NUMBER_DAY_FORMAT'));
    },
    [dateFormatter, t],
  );

  // Extract the numeric representation of the month from the date string
  const dateToMonthName = useCallback(
    (date: string): string => {
      return capitalize(moment(date).format('MMMM'));
    },
    [capitalize],
  );

  // Use the 'moment' library to parse the month name and get its numeric representation.
  const monthNameToDateMonth = useCallback(
    (date: string): string => {
      return (
        moment(date, t('DATE_MOMENT.MONTH_FORMAT')).month() + 1
      ).toString();
    },
    [t],
  );

  // Use the `dateFormatter` function to format the date and extract the year
  const dateToYear = useCallback(
    (date: string): string => {
      return dateFormatter(date, t('DATE_MOMENT.YEAR_FORMAT'));
    },
    [dateFormatter, t],
  );

  // Use the `dateFormatter` function to format the date and extract the hours and minutes
  const dateToHours = useCallback(
    (date: string, timezone?: string): string => {
      return dateFormatter(
        date,
        t('DATE_MOMENT.HOURS_FORMAT'),
        false,
        timezone,
      );
    },
    [dateFormatter, t],
  );

  // Use the `dateFormatter` function to format the date and extract the hours:minutes:seconds, it's indipendently of timezone and locale!
  const dateToHoursAndFormat = useCallback(
    (date: string, timezone?: string): string => {
      return dateFormatter(date, 'HH:mm:ss', false, timezone);
    },
    [dateFormatter],
  );

  // Use the `dateFormatter` function to format the date as a full date with capitalization
  const dateToFullDate = useCallback(
    (date: string, timezone?: string): string => {
      return dateFormatter(
        date,
        `${t('DATE_MOMENT.FULL_DATE')}`,
        true,
        timezone,
      );
    },
    [dateFormatter, t],
  );

  // Split the input date string into its components (day, month, year) and format them as "YYYY-MM-DD"
  const formFullDateToDate = useCallback(
    (date: string): string => {
      const [_dayStr, dayNum, monthStr, yearNum] = date
        .replace(',', '')
        .split(' ');
      return `${yearNum}-${monthNameToDateMonth(monthStr)
        .padStart(2, '0')
        .slice(-2)}-${dayNum}`;
    },
    [monthNameToDateMonth],
  );

  // Get the start and end date of an event
  // dd/mm/yyyy - dd/mm/yyyy
  // dd/mm - dd/mm/yyyy
  // dd - dd/mm/yyyy
  const getEventPeriodString = useCallback(
    (startDate: string, endDate: string, timezone?: string): string => {
      // Format the start and end dates based on their values
      if (startDate === '' || endDate === '') {
        return '';
      }

      const momentStartDate = usingTimezone(startDate, timezone);
      const momentEndDate = usingTimezone(endDate, timezone);

      if (!momentStartDate.isSame(momentEndDate, 'year')) {
        return `${dateFormatter(
          startDate,
          t('DATE_MOMENT.DATE_ORDINARY_FORMAT'),
          true,
          timezone,
        )} - ${dateFormatter(
          endDate,
          t('DATE_MOMENT.DATE_ORDINARY_FORMAT'),
          true,
          timezone,
        )}`;
      }

      if (!momentStartDate.isSame(momentEndDate, 'month')) {
        return `${dateFormatter(
          startDate,
          'DD MMMM',
          true,
          timezone,
        )} - ${dateFormatter(
          endDate,
          t('DATE_MOMENT.DATE_ORDINARY_FORMAT'),
          true,
          timezone,
        )}`;
      }

      return `${dateFormatter(
        startDate,
        'DD',
        true,
        timezone,
      )} - ${dateFormatter(
        endDate,
        t('DATE_MOMENT.DATE_ORDINARY_FORMAT'),
        true,
        timezone,
      )}`;
    },
    [dateFormatter, t, usingTimezone],
  );

  /* Extract from slot list only dates occurences without duplicates */
  const getAvailableDateSlots = useCallback(
    (slots: Slots, timezone: string) => {
      return slots
        .filter(
          (value, index, self) =>
            index === self.findIndex(t => t.eventDay.id === value.eventDay.id),
        )
        .map(slot => {
          const dateLocal = dateToFullDate(slot.eventDay.date);
          const dateLocation = dateToFullDate(slot.eventDay.date, timezone);
          return {
            value: slot.eventDay.id,
            label:
              dateLocal !== dateLocation
                ? `${dateLocation} ${t('TIMEZONE.LOCATION')}, ${dateLocal} ${t(
                    'TIMEZONE.LOCAL',
                  )}`
                : dateLocation,
          };
        });
    },
    [dateToFullDate, t],
  );

  const getTimeFormatter = useCallback(
    (startTime: string, endTime: string, timezone: string) => {
      if (!isSameTimezone(timezone)) {
        return `${dateToHours(startTime, timezone)}-${dateToHours(
          endTime,
          timezone,
        )} ${t('TIMEZONE.LOCATION_SHORT')}, ${dateToHours(
          startTime,
        )}-${dateToHours(endTime)} ${t('TIMEZONE.LOCAL_SHORT')}`;
      }
      return `${dateToHours(startTime, timezone)}-${dateToHours(
        endTime,
        timezone,
      )}`;
    },
    [dateToHours, isSameTimezone, t],
  );

  // Extract available slots and format them
  const getAvailableSlots = useCallback(
    (slots: Slots, timezone: string): any => {
      return slots.reduce(
        (acc: AvailableSlotsPerDays, slot: Slot): AvailableSlotsPerDays => {
          return {
            ...acc,
            [slot.eventDay.id]: [
              ...(acc[slot.eventDay.id] || []),
              {
                value: slot.id,
                label: getTimeFormatter(slot.startTime, slot.endTime, timezone),
              },
            ],
          };
        },
        {},
      );
    },
    [getTimeFormatter],
  );

  // Sort available slots by date
  const sortAvailableSlots = useCallback(
    (availableSlots: Slots) => {
      return availableSlots.sort((a: Slot, b: Slot): number => {
        return dateStringToMoment(a.startTime).isAfter(
          dateStringToMoment(b.startTime),
        )
          ? 1
          : -1;
      });
    },
    [dateStringToMoment],
  );

  const convertDurationToMoment = useCallback(
    (time: string, timezone: string): moment.Moment => {
      const now = getNowToMoment(timezone);
      return now.set({
        hours: moment.duration(time).hours(),
        minutes: moment.duration(time).minutes(),
      });
    },
    [getNowToMoment],
  );

  const convertMomentToDuration = useCallback((date: moment.Moment): string => {
    return moment
      .duration({
        hours: date.hours(),
        minutes: date.minutes(),
      })
      .toISOString();
  }, []);

  return {
    getNow,
    getNowToMoment,
    getUTCNowToMoment,
    isPast,
    isToday,
    getEventPeriodString,
    dateAndTimeToISOString,
    sortAvailableSlots,
    getAvailableDateSlots,
    getAvailableSlots,
    dateToDateFormat,
    dateToDayName,
    dateToDayNumber,
    dateToMonthName,
    dateToYear,
    dateToHours,
    dateToHoursAndFormat,
    dateToFullDate,
    formFullDateToDate,
    usingTimezone,
    isSameTimezone,
    getTimeFormatter,
    dateStringToMoment,
    convertDurationToMoment,
    convertMomentToDuration,
    hoursToDate,
    formatTime,
  };
};

export default useDate;
