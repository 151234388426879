import queryString from 'query-string';

const getRequestHeaders = (headers: RequestInit['headers'], token?: string) => {
  const requestHeaders: Record<string, string> = {
    'Content-Type': 'application/json',
  };

  if (token) {
    requestHeaders['Authorization'] = `Bearer ${token}`;
  }

  for (const name in headers) {
    requestHeaders[name] = (headers as Record<string, string>)[name];
  }

  return new Headers(requestHeaders);
};

const getRequestURLWithQueryString = (
  url: string,
  query: {[k: string]: any} = {},
) => {
  const requestURLWithQueryString = queryString.stringifyUrl({
    url,
    query,
  });

  if (requestURLWithQueryString.indexOf('http') === 0) {
    return new URL(requestURLWithQueryString);
  }

  return new URL(
    `${process.env.REACT_APP_BASE_URL}${requestURLWithQueryString}`,
  );
};

const getRequestBody = (
  headers: Headers,
  body?: {[k: string]: any} | string,
) => {
  if (body) {
    if (headers.get('Content-Type') === 'application/json') {
      return JSON.stringify(body);
    }

    return body as BodyInit;
  }

  return undefined;
};

export const api = async (
  uri: string,
  {method = 'GET', headers = {}}: RequestInit = {},
  {
    token,
    query,
    body,
    responseParser,
  }: {
    token?: string;
    query?: {[k: string]: any};
    body?: {[k: string]: any} | string;
    responseParser?: (response: Response) => Promise<any>;
  } = {},
): Promise<any> => {
  try {
    const requestHeaders = getRequestHeaders(headers, token);
    const requestBody = getRequestBody(requestHeaders, body);

    const requestURLWithQueryString = getRequestURLWithQueryString(uri, query);

    const response = await fetch(requestURLWithQueryString, {
      method,
      mode: 'cors',
      cache: 'no-cache',
      headers: requestHeaders,
      body: requestBody,
    });

    if (response.status === 204) {
      return {
        ok: response.ok,
        status: response.status,
        data: null,
      };
    }
    const data = responseParser
      ? await responseParser(response)
      : await response.json();

    return {
      ok: response.ok,
      status: response.status,
      data,
    };
  } catch (e) {
    console.error(e, 'function');
    throw e;
  }
};
