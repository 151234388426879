import {useMemo} from 'react';

import {SxProps} from '@mui/material';

import {theme} from '@/themes/default';

import {IPropsStyle} from './type';

export const useStyle = (): IPropsStyle => {
  const boxContainerBanner: SxProps = useMemo(
    (): SxProps => ({
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      height: 'auto',
      minHeight: '100px',
      padding: '24px',
      backgroundColor: theme.palette.common.white,
      zIndex: 3,
      display: 'flex',
      flexDirection: 'column',
      rowGap: '30px',
      [theme.breakpoints.up('md')]: {
        columnGap: '30px',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
    }),
    [],
  );

  const backdrop: SxProps = useMemo(
    (): SxProps => ({
      position: 'fixed',
      zIndex: 2,
      backgroundColor: 'rgba(18, 18, 18, 0.5)',
      height: '100%',
      width: '100%',
      top: 0,
      left: 0,
    }),
    [],
  );

  const boxContainerPrivacy: SxProps = useMemo(
    (): SxProps => ({
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      height: 'auto',
      overflowY: 'auto',
      backgroundColor: theme.palette.common.white,
      zIndex: 3,
      display: 'flex',
      flexDirection: 'column',
    }),
    [],
  );

  const closePrivacyPolicy: SxProps = useMemo(
    (): SxProps => ({
      position: 'absolute',
      top: '10px',
      right: '10px',
      border: `1px solid ${theme.palette.black}`,
      borderRadius: '20px',
      padding: '8px',
    }),
    [],
  );

  const buttonsBanner: SxProps = useMemo(
    (): SxProps => ({
      display: 'flex',
      flexDirection: 'column',
      rowGap: '10px',
    }),
    [],
  );

  const buttonBanner: SxProps = useMemo(
    (): SxProps => ({
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '280px',
      },
    }),
    [],
  );

  return {
    boxContainerBanner,
    backdrop,
    boxContainerPrivacy,
    closePrivacyPolicy,
    buttonsBanner,
    buttonBanner,
  };
};
