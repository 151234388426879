import React, {useCallback, useMemo} from 'react';

import {Box, TextField} from '@mui/material';
import {DatePicker} from '@mui/x-date-pickers';
import {useTranslations} from '@vidiemme/react-i18n';
import moment from 'moment-timezone';

import {Button} from '@/atoms/Button';
import useDate from '@/hooks/date';

import {useStyle} from './style';
import {SlotSizeManagementProps} from './type';

const SlotSizeManagement = ({
  timezone = 'UTC',
  slot,
  previuosSlot,
  hideDelete = false,
  deleteSlotHandler,
  fieldChangeHandler,
  isLoading,
  eventStartDate,
  eventEndDate,
}: SlotSizeManagementProps) => {
  const {boxContainer: boxContainerStyle, field: fieldStyle} = useStyle();
  const {usingTimezone} = useDate();
  const {t} = useTranslations();

  const handleStartDateChange = useCallback(
    (val: any) => {
      console.log('handleStartDateChange', val);
      fieldChangeHandler('start_date', val);
    },
    [fieldChangeHandler],
  );

  const handleEndDateChange = useCallback(
    (val: any) => {
      console.log('handleEndDateChange', val);
      fieldChangeHandler('end_date', val);
    },
    [fieldChangeHandler],
  );

  const handleLimitChange = useCallback(
    (val: any) => {
      fieldChangeHandler('limit', val);
    },
    [fieldChangeHandler],
  );

  const getMinDate = useCallback(
    (date: moment.Moment | null | undefined) => {
      let result;

      if (date) {
        result = moment.tz(date, timezone).add(1, 'days');
      } else if (eventStartDate) {
        result = usingTimezone(eventStartDate, timezone);
      }

      return result;
    },
    [eventStartDate, timezone, usingTimezone],
  );

  const maxDate = useMemo(() => {
    return moment.tz(eventEndDate, timezone);
  }, [eventEndDate, timezone]);

  return (
    <Box sx={boxContainerStyle}>
      <DatePicker
        disabled={isLoading}
        label={t('EVENT_CREATE_EDIT.SLOT_SIZE.START_DATE')}
        onChange={handleStartDateChange}
        value={slot.start_date}
        minDate={getMinDate(previuosSlot?.end_date)}
        maxDate={eventEndDate ? maxDate : undefined}
        sx={fieldStyle}
        timezone={timezone}
      />
      <DatePicker
        disabled={isLoading}
        label={t('EVENT_CREATE_EDIT.SLOT_SIZE.END_DATE')}
        onChange={handleEndDateChange}
        value={slot.end_date}
        minDate={getMinDate(slot.start_date)}
        maxDate={eventEndDate ? maxDate : undefined}
        sx={fieldStyle}
        timezone={timezone}
      />

      <TextField
        disabled={isLoading}
        onChange={handleLimitChange}
        id="slot_limit"
        label={t('EVENT_CREATE_EDIT.SLOT_SIZE.SLOT_SIZE')}
        type="number"
        value={slot.limit ? slot.limit.toString() : '0'}
        sx={fieldStyle}
      />
      {!hideDelete && (
        <Button
          disabled={isLoading}
          sx={fieldStyle}
          variant={'secondary'}
          color={'default'}
          onClick={deleteSlotHandler}>
          {t('EVENT_CREATE_EDIT.SLOT_SIZE.BUTTON_DELETE')}
        </Button>
      )}
    </Box>
  );
};
export default SlotSizeManagement;
