import {Backdrop, Box, Typography} from '@mui/material';

import {Button} from '@/atoms/Button';
import {IconSVG} from '@/atoms/IconSVG';
import {theme} from '@/themes/default';

import {useStyle} from './style';
import {IProps} from './type';

const SideModal = ({
  title,
  subTitle,
  closeHandler,
  textButtonOne,
  CTAButtonOne,
  buttonOneDisabled = false,
  buttonOneLoading = false,
  textButtonTwo,
  CTAButtonTwo,
  buttonTwoDisabled = false,
  buttonTwoLoading = false,
  children,
}: IProps) => {
  const {
    sidePageContainerBox,
    sidePageTitleBox,
    sidePageTitleTitleBox,
    sidePageButtonBox,
    sidePageButtonButtonBox,
    sidePageChildrenBox,
    backdropStyle,
    buttonIconClose,
  } = useStyle();

  return (
    <>
      <Backdrop open={true} sx={backdropStyle} onClick={closeHandler} />
      <Box sx={sidePageContainerBox}>
        <Button onClick={closeHandler} sx={buttonIconClose}>
          <IconSVG icon="cross" size={15} color={theme.palette.black} />
        </Button>
        <Box sx={sidePageTitleBox}>
          <Typography variant={'titleAktive1'} sx={sidePageTitleTitleBox}>
            {title}
          </Typography>
          <Typography variant={'bodyRegular'}>{subTitle}</Typography>
        </Box>
        <Box sx={sidePageChildrenBox}>{children}</Box>
        <Box sx={sidePageButtonBox}>
          <Button
            variant={'secondaryBig'}
            disabled={buttonOneDisabled}
            loading={buttonOneLoading}
            color={'default'}
            onClick={CTAButtonOne}
            sx={sidePageButtonButtonBox}>
            {textButtonOne}
          </Button>
          {textButtonTwo && (
            <Button
              variant={'primaryBig'}
              color={'default'}
              disabled={buttonTwoDisabled}
              loading={buttonTwoLoading}
              onClick={CTAButtonTwo}
              sx={sidePageButtonButtonBox}>
              {textButtonTwo}
            </Button>
          )}
        </Box>
      </Box>
    </>
  );
};
export default SideModal;
