import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {Link} from 'react-router-dom';

import {Box, CircularProgress, Stack, Typography} from '@mui/material';
import {useTranslations} from '@vidiemme/react-i18n';

import {Button} from '@/atoms/Button';
import {IconSVG} from '@/atoms/IconSVG';
import {useUserContext} from '@/contexts/user';
import useDate from '@/hooks/date';
import {useToast} from '@/hooks/toast';
import {useIsTablet} from '@/hooks/viewport';
import {AuthRoutes} from '@/navigation/routes';
import {useNavigation} from '@/navigation/useNavigation';
import {useToggleVisibility} from '@/store/events';
import {ROLES} from '@/store/roles';
import {theme} from '@/themes/default';

import type {IProps} from './interfaces';
import useStyle from './style';

const Event: FunctionComponent<IProps> = ({event}: IProps) => {
  const {
    eventBoxContainer,
    eventTitleBoxContainer,
    eventTitleBox,
    eventName,
    eventDateBox,
    eventButtonsBox,
    eventButton,
    eventActionsContainer,
  } = useStyle();

  const {t} = useTranslations();
  const {goToEventEdit, goToEventDelete} = useNavigation();
  const {showErrorToast, showSuccessToast} = useToast();
  const {role} = useUserContext();
  const {getEventPeriodString} = useDate();

  const isTablet = useIsTablet();

  const [visibility, setVisibility] = useState<boolean>(!!event.is_visible);

  const {loading, error, response, run} = useToggleVisibility(event.id);

  const eventDeleteButtonHandler = useCallback(
    (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      goToEventDelete(event.id, {
        state: {
          eventName: event.name,
        },
      });
    },
    [event.id, event.name, goToEventDelete],
  );

  const eventEditButtonHandler = useCallback(
    (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      goToEventEdit(event.id);
    },
    [event.id, goToEventEdit],
  );

  const handleCopyLink = useCallback(
    async (e: any) => {
      e.preventDefault();
      e.stopPropagation();

      const link = `${window.location.origin}${AuthRoutes.INVITATION_FROM_EMAIL}?event_id=${event.id}`;

      try {
        await navigator.clipboard.writeText(link);

        showSuccessToast({message: t('EVENT_MANAGEMENT.LINK_COPIED')});
      } catch (e) {
        console.error(e);
        // I cannot do anything more
      }
    },
    [event.id, showSuccessToast, t],
  );

  const handleToggleVisibility = useCallback(
    (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      if (run) {
        run();
      }
    },
    [run],
  );

  const eventCalendarURL = AuthRoutes.EVENT_CALENDAR.replace(
    ':id',
    event.id.toString(),
  );

  /* Show always this period with location timezone */
  const printPeriod = useMemo(() => {
    const locationTimezone = event.location.timezone;
    const periodLocation = getEventPeriodString(
      event.calendar.start_date,
      event.calendar.end_date,
      locationTimezone,
    );

    return (
      <Stack flexDirection="column">
        <Typography variant="bodyRegular">
          {periodLocation} {t('TIMEZONE.LOCATION')}
        </Typography>
      </Stack>
    );
  }, [
    event.calendar.end_date,
    event.calendar.start_date,
    event.location.timezone,
    getEventPeriodString,
    t,
  ]);

  const buttonsSection = useMemo(() => {
    if (role === ROLES.ADMIN) {
      return (
        <Box sx={eventButtonsBox}>
          <Button
            variant={'primary'}
            color={'default'}
            sx={eventButton}
            onClick={eventEditButtonHandler}>
            {t('EVENT_MANAGEMENT.EDIT_BUTTON')}
          </Button>
          <Button
            variant={'secondary'}
            color={'default'}
            sx={eventButton}
            onClick={eventDeleteButtonHandler}>
            {t('EVENT_MANAGEMENT.DELETE_BUTTON')}
          </Button>
        </Box>
      );
    }
    return null;
  }, [
    eventButton,
    eventButtonsBox,
    eventDeleteButtonHandler,
    eventEditButtonHandler,
    role,
    t,
  ]);

  useEffect(() => {
    if (error) {
      showErrorToast({
        message: t('EVENT_CREATE_EDIT.TOGGLE_VISIBILITY_RESPONSE_ERROR'),
      });
    } else if (response) {
      setVisibility(!!response.is_visible);
      showSuccessToast({
        message: t('EVENT_CREATE_EDIT.EDIT_RESPONSE_SUCCESS'),
      });
    }
  }, [error, response, showErrorToast, showSuccessToast, t]);

  return (
    <Link
      to={eventCalendarURL}
      style={
        isTablet
          ? {backgroundColor: theme.palette.gray1}
          : {
              backgroundColor: theme.palette.gray1,
              width: '100%',
              minWidth: '90%',
            }
      }>
      <Box sx={eventBoxContainer}>
        <Box sx={eventTitleBoxContainer}>
          <Box sx={eventTitleBox}>
            <Typography variant={'pretitle'}>{event.location.name}</Typography>
            <Typography variant={'titleVersace2'} sx={eventName}>
              {event.name}
            </Typography>
          </Box>
          <Box sx={eventActionsContainer}>
            {loading ? (
              <Button>
                <CircularProgress size={20} />
              </Button>
            ) : (
              <Button onClick={handleToggleVisibility}>
                <IconSVG icon={visibility ? 'look' : 'hide'} size={23} />
              </Button>
            )}

            <Button onClick={handleCopyLink}>
              <IconSVG icon={'link'} size={23} />
            </Button>
          </Box>
        </Box>
        <Box sx={eventDateBox}>{printPeriod}</Box>
        {buttonsSection}
      </Box>
    </Link>
  );
};

export default Event;
