import {FunctionComponent, useCallback, useEffect} from 'react';
import {useLocation, useParams} from 'react-router-dom';

import {Box, Typography} from '@mui/material';
import {useTranslations} from '@vidiemme/react-i18n';

import {Button} from '@/atoms/Button';
import {useToast} from '@/hooks/toast';
import {useNavigation} from '@/navigation/useNavigation';
import {useDeleteEvent} from '@/store/events';
import {Modal} from '@/templates/Modal';

import {useStyle} from './style';

const EventDelete: FunctionComponent = () => {
  // Hooks for translation and navigation
  const {t} = useTranslations(); // Get the translation function
  const {goToPreviousPage} = useNavigation(); // Get the function for navigating back

  // Functions to display success and error toasts
  const {showSuccessToast, showErrorToast} = useToast();

  // Styles
  const {boxContainer, titleBox, contentBox, buttonsBox, buttonElement} =
    useStyle();

  // Get the current URL location's state
  const {
    state: {eventName},
  } = useLocation();

  // Get params from URL
  const {id: eventId} = useParams();

  // Close the modal and return to the previous page
  const closeModalHandler = useCallback(() => {
    goToPreviousPage();
  }, [goToPreviousPage]);

  // Use the useDeleteEvent hook to delete the event
  const {
    loading: loadingDeleteEvent,
    error: errorDeleteEvent,
    response: responseDeleteEvent,
    run: runDeleteEvent,
  } = useDeleteEvent(eventId ? parseInt(eventId) : -1);

  const deleteButtonHandler = useCallback(() => {
    if (runDeleteEvent) {
      return runDeleteEvent();
    }
    return null;
  }, [runDeleteEvent]);

  // Show success/error toasts
  useEffect(() => {
    if (errorDeleteEvent) {
      showErrorToast({message: t('EVENT_DELETE.RESPONSE_ERROR')});
    }

    if (responseDeleteEvent) {
      showSuccessToast({message: t('EVENT_DELETE.RESPONSE_SUCCESS')});
      closeModalHandler();
    }
  }, [
    goToPreviousPage,
    showErrorToast,
    showSuccessToast,
    closeModalHandler,
    t,
    errorDeleteEvent,
    responseDeleteEvent,
  ]);

  return (
    <Modal>
      <Box sx={boxContainer}>
        <Box sx={titleBox}>
          <Typography variant={'titleAktive1'}>
            {t('EVENT_DELETE.TITLE')}
          </Typography>
        </Box>
        <Box sx={contentBox}>
          <Typography variant={'bodyRegular'}>
            {t('EVENT_DELETE.TEXT')}
            <Typography variant={'bodyBold'}>{` ${eventName}`}</Typography>?
          </Typography>
          <Typography variant={'bodyRegular'}>
            {t('EVENT_DELETE.ADDITIONAL_TEXT')}
          </Typography>
        </Box>
        <Box sx={buttonsBox}>
          <Button
            variant={'secondaryBig'}
            color={'default'}
            sx={buttonElement}
            onClick={closeModalHandler}>
            {t('EVENT_DELETE.BUTTON_CANCEL')}
          </Button>
          <Button
            variant={'primaryBig'}
            color={'default'}
            sx={buttonElement}
            onClick={deleteButtonHandler}
            disabled={loadingDeleteEvent}
            loading={loadingDeleteEvent}>
            {t('EVENT_DELETE.BUTTON_DELETE')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default EventDelete;
