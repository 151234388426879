import {useMemo} from 'react';

import {SxProps} from '@mui/material';

import {theme} from '@/themes/default';

import {IUseStyle} from './type';

export const useStyle = (): IUseStyle => {
  const boxContainer: SxProps = useMemo(
    (): SxProps => ({
      display: 'flex',
      flexDirection: 'column',
      rowGap: '10px',
      width: '100%',
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        rowGap: '0',
        columnGap: '30px',
        alignItems: 'center',
      },
    }),
    [],
  );

  const field: SxProps = useMemo(
    (): SxProps => ({
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '25%',
      },
    }),
    [],
  );

  return {
    boxContainer,
    field,
  };
};
