import {PropsWithChildren} from 'react';

import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {itIT, enUS} from '@mui/x-date-pickers/locales';
import 'moment/locale/en-gb';
import 'moment/locale/it';

import {useUserContext} from '@/contexts/user';

export const LocalizationMoment = ({children}: PropsWithChildren) => {
  /* Get current language and set adapter locale to en/it format */
  const {lang} = useUserContext();
  return (
    <LocalizationProvider
      dateAdapter={AdapterMoment}
      adapterLocale={lang}
      localeText={
        lang === 'it'
          ? itIT.components.MuiLocalizationProvider.defaultProps.localeText
          : enUS.components.MuiLocalizationProvider.defaultProps.localeText
      }
      children={children}
    />
  );
};
