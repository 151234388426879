import type {Slot} from '@/store/slots';
import {normalizeSlot} from '@/store/slots/normalizers';
import {normalizerUser} from '@/store/user/normalizer';

import type {
  Booking,
  BookingGuest,
  BookingsByLocation,
  ServerBooking,
  ServerBookingGuest,
  ServerBookingsByLocation,
} from './definitions';

export const normalizeBooking = (
  booking: ServerBooking | null,
): Booking | null => {
  if (booking === null) {
    return null;
  }

  const {
    id,
    user_id: userId,
    user: serverUser,
    event_id: eventId,
    presence_type: presenceType,
    note,
    slot_id: slotId,
    slot: serverSlot,
    is_present,
    guests: serverGuests,
    invited_by: invitedBy,
  } = booking;

  const slot = serverSlot ? (normalizeSlot(serverSlot) as Slot) : undefined;
  const user = serverUser ? normalizerUser(serverUser) : undefined;
  const invitedByUser = invitedBy ? normalizerUser(invitedBy) : undefined;
  const guests = serverGuests
    ? normalizeBookingGuests(serverGuests)
    : undefined;

  return {
    id,
    userId,
    user,
    eventId,
    presenceType,
    note,
    slotId,
    slot,
    isPresent: !!is_present,
    guests,
    invitedBy: invitedByUser,
  };
};

export const normalizeBookingsByLocation = (
  bookings: ServerBookingsByLocation | null,
): BookingsByLocation | null => {
  if (bookings === null) {
    return null;
  }

  return Object.keys(bookings).reduce((acc, locationName) => {
    const bookingsByLocation = bookings[locationName];

    return {
      ...acc,
      [locationName]: bookingsByLocation.map(booking => {
        return normalizeBooking(booking) as Booking;
      }),
    };
  }, {});
};

export const normalizeBookingGuests = (
  serverGuests: ServerBookingGuest[],
): BookingGuest[] => {
  return serverGuests.map(serverGuest => ({
    firstName: serverGuest.first_name,
    lastName: serverGuest.last_name,
  }));
};
