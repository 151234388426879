import {FunctionComponent, useMemo} from 'react';

import {useTranslations} from '@vidiemme/react-i18n';

import {LoadingMessage} from '@/organisms/LoadingMessage';
import {MessagePage} from '@/organisms/MessagePage';
import {ResponseErrorMessage} from '@/organisms/ResponseErrorMessage';
import {InvitationsLocation} from '@/pages/Invitations/InvitationsLocation';
import {Invitation, useGetInvitations} from '@/store/invitations';
import {Base} from '@/templates/Base';

const Invitations: FunctionComponent = () => {
  const {t} = useTranslations();

  const {loading, error, response} = useGetInvitations();

  const body = useMemo(() => {
    if (error) {
      return <ResponseErrorMessage showToast />; // Display error message
    }

    if (response) {
      if (Object.keys(response!).length === 0) {
        return (
          <MessagePage
            message={t('INVITATIONS.NO_MESSAGE')}
            title={t('INVITATIONS.NO_TITLE')}
          />
        );
      }

      return Object.values(response!).map(
        (invitationsSingleLocation: Invitation[]) => (
          <InvitationsLocation
            key={invitationsSingleLocation[0].location.name}
            invitationsSingleLocation={invitationsSingleLocation}
          />
        ),
      );
    }

    if (loading) {
      return <LoadingMessage />; // Display loading message
    }

    return null;
  }, [error, loading, response, t]);

  return (
    <Base title={t('INVITATIONS.TITLE')} disableGutters={true}>
      {body}
    </Base>
  );
};

export default Invitations;
