import React, {useState, useCallback, ChangeEvent, useEffect} from 'react';

import {Box, TextField, FormControlLabel, Checkbox} from '@mui/material';
import {DatePicker, TimePicker} from '@mui/x-date-pickers';
import {useTranslations} from '@vidiemme/react-i18n';
import moment from 'moment-timezone';

import useDate from '@/hooks/date';
import {Category} from '@/store/categories';
import {INPUT_PROPS, CHARACTER_LIMIT} from '@/store/inputProps';

import {useStyle} from './style';
import {EventGeneralInfoProps, dateProp, GeneralInfoProps} from './type';

const EventGeneralInfo = ({
  changeInfoHandler,
  fields,
  timezone = 'UTC',
  eventCategory,
  isLoading,
}: GeneralInfoProps) => {
  const {
    boxContent: boxContentStyle,
    boxResponsiveContent: boxResponsiveContentStyle,
    midField: midFieldStyle,
    eventNameField: eventNameFieldStyle,
  } = useStyle();

  const {t} = useTranslations();
  const {
    convertDurationToMoment,
    convertMomentToDuration,
    isPast,
    usingTimezone,
    getNowToMoment,
  } = useDate();

  const [eventName, setEventName] = useState(fields?.eventName || '');
  const [guestPerPerson, setGuests] = useState<number>(
    fields?.guestPerPerson || 0,
  );
  const [startDate, setStartDate] = useState<dateProp>(
    fields?.startDate ? usingTimezone(fields?.startDate, timezone) : null,
  );
  const [endDate, setEndDate] = useState<dateProp>(
    fields?.endDate ? usingTimezone(fields?.endDate, timezone) : null,
  );

  const [slotDuration, setSlotDuration] = useState<dateProp>(
    fields?.slotDuration
      ? convertDurationToMoment(fields?.slotDuration, timezone)
      : null,
  );

  const [allowMultipleBookings, setAllowMultipleBookings] = useState<boolean>(
    fields?.allowMultipleBookings || false,
  );

  const [minBookingTime, setMinBookingTime] = useState<number>(
    fields?.minBookingTime || 0,
  );

  /* Get minimum start date for start_date datepicker */
  const getMinStartDate = useCallback(() => {
    //Event EDIT
    if (fields?.startDate && isPast(fields.startDate, timezone)) {
      return usingTimezone(fields.startDate, timezone);
    } else {
      return getNowToMoment(timezone);
    }
  }, [fields?.startDate, getNowToMoment, isPast, timezone, usingTimezone]);

  useEffect(() => {
    const obj: EventGeneralInfoProps = {
      eventName,
      guestPerPerson: guestPerPerson ?? 0,
      startDate: startDate?.format() ?? '',
      endDate: endDate?.format() ?? '',
      allowMultipleBookings,
      minBookingTime: minBookingTime ?? 0,
    };

    if (slotDuration) {
      obj.slotDuration = convertMomentToDuration(slotDuration);
    }

    changeInfoHandler({generalInfo: obj});
  }, [
    minBookingTime,
    allowMultipleBookings,
    convertMomentToDuration,
    endDate,
    eventName,
    guestPerPerson,
    slotDuration,
    startDate,
  ]); // DO NOT update dependency with changeInfoHandler method

  const eventNameChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setEventName(event.target.value);
    },
    [],
  );

  const guestPerPersonChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (isNaN(event.target.valueAsNumber) || event.target.valueAsNumber < 0) {
        setGuests(0);
      } else {
        setGuests(event.target.valueAsNumber);
      }
    },
    [],
  );

  const startDateChangeHandler = setStartDate;

  const endDateChangeHandler = setEndDate;

  const slotDurationChangeHandler = setSlotDuration;

  const allowMultipleBookingsChangeHandler = useCallback((event: any) => {
    setAllowMultipleBookings(event.target.checked);
  }, []);

  const minBookingTimeChangeHandler = useCallback((event: any) => {
    if (isNaN(event.target.valueAsNumber)) {
      setMinBookingTime(0);
    } else {
      setMinBookingTime(event.target.valueAsNumber);
    }
  }, []);

  return (
    <Box sx={boxContentStyle}>
      <TextField
        disabled={isLoading}
        onChange={eventNameChangeHandler}
        id="event-name"
        label={t('EVENT_CREATE_EDIT.GENERAL_INFO.EVENT_NAME')}
        type="text"
        value={eventName}
        inputProps={INPUT_PROPS}
        helperText={`${eventName.length}/${CHARACTER_LIMIT}`}
        sx={eventNameFieldStyle}
      />
      <Box sx={boxResponsiveContentStyle}>
        <DatePicker
          label={t('EVENT_CREATE_EDIT.GENERAL_INFO.START_DATE')}
          onChange={startDateChangeHandler}
          value={startDate ?? null}
          minDate={getMinStartDate()}
          sx={midFieldStyle}
          timezone={timezone}
          disabled={isLoading}
        />
        <DatePicker
          disabled={isLoading}
          label={t('EVENT_CREATE_EDIT.GENERAL_INFO.END_DATE')}
          onChange={endDateChangeHandler}
          value={endDate ?? null}
          minDate={startDate ?? getNowToMoment()}
          sx={midFieldStyle}
          timezone={timezone}
        />
        {(eventCategory === Category.SPECIAL_SALE ||
          eventCategory === Category.ORDINARY_OPENING) && (
          <TextField
            disabled={isLoading}
            onChange={guestPerPersonChangeHandler}
            id="guest_per_person"
            label={t('EVENT_CREATE_EDIT.GENERAL_INFO.GUESTS')}
            type="number"
            value={guestPerPerson ? guestPerPerson.toString() : '0'}
            sx={midFieldStyle}
          />
        )}
      </Box>
      <Box sx={boxResponsiveContentStyle}>
        <TimePicker
          disabled={isLoading || !!fields} // in edit this field is disabled
          label={t('EVENT_CREATE_EDIT.GENERAL_INFO.SLOT_DURATION')}
          minutesStep={30}
          minTime={moment().hour(0).minutes(30).seconds(0)}
          skipDisabled={true}
          onChange={slotDurationChangeHandler}
          value={slotDuration}
          timezone={timezone}
          sx={midFieldStyle}
          ampm={false}
          format="hh:mm"
        />
        {(eventCategory === Category.CLOTHING ||
          eventCategory === Category.ORDINARY_OPENING) && (
          <TextField
            disabled={isLoading}
            onChange={minBookingTimeChangeHandler}
            id="min_booking_time"
            label={t('EVENT_CREATE_EDIT.GENERAL_INFO.MIN_BOOKING_TIME')}
            type="number"
            value={minBookingTime ? minBookingTime.toString() : '0'}
            sx={midFieldStyle}
          />
        )}
        {(eventCategory === Category.SPECIAL_SALE ||
          eventCategory === Category.ORDINARY_OPENING) && (
          <FormControlLabel
            disabled={isLoading || !!fields} // in edit this field is disabled
            checked={allowMultipleBookings}
            onChange={allowMultipleBookingsChangeHandler}
            control={<Checkbox />}
            label={t('EVENT_CREATE_EDIT.GENERAL_INFO.ALLOW_MULTIPLE_BOOKINGS')}
            sx={midFieldStyle}
          />
        )}
      </Box>
    </Box>
  );
};
export default EventGeneralInfo;
