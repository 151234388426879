import {useMemo} from 'react';

import {SxProps} from '@mui/material';

import {IUseStyle} from './type';

export const useStyle = (): IUseStyle => {
  const boxContent: SxProps = useMemo(
    (): SxProps => ({
      display: 'flex',
      flexDirection: 'column',
      rowGap: '24px',
    }),
    [],
  );

  return {
    boxContent,
  };
};
