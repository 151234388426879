import React, {useState, useEffect, useCallback, useMemo} from 'react';

import {Box, Typography} from '@mui/material';
import {DatePicker} from '@mui/x-date-pickers';
import {useTranslations} from '@vidiemme/react-i18n';
import moment from 'moment-timezone';

import useDate from '@/hooks/date';

import {useStyle} from './style';
import {ReservationDatesFields, ReservationOpeningsProps} from './type';

const ReservationOpenings = ({
  versace,
  capri,
  guests,
  timezone = 'UTC',
  changeInfoHandler,
  isLoading,
  eventStartDate,
  eventEndDate,
}: ReservationOpeningsProps) => {
  const {
    boxContainer: boxContainerStyle,
    boxBrandWrapper: boxBrandWrapperStyle,
    field: fieldStyle,
  } = useStyle();
  const {t} = useTranslations();
  const {usingTimezone} = useDate();

  const [versaceDates, setVersaceDates] = useState<ReservationDatesFields>(
    versace?.start_date && versace?.end_date
      ? {
          start_date: usingTimezone(versace.start_date, timezone),
          end_date: usingTimezone(versace.end_date, timezone),
        }
      : {start_date: null, end_date: null},
  );

  const [capriDates, setCapriDates] = useState<ReservationDatesFields>(
    capri?.start_date && capri?.end_date
      ? {
          start_date: usingTimezone(capri.start_date, timezone),
          end_date: usingTimezone(capri.end_date, timezone),
        }
      : {start_date: null, end_date: null},
  );

  const [guestsDates, setExternalDates] = useState<ReservationDatesFields>(
    guests?.start_date && guests?.end_date
      ? {
          start_date: usingTimezone(guests.start_date, timezone),
          end_date: usingTimezone(guests.end_date, timezone),
        }
      : {start_date: null, end_date: null},
  );

  useEffect(() => {
    changeInfoHandler({
      reservationOpenings: {
        versace: {
          start_date: versaceDates.start_date?.format() ?? '',
          end_date: versaceDates.end_date?.format() ?? '',
        },
        capri: {
          start_date: capriDates.start_date?.format() ?? '',
          end_date: capriDates.end_date?.format() ?? '',
        },
        guests: {
          start_date: guestsDates.start_date?.format() ?? '',
          end_date: guestsDates.end_date?.format() ?? '',
        },
      },
    });
  }, [capriDates, guestsDates, versaceDates]); // DO NOT update dependency with changeInfoHandler method

  const getMinDate = useCallback(
    (date?: moment.Moment | null | undefined) => {
      if (date) {
        return date;
      }

      if (eventStartDate) {
        return usingTimezone(eventStartDate, timezone);
      }
    },
    [eventStartDate, usingTimezone, timezone],
  );

  const dateChangeHandler = useCallback(
    (
      val: moment.Moment | null,
      type: 'versace' | 'capri' | 'guests',
      field: 'start_date' | 'end_date',
    ) => {
      switch (type) {
        case 'versace':
          setVersaceDates({...versaceDates, [field]: val});
          break;
        case 'capri':
          setCapriDates({...capriDates, [field]: val});
          break;
        case 'guests':
          setExternalDates({...guestsDates, [field]: val});
          break;
      }
    },
    [capriDates, guestsDates, versaceDates],
  );

  const maxDate = useMemo(() => {
    return moment.tz(eventEndDate, timezone);
  }, [eventEndDate, timezone]);

  return (
    <Box sx={boxContainerStyle}>
      <Box sx={boxBrandWrapperStyle}>
        <Typography variant={'body100Bold'}>
          {t('EVENT_CREATE_EDIT.RESERVATION_OPENINGS.VERSACE_LABEL')}
        </Typography>
        <DatePicker
          disabled={isLoading}
          label={t('EVENT_CREATE_EDIT.RESERVATION_OPENINGS.VERSACE_START_DATE')}
          onChange={val => dateChangeHandler(val, 'versace', 'start_date')}
          value={versaceDates.start_date}
          minDate={getMinDate()}
          maxDate={eventEndDate ? maxDate : undefined}
          sx={fieldStyle}
          timezone={timezone}
        />
        <DatePicker
          disabled={isLoading}
          label={t('EVENT_CREATE_EDIT.RESERVATION_OPENINGS.VERSACE_END_DATE')}
          onChange={val => dateChangeHandler(val, 'versace', 'end_date')}
          value={versaceDates.end_date}
          minDate={getMinDate(versaceDates.start_date)}
          maxDate={eventEndDate ? maxDate : undefined}
          sx={fieldStyle}
          timezone={timezone}
        />
      </Box>
      <Box sx={boxBrandWrapperStyle}>
        <Typography variant={'body100Bold'}>
          {t('EVENT_CREATE_EDIT.RESERVATION_OPENINGS.CAPRI_LABEL')}
        </Typography>
        <DatePicker
          disabled={isLoading}
          label={t('EVENT_CREATE_EDIT.RESERVATION_OPENINGS.CAPRI_START_DATE')}
          onChange={val => dateChangeHandler(val, 'capri', 'start_date')}
          value={capriDates.start_date}
          minDate={getMinDate()}
          maxDate={eventEndDate ? maxDate : undefined}
          sx={fieldStyle}
          timezone={timezone}
        />
        <DatePicker
          disabled={isLoading}
          label={t('EVENT_CREATE_EDIT.RESERVATION_OPENINGS.CAPRI_END_DATE')}
          onChange={val => dateChangeHandler(val, 'capri', 'end_date')}
          value={capriDates.end_date}
          minDate={getMinDate(capriDates.start_date)}
          maxDate={eventEndDate ? maxDate : undefined}
          sx={fieldStyle}
          timezone={timezone}
        />
      </Box>
      <Box sx={boxBrandWrapperStyle}>
        <Typography variant={'body100Bold'}>
          {t('EVENT_CREATE_EDIT.RESERVATION_OPENINGS.EXTERNAL_LABEL')}
        </Typography>
        <DatePicker
          disabled={isLoading}
          label={t(
            'EVENT_CREATE_EDIT.RESERVATION_OPENINGS.EXTERNAL_START_DATE',
          )}
          onChange={val => dateChangeHandler(val, 'guests', 'start_date')}
          value={guestsDates.start_date}
          minDate={getMinDate()}
          maxDate={eventEndDate ? maxDate : undefined}
          sx={fieldStyle}
          timezone={timezone}
        />
        <DatePicker
          disabled={isLoading}
          label={t('EVENT_CREATE_EDIT.RESERVATION_OPENINGS.EXTERNAL_END_DATE')}
          onChange={val => dateChangeHandler(val, 'guests', 'end_date')}
          value={guestsDates.end_date}
          minDate={getMinDate(guestsDates.start_date)}
          maxDate={eventEndDate ? maxDate : undefined}
          sx={fieldStyle}
          timezone={timezone}
        />
      </Box>
    </Box>
  );
};

export default ReservationOpenings;
