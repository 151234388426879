import {Box, Typography} from '@mui/material';
import {useTranslations} from '@vidiemme/react-i18n';

import {useStyle} from '@/organisms/PageNotFound/style';
import {Base} from '@/templates/Base';

const PageNotFound = () => {
  const {notFoundBox, notFoundMessage} = useStyle();
  const {t} = useTranslations();
  return (
    <Base>
      <Box sx={notFoundBox}>
        <Typography variant={'titleVersace1'}>404</Typography>
        <Typography variant={'titleVersace1'}>
          {t('PAGE_NOT_FOUND.PAGE_NOT_FOUND')}
        </Typography>
        <Typography variant={'bodyRegular'} sx={notFoundMessage}>
          {t('PAGE_NOT_FOUND.PAGE_NOT_FOUND_MESSAGE')}
        </Typography>
      </Box>
    </Base>
  );
};
export default PageNotFound;
