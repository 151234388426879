import {useMemo} from 'react';

import {useApi} from '@/store/api';

import type {
  InvitationsServerResponse,
  UseGetInvitations,
  UseRegisterInvitation,
} from './definitions';
import {normalizeInvitations} from './normalizers';

export const useGetInvitations: UseGetInvitations = () => {
  const {loading, error, response} = useApi<InvitationsServerResponse>(
    '/api/v1/users/me/invitations',
  );

  return {
    loading,
    error,
    response: normalizeInvitations(response),
  };
};

export const useRegisterInvitation: UseRegisterInvitation = ({
  userId,
  eventId,
}) => {
  const options = useMemo(
    () => ({
      req: {
        method: 'POST',
      },
      body: {
        event_id: eventId,
        user_id: userId,
      },
    }),
    [eventId, userId],
  );

  return useApi('/api/v1/invitations', options);
};
