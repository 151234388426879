import React, {useCallback} from 'react';
import {Link} from 'react-router-dom';

import {Box, Container, Typography} from '@mui/material';
import {useTranslations} from '@vidiemme/react-i18n';

import {Button} from '@/atoms/Button';
import {IconSVG} from '@/atoms/IconSVG';
import {useQueryString} from '@/hooks/queryString';
import {AnonymousRoutes} from '@/navigation/routes';
import {LoginSSO} from '@/organisms/LoginSSO';
import {SplashPage} from '@/organisms/SplashPage';
import {useStyle} from '@/pages/LoginEmployee/style';
import {useGetBrands} from '@/store/brand';

const LoginEmployee = () => {
  const {t} = useTranslations();

  const {
    loginEmployeeMainBox,
    loginEmployeeTitleTypography,
    loginEmployeeTitleBox,
    loginEmployeeSSOBox,
    loginEmployeeContainer,
    guestLoginButton,
    privacyBox,
  } = useStyle();

  const {response: brands} = useGetBrands();

  const qsEventID = useQueryString('event_id');

  const guestLoginButtonHandler = useCallback(() => {
    window.location.href = qsEventID
      ? `${AnonymousRoutes.LOGIN_EXTERNAL}?event_id=${qsEventID}`
      : AnonymousRoutes.LOGIN_EXTERNAL;
  }, [qsEventID]);

  return (
    <Container sx={loginEmployeeContainer} maxWidth={false}>
      <SplashPage />
      <Box sx={loginEmployeeMainBox}>
        <Box sx={loginEmployeeTitleBox}>
          <IconSVG icon={'logo-versace'} size={60} />
          <Typography
            variant={'titleVersace1'}
            sx={loginEmployeeTitleTypography}>
            {t('LOGIN_EMPLOYEE.E_PLANNING_TOOL')}
          </Typography>
          <Typography variant={'titleAktive2'}>
            {t('LOGIN_EMPLOYEE.LOGIN')}
          </Typography>
        </Box>
        <Box sx={loginEmployeeSSOBox}>
          {brands?.map(brand => {
            return <LoginSSO key={brand.id} brand={brand} />;
          })}
          <Button
            variant={'primary'}
            color={'default'}
            sx={guestLoginButton}
            onClick={guestLoginButtonHandler}>
            {t(`LOGIN_EMPLOYEE.LOGIN_GUEST`)}
          </Button>
        </Box>
        <Box>
          <Box sx={privacyBox}>
            <Link to={AnonymousRoutes.PRIVACY_POLICY}>
              <Typography variant={'bodyRegularLink'}>
                {t('NAVBAR.item_PRIVACY_POLICY')}
              </Typography>
            </Link>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default LoginEmployee;
