import {useCallback, useEffect, useState} from 'react';

import {Box, Typography, Backdrop} from '@mui/material';
import {useTranslations} from '@vidiemme/react-i18n';

import {Button} from '@/atoms/Button';
import {IconSVG} from '@/atoms/IconSVG';
import {PrivacyPolicy} from '@/pages/PrivacyPolicy';
import {theme} from '@/themes/default';

import {useStyle} from './style';
import {PolicyAccepted} from './type';

const PrivacyPolicyBanner = () => {
  const {
    boxContainerPrivacy,
    closePrivacyPolicy,
    boxContainerBanner,
    backdrop,
    buttonsBanner,
    buttonBanner,
  } = useStyle();
  const {t} = useTranslations();

  const [showPolicyBanner, setPolicyBanner] = useState<boolean>(false);
  const [showPrivacyPreview, setShowPrivacyPreview] = useState<boolean>(false);

  const retrievePolicyChoice = useCallback(async () => {
    const privacyChoice = localStorage.getItem(PolicyAccepted.NAME);
    setPolicyBanner(!privacyChoice);
    return Promise.resolve(privacyChoice);
  }, []);

  const storePolicyChoice = useCallback(async (item: string) => {
    localStorage.setItem(PolicyAccepted.NAME, item);

    return Promise.resolve();
  }, []);

  useEffect(() => {
    retrievePolicyChoice();
  }, [retrievePolicyChoice]);

  const buttonHandlerCallback = useCallback(async () => {
    await storePolicyChoice('1');
    setPolicyBanner(false);
  }, [storePolicyChoice]);

  const privacyPolicyPreviewHandler = useCallback(() => {
    setShowPrivacyPreview(showPreview => !showPreview);
  }, []);

  if (showPolicyBanner) {
    return (
      <>
        <Backdrop open={true} sx={backdrop} />
        {showPrivacyPreview ? (
          <Box sx={boxContainerPrivacy}>
            <Button
              onClick={privacyPolicyPreviewHandler}
              sx={closePrivacyPolicy}>
              <IconSVG icon="cross" size={20} color={theme.palette.black} />
            </Button>
            <PrivacyPolicy hideNavbar={true} hideFooter={true} />
          </Box>
        ) : (
          <Box sx={boxContainerBanner}>
            <Typography variant="bodyRegular">
              {t('PRIVACY_POLICY_BANNER.TEXT')}
            </Typography>
            <Box sx={buttonsBanner}>
              <Button
                variant={'secondary'}
                color={'default'}
                onClick={privacyPolicyPreviewHandler}
                sx={buttonBanner}>
                {t('NAVBAR.item_PRIVACY_POLICY')}
              </Button>
              <Button
                variant={'primary'}
                color={'default'}
                onClick={buttonHandlerCallback}
                sx={buttonBanner}>
                {t('PRIVACY_POLICY_BANNER.BUTTON_CONFIRM')}
              </Button>
            </Box>
          </Box>
        )}
      </>
    );
  }
  return null;
};

export default PrivacyPolicyBanner;
