import {useMemo} from 'react';

import {SxProps} from '@mui/material';

import {theme} from '@/themes/default';

import {IUseStyle} from './type';

export const useStyle = (): IUseStyle => {
  const boxContent: SxProps = useMemo(
    (): SxProps => ({
      display: 'flex',
      flexDirection: 'column',
      rowGap: '24px',
    }),
    [],
  );

  const boxResponsiveContent: SxProps = useMemo(
    (): SxProps => ({
      display: 'flex',
      flexDirection: 'column',
      rowGap: '24px',
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        columnGap: '30px',
      },
    }),
    [],
  );

  const midField: SxProps = useMemo(
    (): SxProps => ({
      [theme.breakpoints.up('md')]: {
        width: '33.3333333333%',
      },
    }),
    [],
  );

  const eventNameField: SxProps = useMemo(
    (): SxProps => ({
      '.MuiFormHelperText-root': {
        margin: 0,
        paddingLeft: '14px',
        paddingTop: '3px',
        backgroundColor: theme.palette.gray1,
      },
    }),
    [],
  );

  return {
    boxContent,
    boxResponsiveContent,
    midField,
    eventNameField,
  };
};
