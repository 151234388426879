import React, {useCallback, useEffect, useState} from 'react';

import {Box} from '@mui/material';
import {useTranslations} from '@vidiemme/react-i18n';

import {Button} from '@/atoms/Button';
import useDate from '@/hooks/date';
import {ExtraordinarySchedule} from '@/molecules/ExtraordinarySchedule';
import {DateSchedule, Interval} from '@/molecules/ExtraordinarySchedule/type';

import {useStyle} from './style';
import {EventExtraordinarySchedulesProps} from './type';

const EventExtraordinarySchedule = ({
  schedules,
  changeInfoHandler,
  isLoading,
  eventStartDate,
  eventEndDate,
  timezone = 'UTC',
  applyTimezone = true,
}: EventExtraordinarySchedulesProps) => {
  const {boxContainer: boxContainerStyle, addDateButton: addDateButtonStyle} =
    useStyle();
  const {usingTimezone} = useDate();
  const {t} = useTranslations();

  const [scheduleList, setScheduleList] = useState(() => {
    if (schedules) {
      /* Convert all string dates with moment type */
      return schedules.map(scheduleItem => {
        return {
          date: scheduleItem.date
            ? usingTimezone(scheduleItem.date, timezone)
            : null,
          intervals: scheduleItem.intervals.map(intervalItem => ({
            start_time: intervalItem.start_time
              ? applyTimezone
                ? usingTimezone(intervalItem.start_time, timezone)
                : usingTimezone(intervalItem.start_time)
              : null,
            end_time: intervalItem.end_time
              ? applyTimezone
                ? usingTimezone(intervalItem.end_time, timezone)
                : usingTimezone(intervalItem.end_time)
              : null,
          })),
        };
      });
    }
    /* Set default date schedule item */
    return [
      {
        date: null,
        intervals: [
          {
            start_time: null,
            end_time: null,
          },
        ],
      },
    ];
  });

  /* Update specific schedule with child changes and set new state updated */
  const scheduleChangeHandler = useCallback(
    (scheduleUpdated: DateSchedule, scheduleUpdateIndex: number) => {
      setScheduleList(
        scheduleList.map((schedule, scheduleIndex) => {
          if (scheduleIndex === scheduleUpdateIndex) {
            return {
              date: scheduleUpdated.date,
              intervals: scheduleUpdated.intervals.map(
                (scheduleUpdateInterval: Interval) => scheduleUpdateInterval,
              ),
            };
          }
          return schedule;
        }),
      );
    },
    [scheduleList],
  );

  const addScheduleHandler = useCallback(() => {
    setScheduleList([
      ...scheduleList,
      {
        date: null,
        intervals: [
          {
            start_time: null,
            end_time: null,
          },
        ],
      },
    ]);
  }, [scheduleList]);

  useEffect(() => {
    changeInfoHandler({
      extraordinarySchedules: scheduleList.map(scheduleItem => {
        return {
          date: scheduleItem.date?.format() ?? null,
          intervals: scheduleItem.intervals.map(scheduleItemInterval => {
            return {
              start_time:
                scheduleItemInterval.start_time?.utc().format() ?? null,
              end_time: scheduleItemInterval.end_time?.utc().format() ?? null,
            };
          }),
        };
      }),
    });
  }, [scheduleList]); // DO NOT update dependency with changeInfoHandler method

  return (
    <Box sx={boxContainerStyle}>
      {scheduleList.map((schedule, index) => (
        <ExtraordinarySchedule
          key={`schedule-${index}`}
          day={schedule}
          handleChange={item => scheduleChangeHandler(item, index)}
          isLoading={isLoading}
          eventStartDate={eventStartDate}
          eventEndDate={eventEndDate}
          timezone={timezone}
        />
      ))}
      {/* ADD BUTTON */}
      <Button
        disabled={isLoading}
        sx={addDateButtonStyle}
        variant={'primary'}
        color={'default'}
        onClick={addScheduleHandler}>
        {t('EVENT_CREATE_EDIT.EXTRAORDINARY_SCHEDULE.ADD_DATE')}
      </Button>
    </Box>
  );
};

export default EventExtraordinarySchedule;
