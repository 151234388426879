import {useMemo} from 'react';

import {SxProps} from '@mui/material';

import {theme} from '@/themes/default';

import {IUseStyle} from './type';

export const useStyle = (): IUseStyle => {
  const boxContainer: SxProps = useMemo(
    (): SxProps => ({
      display: 'flex',
      flexDirection: 'column',
      width: 'calc(100vw - 100px)', // 110 = 20*2 padding + 30*2 boxshadow
      [theme.breakpoints.up('md')]: {
        width: '450px',
      },
    }),
    [],
  );

  const titleBox: SxProps = useMemo(
    (): SxProps => ({marginBottom: '16px'}),
    [],
  );

  const contentBox: SxProps = useMemo(
    (): SxProps => ({
      display: 'flex',
      flexDirection: 'column',
      rowGap: '20px',
      marginBottom: '40px',
    }),
    [],
  );

  const buttonsBox: SxProps = useMemo(
    (): SxProps => ({
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      rowGap: '10px',
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        rowGap: '0px',
      },
    }),
    [],
  );

  const buttonElement: SxProps = useMemo(
    (): SxProps => ({
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '50%',
      },
    }),
    [],
  );

  return {
    boxContainer,
    titleBox,
    contentBox,
    buttonsBox,
    buttonElement,
  };
};
